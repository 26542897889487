import { t } from '@lingui/macro';
import { Tag } from 'antd';

import { HeaderComponent } from './Components/HeaderComponent';
import { NoImage } from '../../../../components/NoImage';
import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import styles from '../../Products/Products/Products.module.less';
import stores from '../../../../stores/index.mobx';
import { Partner } from '../../../../stores/Partner.mobx';
import PartnersView from '../../Products/Products/Components/PartnersView';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'stock',
		model: 'stock',
		view: {
			customDrawer: 'view/catalog/products',
		},
		edit: {},
		page: {
			shouldFetch: false,
			empty: {
				image: 'boxes',
				text: t`Нема артикала`,
			},
			searchable: true,
			searchPlaceholder: t`Претражи артикле`,
			table: {
				props: {
					scroll: { x: 1000 },
				},
				showActions: false,
				columns: () => [
					{
						title: '',
						dataIndex: 'coverImage',
						key: 'product.coverImage',
						width: 50,
						render: (image, record) => {
							return image ? (
								<>
									<img
										src={image.urls['64x64']}
										alt="Slika"
										className={styles.image}
									/>
								</>
							) : (
								<NoImage
									name={
										record?.product?.parent
											? `${record.product?.parent.name} ${record.product?.variantName}`
											: record.product?.name || ''
									}
									size="small"
								/>
							);
						},
					},
					{
						title: t`Шифра`,
						dataIndex: ['product', 'sku'],
						key: 'sku',
						width: 100,
						shouldLink: true,
					},
					{
						title: t`Артикал`,
						dataIndex: 'product',
						key: 'product',
						ellipsis: true,
						render: (product) => {
							return product?.parent ? (
								<>
									{product.parent.name}{' '}
									<Tag color="magenta" bordered={false}>
										{product.variantName}
									</Tag>
								</>
							) : (
								product.name
							);
						},
						shouldLink: true,
					},
					{
						title: t`Добављачи`,
						key: 'partners',
						dataIndex: 'partners',
						filters: () =>
							stores.partners.list?.map((partner: Partner) => ({
								text: partner.name,
								value: partner.id,
							})),
						filterMultiple: true,
						ellipsis: true,
						filterSearch: true,
						onFilter: (value: string, record) => {
							return (record?.partners || []).indexOf(value) > -1;
						},
						render: (text, record) => {
							return <PartnersView record={record.product} />;
						},
					},
					{
						title: t`Количина`,
						dataIndex: 'quantity',
						key: 'quantity',
						render: (quantity) => {
							return numberFormat(quantity, false, 3, false);
						},
						width: 100,
					},
				],
			},
			additionalButtons: <HeaderComponent />,
		},
	});
