import { Plural, Trans } from '@lingui/macro';
import {
	Badge,
	Button,
	Calendar as AntCalendar,
	DatePicker,
	Grid,
	List,
	Space,
	Spin,
} from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useImperativeHandle, useMemo } from 'react';
import { useWindowSize } from 'react-use';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import PeriodicReport from './Drawers/PeriodicReport';

import styles from './Calendar.module.less';
import ReceiptsDay from './Drawers/ReceiptsDay';
import { addToDrawersRegistry, useDrawer } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import StoreSelect from '../../Home/Components/StoreSelect';
import classNames from 'classnames';

function Calendar(undefined, ref) {
	const screens = Grid.useBreakpoint();
	const { height } = useWindowSize();

	const {
		dailyReceipts: { byDay, fetchAll, isFetching },
		stores: { currentStoreId, available },
	} = stores;

	const [currentDate, setCurrentDate] = useQueryParam('date', StringParam);
	const [, openReceiptsDay, , , , ReceiptsDayDrawer] =
		useDrawer('receipts-day');
	const [, openPeriodicReport, , , , PeriodicReportDrawer] =
		useDrawer('periodic-report');

	const StoreQueryParam = withDefault(StringParam, currentStoreId || 'all');
	const [currentStore, setCurrentStore] = useQueryParam(
		'store',
		StoreQueryParam
	);

	useEffect(() => {
		if (!currentDate) {
			setCurrentDate(dayjs().toISOString());
		} else {
			fetchAll(currentDate, currentStore);
		}
	}, [currentDate, currentStore, fetchAll, setCurrentDate]);

	const days = useMemo(() => {
		const tmp = [];
		let start = dayjs(currentDate).startOf('month');
		while (start.month() === dayjs(currentDate).month()) {
			const day = start.format('YYYY-MM-DD');
			const dailyReceipt = byDay[day];
			tmp.push({
				dailyReceipt,
				day: start.format('dddd, DD. MMMM'),
				amount: dailyReceipt?.summedAmount || 0,
				count: dailyReceipt?.receiptCount || 0,
				date: start.clone(),
			});
			start = start.add(1, 'day');
		}
		return tmp;
	}, [currentDate, byDay]);

	function onChange(date) {
		setCurrentDate(date.toISOString());
	}

	function onSelect(date) {
		const day = date.format('YYYY-MM-DD');
		const dailyReceipt = byDay[day];
		if (dailyReceipt) {
			openReceiptsDay(`${date.toISOString()},receipts,${currentStore}`);
		}
	}

	function onPanelChange(date) {
		setCurrentDate(date.toISOString());
	}

	function dateCellRender(date) {
		const day = date.format('YYYY-MM-DD');
		const dailyReceipt = byDay[day];
		if (dailyReceipt) {
			return (
				<>
					<Badge
						status={
							dayjs().format('YYYY-MM-DD') === day ? 'processing' : 'success'
						}
						color="green"
						text={numberFormat(dailyReceipt.summedAmount, screens.xl, 2, true)}
					/>
					{height > 520 && (
						<>
							<br />
							<small>
								<i className="fi fi-rr-receipt" />
							</small>{' '}
							{dailyReceipt.receiptCount}
						</>
					)}
				</>
			);
		}
		return (
			<Badge
				status="default"
				text={
					<span className={styles.noReceipts}>
						{numberFormat(0, screens.xl, 2, true)}
					</span>
				}
			/>
		);
	}

	const fullCellRender = (current, info) => {
		const day = current.format('YYYY-MM-DD');
		const today = dayjs().format('YYYY-MM-DD');
		const dailyReceipt = byDay[day];

		return (
			<div
				className={classNames(
					styles.dateCell,
					dailyReceipt && styles.hasReceipts,
					day === today && styles.today
				)}
			>
				<div className={styles.day}>{current.format('DD')}</div>
				{dailyReceipt && (
					<div className={styles.info}>
						<div>
							<i className="fi fi-rr-coins" />{' '}
							{numberFormat(dailyReceipt.summedAmount, screens.xl, 2, true)}
						</div>
						<div>
							<i className="fi fi-rr-receipt" />{' '}
							<Plural
								value={dailyReceipt.receiptCount}
								one="# рачун"
								few="# рачуна"
								other="# рачуна"
							/>
						</div>
					</div>
				)}

				{!dailyReceipt && (
					<div className={classNames(styles.info, styles.dimmed)}>
						<div>
							<i className="fi fi-rr-coins" />{' '}
							{numberFormat(0, screens.xl, 2, true)}
						</div>
						<div>
							<i className="fi fi-rr-receipt" /> <Trans>Нема рачуна</Trans>
						</div>
					</div>
				)}
			</div>
		);
	};

	useImperativeHandle(ref, () => ({
		renderHeader: () => (
			<Space>
				{available.length > 1 && screens.xl && (
					<>
						{screens.xxl && (
							<div>
								<Trans>Продајно место</Trans>
							</div>
						)}
						<StoreSelect
							value={currentStore}
							onChange={(value) => {
								setCurrentStore(value);
							}}
						/>
					</>
				)}

				<Button.Group>
					<Button
						icon={<i className="fi fi-rr-angle-left"></i>}
						onClick={() => onChange(dayjs(currentDate).subtract(1, 'month'))}
					/>
					<DatePicker
						className={styles.datePicker}
						onChange={onChange}
						picker="month"
						format="MM/YYYY"
						style={{ width: 110 }}
						allowClear={false}
						value={dayjs(currentDate)}
					/>
					<Button
						icon={<i className="fi fi-rr-angle-right"></i>}
						onClick={() => onChange(dayjs(currentDate).add(1, 'month'))}
					/>
				</Button.Group>
				<Button
					onClick={() => {
						openPeriodicReport();
					}}
				>
					<Trans>Периодични извештај</Trans>
				</Button>
			</Space>
		),
		getDocsLink: () =>
			'koriscenje/moduli-aplikacije/fiskalni-racuni/po-danima/',
	}));

	return (
		<>
			<Spin spinning={isFetching}>
				{screens.lg && height > 470 && (
					<AntCalendar
						className={styles.calendar}
						value={dayjs(currentDate)}
						headerRender={() => null}
						cellRender={dateCellRender}
						onSelect={onSelect}
						onPanelChange={onPanelChange}
						fullCellRender={fullCellRender}
					/>
				)}
				{(((screens.xs || screens.sm) && !screens.lg) || height <= 470) && (
					<List
						className={styles.list}
						dataSource={days}
						renderItem={(item) => (
							<List.Item
								onClick={() => {
									if (item.dailyReceipt) {
										openReceiptsDay(
											`${item.date.toISOString()},receipts,${currentStore}`
										);
									}
								}}
							>
								<List.Item.Meta
									avatar={item.day}
									description={
										<div
											className={`${styles.listButton} ${
												item.amount === 0 && styles.noReceipts
											}`}
										>
											<div>
												<i className="fi fi-rr-coins" />{' '}
												{numberFormat(item.amount, true, 2, true)}
											</div>
											<div>
												<i className="fi fi-rr-receipt" />{' '}
												{item.count > 0 ? (
													<Plural
														value={item.count}
														one="# рачун"
														few="# рачуна"
														other="# рачуна"
													/>
												) : (
													<Trans>Нема рачуна</Trans>
												)}
											</div>
										</div>
									}
								/>
								<Button
									onClick={() => {
										if (item.dailyReceipt) {
											openReceiptsDay(
												`${item.date.toISOString()},receipts,${currentStore}`
											);
										}
									}}
									type="link"
									className={item.amount === 0 && styles.noReceipts}
								>
									<i className="fi fi-rr-angle-right"></i>
								</Button>
							</List.Item>
						)}
					/>
				)}
			</Spin>
			<ReceiptsDayDrawer />
			<PeriodicReportDrawer />
		</>
	);
}

addToDrawersRegistry('receipts-day', ReceiptsDay);
addToDrawersRegistry('periodic-report', PeriodicReport);
export default () => ({
	Page: observer(Calendar, { forwardRef: true }),
});
