import { t, Trans } from '@lingui/macro';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { useCallback } from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { usePermissions } from '../../../../../../hooks/permissions';
import stores from '../../../../../../stores/index.mobx';

export default function WriteOffCreateButton() {
	const {
		writeOffs: { create, isCreating },
	} = stores;

	const [, openEditWriteOff] = useDrawer('edit/stocks/write-off');

	const createButtonHandler = useCallback(async () => {
		const response = await flowResult(
			create({
				date: dayjs(),
				items: [],
			})
		);

		openEditWriteOff(response.id);
	}, [create, openEditWriteOff]);

	const canCreateWriteOffPermission = usePermissions(
		'stocks',
		'write-off',
		'create'
	);

	if (!canCreateWriteOffPermission) {
		return null;
	}

	return (
		<Button
			type="primary"
			onClick={createButtonHandler}
			disabled={isCreating}
			loading={isCreating}
		>
			<Trans>Додај отпис</Trans>
		</Button>
	);
}
