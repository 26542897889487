import { t } from '@lingui/macro';
import { Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useKey, useThrottleFn } from 'react-use';
import { StringParam, useQueryParam } from 'use-query-params';

type Props = {
	searchPlaceholder?: string;
};

export function SearchInput({ searchPlaceholder = t`Претражи` }: Props) {
	const searchInputRef = useRef(null);
	const [searchQueryParam, setSearchQueryParam] = useQueryParam(
		'search',
		StringParam
	);
	const [searchValue, setSearchValue] = useState(searchQueryParam);
	const throttledValue = useThrottleFn((value) => value, 100, [searchValue]);

	useEffect(() => {
		setSearchQueryParam(throttledValue);
	}, [throttledValue, setSearchQueryParam]);

	useKey(
		(e) => {
			return e.key === 'f3' || ((e.ctrlKey || e.metaKey) && e.key === 'f');
		},
		(e) => {
			if (searchInputRef.current) {
				searchInputRef.current.focus();
				e.preventDefault();
			}
		},
		undefined,
		[]
	);
	return (
		<Input
			prefix={<i className="fi fi-rr-search"></i>}
			onChange={(e) => setSearchValue(e.target.value)}
			placeholder={searchPlaceholder}
			value={searchValue}
			ref={searchInputRef}
		/>
	);
}
