import { Table, Spin, Card, Button, Tag, Badge } from 'antd';
// import { Chart, Area, Point, Line } from 'bizcharts';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Product } from '../../../../../../stores/Product.mobx';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import numberFormat from '../../../../../../lib/numberFormat';
import { t } from '@lingui/macro';

import styles from '../../Products.module.less';
import { useDrawer } from '../../../../../../components/Page';
import {
	INVOICE_TYPE_FROM_STRING,
	PAYMENT_TYPE_FROM_STRING,
	TRANSACTION_TYPE_FROM_STRING,
	getPaymentTypeTextShort,
	getTransactionTypeText,
} from '../../../../../../constants/journal';
import { bignumber, evaluate } from 'mathjs';
import classNames from 'classnames';
import {
	InvoiceType,
	TransactionType,
} from '../../../../../../constants/invoice';
import round from 'lodash/round';
interface Props {
	product?: Product;
}

const SalesViewBase = (props: Props) => {
	const { product } = props;

	const PAYMENT_TYPE_TEXT_SHORT = getPaymentTypeTextShort();
	const TRANSACTION_TYPE_TEXT = getTransactionTypeText();

	const [sales, setSales] = useState([]);

	useEffect(() => {
		flowResult(product?.sales?.fetchAll(product.id, 5))?.then((data) => {
			setSales(data);
		});
	}, [product]);

	const [, openReceipt, , , , ReceiptViewDrawer] = useDrawer(
		'view/receipts/list|sales-view'
	);
	return (
		<>
			<Spin spinning={product?.sales?.isFetching}>
				<Card className={classNames(styles.card, styles.tableCard)}>
					<Table
						size="small"
						dataSource={sales || []}
						scroll={{ x: 1700 }}
						columns={[
							{
								title: t`Датум и време`,
								key: 'receipt.sdcTime',
								render(record) {
									return dayjs(record.receipt.sdcTime).format(
										'DD.MM.YYYY HH:mm:ss'
									);
								},
								width: 160,
							},
							{
								title: t`Количина`,
								dataIndex: 'quantity',
								key: 'quantity',
								width: 100,
								render(text) {
									return numberFormat(text, false, 3, false);
								},
							},
							{
								title: t`Износ`,
								key: 'totalAmount',
								render(record) {
									return numberFormat(
										evaluate('quantity * unitPrice', {
											quantity: bignumber(record.quantity),
											unitPrice: bignumber(record.unitPrice),
										}).toNumber(),
										true,
										2,
										true
									);
								},
								width: 150,
							},
							{
								title: t`Број рачуна`,
								key: 'receipt.invoiceNumber',
								render: (record) => (
									<Button
										type="link"
										className={styles.columnLink}
										onClick={() => openReceipt(record.receipt.id)}
									>
										{record.receipt.invoiceNumber}
									</Button>
								),
							},
							{
								title: t`Статус`,
								width: 200,
								key: 'status',
								render: (receiptItem) => {
									const record = receiptItem.receipt;
									if (record.void) {
										return <Badge status="error" text={t`Поништен`} />;
									}

									if (record.voids) {
										return (
											<Badge status="warning" text={t`Поништава реф. док.`} />
										);
									}

									if (
										[
											InvoiceType.NORMAL,
											InvoiceType.PROFORMA,
											InvoiceType.TRAINING,
										].includes(INVOICE_TYPE_FROM_STRING[record.invoiceType])
									) {
										const hasRefunds =
											record.connectedReceipts.some(
												(receipt) =>
													TRANSACTION_TYPE_FROM_STRING[
														receipt.transactionType
													] === TransactionType.REFUND && !receipt.void
											) &&
											TRANSACTION_TYPE_FROM_STRING[record.transactionType] ===
												TransactionType.SALE;

										if (hasRefunds) {
											const filteredItems = record.connectedReceipts?.filter(
												(cr) =>
													TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
														TransactionType.REFUND &&
													[
														InvoiceType.NORMAL,
														InvoiceType.PROFORMA,
														InvoiceType.TRAINING,
													].includes(
														INVOICE_TYPE_FROM_STRING[cr.invoiceType]
													) &&
													!cr.void &&
													!cr.voids
											);
											// 		console.log(filteredItems);
											const refundedItems = (filteredItems || []).reduce(
												(acc, cur) =>
													acc +
													(cur.receiptItems || []).reduce(
														(iacc, icur) => iacc + icur.quantity,
														0
													),
												0
											);
											const items = (record.receiptItems || []).reduce(
												(iacc, icur) => iacc + icur.quantity,
												0
											);

											const remainingItems = refundedItems - items;

											if (round(remainingItems, 2) === 0) {
												return <Badge color="gold" text={t`Рефундиран`} />;
											} else if (refundedItems > 0) {
												return (
													<Badge color="gold" text={t`Делимично рефундиран`} />
												);
											}
										}
									}

									return <Badge status="success" text={t`Издат`} />;
								},
							},

							{
								title: t`Врста трансакције`,
								ellipsis: true,
								key: 'transactionType',
								dataIndex: ['receipt', 'transactionType'],
								render(text) {
									return TRANSACTION_TYPE_TEXT[
										TRANSACTION_TYPE_FROM_STRING[text]
									];
								},
								width: 150,
							},
							{
								title: t`Начини плаћања`,
								key: 'receipt.paymentType',
								width: 200,
								render(record) {
									return record.receipt.payment.map((payment) => (
										<Tag
											bordered={false}
											title={numberFormat(payment.amount, false, 2, true)}
										>
											{
												PAYMENT_TYPE_TEXT_SHORT[
													PAYMENT_TYPE_FROM_STRING[payment.paymentType]
												]
											}
										</Tag>
									));
								},
							},

							{
								title: t`Касир`,
								dataIndex: ['receipt', 'cashier', 'fullName'],
								key: 'cashierId',
								ellipsis: true,
							},
							{
								title: t`Продајно место`,
								dataIndex: ['receipt', 'store', 'name'],
								ellipsis: true,
								key: 'storeId',
							},
						]}
						pagination={false}
					/>
				</Card>
			</Spin>
			<ReceiptViewDrawer location="sales-view" />
		</>
	);
};

export const SalesView = observer(SalesViewBase);
