import { Plural, t, Trans } from '@lingui/macro';
import { Button, Form, Modal, Select } from 'antd';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import stores from '../../../../stores/index.mobx';
import { Customer } from '../../../../stores/LocalSale.mobx';

interface Props {
	visible: boolean;
	onClose: () => void;
	onSubmit: (type: 'stickers' | 'points', quantity: number) => void;
	customer: Customer;
}

function UseStickersModal({ visible, onClose, onSubmit, customer }: Props) {
	const {
		mojBadi: {
			settings: { loyaltyConfiguration },
		},
	} = stores;

	const [form] = Form.useForm();
	const inputRef = useRef(null);

	const submit = useCallback(() => {
		form.validateFields().then((values) => {
			onSubmit('stickers', Number(values.quantity));
			onClose();
		});
	}, [form, onClose, onSubmit]);

	useEffect(() => {
		if (visible) {
			const intervalId = setInterval(() => {
				if (inputRef.current && inputRef.current !== document.activeElement) {
					inputRef.current?.focus();
				}
			}, 100);
			return () => {
				clearInterval(intervalId);
			};
		} else {
			form.resetFields();
		}
	}, [visible, form]);

	const initialValue = useMemo(() => {
		if (!customer || !loyaltyConfiguration) {
			return 0;
		}

		return loyaltyConfiguration.stickerRules
			.filter((rule) => rule.stickerCount <= customer.stickers.length)
			.map((rule) => rule.stickerCount)
			.reduce((maxValue, value) => (value > maxValue ? value : maxValue), 0);
	}, [customer, loyaltyConfiguration]);

	return (
		<Modal
			centered
			open={visible}
			title={t`Одаберите број налепница`}
			onCancel={onClose}
			width={400}
			footer={
				<Button type="primary" onClick={submit}>
					<Trans>Потврди</Trans>
				</Button>
			}
		>
			<Form form={form} validateTrigger="onFinish" layout="vertical">
				<Form.Item
					style={{ marginBottom: 0 }}
					name="quantity"
					label={t`Број налепница`}
					rules={[
						{
							required: true,
							message: t`Одаберите број налепница`,
						},
					]}
					initialValue={initialValue}
				>
					<Select ref={inputRef} placeholder={t`Одаберите број налепница`}>
						<Select.Option value={0}>
							<Trans>Купац не жели да користи налепнице</Trans>
						</Select.Option>
						{loyaltyConfiguration.stickerRules.map((sticker) => (
							<Select.Option
								key={sticker.stickerCount}
								value={sticker.stickerCount}
								disabled={sticker.stickerCount > customer?.stickers?.length}
							>
								<Plural
									value={sticker.stickerCount}
									zero={
										<span>
											{sticker.stickerCount} налепница ({sticker.discount}%
											попуста)
										</span>
									}
									one={
										<span>
											{sticker.stickerCount} налепница ({sticker.discount}%
											попуста)
										</span>
									}
									two={
										<span>
											{sticker.stickerCount} налепнице ({sticker.discount}%
											попуста)
										</span>
									}
									other={
										<span>
											{sticker.stickerCount} налепница ({sticker.discount}%
											попуста)
										</span>
									}
								/>
							</Select.Option>
						))}
					</Select>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default memo(UseStickersModal);
