import { Select } from 'antd';
import { observer } from 'mobx-react-lite';
import cyr2lat from 'cyrillic-to-latin';

import stores from '../../stores/index.mobx';

function UnitSelect(props) {
	const {
		units: { available, isFetching },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) =>
				cyr2lat(option.children.toLowerCase()).includes(
					cyr2lat(input.toLowerCase())
				)
			}
			loading={isFetching}
			popupMatchSelectWidth={false}
			{...props}
		>
			{(available || []).map((unit) => (
				<Select.Option key={unit.id} value={unit.id}>
					{unit.label}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(UnitSelect);
