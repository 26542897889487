import round from 'lodash/round';
import stores from '../stores/index.mobx';
import { TAX_FREE_LABEL } from '../constants/invoice';
import {
	INVOICE_TYPE_FROM_STRING,
	InvoiceTypeAPI,
	TRANSACTION_TYPE_FROM_STRING,
	TransactionTypeAPI,
} from '../constants/journal';
import clamp from 'lodash/clamp';
import BadRequestError from './exceptions/BadRequestError';
import dayjs from 'dayjs';
import NotFoundError from './exceptions/NotFoundError';
import { bignumber, evaluate } from 'mathjs';
export function getPaymentsSum(payments: Record<string, number>): number {
	return Object.values(payments || {}).reduce(
		(sum, payment) =>
			round(
				evaluate('sum + payment', {
					sum: bignumber(sum),
					payment: bignumber(payment),
				}).toNumber(),
				2
			),
		0
	);
}

export function getDiscountedAmount(items) {
	return round(
		items.reduce((prev, curr) => {
			return round(
				evaluate('prev + unitPrice * quantity', {
					prev: bignumber(prev),
					unitPrice: bignumber(curr.unitPrice),
					quantity: bignumber(round(curr.quantity, 3)),
				}).toNumber(),
				2
			);
		}, 0),
		2
	);
}

export function fiscalizationItemsToInvoiceRequestItems(
	items,
	discount = 0,
	taxFree = false
) {
	if (!items || items.length === 0) {
		return [];
	}

	const { products } = stores;

	return items.map((item) => {
		const product = products.bySku[item.sku];

		if (!product) {
			throw new NotFoundError(NotFoundError.ERROR_NOT_FOUND_PRODUCT, {
				sku: item.sku,
			});
		}
		const taxRateLabel = product.parentId
			? product.parent.taxRateLabel
			: product.taxRateLabel;

		const unitPrice = item.unitPrice || product.currentStorePrice;

		const baseDiscountedPrice = round(
			evaluate('unitPrice - (unitPrice * discount) / 100', {
				unitPrice: bignumber(unitPrice),
				discount: bignumber(item.discount || 0),
			}).toNumber(),
			2
		);

		const totalDiscountedPrice = round(
			evaluate('baseDiscountedPrice - (baseDiscountedPrice * discount) / 100', {
				baseDiscountedPrice: bignumber(baseDiscountedPrice),
				discount: bignumber(discount || 0),
			}).toNumber(),
			2
		);

		return {
			productId: product.parentId ? product.parent.id : product.id,
			variantId: product.parentId ? product.id : null,
			product: product.parentId ? product.parent.toPlain() : product.toPlain(),
			variant: product.parentId ? product.toPlain() : null,
			taxRateLabel: taxFree ? TAX_FREE_LABEL : taxRateLabel,
			quantity: item.quantity,
			finalPrice: totalDiscountedPrice,
			unitPrice: totalDiscountedPrice,
			discount: round(
				evaluate('unitPrice - totalDiscountedPrice', {
					unitPrice: bignumber(unitPrice),
					totalDiscountedPrice: bignumber(totalDiscountedPrice),
				}).toNumber(),
				2
			),
		};
	});
}

export function isTaxFree(buyerCostCenterId) {
	const [buyerCostCenterIdType] = (buyerCostCenterId || '').split(':');

	return ['20', '21', '30', '31', '32', '33'].includes(buyerCostCenterIdType);
}

export function getChangeAmount(
	transactionType,
	payments,
	discountedAmount,
	advanceAmount = 0,
	unknownAmountAdvance = false
) {
	if (unknownAmountAdvance || transactionType === TransactionTypeAPI.REFUND) {
		return 0;
	}
	return clamp(
		round(
			evaluate('paymentsSum + advanceAmount - discountedAmount', {
				paymentsSum: bignumber(getPaymentsSum(payments)),
				advanceAmount: bignumber(advanceAmount),
				discountedAmount: bignumber(discountedAmount),
			}).toNumber(),
			2
		),
		0,
		Infinity
	);
}

export function getTotalsByLabel(items): Record<string, number> {
	return items.reduce((acc, curr) => {
		acc[curr.taxRateLabel] = round(
			evaluate('acc + unitPrice', {
				acc: bignumber(acc[curr.taxRateLabel] || 0),
				unitPrice: bignumber(curr.unitPrice),
			}).toNumber(),
			2
		);

		return acc;
	}, {});
}

export function getAdvancePaymentsByLabel(
	mode,
	unknownAmountAdvance,
	discountedAmount,
	paymentsSum,
	changeAmount,
	items,
	advanceSpecification: Record<string, number> = null,
	advanceReceipt = null
) {
	const totalsByLabel = getTotalsByLabel(items);

	if (unknownAmountAdvance) {
		const labels = Object.keys(totalsByLabel);
		if (labels.length > 1 && !advanceSpecification) {
			throw new BadRequestError(
				BadRequestError.ERROR_BAD_REQUEST_ADVANCE_SPECIFICATION_MISSING
			);
		}
		const finalPrice = round(
			evaluate('paymentsSum - changeAmount', {
				paymentsSum: bignumber(paymentsSum),
				changeAmount: bignumber(changeAmount),
			}).toNumber(),
			2
		);

		if (labels.length === 1) {
			return [
				{
					finalPrice,
					taxRateLabel: labels[0],
					quantity: 1,
					isAdvance: true,
				},
			];
		}

		if (
			labels.length !== Object.keys(advanceSpecification).length ||
			finalPrice !==
				Object.values(advanceSpecification).reduce(
					(acc, curr) =>
						round(
							evaluate('acc + curr', {
								acc: bignumber(acc),
								curr: bignumber(curr),
							}).toNumber(),
							2
						),
					0
				)
		) {
			throw new BadRequestError(
				BadRequestError.ERROR_BAD_REQUEST_ADVANCE_SPECIFICATION_MISMATCH
			);
		}

		return Object.entries(advanceSpecification).map(
			([taxRateLabel, amount]) => ({
				finalPrice: amount,
				taxRateLabel,
				quantity: 1,
				isAdvance: true,
			})
		);
	}

	const advanceItemsSum =
		mode === 'normal'
			? discountedAmount
			: advanceReceipt.advanceItems?.reduce(
					(prev, curr) =>
						round(
							evaluate('prev + unitPrice * quantity', {
								prev: bignumber(prev),
								unitPrice: bignumber(curr.unitPrice),
								quantity: bignumber(curr.quantity),
							}).toNumber(),
							2
						),
					0
			  );
	return Object.entries(totalsByLabel).map(([label, amount]) => ({
		finalPrice: round(
			evaluate('(amount * (paymentsSum - changeAmount)) / advanceItemsSum', {
				amount: bignumber(Number(amount)),
				paymentsSum: bignumber(paymentsSum),
				changeAmount: bignumber(changeAmount),
				advanceItemsSum: bignumber(advanceItemsSum),
			}).toNumber(),

			2
		),
		taxRateLabel: label,
		quantity: 1,
		isAdvance: true,
	}));
}

export function formatReceiptDelivery(receiptDelivery) {
	return {
		...receiptDelivery,
		thermal: receiptDelivery.thermalPrinter,
		thermalPrinter: undefined,
	};
}

export function convertData(values) {
	return {
		...values,
		invoiceType: INVOICE_TYPE_FROM_STRING[values.invoiceType],
		transactionType: TRANSACTION_TYPE_FROM_STRING[values.transactionType],
	};
}

export function getFirstAdvanceSaleReceipt(connectedReceipts) {
	return connectedReceipts.find(
		(cr) =>
			cr.invoiceType === InvoiceTypeAPI.ADVANCE &&
			cr.transactionType === TransactionTypeAPI.SALE &&
			!cr.void &&
			!cr.voids
	);
}

export function getAdvanceAmount(connectedReceipts) {
	return round(
		connectedReceipts
			.filter(
				(cr) =>
					cr.invoiceType === InvoiceTypeAPI.ADVANCE && !cr.void && !cr.voids
			)
			.reduce((acc, curr) => {
				const paymentTotal =
					curr.transactionType === TransactionTypeAPI.SALE
						? curr.paymentTotal
						: -curr.paymentTotal;

				return round(
					evaluate('acc + paymentTotal - paymentChange', {
						acc: bignumber(acc),
						paymentTotal: bignumber(paymentTotal),
						paymentChange: bignumber(curr.paymentChange),
					}).toNumber(),
					2
				);
			}, 0),
		2
	);

	// TODO handle incomplete closings (last is refund)
}

export function getAdvanceItemsSum(advanceItems) {
	return advanceItems.reduce(
		(prev, curr) =>
			round(
				evaluate('prev + totalAmount', {
					prev: bignumber(prev),
					totalAmount: bignumber(curr.totalAmount),
				}).toNumber(),
				2
			),
		0
	);
}

export function getLastAdvanceReceipt(connectedReceipts) {
	return connectedReceipts.findLast(
		(cr) => cr.invoiceType === InvoiceTypeAPI.ADVANCE && !cr.void && !cr.voids
	);
}

export function getLastAdvanceSaleReceipt(connectedReceipts) {
	return connectedReceipts.findLast(
		(cr) =>
			cr.invoiceType === InvoiceTypeAPI.ADVANCE &&
			cr.transactionType === TransactionTypeAPI.SALE &&
			!cr.void &&
			!cr.voids
	);
}

export function getTotalsByLabelUnknown(connectedReceipts) {
	return connectedReceipts.reduce((acc, curr) => {
		curr.receiptItems.forEach((item) => {
			const label = item.taxLabels[0];
			acc[label] = round(
				evaluate('acc + unitPrice * quantity', {
					acc: bignumber(acc[label] || 0),
					unitPrice: bignumber(
						curr.transactionType === TransactionTypeAPI.SALE
							? item.unitPrice
							: -item.unitPrice
					),
					quantity: bignumber(item.quantity),
				}).toNumber(),
				2
			);
		});

		return acc;
	}, {});
}

export function getAdvancePaymentsSum(receipt) {
	return receipt.connectedReceipts
		?.filter(
			(r) =>
				r.invoiceType === InvoiceTypeAPI.ADVANCE &&
				dayjs(r.sdcTime).isBefore(receipt.sdcTime) &&
				r.invoiceNumber !== receipt.referentDocumentNumber
		)
		.reduce((acc, cur) => {
			const paymentTotal =
				cur.transactionType === TransactionTypeAPI.SALE
					? cur.paymentTotal
					: -cur.paymentTotal;
			return round(
				evaluate('acc + paymentTotal - paymentChange', {
					acc: bignumber(acc),
					paymentTotal: bignumber(paymentTotal),
					paymentChange: bignumber(cur.paymentChange),
				}).toNumber(),
				2
			);
		}, 0);
}
