import { Plural, Trans, t } from '@lingui/macro';
import { Dropdown, MenuProps } from 'antd';
import { useCallback, useMemo, useRef, useState } from 'react';
import stores from '../../../../../../stores/index.mobx';
import jszip from 'jszip';
import { saveAs } from 'file-saver';
import styles from '../EditButtons/EditButtons.module.less';
import CalculationPrint from '../CalculationPrint/CalculationPrint';
import htmlToPdf from '../../../../../../lib/htmlToPdf';
import { flushSync } from 'react-dom';
import cyr2lat from 'cyrillic-to-latin';
import { flowResult } from 'mobx';
import { ArrayParam, useQueryParams } from 'use-query-params';
import ExcelJS from 'exceljs';
import round from 'lodash/round';
import { StockTurnoverStatus } from '../../../../../../stores/StockTurnover.mobx';
type CalculationDownloadButtonProps = {
	selected: string[];
	allSelected: boolean;
};

export default function CalculationDownloadButton({
	selected,
	allSelected,
}: CalculationDownloadButtonProps) {
	const [isLoading, setIsLoading] = useState(false);
	const printAreaRef = useRef<HTMLDivElement>(null);
	const [printData, setPrintData] = useState<any>(null);
	const {
		calculations: { getById },
		warehouses: { getById: getWarehouseById },
		partners: { getById: getPartnerById },
	} = stores;

	const menuItems: MenuProps['items'] = useMemo(
		() => [
			{
				key: 'xlsxReport',
				label: t`Преузми извештај у XLSX формату`,
			},
		],
		[]
	);

	const [filterQueryParams] = useQueryParams({
		date: ArrayParam,
		partnerId: ArrayParam,
		status: ArrayParam,
	});

	const menuProps = useMemo(
		() => ({
			items: menuItems,
			onClick: async ({ key }) => {
				if (key === 'xlsxReport') {
					setIsLoading(true);
					const data = !allSelected
						? selected.map((id) => getById(id))
						: await flowResult(
								stores.calculations.fetchAllNotPaginated(filterQueryParams)
						  );
					const workbook = new ExcelJS.Workbook();
					workbook.creator = t`Бади`;
					workbook.created = new Date();
					workbook.modified = new Date();
					const worksheet = workbook.addWorksheet(t`Извештај калкулација`);

					worksheet.getColumn(1).width = 12;
					worksheet.getColumn(2).width = 12;
					worksheet.getColumn(3).width = 20;
					worksheet.getColumn(4).width = 20;
					worksheet.getColumn(5).width = 30;
					worksheet.getColumn(6).width = 15;
					worksheet.getColumn(7).width = 8;
					worksheet.getColumn(8).width = 50;
					worksheet.getColumn(9).width = 10;
					worksheet.getColumn(10).width = 5;
					worksheet.getColumn(11).width = 15;
					worksheet.getColumn(12).width = 15;
					worksheet.getColumn(13).width = 15;
					worksheet.getColumn(14).width = 8;
					worksheet.getColumn(15).width = 15;
					worksheet.getColumn(16).width = 15;

					worksheet.addTable({
						name: `calculationsReport`,
						headerRow: true,
						totalsRow: false,
						ref: `A1`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: t`Датум` },
							{ name: t`Статус` },
							{ name: t`Број фактуре` },
							{ name: t`Број отпремнице` },
							{ name: t`Добављач` },
							{ name: t`Складиште` },
							{ name: t`Шифра` },
							{ name: t`Назив артикла` },
							{ name: t`Количина` },
							{ name: t`ЈМ` },
							{ name: t`Набавна цена` },
							{ name: t`Продајна цена` },
							{ name: t`Разлика у цени` },
							{ name: t`ПДВ` },
							{ name: t`Износ ПДВ` },
							{ name: t`Продајна цена са ПДВ` },
						],
						rows: data.reduce((acc, calculation) => {
							const rows = calculation.items.map((item) => [
								calculation.date?.format?.('DD.MM.YYYY') || '',
								calculation.stockTurnoverItem.status ===
								StockTurnoverStatus.DRAFT
									? t`Нацрт`
									: t`Потврђена`,
								calculation.invoice?.number || '',
								calculation.invoice?.deliveryNoteNumber || '',
								getPartnerById(calculation.partnerId)?.name,
								getWarehouseById(item.warehouseId)?.name,
								Number(item.productSku),
								item.productName ||
									(item.product?.parent
										? `${item.product?.parent?.name} ${item.product?.variantName}`
										: item.product?.name),
								round(item.quantity, 3),
								item.productUnit,
								round(item.purchasePrice, 4),
								round(item.salePrice, 4),
								round(item.priceDifference, 4),
								`${round(item.taxRate, 2)}%`,
								round(item.taxAmount, 4),
								round(item.salePriceWithVat, 4),
							]);
							return [...acc, ...rows];
						}, []),
					});
					workbook.xlsx.writeBuffer().then((buffer) => {
						const blob = new Blob([buffer], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						saveAs(
							blob,
							`${cyr2lat(t`Калкулације`)}-${new Date().toISOString()}.xlsx`
						);
					});

					setIsLoading(false);
				}
			},
		}),
		[
			allSelected,
			filterQueryParams,
			getById,
			getPartnerById,
			getWarehouseById,
			menuItems,
			selected,
		]
	);

	const download = useCallback(async () => {
		setIsLoading(true);
		const data = !allSelected
			? selected.map((id) => getById(id))
			: await flowResult(
					stores.calculations.fetchAllNotPaginated(filterQueryParams)
			  );
		const pdfs = [];
		await data.reduce(async (previousPromise, calculation) => {
			await previousPromise;
			flushSync(() => {
				setPrintData({
					calculation: calculation,
					invoice: calculation.invoice,
				});
			});

			const filename = `${calculation.number.replaceAll('/', '-')}.pdf`;

			const doc = await htmlToPdf(printAreaRef.current, {
				jsPDFOptions: {
					format: 'a4',
					orientation: 'portrait',
					unit: 'cm',
				},
				html2canvasOptions: {
					scale: 2,
				},
			});
			pdfs.push([doc.output('blob'), filename]);
		}, Promise.resolve());

		if (selected.length === 1 && !allSelected) {
			saveAs(pdfs[0][0], pdfs[0][1]);
		} else {
			const zip = new jszip();
			pdfs.forEach((pdf) => {
				zip.file(pdf[1], pdf[0]);
			});
			const content = await zip.generateAsync({ type: 'blob' });
			saveAs(
				content,
				`${cyr2lat(t`Калкулације`)}-${new Date().toISOString()}.zip`
			);
		}
		setIsLoading(false);
	}, [allSelected, selected, filterQueryParams, getById]);

	return (
		<>
			<Dropdown.Button
				type="default"
				onClick={download}
				disabled={isLoading}
				loading={isLoading}
				menu={menuProps}
			>
				{allSelected && <Trans>Преузми све калкулације</Trans>}
				{!allSelected && (
					<Plural
						value={selected.length}
						one="Преузми # калкулацију"
						few="Преузми # калкулације"
						other="Преузми # калкулација"
					/>
				)}
			</Dropdown.Button>
			<div
				className={styles.printArea}
				style={{
					display: 'block',
					left: '-10000px',
					position: 'absolute',
				}}
			>
				<div
					ref={printAreaRef}
					id="printarea"
					style={{
						width: '210mm',
						padding: '1cm',
					}}
				>
					{printData && (
						<CalculationPrint {...printData} invoice={printData?.invoice} />
					)}
				</div>
			</div>
		</>
	);
}
