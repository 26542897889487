import { t } from '@lingui/macro';
import { Badge, DatePicker, Input } from 'antd';
import dayjs from 'dayjs';
import cyr2lat from 'cyrillic-to-latin';

import CalculationsItemsInput from './Components/CalculationItems';
import CalculationCreateButton from './Components/CreateButton';
import { EditButtons } from './Components/EditButtons';
import { CreatePage } from '../../../../components/Page';
import PartnerSelect from '../../../../components/Selects/PartnerSelect';
import numberFormat from '../../../../lib/numberFormat';
import { Calculation } from '../../../../stores/Calculation.mobx';
import { StockTurnoverStatus } from '../../../../stores/StockTurnover.mobx';
import { InvoiceSelect } from '../../Documents/OutgoingInvoices/Components/InvoiceSelect';
import CalculationDownloadButton from './Components/DownloadButton';
import { DateRangeFilter } from '../../../../components/Filters/DateRange';
import stores from '../../../../stores/index.mobx';
import { Partner } from '../../../../stores/Partner.mobx';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'calculation',
		model: 'calculation',
		view: {
			useEdit: true,
		},
		edit: {
			width: 1800,
			beforeSave: (entity) => {
				return {
					...entity,
					items: entity.items.filter(
						(item) => item.warehouseId && item.productId && item.quantity
					),
					number: undefined,
				};
			},
			disabled: (record: Calculation) => {
				return (
					record?.stockTurnoverItem?.status === StockTurnoverStatus.CONFIRMED
				);
			},
			disabledReason: (entity) => {
				return t`Калкулација је потврђена и није могуће вршити измене.`;
			},
			title: {
				new: t`Додавање калкулације`,
				existing: (entity) => t`Измена калкулације ${entity?.number || ''}`,
			},
			shouldFetch: true,
			buttons: (props) => <EditButtons {...props} />,
			fields: (record, form, setFields) => {
				return [
					{
						key: 'basic',
						label: t`Основне информације`,
						fields: [
							{
								key: 'number',
								label: t`Број калкулације`,
								xs: 24,
								sm: 4,
								component: <Input disabled />,
							},
							{
								key: 'date',
								label: t`Датум`,
								xs: 24,
								sm: 4,
								component: <DatePicker style={{ width: '100%' }} format="L" />,
								initialValue: dayjs(),
								rules: [
									{
										required: true,
										message: t`Датум је обавезан`,
									},
								],
							},
							{
								key: 'partnerId',
								label: t`Добављач`,
								xs: 24,
								sm: 8,
								component: <PartnerSelect />,
								rerenderOnChange: true,
							},
							{
								key: 'invoiceId',
								label: t`Фактура`,
								xs: 24,
								sm: 4,
								component: (
									<InvoiceSelect
										direction="incoming"
										types={['invoice']}
										partnerId={form.getFieldValue('partnerId')}
										hideNotInSystem
									/>
								),
							},
							{
								key: 'deliveryNoteNumber',
								label: t`Отпремница`,
								xs: 24,
								sm: 4,
								component: <Input />,
							},
						],
					},
					{
						key: 'items',
						label: t`Ставке калкулације`,
						fields: [
							{
								key: 'items',
								component: (
									<CalculationsItemsInput
										partnerId={form.getFieldValue('partnerId')}
									/>
								),
								span: 24,
							},
						],
					},
				];
			},
		},
		page: {
			shouldFetch: true,
			searchable: false,
			empty: {
				image: 'calculator',
				text: t`Нема калкулација`,
			},
			createButtonText: t`Додај калкулацију`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете ову калкулацију?`,
			table: {
				selectable: true,
				selectedActions: (props) => <CalculationDownloadButton {...props} />,
				props: {
					scroll: { x: 800 },
				},
				columns: () => [
					{
						title: t`Број`,
						dataIndex: 'number',
						key: 'number',
						width: 120,
						shouldLink: true,
					},
					{
						title: t`Статус`,
						dataIndex: ['stockTurnoverItem', 'status'],
						key: 'status',
						render: (status) => {
							if (status === StockTurnoverStatus.DRAFT) {
								return <Badge color="lightGrey" text={t`Нацрт`} />;
							}

							if (status === StockTurnoverStatus.CONFIRMED) {
								return <Badge color="green" text={t`Потврђена`} />;
							}
						},
						width: 120,
						filterKey: 'status',
						filterSearch: (input, record) => {
							return cyr2lat(record.text.toLowerCase()).includes(
								cyr2lat(input.toLowerCase())
							);
						},
						filters: [
							{
								text: t`Нацрт`,
								value: StockTurnoverStatus.DRAFT,
							},
							{
								text: t`Потврђена`,
								value: StockTurnoverStatus.CONFIRMED,
							},
						],
						filterMultiple: true,
					},
					{
						title: t`Датум`,
						dataIndex: 'date',
						key: 'date',
						render: (date) => {
							return date.format('L');
						},
						width: 120,
						filterKey: 'date',
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<DateRangeFilter
								setSelectedKeys={setSelectedKeys}
								selectedKeys={selectedKeys}
								confirm={confirm}
								clearFilters={clearFilters}
							/>
						),
					},
					{
						title: t`Набавна вредност`,
						dataIndex: ['stockTurnoverItem', 'purchaseAmount'],
						key: 'purchaseAmount',
						render: (value) => {
							return numberFormat(value, false, 2, true);
						},
						width: 150,
					},

					{
						title: t`Продајна вредност`,
						dataIndex: ['stockTurnoverItem', 'saleAmount'],
						key: 'saleAmount',
						render: (value) => {
							return numberFormat(value, false, 2, true);
						},
						width: 150,
					},
					{
						title: t`Добављач`,
						dataIndex: ['partner', 'name'],
						key: 'partnerId',
						ellipsis: true,
						width: 200,
						filterKey: 'partnerId',
						filters: () =>
							stores.partners.list?.map((partner: Partner) => ({
								text: partner.name,
								value: partner.id,
							})),
						filterMultiple: true,
						filterSearch: true,
					},
					{
						title: t`Фактура`,
						dataIndex: ['invoice', 'number'],
						key: 'invoiceId',
						ellipsis: true,
						width: 200,
					},
					{
						title: t`Отпремница`,
						dataIndex: 'deliveryNoteNumber',
						key: 'deliveryNoteNumber',
						ellipsis: true,
						width: 200,
					},
				],
			},
			customCreateButton: () => <CalculationCreateButton />,
		},
	});
