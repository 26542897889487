import type { Dayjs } from 'dayjs';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';
import { flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { Receipt, ReceiptItem } from './Receipt.mobx';
import { DayjsTransformer } from './transformers/Dayjs';
import { TransactionTypeAPI } from '../constants/journal';

const { Store, Entity } = CreateStore({
	name: 'dailyReceipt',
	paginated: false,
});

export type PaymentStats = {
	cash?: number;
	card?: number;
	other?: number;
	check?: number;
	mobilemoney?: number;
	wiretransfer?: number;
	voucher?: number;
};

export type Stats = {
	sales: PaymentStats;
	refunds: PaymentStats;
};

export type Details = {
	stats: Stats;
	receipts: Receipt[];
};

class DailyReceipt extends Entity {
	@observable summedAmount?: number;
	@observable receiptCount?: number;
	@observable type?: string;
	@observable isFetchingDetails?: boolean;
	@observable details?: Details;

	@DayjsTransformer
	day: Dayjs;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable(): boolean {
		return false;
	}

	get isEditable(): boolean {
		return false;
	}

	get receiptItems() {
		return sortBy(
			Object.values(
				(this.details?.receipts || []).reduce((acc, receipt) => {
					receipt.receiptItems.forEach((item) => {
						acc[`${item.productId}-${item.unitPrice}`] = acc[
							`${item.productId}-${item.unitPrice}`
						] || {
							id: item.productId,
							name: item.name,
							unitPrice: item.unitPrice,
							unit: item.unit,
							isPieceUnitOfMeasure: item.isPieceUnitOfMeasure,
							gtin: item.gtin,
							sku: item.sku,
							quantity: 0,
							key: `${item.productId}-${item.unitPrice}`,
						};

						acc[`${item.productId}-${item.unitPrice}`].quantity +=
							receipt.transactionType === TransactionTypeAPI.SALE
								? item.quantity
								: -item.quantity;
					});
					return acc;
				}, {})
			).filter((item: ReceiptItem) => item.quantity !== 0),
			'name'
		);
	}

	@flow.bound
	*fetchDetails(storeId) {
		this.isFetchingDetails = true;
		try {
			const { data } = yield this.getClient().get(
				`/daily-receipts/${this.day.toISOString()}/details?storeId=${storeId}`
			);
			this.details = {
				stats: data.stats,
				receipts: sortBy(data.receipts, 'sdcTime'),
			};
			this.isFetchingDetails = false;
		} catch (error) {
			console.error(error);
			this.isFetchingDetails = false;
			throw error;
		}
	}
}

class DailyReceipts extends Store<DailyReceipt> {
	chartData: DailyReceipt[] = [];
	constructor() {
		super(DailyReceipt);
	}

	get isCreatable() {
		return false;
	}

	get byDay() {
		return keyBy(this.all, (receipt) => receipt.day.format('YYYY-MM-DD'));
	}

	*fetchAll(date, storeId): Generator<any, void, any> {
		try {
			this.isFetching = true;
			const { data } = yield this.getClient().get(
				`/daily-receipts/${date}?storeId=${storeId}`
			);
			this.replaceAll(data);
			this.isFetching = false;
		} catch (error) {
			console.error(error);
			this.isFetching = false;
			throw error;
		}
	}

	@flow.bound
	*fetchAllChart(date, storeId): Generator<any, void, any> {
		try {
			this.isFetching = true;
			const { data } = yield this.getClient().get(
				`/daily-receipts-chart/${date}?storeId=${storeId}`
			);
			this.chartData = this.chartData.slice(0, data.length);
			const currentLength = this.chartData.length;
			for (let i = 0, length = data.length; i < length; i++) {
				if (currentLength < i + 1) {
					this.chartData.push(new DailyReceipt(data[i], this));
				} else {
					this.chartData[i].replace(data[i]);
				}

				this.setLastUpdated(data[i].updatedAt);
			}
			this.isFetching = false;
		} catch (error) {
			console.error(error);
			this.isFetching = false;
			throw error;
		}
	}
}

export { DailyReceipts, DailyReceipt };
