import { t, Trans } from '@lingui/macro';
import {
	Button,
	Checkbox,
	Col,
	DatePicker,
	Drawer,
	Dropdown,
	Form,
	Menu,
	Row,
	Select,
	Space,
} from 'antd';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import printjs from 'print-js';
import { useCallback, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import ExcelJS from 'exceljs';

import styles from './PeriodicReport.module.less';
import globalStyles from '../../../../../../assets/styles/global.module.less';
import CashierSelect from '../../../../../../components/CashierSelect';
import { useDrawer } from '../../../../../../components/Page';
import A4Periodic from '../../../../../../components/Reports/Periodic/A4Periodic';
import { ThermalPeriodic } from '../../../../../../components/Reports/Periodic/thermalPeriodic';
import StoreSelect from '../../../../../../components/StoreSelect';
import stores from '../../../../../../stores/index.mobx';
import { PeriodicReport as PeriodicReportType } from '../../../../../../stores/Report.mobx';
import classNames from 'classnames';
import locale from '../../../../../../components/Reports/Periodic/locale/sr-Cyrl-RS.json';
import { flowResult } from 'mobx';
import numberFormat from '../../../../../../lib/numberFormat';
import round from 'lodash/round';

type PrintData = {
	from: Dayjs | null;
	to: Dayjs | null;
	storeId: string | null;
	invoiceTypes: ('normal' | 'advance')[];
	cashiers: string[];
	getProducts: boolean;
	getDaily: boolean;
	getByPaymentMethod: boolean;
	splitProductsByPaymentMethod: boolean;
	data: PeriodicReportType;
	getGroupVat: boolean;
};

const PeriodicReport = () => {
	const printDestination = useRef(null);
	const printAreaRef = useRef<HTMLDivElement>(null);

	const [initialDate] = useQueryParam<string, string>('date');
	const [currentStore] = useQueryParam('store', StringParam);

	const [printData, setPrintData] = useState<PrintData>({
		storeId: null,
		from: null,
		to: null,
		invoiceTypes: [],
		data: null,
		cashiers: [],
		getProducts: false,
		getDaily: false,
		getByPaymentMethod: false,
		splitProductsByPaymentMethod: false,
		getGroupVat: false,
	});
	const [, , close, visible] = useDrawer('periodic-report');

	const [splitEnabled, setSplitEnabled] = useState(false);

	const [form] = Form.useForm();

	function formatDate(date: string | Dayjs, dateOnly = false): string {
		if (dateOnly) {
			return dayjs(date).format('DD.MM.YYYY');
		}
		return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
	}

	const {
		devices: { thermalPrinters },
		report: { fetchPeriodicReport, isFetching },
		stores: { currentStoreId: storeId, getById: getStoreById },
		company: { vat, tin, name },
		users: { getById: getUserById },
		taxRates,
	} = stores;

	const printThermal = useMemo(() => {
		return window.electron && thermalPrinters.length;
	}, [thermalPrinters]);

	const menu = useMemo(() => {
		return (
			<Menu
				onClick={({ key }) => {
					if (key === '1') {
						printDestination.current = 'a4';
					} else {
						printDestination.current = 'xslx';
					}
					form.submit();
				}}
				items={[
					...(printThermal
						? [
								{
									key: '1',
									label: t`Штампај на А4 штампачу`,
								},
						  ]
						: []),
					{
						key: '2',
						label: t`Преузми у XLSX формату`,
					},
				]}
			/>
		);
	}, [form, printThermal]);

	const onSubmit = useCallback(async () => {
		const query = form.getFieldsValue();

		const data = await flowResult(
			fetchPeriodicReport({
				from: query.period[0].toISOString(),
				to: query.period[1].toISOString(),
				cashierId: query.cashierId,
				storeId: query.storeId,
				invoiceType: query.invoiceType,
				getProducts: query.getProducts,
				getDaily: query.getDaily,
				getByPaymentMethod: query.getByPaymentMethod,
				splitProductsByPaymentMethod: query.splitProductsByPaymentMethod,
				getGroupVat: query.getGroupVat,
			})
		);
		if (printDestination.current === 'thermal') {
			const thermalReport = new ThermalPeriodic(
				query.storeId,
				query.period[0],
				query.period[1],
				query.invoiceType,
				data,
				query.cashierId,
				query.getProducts,
				query.getDaily,
				query.getByPaymentMethod,
				query.splitProductsByPaymentMethod,
				query.getGroupVat
			);
			thermalReport.print();
		}

		if (printDestination.current === 'a4') {
			flushSync(() => {
				setPrintData({
					from: query.period[0],
					to: query.period[1],
					storeId: query.storeId,
					invoiceTypes: query.invoiceType,
					cashiers: query.cashierId,
					getProducts: query.getProducts,
					getDaily: query.getDaily,
					getByPaymentMethod: query.getByPaymentMethod,
					splitProductsByPaymentMethod: query.splitProductsByPaymentMethod,
					getGroupVat: query.getGroupVat,
					data,
				});
			});
			printjs({
				printable: 'printarea',
				type: 'html',
				targetStyles: ['*'],
				font_size: '',
			});
		}

		if (printDestination.current === 'xslx') {
			const workbook = new ExcelJS.Workbook();
			workbook.creator = t`Бади`;
			workbook.created = new Date();
			workbook.modified = new Date();
			const worksheet = workbook.addWorksheet(locale.periodicReport);
			let currentRow = 1;

			worksheet.getColumn(1).width = 25;
			worksheet.getColumn(2).width = 15;
			worksheet.getColumn(3).width = 15;
			worksheet.getColumn(4).width = 15;
			worksheet.getColumn(5).width = 15;
			worksheet.getColumn(6).width = 15;
			worksheet.getColumn(7).width = 15;

			let row = worksheet.getRow(currentRow);
			row.values = [locale.periodicReport];
			row.font = { bold: true, size: 20 };
			currentRow += 2;

			row = worksheet.getRow(currentRow);
			row.values = [locale.tin, tin];
			currentRow++;

			row = worksheet.getRow(currentRow);
			row.values = [locale.company, name];
			currentRow++;

			row = worksheet.getRow(currentRow);
			row.values = [locale.store, getStoreById(storeId).name];
			currentRow += 2;

			row = worksheet.getRow(currentRow);
			row.values = [
				locale.period,
				`${query.period[0].format('L')} - ${query.period[1].format('L')}`,
			];
			currentRow++;

			row = worksheet.getRow(currentRow);
			row.values = [
				locale.invoiceTypes,
				query.invoiceType.map((i) => locale[i]).join(', '),
			];
			currentRow++;
			if (query.cashierId && query.cashierId.length) {
				row = worksheet.getRow(currentRow);
				row.values = [
					locale.cashiers,
					query.cashierId.map((i) => getUserById(i).fullName).join(', '),
				];
				currentRow++;
			}

			if (!query.cashierId || !query.cashierId.length) {
				row = worksheet.getRow(currentRow);
				row.values = [locale.cashiers, locale.all];
				currentRow++;
			}

			currentRow++;

			row = worksheet.getRow(currentRow);
			row.values = [locale.typesReport];
			row.font = { bold: true, size: 16 };
			currentRow += 2;

			data.byType.forEach((type) => {
				row = worksheet.getRow(currentRow);
				row.values = [locale[type.type]];
				row.font = { bold: true };

				currentRow += 2;

				if (type.byLabel.length) {
					worksheet.addTable({
						name: `typesReport_${type.type.replace('-', '_')}`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: locale.label },
							{ name: locale.tax },
							{ name: locale.volumeWithTaxShort },
						],
						rows: [
							...type.byLabel.map((item) => [
								`${item.label} (${
									taxRates.byLabel(item.label)?.name
								} - ${numberFormat(
									taxRates.byLabel(item.label)?.rate,
									false,
									2,
									true
								)}%)`,
								round(item.tax, 2),
								round(item.amount, 2),
							]),
							[locale.total, round(type.tax, 2), round(type.amount, 2)],
						],
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += type.byLabel.length + 3;
			});

			row = worksheet.getRow(currentRow);
			row.values = [locale.total];
			row.font = { bold: true };
			currentRow += 2;

			if (data.byLabel.length) {
				worksheet.addTable({
					name: `typesReport_total`,
					headerRow: true,
					totalsRow: false,
					ref: `A${currentRow}`,
					style: {
						theme: 'TableStyleLight12',
					},
					columns: [
						{ name: locale.label },
						{ name: locale.tax },
						{ name: locale.volumeWithTaxShort },
					],
					rows: [
						...data.byLabel.map((item) => [
							`${item.label} (${
								taxRates.byLabel(item.label)?.name
							} - ${numberFormat(
								taxRates.byLabel(item.label)?.rate,
								false,
								2,
								true
							)}%)`,
							round(item.tax, 2),
							round(item.amount, 2),
						]),
						[
							locale.total,
							round(data.total.tax, 2),
							round(data.total.amount, 2),
						],
					],
				});
			} else {
				row = worksheet.getRow(currentRow);
				row.values = [locale.noData];
			}

			currentRow += data.byLabel.length + 3;

			if (query.getDaily) {
				currentRow++;

				row = worksheet.getRow(currentRow);
				row.values = [locale.dailyReport];
				row.font = { bold: true, size: 16 };
				currentRow += 2;

				const dailyData = [];

				data.byDay.forEach((day) => {
					day.byLabel.forEach((item, index) => {
						dailyData.push([
							index === 0 ? formatDate(day.day, true) : '',
							`${item.label} (${
								taxRates.byLabel(item.label).name
							} - ${numberFormat(
								taxRates.byLabel(item.label).rate,
								false,
								2,
								true
							)}`,
							item.count,
							round(item.amount - item.tax, 2),
							round(item.tax, 2),
							round(item.amount, 2),
						]);
					});
					dailyData.push([
						'',
						locale.total,
						day.total.count,
						round(day.total.amount - day.total.tax, 2),
						round(day.total.tax, 2),
						round(day.total.amount, 2),
					]);
				});

				if (dailyData.length) {
					worksheet.addTable({
						name: `dailyReport`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: locale.date },
							{ name: locale.label },
							{ name: locale.receipts },
							{ name: locale.baseVolume },
							{ name: locale.tax },
							{ name: locale.volumeWithTax },
						],
						rows: dailyData,
					});
					const dayDataLength = data.byDay.length
						? data.byDay[0].byLabel.length + 1
						: 0;

					currentRow += data.byDay.length * dayDataLength;
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				// for (let i = dataStartRow; i < currentRow; i += dayDataLength) {
				// 	worksheet.mergeCells(`A${i}:A${i + dayDataLength - 1}`);
				// }

				currentRow += 2;
			}

			if (query.getProducts) {
				currentRow++;

				row = worksheet.getRow(currentRow);
				row.values = [locale.products];
				row.font = { bold: true, size: 16 };
				currentRow += 2;

				if (data.products.length) {
					worksheet.addTable({
						name: `productsReport`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: locale.sku },
							{ name: locale.name },
							{ name: locale.unit },
							...(query.splitProductsByPaymentMethod
								? [{ name: locale.paymentMethod }]
								: []),
							{ name: locale.quantity },
							{ name: locale.unitPrice },
							{ name: locale.total },
						],
						rows: data.products.map((item) => [
							item.sku,
							item.name,
							item.unit,
							...(query.splitProductsByPaymentMethod
								? [locale[item.paymentType]]
								: []),
							round(item.quantity, 3),
							round(item.unitPrice, 2),
							round(item.amount, 2),
						]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.products.length;
			}

			if (query.getByPaymentMethod) {
				currentRow++;

				row = worksheet.getRow(currentRow);
				row.values = [locale.paymentMethod];
				row.font = { bold: true, size: 16 };
				currentRow += 2;

				if (data.paymentMethods.length) {
					worksheet.addTable({
						name: `paymentMethod`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [{ name: locale.paymentMethod }, { name: locale.total }],
						rows: data.paymentMethods.map((item) => [
							locale[item.paymentType],
							round(item.amount, 2),
						]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.paymentMethods.length;
			}

			if (query.getGroupVat) {
				currentRow++;

				row = worksheet.getRow(currentRow);
				row.values = [locale.groupVat];
				row.font = { bold: true, size: 16 };
				currentRow += 2;

				row = worksheet.getRow(currentRow);
				row.values = [locale.groupVatAdvanceSale];
				row.font = { bold: true };
				currentRow += 2;

				if (data.groupVat.advanceSales.length) {
					worksheet.addTable({
						name: `groupVatAdvanceSale`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: ' ' },
							{ name: locale.advancePaymentAmount },
							{ name: locale.baseVolume },
							{ name: locale.vatAmount },
						],
						rows: data.groupVat.advanceSales
							.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
							.map((item) => [
								`${locale.groupVatAdvanceSaleLabel} ${numberFormat(
									taxRates.byLabel(item.taxLabel).rate,
									false,
									2,
									true
								)}%`,
								round(item.totalAmount, 2),
								round(item.baseAmount, 2),
								round(item.taxAmount, 2),
							]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.groupVat.advanceSales.length;

				row = worksheet.getRow(currentRow);
				row.values = [locale.groupVatNormalSale];
				row.font = { bold: true };
				currentRow += 2;

				if (data.groupVat.normalSales.length) {
					worksheet.addTable({
						name: `groupVatNormalSale`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: ' ' },
							{ name: locale.baseVolume },
							{ name: locale.vatAmount },
						],
						rows: data.groupVat.normalSales
							.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
							.map((item) => [
								`${locale.groupVatNormalSaleLabel} ${numberFormat(
									taxRates.byLabel(item.taxLabel).rate,
									false,
									2,
									true
								)}%`,
								round(item.baseAmount, 2),
								round(item.taxAmount, 2),
							]),
					});
					currentRow += 2 + data.groupVat.normalSales.length;

					row = worksheet.getRow(currentRow);
					row.values = [locale.groupVatNormalRefund];
					row.font = { bold: true };
					currentRow += 2;

					worksheet.addTable({
						name: `groupVatNormalRefund`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: ' ' },
							{ name: locale.baseVolume },
							{ name: locale.vatAmount },
						],
						rows: data.groupVat.normalRefunds
							.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
							.map((item) => [
								`${locale.groupVatNormalRefundLabel} ${numberFormat(
									taxRates.byLabel(item.taxLabel).rate,
									false,
									2,
									true
								)}%`,
								round(item.baseAmount, 2),
								round(item.taxAmount, 2),
							]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.groupVat.normalRefunds.length;

				row = worksheet.getRow(currentRow);
				row.values = [locale.groupVatAdvanceRefund];
				row.font = { bold: true };
				currentRow += 2;

				if (data.groupVat.advanceRefunds.length) {
					worksheet.addTable({
						name: `groupVatAdvanceRefund`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: ' ' },
							{ name: locale.baseVolume },
							{ name: locale.vatAmount },
						],
						rows: data.groupVat.advanceRefunds
							.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
							.map((item) => [
								`${locale.groupVatNormalRefundLabel} ${numberFormat(
									taxRates.byLabel(item.taxLabel).rate,
									false,
									2,
									true
								)}%`,
								round(item.baseAmount, 2),
								round(item.taxAmount, 2),
							]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.groupVat.advanceRefunds.length;

				row = worksheet.getRow(currentRow);
				row.values = [locale.groupVatNormalVoids];
				row.font = { bold: true };
				currentRow += 2;

				if (data.groupVat.normalVoids.length) {
					worksheet.addTable({
						name: `groupVatNormalVoids`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: ' ' },
							{ name: locale.baseVolume },
							{ name: locale.vatAmount },
						],
						rows: data.groupVat.normalVoids
							.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
							.map((item) => [
								`${locale.groupVatNormalRefundLabel} ${numberFormat(
									taxRates.byLabel(item.taxLabel).rate,
									false,
									2,
									true
								)}%`,
								round(item.baseAmount, 2),
								round(item.taxAmount, 2),
							]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.groupVat.normalVoids.length;

				row = worksheet.getRow(currentRow);
				row.values = [locale.groupVatAdvanceVoids];
				row.font = { bold: true };
				currentRow += 2;

				if (data.groupVat.advanceVoids.length) {
					worksheet.addTable({
						name: `groupVatAdvanceVoids`,
						headerRow: true,
						totalsRow: false,
						ref: `A${currentRow}`,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: ' ' },
							{ name: locale.baseVolume },
							{ name: locale.vatAmount },
						],
						rows: data.groupVat.advanceVoids
							.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
							.map((item) => [
								`${locale.groupVatNormalRefundLabel} ${numberFormat(
									taxRates.byLabel(item.taxLabel).rate,
									false,
									2,
									true
								)}%`,
								round(item.baseAmount, 2),
								round(item.taxAmount, 2),
							]),
					});
				} else {
					row = worksheet.getRow(currentRow);
					row.values = [locale.noData];
				}

				currentRow += 2 + data.groupVat.advanceVoids.length;
			}

			workbook.xlsx.writeBuffer().then((buffer) => {
				const blob = new Blob([buffer], {
					type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				});
				const url = URL.createObjectURL(blob);
				window.location.assign(url);
				URL.revokeObjectURL(url);
			});
		}
	}, [
		form,
		fetchPeriodicReport,
		tin,
		name,
		getStoreById,
		storeId,
		getUserById,
		taxRates,
	]);

	const onChange = useCallback((_, values) => {
		setSplitEnabled(values.getProducts);
	}, []);

	return (
		<Drawer
			open={visible}
			width={600}
			title={t`Периодични извештај`}
			onClose={close}
			// zIndex={zIndex}
			className={styles.drawer}
			footer={
				<>
					<Space className={styles.rightButtons}>
						{!!printThermal && (
							<Dropdown.Button
								disabled={isFetching}
								icon={<i className="fi fi-rr-angle-small-down"></i>}
								overlay={menu}
								onClick={() => {
									printDestination.current = 'thermal';
									form.submit();
								}}
							>
								<i className="fi fi-rr-print"></i>{' '}
								<Trans>Штампај на термалном штампачу</Trans>
							</Dropdown.Button>
						)}
						{!printThermal && (
							<Dropdown.Button
								disabled={isFetching}
								icon={<i className="fi fi-rr-angle-small-down"></i>}
								overlay={menu}
								onClick={() => {
									printDestination.current = 'a4';
									form.submit();
								}}
							>
								<i className="fi fi-rr-print"></i>{' '}
								<Trans>Штампај на А4 штампачу</Trans>
							</Dropdown.Button>
						)}
					</Space>
					<Space className={styles.leftButtons}>
						<Button
							onClick={() => {
								close();
							}}
						>
							<Trans>Затвори</Trans>
						</Button>
					</Space>
				</>
			}
		>
			{visible && (
				<Form
					form={form}
					layout="vertical"
					onFinish={onSubmit}
					onValuesChange={onChange}
					preserve={false}
				>
					<Form.Item
						label={t`Период`}
						name="period"
						rules={[{ required: true, message: t`Одаберите период` }]}
						initialValue={
							initialDate
								? [
										dayjs(initialDate).startOf('month'),
										dayjs(initialDate).endOf('month'),
								  ]
								: [dayjs(), dayjs()]
						}
					>
						<DatePicker.RangePicker
							style={{ width: '100%' }}
							format="L"
							popupClassName={styles.datePicker}
						/>
					</Form.Item>
					<Form.Item
						label={t`Врста рачуна`}
						name="invoiceType"
						rules={[{ required: true, message: t`Одаберите врсту рачуна` }]}
						initialValue={['normal', 'advance']}
					>
						<Select mode="multiple">
							<Select.Option value={'normal'}>
								<Trans>Промет</Trans>
							</Select.Option>
							<Select.Option value={'advance'}>
								<Trans>Аванс</Trans>
							</Select.Option>
						</Select>
					</Form.Item>
					<Form.Item
						label={t`Продајно место`}
						name="storeId"
						initialValue={
							currentStore && currentStore !== 'all' && currentStore !== ''
								? currentStore
								: storeId
						}
					>
						<StoreSelect showAllOption />
					</Form.Item>
					<Form.Item label={t`Касири`} name="cashierId">
						<CashierSelect mode="multiple" />
					</Form.Item>
					<Form.Item
						name="getDaily"
						valuePropName="checked"
						initialValue={true}
						className={globalStyles.condensedFormField}
					>
						<Checkbox value="true">
							<Trans>Штампај извештај по данима</Trans>
						</Checkbox>
					</Form.Item>
					<Row>
						<Col span={12}>
							<Form.Item
								name="getProducts"
								valuePropName="checked"
								className={globalStyles.condensedFormField}
							>
								<Checkbox value="true">
									<Trans>Штампај продате артикле</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="splitProductsByPaymentMethod"
								valuePropName="checked"
								className={globalStyles.condensedFormField}
							>
								<Checkbox disabled={!splitEnabled} value="true">
									<Trans>Раздвоји по начину плаћања</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Form.Item
						name="getByPaymentMethod"
						valuePropName="checked"
						className={globalStyles.condensedFormField}
					>
						<Checkbox value="true">
							<Trans>Штампај износе по начину плаћања</Trans>
						</Checkbox>
					</Form.Item>
					{vat && (
						<Form.Item
							name="getGroupVat"
							valuePropName="checked"
							className={globalStyles.condensedFormField}
						>
							<Checkbox value="true">
								<Trans>Извештај за збирну евиденцију ПДВ-а</Trans>
							</Checkbox>
						</Form.Item>
					)}
				</Form>
			)}
			<div className={classNames(styles.printArea, 'printAreaWrapper')}>
				<div ref={printAreaRef} id="printarea">
					{printData.data && <A4Periodic {...printData} />}
				</div>
			</div>
		</Drawer>
	);
};

export default observer(PeriodicReport);
