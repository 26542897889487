import { t, Trans } from '@lingui/macro';
import { Alert, Divider, Typography } from 'antd';
import { forwardRef, useImperativeHandle } from 'react';

import styles from './About.module.less';
import Logo from '../../../../components/Logo';
import { Title } from '../../../../components/Title';
import {
	getApplicationName,
	APPLICATION_VERSION,
} from '../../../../constants/application';

function About(undefined, ref) {
	const APPLICATION_NAME = getApplicationName();
	useImperativeHandle(ref, () => ({
		getDocsLink: () => 'koriscenje/moduli-aplikacije/podesavanja/o-aplikaciji/',
	}));
	return (
		<div className={styles.wrapper}>
			<Logo
				color="dark"
				version={`${APPLICATION_VERSION} ${
					window.electron
						? t`(верзија клијента: ${window.electron.clientVersion || '1.x.x'})`
						: ''
				}`}
			/>
			<p>
				<Trans>
					<strong>{APPLICATION_NAME}</strong> је систем за управљање
					малопродајним објектима. Поседује функционалност електронског
					фискалног уређаја који је имплементиран према закону о фискализацији и
					техничком водичу који је прописала Пореска управа Републике Србије.
				</Trans>
			</p>
			<Title>
				<Trans>Контакт</Trans>
			</Title>
			<p>
				<Trans>
					<strong>Назив фирме:</strong> Дејан Велимировић ПР Агенција за
					рачунарско програмирање DVSoftware Београд (Палилула)
				</Trans>
				<br />
				<Trans>
					<strong>Седиште фирме:</strong> Милорада Димитријевића 16/35, 11060
					Београд, Република Србија
				</Trans>
				<br />
				<strong>
					<Trans>Контакт телефон:</Trans>
				</strong>{' '}
				060 1755 733
				<br />
				<strong>
					<Trans>Електронска пошта:</Trans>
				</strong>{' '}
				<Typography.Link href="mailto:info@dvsoftware.rs">
					info@dvsoftware.rs
				</Typography.Link>
				<br />
				<strong>
					<Trans>Веб сајт:</Trans>
				</strong>{' '}
				<Typography.Link href="https://dvsoftware.rs" target="_blank">
					https://dvsoftware.rs
				</Typography.Link>
				<br />
			</p>
			<Title>
				<Trans>Ново у верзији</Trans> 3.9.5 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					10.03.2025
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Преузимање калкулација и нивелација</strong>
					<p>
						Сада је могуће преузети калкулације и нивелације у PDF формату. Ова
						опција се појављује након што су селектоване калкулације или
						нивелације чекирањем дугмета за обележавање испред сваког документа.
					</p>
				</li>
				<li>
					<strong>Преузимање извештаја калкулација и нивелација</strong>
					<p>
						Сада је могуће преузети извештаје калкулација и нивелација у XLSX
						формату. Ова опција се појављује након што су селектоване
						калкулације или нивелације чекирањем дугмета за обележавање испред
						сваког документа.
					</p>
				</li>
				<li>
					<strong>Преузимање стања залиха у XLSX формату</strong>
					<p>
						Сада је могуће преузети стање залиха на жељени дан у XLSX формату.
					</p>
				</li>
				<li>
					<strong>Број рачуна на календарском приказу</strong>
					<p>Сада се за сваки дан приказује број издатих рачуна</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Редослед начина плаћања на статистици</strong>
					<p>
						Редослед начина плаћања на статистици сада прати подешавања
						продајног места
					</p>
				</li>
				<li>
					<strong>
						Отварање статистике дана са календарског приказа на мобилним
						уређајима
					</strong>
					<p>
						Поправљена је грешка која је узроковала да се статистика дана не
						отвара на мобилним уређајима
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-tachometer-alt-fastest"></i> - Побољшања
				перформанси
			</Typography.Title>
			<ul>
				<li>
					<strong>Нова верзија библиотеке за генерисање PDF фајлова</strong>
					<p>
						Ажурирана је верзија библиотеке за генерисање PDF фајлова, што је
						довело до бржег генерисања докумената.
					</p>
				</li>
				<li>
					<strong>Побољшане перформансе стања залиха</strong>
					<p>
						Побољшане су перформансе стања залиха, при претрази и филтрирању по
						добављачу.
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-lipstick"></i> - Козметичке измене
			</Typography.Title>
			<ul>
				<li>
					<strong>Календарски приказ</strong>
					<p>Измењен је изглед календарског приказа.</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.9.4 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					03.03.2025
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Периодични извештај у XLSX формату</strong>
					<p>Сада је могуће преузети периодични извештај у XLSX формату.</p>
				</li>
				<li>
					<strong>Дозволе за залихе</strong>
					<p>Додата је могућност измена дозвола за приступ залихама.</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Штампа докумената на тамном режиму</strong>
					<p>
						Поправљена је штампа докумената на тамном режиму, односно текст више
						није светле боје.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.9.3 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					19.02.2025
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Извештај за потребе збирне евиденције ПДВ-а</strong>
					<p>
						При изради периодичног извештаја се могу генерисати вредности које
						је потребно попунити при подношењу збирне евиденције ПДВ-а на СЕФ-у
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Приказ имена касира на издатим рачунима</strong>
					<p>Поправљен је приказ имена касира на претходно издатим рачунима.</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.9.2 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					05.02.2025.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>
						Груписање аутоматских нивелација насталих из фискалних рачуна
					</strong>
					<p>Нивелације се сада групишу по дану када су настале.</p>
				</li>
				<li>
					<strong>Филтрирање по партнеру на стању залиха</strong>
					<p>Омогућено је филтрирање по партнеру на стању залиха</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Приказ залиха на мањим екранима</strong>
					<p>
						Поправљен је приказ на појединим страницама на модулу залиха на
						мањим екранима.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.9.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					21.01.2025.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Промет без ПДВ-а</strong>
					<p>
						Додата је опција да се рачун изда без ПДВ-а када је то у складу са
						Законом.
					</p>
				</li>
				<li>
					<strong>Поништавање поништавања</strong>
					<p>Омогућено је поништавање поништавања рачуна у случају грешке</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Приказ стране валуте на каси</strong>
					<p>
						Поправљена је грешка која је проузроковала да се уместо стране
						валуте приказује РСД.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.9.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					01.01.2025.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Залихе</strong>
					<p>Омогућен је унос отписа, конверзија и пописа</p>
				</li>
				<li>
					<strong>Мој Бади</strong>
					<p>Омогућено примењивање понуда и попуста</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Увоз појединих е-Фактура</strong>
					<p>
						Поправљен увоз е-Фактура које не садрже податак о датуму настанка
						ПДВ обавезе
					</p>
				</li>
				<li>
					<strong>Слање рачуна путем електронске поште</strong>
					<p>
						Поправљен проблем који је проузроковао да се уместо тренутног, слао
						претходно отворен рачун.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.8.2 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					10.11.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Мој Бади</strong>
					<p>
						Омогућен је унос података за{' '}
						<a href="https://moj.badi.rs" target="_blank">
							Мој Бади
						</a>
						, који ће бити доступан ускоро. Припремите своју понуду на време.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.8.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					08.11.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Претрага по више термина</strong>
					<p>
						Сада је могуће вршити претрагу по више термина истовремено, без
						обзира где се налазе у називу или опису производа. На пример,
						уколико унесете ”чоколада 80г”, резултат ће бити све чоколаде масе
						80г.
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>
						Вредност поља за количину након претраге се није враћала на 1
					</strong>
					<p>Сада се вредност поља враћа на 1 након потврђеног уноса.</p>
				</li>
				<li>
					<strong>Унос запете за одвајање децимала</strong>
					<p>
						Сва поља за унос цифара сада примају и тачку и запету за одвајање
						децимала.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.8.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					04.11.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Приказ добављача на артиклима</strong>
					<p>
						Сада се на артиклима приказују добављачи, ова могућност је доступна
						само на Профи пакету.
					</p>
				</li>
				<li>
					<strong>Приказ артикла</strong>
					<p>Сада се информације о артиклу могу видети у режиму приказа.</p>
				</li>
				<li>
					<strong>Статистике на артиклу</strong>
					<p>
						Сада су на артиклу доступне статистике продаје, промета, набавке
						(само Профи пакет) и залиха (само Профи пакет).
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.7.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					01.09.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>
						Приказ лимита за паушално опорезивање и ПДВ на почетној страни
					</strong>
					<p>
						Сада је могуће видети лимите за паушално опорезивање и ПДВ на
						почетној страни уколико сте паушално опорезивани обвезник.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.7.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					28.05.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Ручно додавање канала продаје</strong>
					<p>
						Сада је могуће ручно додати канале продаје за потребе вашег
						пословања.
					</p>
				</li>
				<li>
					<strong>Избор канала продаје на каси</strong>
					<p>
						Сада је могуће изабрати канал продаје на каси, и том приликом ће
						бити видљиви само артикли који су доступни на том каналу продаје, и
						цене артикала ће бити приказане у складу са ценама на том каналу
						продаје.
					</p>
				</li>
				<li>
					<strong>
						Филтирање по каналима продаје на модулу за доступност производа
					</strong>
					<p>Сада је могуће филтрирати производе по каналима продаје.</p>
				</li>
				<li>
					<strong>Линк до калкулације</strong>
					<p>Број калкулације је сада линк који отвара калкулацију.</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>
						Онемогућена поља у форми уколико није дозвољена њихова измена
					</strong>
					<p>
						Поједина поља у формама су била омогућена за измену, иако није било
						дозвољено.
					</p>
				</li>
				<li>
					<strong>Рефундација аванса приликом рефундације</strong>
					<p>
						У случајевима када је дошло до промене писма у односу на претходно
						издат авансни рачун, долазило је до грешке у рачунању претходно
						уплаћених аванса.
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-lipstick"></i> - Козметичке измене
			</Typography.Title>
			<ul>
				<li>
					<strong>Измена приказа категорија на каси</strong>
					<p>
						Промењен је изглед иконице категорија на каси, како би се боље
						уклопила у дизајн.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.6.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					16.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Асинхроно ажурирање на другим каналима продаје</strong>
					<p>
						Артикли се сада ажурирају асинхроно, како не би долазило до успорења
						приликом измене артикала и доступности.
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Прецизност рачунских операција</strong>
					<p>
						Побољшана је прецизност свих рачунских операција приликом издавања
						рачуна. Ова измена би требала решити све преостале грешке у
						заокруживању.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.5.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					13.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Величина фонта при штампи фискалних рачуна</strong>
					<p>
						Сада је могуће променити величину фонта за штампу на термалном
						штампачу.
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Приступ опцијама за програмере на основном пакету</strong>
					<p>Ова секција није била доступна на основном пакету.</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.4.2 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					08.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Немогућност ужурирања артикала</strong>
					<p>
						Исправљена је грешка која је у неким случајевима онемогућавала
						ажурирање артикала
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.4.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					08.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Грешка у заокруживању</strong>
					<p>Исправљена је грешка у заокруживању на још једном месту</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.4.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					07.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Аутоматска промена доступности артикала</strong>
					<p>
						Сада је могуће укључити праћење залиха за артикле на додатним
						каналима продаје, и аутоматски их укључивати или искључивати у
						зависности од стања залиха.
					</p>
				</li>
				<li>
					<strong>Измена цене ако артикли још нису на стању</strong>
					<p>
						Сада је могуће мењати цену артиклима без израде нивелација и
						калкулација све док се први пут не ураде. Ово омогућава да се
						артикли унесу у систем и продају, а да се касније ураде нивелације и
						калкулације, најчешће уколико је потребно да се излистају на другим
						каналима продаје
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Грешка у заокруживању</strong>
					<p>
						У неким случајевима се дешавало да је укупна вредност фискалног
						рачуна заокружена на нижу децималу иако је требало да буде
						заокружена на вишу.
					</p>
				</li>
				<li>
					<strong>Доступност артикала</strong>
					<p>
						Доступност артикала се није ажурирала у реалном времену на другим
						инстанцама апликације
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.3.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					04.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Креирање артикала на платформи Glovo</strong>
					<p>
						Услед измена на платформи Glovo, није било могуће креирати артикале.
						Ова грешка је сада исправљена.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.3.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					02.04.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Штампа исечака ПОС терминала</strong>
					<p>
						Од сада је могуће штампати исечке ПОС терминала на термалном
						штампачу.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.2.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					22.03.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Продаја без ПДВ-а</strong>
					<p>
						За обрасце ППО-ПДВ, МППО-ПДВ и ИППО-ПДВ се сада рачуни издају без
						ПДВ-а.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.2.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					20.03.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Копија и рефундације преко АПИ-ја</strong>
					<p>Сада је могуће издати копију или рефундацију преко АПИ-ја.</p>
				</li>
				<li>
					<strong>Локални АПИ</strong>
					<p>
						Омогућена је комуникација са апликацијом помоћу локалног АПИ
						сервера.
					</p>
				</li>
				<li>
					<strong>Измена команде за отварање фиоке за новац</strong>
					<p>
						Сада је могуће променити команду која се шаље за отварање фиоке за
						новац, уколико ваш термални штампач подржава другачију команду.
					</p>
				</li>
				<li>
					<strong>Чување детаља картичне трансакције</strong>
					<p>
						Уколико је конфигурисан ПОС терминал, детаљи картичне трансакције ће
						бити сачувани уз рачун. За сада се ови подаци нигде не приказују,
						али ће бити додати у неком од наредних ажурирања, као и могућност
						штампе података након фискалног рачуна, уместо да то ради ПОС
						терминал.
					</p>
				</li>
				<li>
					<strong>Извоз КПО књиге</strong>
					<p>Сада је могуће извести КПО књигу у PDF формату.</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Филтери</strong>
					<p>Поправљено непредвидиво понашање филтера у неким ситуацијама</p>
				</li>
				<li>
					<strong>Пагинација на КПО књизи</strong>
					<p>Поправљен проблем са пагинацијом на КПО књизи.</p>
				</li>
				<li>
					<strong>Пречице на каси са укљученим CAPS LOCK-ом и SHIFT-ом</strong>
					<p>
						Неке од пречица које су садржале слова, нису радиле уколико је био
						укључен CAPS LOCK или SHIFT.
					</p>
				</li>
				<li>
					<strong>Заокруживање количине приликом рефундације</strong>
					<p>Исправљено погрешно заокруживање количине са 2 на 3 децимале.</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-tachometer-alt-fastest"></i> - Побољшања
				перформанси
			</Typography.Title>
			<ul>
				<li>
					<strong>Фонтови</strong>
					<p>
						Сви фонтови коришћени на апликацији се сада преузимају у позадини и
						кеширају на уређају, што би требало да побољша перформансе приликом
						учитавања апликације као и код првог издавања рачуна. Такође, ова
						измена би требала да реши проблем са немогућношћу издавања рачуна
						уколико није доступна интернет веза, који се повремено јављао.
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.1.5 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					15.02.2024.
				</Typography.Text>
			</Title>
			<Typography.Title level={5}>
				<i className="fi fi-rr-party-horn"></i> - Нове функционалности
			</Typography.Title>
			<ul>
				<li>
					<strong>Калкулација на основу отпремнице</strong>
					<p>
						Омогућено је потврђивање калкулације са унетим бројем отпремнице,
						због добављача који касне са слањем е-Фактуре. Касније је могуће
						повезати већ потврђену калкулацију са пристиглом фактуром.
					</p>
				</li>
				<li>
					<strong>
						Приказ шифре и описа код ИД купца и Опционог поља купца
					</strong>
					<p>
						Сада се приказују шифре и описи ставки код ИД купца и Опционог поља
						купца.
					</p>
				</li>
				<li>
					<strong>Допуњена листа опција за поље ИД купца</strong>
					<p>
						Додате су шифре 14, 15 и 16 које се односе на пољопривредна
						газдинства, као и шифра 36 за број личне карте страног лица из
						Европске Уније
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
			</Typography.Title>
			<ul>
				<li>
					<strong>Дуплирање дугмета за додавање у изборницима</strong>
					<p>
						Уколико при претрази на изборнику није било резлтата, дуплирало се
						дугме за додавање ставке.
					</p>
				</li>
				<li>
					<strong>Последње изабрано складиште</strong>
					<p>
						При уносу калкулација и нивелација, складиште се памтило тек након
						другог попуњеног реда.
					</p>
				</li>
				<li>
					<strong>
						Приказ погрешне странице на уносу ставки калкулације и нивелације
					</strong>
					<p>
						Када је унето 10 артикала, нови ред се приказивао уместо последњег
						реда уместо преласка на нову страницу.
					</p>
				</li>
				<li>
					<strong>Приказ подешавања уређаја</strong>
					<p>
						Корисници који су имали дозволе за приступ овој секцији је нису
						видели.
					</p>
				</li>
			</ul>
			<Typography.Title level={5}>
				<i className="fi fi-rr-tachometer-alt-fastest"></i> - Побољшања
				перформанси
			</Typography.Title>
			<ul>
				<li>
					<strong>Претрага</strong>
					<p>
						Претраге на апликацији сада чекају да од последњег уноса прође 500
						милисекунди пре иницирања претраге, како би се уштедело на ресурсима
					</p>
				</li>
			</ul>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.1.4 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					07.02.2024.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-party-horn"></i> - Нове функционалности
				</Typography.Title>
				<ul>
					<li>
						<strong>Нивелације (алфа)</strong>
						<p>Омогућена израда нивелација.</p>
					</li>
					<li>
						<strong>Приказ шифре артикла на калкулацијама</strong>
						<p>Сада се шифра приказује поред назива артикла</p>
					</li>
					<li>
						<strong>Приказ калкулације на фактурама</strong>
						<p>На листи фактура се сада приказује придружена калкулација</p>
					</li>
					<li>
						<strong>Врста приказа артикала</strong>
						<p>
							Артикле је сада могуће приказати као листу свих варијанти, не само
							као листу основних артикала
						</p>
					</li>
					<li>
						<strong>Канали продаје</strong>
						<p>
							Уколико се на листи артикала приказују све варијанте, сада се
							приказују и канали продаје који су омогућени за сваки артикал
						</p>
					</li>
					<li>
						<strong>Улазни ПДВ</strong>
						<p>Сада је на калкулацији могуће унети улазни ПДВ за артикле</p>
					</li>
					<li>
						<strong>Шифра на инвентару</strong>
						<p>Сада се код сваког артикла на инвентару приказује и шифра</p>
					</li>
				</ul>
				<Typography.Title level={5}>
					<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
				</Typography.Title>
				<ul>
					<li>
						<strong>Грешке у форми за унос артикала</strong>
						<p>
							Грешке које су се јављале приликом уноса артикала су сада
							поправљене.
						</p>
					</li>
					<li>
						<strong>Грешке у заокруживању</strong>
						<p>
							Отклоњене су грешке приликом заокруживања приликом уноса артикала
							у калкулацију.
						</p>
					</li>
					<li>
						<strong>Shoppster интеграција</strong>
						<p>Поправљен проблем да форма за подешавање није била исправна</p>
					</li>
				</ul>
				<Typography.Title level={5}>
					<i className="fi fi-rr-tachometer-alt-fastest"></i> - Побољшања
					перформанси
				</Typography.Title>
				<ul>
					<li>
						<strong>Претрага</strong>
						<p>
							Претраге на апликацији сада чекају да од последњег уноса прође 500
							милисекунди пре иницирања претраге, како би се уштедело на
							ресурсима
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.1.3 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					10.01.2024.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-party-horn"></i> - Нове функционалности
				</Typography.Title>
				<ul>
					<li>
						<strong>Штампа калкулације</strong>
						<p>Омогућена штампа калкулације.</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.1.2 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					27.12.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-party-horn"></i> - Нове функционалности
				</Typography.Title>
				<ul>
					<li>
						<strong>Рекапитулација калкулације</strong>
						<p>
							Детаљна рекапитулација калкулације се сада приказује испод ставки
							калкулације
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.1.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					19.12.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
				</Typography.Title>
				<ul>
					<li>
						<strong>Износ готовине приликом рефундације</strong>
						<p>
							У појединим случајевима се за износ готовине приликом рефундације
							слала 0. Овај проблем је сада поправљен.
						</p>
					</li>
					<li>
						<strong>Разне исправке процеса отпочињања вођења залиха</strong>
						<p>
							Исправљено је неколико грешака које су се јављале приликом
							отпочињања вођења залиха.
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.1.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					17.12.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-party-horn"></i> - Нове функционалности
				</Typography.Title>
				<ul>
					<li>
						<strong>Праћење залиха робе (алфа)</strong>
						<p>
							Почетак развоја функциоалности за праћење залиха робе. У овој
							верзији је додата могућност уноса почетног стања, израда
							калкулација и умањење залиха приликом издавања фискалног рачуна.
							<br />
							<br />
							Ова функционалност још увек није доступна свим корисницима. Ако
							желите да тестирате ову функционалност, контактирајте нас како
							бисмо вам је омогућили.
						</p>
					</li>
					<li>
						<strong>Измене приступа путем АПИ-ја</strong>
						<p>
							Измењен је начин издавања фискалних рачуна преко АПИ сервиса, и
							додата је могућност издавања авансних рачуна, додавања авансних
							уплата и затварања авансних рачуна.
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.0.3 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					04.12.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
				</Typography.Title>
				<ul>
					<li>
						<strong>
							Немогућност уноса ЈБКЈС-а при издавању фискалног рачуна
						</strong>
						<p>
							Поправљена је грешка која је онемогућавала унос ":", и самим тим
							спречавала унос ЈБКЈС-а у прозору за плаћање.
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.0.2 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					29.11.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
				</Typography.Title>
				<ul>
					<li>
						<strong>Немогућност поништавања авансног рачуна</strong>
						<p>
							Поправљена је грешка која се јављала приликом покушаја поништавања
							авансног рачуна.
						</p>
					</li>
					<li>
						<strong>Штампа на А4 штампачу иако је опција искључена</strong>
						<p>
							Поправљен је проблем да је апликација увек слала фискални рачун на
							штампу, без обзира што је опција штампе на А4 штампачу онемогућена
							за продајно место.
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.0.1 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					27.11.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
				</Typography.Title>
				<ul>
					<li>
						<strong>Конверзија по средњем курсу за ранији датум</strong>
						<p>
							Поправљена је грешка да се конверзија по средњем курсу није вршила
							по датуму који је одабран приликом издавања аванса, већ по
							данашњем датуму.
						</p>
					</li>
				</ul>
			</>
			<br />
			<Title>
				<Trans>Ново у верзији</Trans> 3.0.0 <Divider type="vertical" />{' '}
				<Typography.Text type="secondary" style={{ fontWeight: 'normal' }}>
					23.11.2023.
				</Typography.Text>
			</Title>
			<>
				<Typography.Title level={5}>
					<i className="fi fi-rr-party-horn"></i> - Нове функционалности
				</Typography.Title>
				<ul>
					<li>
						<strong>Вишејезичност</strong>
						<p>
							Због све већег броја иностраних корисника, одлучили смо да на
							апликацији омогућимо подршку за више језика. Тренутно су доступни
							српски, енглески и руски језик. Такође, подразумевано писмо је
							сада ћирилица, али уколико желите, можете да промените писмо у
							латиницу.
							<br />
							<br />
							<img src="/images/select-language.png" style={{ width: '50%' }} />
						</p>
					</li>
					<li>
						<strong>Дозволе за продајно место</strong>
						<p>
							Сада је могуће корисницима ограничити приступ по продајном месту.
							<br />
							<br />
							<img
								src="/images/role-stores-field.png"
								style={{ width: '50%' }}
							/>
						</p>
					</li>
					<li>
						<strong>Пензионерске картице</strong>
						<p>
							У шифарник за одабир врсте купца, додата је могућност избора за
							број пензионерске картице.
						</p>
					</li>
					<li>
						<strong>
							Избор продајног места на почетној страни и издатим рачунима по
							данима
						</strong>
						<p>
							Сада је могуће изабрати продајно место за које ће се приказивати
							статистика.
						</p>
					</li>
					<li>
						<strong>Фокус поља у филтерима</strong>
						<p>
							Текстуална поља у филтерима табела се сада аутоматски фокусирају,
							као би унос био бржи.
						</p>
					</li>
					<li>
						<strong>Димензије и маса</strong>
						<p>
							Омогућен унос димензија и масе артикла.
							<br />
							<br />
							<img
								src="/images/about/changelog/dimensions.png"
								style={{ width: '50%' }}
							/>
						</p>
					</li>
					<li>
						<strong>Приступ опцијама фискалног рачуна</strong>
						<p>
							Сада можете приступити опцијама фискалног рачуна директно са
							листе.
							<br />
							<br />
							<img
								src="/images/about/changelog/receipt-options.png"
								style={{ width: '30%' }}
							/>
						</p>
					</li>
				</ul>
				<Typography.Title level={5}>
					<i className="fi fi-rr-ban-bug"></i> - Исправке грешака
				</Typography.Title>
				<ul>
					<li>
						<strong>Величина фонта након фискалног рачуна</strong>
						<p>
							Сада је сав текст након фискалног рачуна исписан мањим фонтом, у
							складу са спецификацијама.
						</p>
					</li>
					<li>
						<strong>Дозволе</strong>
						<p>
							Поправљени су проблеми са дозволама, односно некада су корисници
							видели функционалност за коју нису имали дозволу.
						</p>
					</li>
					<li>
						<strong>Плаћање</strong>
						<p>
							Преписана је цела компонента за плаћање, што је решило неке од
							проблема приликом издавања фискалних рачуна.
						</p>
					</li>
					<li>
						<strong>Негативни износи у КПО књизи</strong>
						<p>
							Поправљен је проблем са погрешним одузимањем фискалних рачуна
							уместо сабирањем у КПО књизи. Исправљени су сви до сада тако унети
							износи.
						</p>
					</li>
					<li>
						<strong>Избор године КПО књиге</strong>
						<p>
							Поправљен је проблем са нефункционалним избором године у КПО
							књизи.
						</p>
					</li>
					<li>
						<strong>Увоз е-Фактура</strong>
						<p>
							Поправљен је проблем који је проузроковао да увоз е-Фактура није
							никада почињао.
						</p>
					</li>
				</ul>
				<Typography.Title level={5}>
					<i className="fi fi-rr-lipstick"></i> - Козметичке измене
				</Typography.Title>
				<ul>
					<li>
						<strong>Нови брендинг апликације</strong>
						<p>
							Одлучили смо да променимо брендинг апликације и да јој променимо
							назив у <strong>Бади</strong>. Назив StoreBuddy смо сматрали нешто
							ограничавајућим фактором с обзиром да је уско везан за продавнице,
							а желимо да апликација иде у смеру да буде коришћена и у другим
							областима. Такође, нови назив је краћи и лакши за памћење.
							<br />
							<br />
							<video autoPlay loop height={100}>
								<source src="/videos/rename.mp4" type="video/mp4" />
							</video>
						</p>
					</li>
					<li>
						<strong>Нови дизајн апликације</strong>
						<p>
							Посебну пажњу смо посветили дизајну апликације и свакој компоненти
							апликације. Нови дизајн је чистији и једноставнији за коришћење.
							Иконице су једноставније и препознатљивије.
						</p>
					</li>
					<li>
						<strong>Побољшан изглед А4 фискалних рачуна</strong>
						<p>
							Измењени су неки елементи попут сепаратора, тако да сада модерније
							изгледају.
						</p>
					</li>
					<li>
						<strong>Статус везе са сервером</strong>
						<p>
							Статус везе са сервером је сада померен, и налази се преко
							иницијала корисника у облику кружића.
							<br />
							<br />
							<img src="/images/about/changelog/avatar.png" />
						</p>
					</li>
				</ul>
				<Typography.Title level={5}>
					<i className="fi fi-rr-tachometer-alt-fastest"></i> - Побољшања
					перформанси
				</Typography.Title>
				<ul>
					<li>
						<strong>Оптимизација</strong>
						<p>Разна побољшања перформанси</p>
					</li>
				</ul>
				<Typography.Title level={5}>
					<i className="fi fi-rr-settings"></i> - Остало
				</Typography.Title>
				<ul>
					<li>
						<strong>Ажурирање верзије Electron-а</strong>
						<p>
							Ажурирана је верзија Electron-а на којој ради апликација на
							последњу верзију која подржава Windows 7 оперативни систем,
							односно 22.3.27.
						</p>
					</li>
				</ul>
			</>
			<br />
			<Alert
				type="info"
				message={t`Испод се налази историја верзија апликације StoreBuddy, односно пре промене назива апликације.`}
			/>
			<br />
			<br />
			<strong>2.5.1</strong> - 12.09.2023.
			<br />
			<br />
			<ul>
				<li>
					🐛&nbsp;U retkim situacijama se dešavalo da Firefox sačuva podatke
					prethodnog računa i pošalje ih na V-PFR umesto novih podataka
				</li>
			</ul>
			<strong>2.5.0</strong> - 22.08.2023.
			<br />
			<ul>
				<li>🍾&nbsp;Dodatni kanali prodaje</li>
				<li>
					🐛&nbsp;Štampa ostvarenih popusta na fiskalnom računu nije uzimala
					količinu u obzir
				</li>
				<li>🐛&nbsp;Ispravljen problem sa dodavanjem varijanti</li>
				<li>⚡&nbsp;Razna poboljšanja performansi</li>
			</ul>
			<strong>2.4.2</strong> - 07.07.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Prikaz načina plaćanja po računu na pregledu poslednjih računa
				</li>
				<li>
					🐛&nbsp;Svaka instalacija sada ima unikatni clientId koji se može
					koristiti za pristup putem API-ja
				</li>
			</ul>
			<strong>2.4.1</strong> - 05.07.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Automatsko popunjavanje šifre delatnosti prilikom registracije
				</li>
				<li>🍾&nbsp;Štampa ostvarenog popusta na fiskalnom računu</li>
				<li>
					🍾&nbsp;PDF fakture se sada šalje kao prilog na sistem e-Faktura
				</li>
				<li>
					🍾&nbsp;Fakture poslate preko sistema e-Faktura su obeležene ikonicom
					na listi faktura
				</li>
				<li>
					🍾&nbsp;Sažetak iznosa fakture je sada vidljiv prilikom njenog
					kreiranja
				</li>
				<li>🍾&nbsp;Prikaz načina plaćanja po računu na pregledu dana</li>

				<li>🐛&nbsp;Računi su sada sortirani po vremenu na pregledu dana</li>
				<li>
					🐛&nbsp;Isravljeno računanje prethodnih avansa i popusta na fakturama
				</li>
				<li>
					🐛&nbsp;Popravljen obračun popusta kada se fiskalni račun šalje na
					e-mail
				</li>
				<li>🐛&nbsp;Popravljena štampa identifikacione oznake na fakturama</li>
				<li>🐛&nbsp;Popravljeno slanje popusta na sistem e-Faktura</li>
				<li>🐛&nbsp;Popravljeno slanje napomene na sistem e-Faktura</li>
				<li>
					🐛&nbsp;Popravljen uvoz informacija o preduzeću sa servisa NBS kada
					matični broj počinje sa 0
				</li>
				<li>🐛&nbsp;Popravljen uvoz KPO knjige sa Pausal aplikacije</li>
			</ul>
			<strong>2.4.0</strong> - 02.07.2023.
			<br />
			<ul>
				<li>🍾&nbsp;KPO knjiga</li>
				<li>🍾&nbsp;Omogućeno storniranje faktura</li>
				<li>🍾&nbsp;Omogućeno dodavanje priloga na fakturama</li>
				<li>🍾&nbsp;Dodata mogućnost za unos podrazumevane valute plaćanja</li>
				<li>
					🍾&nbsp;Dodata mogućnost izbora tekućih računa podrazumevanih za
					načine plaćanja na fakturi
				</li>
				<li>🐛&nbsp;Popravljena izmena tekućeg računa</li>
			</ul>
			<strong>2.3.3</strong> - 25.06.2023.
			<br />
			<ul>
				<li>🍾&nbsp;Podrška za PeriPage termalne štampače</li>
			</ul>
			<strong>2.3.2</strong> - 19.06.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Mogućnost izbora protokola prilikom dodavanja termalnog
					štampača
				</li>
			</ul>
			<strong>2.3.1</strong> - 12.06.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Mogućnost podrazumevanog podešavanja za avans bez poznatog
					konačnog iznosa
				</li>
				<li>
					🍾&nbsp;Definisanje popusta po kategoriji, artiklu, vremenskom
					periodu...
				</li>
				<li>🐛&nbsp;Popravljen prikaz fiskalnog računa u aplikaciji</li>
				<li>
					🐛&nbsp;Dodatan tekst se sada ispisuje na preuzetoj verziji fiskalnog
					računa
				</li>
			</ul>
			<strong>2.3.0</strong> - 09.06.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Mogućnost izrade i slanja faktura putem sistema e-Faktura i
					putem E-maila
				</li>
				<li>🍾&nbsp;Razdvojena su polja za adresu kompanije</li>
				<li>
					🍾&nbsp;Prilikom registracije se sada automatski kreira prodajno mesto
				</li>
				<li>🍾&nbsp;NBS IPS QR na dijalogu za unos plaćanja fakture</li>
				<li>🍾&nbsp;Mogućnost dodavanja lokacija partnerima</li>
				<li>🐛&nbsp;Popravljen problem sa brisanjem opisa kategorije</li>
				<li>🐛&nbsp;Popravljene dozvole za modul Partneri</li>
				<li>🐛&nbsp;Popravljen uvoz pojedinih e-faktura</li>
				<li>🐛&nbsp;Popravljen povremen prikaz pogrešnih boja na grafikonu</li>
				<li>
					🐛&nbsp;Ispravljeno ponašanje izbora vremenskog okvira na periodičnom
					izveštaju
				</li>
				<li>💄&nbsp;Redizajnirana stranica za prijavljivanje</li>
				<li>💄&nbsp;Redizajnirana komponenta za unos tekućih računa</li>
			</ul>
			<strong>2.2.0</strong> - 01.06.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Dodata mogućnost da se na računu više puta doda isti artikal,
					za artikle na kojima je ta opcija omogućena
				</li>
				<li>
					🍾&nbsp;Dodata mogućnost štampe dodatnog teksta nakon fiskalnog računa
				</li>
				<li>
					🍾&nbsp;Dodata mogućnost za izdavanje avansnih računa bez poznate
					konačne vrednosti
				</li>
				<li>💄&nbsp; Kozmetičke izmene prozora za plaćanje</li>
				<li>
					🐛&nbsp;Popravljeno dodavanje artikla sa novokreiranom kategorijom
				</li>
			</ul>
			<strong>2.1.19</strong> - 08.05.2023.
			<br />
			<ul>
				<li>🍾&nbsp;Omogućeno dodavanje popusta na celom računu</li>
			</ul>
			<strong>2.1.18</strong> - 20.03.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;U pojedinim situacijama se na POS terminal slala vrednost sa
					više od 2 decimale, što je rezultiralo odbijanjem transakcije
				</li>
			</ul>
			<strong>2.1.17</strong> - 16.03.2023.
			<br />
			<ul>
				<li>🐛&nbsp;Popust se više ne gubi ponovnim kucanjem istog artikla</li>
			</ul>
			<strong>2.1.16</strong> - 07.03.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Omogućena pretraga po opisu proizvoda (potrebno je uključiti u
					podešavanjima preduzeća)
				</li>
				<li>🐛&nbsp;Popravljen problem sa izdavanjem kopije refundacije</li>
			</ul>
			<strong>2.1.15</strong> - 06.03.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen unos cene za usluge i digitalne proizvode na
					varijantama
				</li>
			</ul>
			<strong>2.1.14</strong> - 01.03.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Omogućen unos referentnog broja računa prilikom izdavanja
					računa
				</li>
				<li>
					🐛&nbsp;Sprečeno izdavanje refundacije avansa ukoliko je iznos
					refundacije 0
				</li>
			</ul>
			<strong>2.1.13</strong> - 01.03.2023.
			<br />
			<ul>
				<li>
					🍾&nbsp;Omogućeno dodavanje avansne uplate na račun koji je izdat pre
					početka primene eFiskalizacije
				</li>
			</ul>
			<strong>2.1.12</strong> - 25.02.2023.
			<br />
			<ul>
				<li>🐛&nbsp;Rešen problem duple inicijalizacije V-PFR biblioteke</li>
				<li>🐛&nbsp;Popravljen editor opisa artikla</li>
			</ul>
			<strong>2.1.11</strong> - 21.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa slanjem računa na server posle neuspešne
					sinhronizacije
				</li>
			</ul>
			<strong>2.1.10</strong> - 16.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljeno preskakanje simbola "," i "." prilikom unosa šifre
				</li>
				<li>🍾&nbsp;Obaveštenje o novoj verziji aplikacije</li>
			</ul>
			<strong>2.1.9</strong> - 16.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa nepokretanjem sinhronizacije neposlatih
					računa
				</li>
			</ul>
			<strong>2.1.8</strong> - 12.02.2023.
			<br />
			<ul>
				<li>🐛&nbsp;Popravljen problem sа pojedinim dozvolama</li>
			</ul>
			<strong>2.1.7</strong> - 08.02.2023.
			<br />
			<ul>
				<li>🐛&nbsp;Bolje izveštavanje o greškama prilikom izdavanja računa</li>
			</ul>
			<strong>2.1.6</strong> - 07.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa preskakanjem karaktera dobijenih sa bar
					kod čitača u režimu emulacije tastature
				</li>
			</ul>
			<strong>2.1.5</strong> - 07.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa obrisanim korisnicima koji su ostali
					ulogovani
				</li>
			</ul>
			<strong>2.1.4</strong> - 06.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa štampom fonta variabilne širine na
					termalnom štampaču
				</li>
			</ul>
			<strong>2.1.3</strong> - 03.02.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa slanjem pogrešnog računa na e-mail
				</li>
			</ul>
			<strong>2.1.2</strong> - 18.01.2023.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa povremenim štampanjem/slanjem prethodnog
					računa umesto upravo izdatog
				</li>
			</ul>
			<strong>2.1.1</strong> - 11.01.2023.
			<br />
			<ul>
				<li>🐛&nbsp;Popravljen problem sa izmenom korisnika</li>
			</ul>
			<strong>2.1.0</strong> - 30.12.2022.
			<br />
			<ul>
				<li>🍾&nbsp;Inicijalna podrška za aplikacije</li>
				<li>🍾&nbsp;Prijem e-faktura</li>
				<li>🍾&nbsp;Moguće je isključiti zvučne efekte</li>
				<li>
					🍾&nbsp;Omogućeno zatvaranje avansnih računa koji su izdati pre
					početka primene eFiskalizacije
				</li>
				<li>💄&nbsp;Nova ikonica</li>
				<li>
					💄&nbsp;Poboljšan korisnički interfejs u slučaju greške prilikom
					naplate karticama
				</li>
				<li>🐛&nbsp;Popravljena komponenta za unos bankovnih računa</li>
				<li>
					🐛&nbsp;Popravljena komponenta za unos kontakt informacija partnera
				</li>
				<li>🐛&nbsp;Popravljen unos kategorija za artikal</li>
			</ul>
			<strong>2.0.5</strong> - 26.12.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljen problem sa refundacijom računa koji su nastali pre
					početka primene eFiskalizacije.
				</li>
			</ul>
			<strong>2.0.4</strong> - 20.12.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp;Na uredjajima sa ekranom na dodir nije bilo moguće otvoriti
					pod menije
				</li>
			</ul>
			<strong>2.0.3</strong> - 19.12.2022.
			<br />
			<ul>
				<li>🍾&nbsp;Akcije su sada fiksirane sa desne strane tabele</li>
				<li>
					🐛&nbsp;Popravljen unos cena sa decimalama na pojedinim uređajima i
					operativnim sistemima
				</li>
				<li>
					🐛&nbsp;Popravljen problem sa pogrešnim sabiranjem cene ukoliko je
					prilikom izmene stavke računa uneta cena sa decimalama
				</li>
				<li>
					🐛&nbsp;Na računu štampanom sa varijabilnom širinom fonta je potpis
					kupca bio na pogrešnom mestu
				</li>
			</ul>
			<strong>2.0.2</strong> - 17.12.2022.
			<br />
			<ul>
				<li>🐛&nbsp;Popravljen problem sa dozvolama za kreiranje artikala</li>
			</ul>
			<strong>2.0.1</strong> - 15.12.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp;Popravljena štampa fiskalnih računa u tamnom režimu aplikacije
				</li>
			</ul>
			<strong>2.0.0</strong> - 14.12.2022.
			<br />
			<ul>
				<li>⚡&nbsp;Poboljšane performase cele aplikacije</li>
				<li>⚡&nbsp;Zamenjen WYSIWYG editor za manji i brži</li>
				<li>⚡&nbsp;Poboljšane performanse pretrage</li>
				<li>💄&nbsp; Kozmetičke izmene</li>
				<li>
					🍾&nbsp;Aplikaciju je sada moguće koristiti i putem web pregledača na
					mobilnim uređajima.
				</li>
				<li>🍾&nbsp;Aplikacija se sada prilagođava veličini ekrana</li>
				<li>🍾&nbsp;Podrška za tamni režim</li>
				<li>
					🍾&nbsp;Grafikon na početnoj strani sada prikazuje više informacija
				</li>
				<li>
					🍾&nbsp;Aplikacija sada održava konstantnu vezu sa serverom kako bi se
					informacije ažurirale u realnom vremenu
				</li>
				<li>
					🍾&nbsp;Poboljšano prikazivanje grešaka koje aplikacija dobija od
					PFR-a
				</li>
				<li>
					🍾&nbsp;Izbor trenutnog prodajnog mesta je premešten u korisnički meni
				</li>
				<li>
					🍾&nbsp;Zvučni signali prilikom dodavanja artikala na račun, brisanja
					artikala sa računa ili ukoliko artikal nije pronađen
				</li>
				<li>🍾&nbsp;Izdavanje računa putem API servisa</li>
				<li>
					🍾&nbsp;Mogućnost štampanja fiskalnog računa latiničnim pismom i na
					engleskom jeziku
				</li>
				<li>
					🍾&nbsp;Mogućnost štampanja fiskalnog računa fontom varijabilne širine
				</li>
				<li>
					🍾&nbsp;Omogućeno dodavanje kategorija iz prozora za dodavanje
					artikala
				</li>
				<li>
					🍾&nbsp;Prazan prostor oko logo-a na fiskalnom računu se sada uklanja
				</li>
				<li>🍾&nbsp;Omogućena ponovna štampa fiskalnog računa</li>
				<li>🍾&nbsp;Omogućeno naknadno slanje fiskalnog računa na e-mail</li>
				<li>🍾&nbsp;Omogućeno preuzimanje fiskalnog računa u PDF formatu</li>
				<li>
					🍾&nbsp;Ukoliko je odabran tip kupca, sada je obavezan i unos broja
					dokumenta
				</li>
				<li>
					🍾&nbsp;Ukoliko je odabran tip opcionog polja kupca, sada je obavezan
					i unos broja dokumenta
				</li>
				<li>🍾&nbsp;Promenjen način unosa varijanti</li>
				<li>🍾&nbsp;Referentni dokument je sada linkovan na listi računa</li>
				<li>🍾&nbsp;Sada je moguće obrisati logo preduzeća</li>
				<li>
					🐛&nbsp;Promenjen način veze sa POS terminalom, tako da se sada veza
					ostvaruje kada je potrebno izvršiti naplatu, i nakon toga se zatvara.
					Ranije je veza ostajala otvorena, što je uzrokovalo probleme nakon
					dužeg vremena rada aplikacije.
				</li>
				<li>
					🐛&nbsp;Popravljen problem sa unosom PIN koda bezbednosnog elementa
					ukoliko se korisnik nalazi na kasi.
				</li>
				<li>
					🐛&nbsp;Poboljšano prikazivanje iskačućih panela, odnosno njihov
					redosled prikaza se više neće obrnuti tako da noviji otvoreni panel
					bude iza starijeg, što se u pojedinim slučajevima dešavalo
				</li>
				<li>
					🐛&nbsp;Promenjen način slanja iznosa gotovine PFR-u, tako da se sada
					šalje stvarno naplaćen iznos, odnosno umanjen za povraćaj
				</li>
				<li>
					🐛&nbsp;Popravljeno štampanje periodičnog izveštaja na termalnom
					štampaču ukoliko je uključena opcija štampanja iznosa po načinu
					plaćanja
				</li>
			</ul>
			<strong>1.2.19</strong> - 29.10.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp;Ispravljen još jedan problem sa obračunom PDV-a kod avansnih
					računa ukoliko je odabran SNPDV ili LNPDV kao tip opcionog polja kupca
				</li>
			</ul>
			<strong>1.2.18</strong> - 27.09.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp;Ispravljen problem sa obračunom PDV-a kod avansnih računa
					ukoliko je odabran SNPDV ili LNPDV kao tip opcionog polja kupca
				</li>
			</ul>
			<strong>1.2.17</strong> - 15.07.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp;Omogućeno je storniranje računa prema uputstvu iz tehničkog
					vodiča
				</li>
				<li>🍾&nbsp;Prikaz statusa računa u tabeli</li>
				<li>
					🐛&nbsp;Ukoliko je korisnik greškom umesto zatvaranja avansa, isti
					samo refundirao, zatvaranjem avansa će se izvršiti samo taj korak, bez
					refundacije.
				</li>
				<li>
					🐛&nbsp;Ukoliko je bilo više vezanih avansnih uplata, zatvaranjem
					avansnog računa bi se refundirao samo odabrani avansni račun umesto
					svih. Ovaj problem je sada popravljen.
				</li>
			</ul>
			<strong>1.2.16</strong> - 08.07.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp;Potencijalno rešen problem sa neispravnim ponašanjem liste
					dostupnih štampača kod nekih korisnika
				</li>
			</ul>
			<strong>1.2.15</strong> - 08.07.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp;Omogućena izmena korisničkog naloga za korisnike Lite paketa
				</li>
				<li>🐛&nbsp;Omogućena refundacija zatvorenog avansnog računa</li>
			</ul>
			<strong>1.2.14</strong> - 03.07.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp;Dodata opcija za štampanje periodičnog izveštaja po načinu
					plaćanja
				</li>
				<li>
					🍾&nbsp;Dodata opcija za razdvajanje izveštaja prodatih artikala po
					načinu plaćanja
				</li>
			</ul>
			<strong>1.2.13</strong> - 24.06.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp;Dodata opcija za štampanje liste prodatih artikala na
					periodičnom izveštaju
				</li>
			</ul>
			<strong>1.2.12</strong> - 12.06.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Dodata opcija štampanja broja tekućeg računa i poziva na broj
					na kraju fiskalnog računa
				</li>
				<li>🍾&nbsp; Omogućena promena širine panela sa artiklima na kasi</li>
				<li>⚡&nbsp; Poboljšane performanse aplikacije</li>
			</ul>
			<strong>1.2.11</strong> - 10.06.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Broj računa je dodat na periodični izveštaj (samo na A4)
				</li>
				<li>
					🍾&nbsp; Podrška za vagu (samo Elicom vage u režimu kontinuiranog
					slanja težine)
				</li>
				<li>
					🍾&nbsp; Mogućnost izbora prikaza panela sa artiklima na kasi (lista i
					mreža)
				</li>
				<li>
					🐛&nbsp; Popravljen problem da kada se nakon pretrage ne unese
					količina, ona bude NaN
				</li>
			</ul>
			<strong>1.2.10</strong> - 04.06.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Promet bez poreza je dodat na periodični izveštaj (samo na
					A4)
				</li>
			</ul>
			<strong>1.2.9</strong> - 04.06.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Omogućena promena lozinke korisnika</li>
				<li>
					🐛&nbsp; Ispravljen problem sa dozvolama za kreiranje artikala i
					kategorija
				</li>
				<li>🐛&nbsp; Popravljen prikaz varijanti na listi artikala</li>
			</ul>
			<strong>1.2.8</strong> - 04.06.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Periodični izveštaj</li>
				<li>
					🐛&nbsp; Sprečena višestruka sinhronizacija istih računa prilikom
					prekida internet veze
				</li>
			</ul>
			<strong>1.2.7</strong> - 27.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Popravljen problem koji je prouzrokovao da se za LNPDV, SNPDV
					i PPO-PDV obračunava PDV ukoliko se račun šalje na e-mail
				</li>
				<li>
					🐛&nbsp; Potencijalno popravljen problem sa pogrešnim obračunavanjem
					srednjeg kursa u retkim situacijama
				</li>
			</ul>
			<strong>1.2.6</strong> - 23.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Popravljen problem što se prilikom zatvaranja avansnog računa
					u polju za reklamu prikazivao pogrešan datum poslednjeg avansnog
					računa
				</li>
			</ul>
			<strong>1.2.5</strong> - 21.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Ispravka za pogrešnu vremensku zonu nije funkcionisala na
					svim računarima.
				</li>
				<li>
					🐛&nbsp; Sprečeno dupliranje artikala u pretrazi prilikom dodavanja
					ili izmene artikala.
				</li>
				<li>
					🐛&nbsp; Ispravljen pogrešan način plaćanja prilikom izrade kopije
					računa.
				</li>
			</ul>
			<strong>1.2.4</strong> - 20.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Ispravljeno slanje vremena PFR-u u pogrešnoj vremenskoj zoni
					(UTC umesto lokalnog vremena)
				</li>
			</ul>
			<strong>1.2.3</strong> - 20.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Ispravljen problem da dugme za potvrdu plaćanja nije bilo
					omogućeno u nekim slučajevima kada je unet datum avansnog računa.
				</li>
			</ul>
			<strong>1.2.2</strong> - 20.05.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Usklađen izbor vrste prometa sa zahtevima Poreske uprave.
					Vrsta prometa se može odabrati samo prilikom registracije preduzeća.
				</li>
				<li>🍾&nbsp; Nove ikonice</li>
				<li>
					🐛&nbsp; Mrežni termalni štampač više ne drži otvorenu konekciju već
					se konektuje prilikom štampanja svakog računa. Rešava problem sa
					štampom sa više računara ukoliko štampač ne podržava više konekcija.
				</li>
			</ul>
			<strong>1.2.1</strong> - 18.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Uklonjeno periodično slanje komande štampaču radi održavanja
					konekcije jer prouzrukuje probleme
				</li>
			</ul>
			<strong>1.2.0</strong> - 16.05.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Omogućen izbor dostupnih metoda plaćanja. Sada je moguće
					izabrati da budu dostupne sve metode plaćanja ili samo gotovina,
					prenos na račun, vaučer i drugo bezgotovinsko plaćanje za prodajna
					mesta koja vrše promet hrane i pića koja se konzumiraju na licu mesta.
				</li>
				<li>
					🍾&nbsp; Omogućen izbor redosleda načina plaćanja, kao i koji načini
					plaćanja će biti sakriveni na dijalogu za plaćanje.
				</li>
				<li>
					🍾&nbsp; Prilikom refundacije, kopija računa sa mestom za potpis se
					sada izdaje samo u slučaju da je metoda plaćanja gotovina
				</li>
				<li>
					🍾&nbsp; Dodato obaveštenje o uspešno izdatom računu i iznosu
					povraćaja novca
				</li>
				<li>
					🐛&nbsp; Mehanizam ponovnog pokušaja štampe za serijske i mrežne
					termalne štampače
				</li>
				<li>🐛&nbsp; Ujednačena širina teksta na celom fiskalnom računu</li>
				<li>
					🐛&nbsp; Popravljen prikaz ispravne valute ukoliko se sa prikaza
					kategorija na kasi odabere proizvod koji ima varijante
				</li>
				<li>
					🐛&nbsp; Aplikacija više ne štampa račun na termalnom štampaču ukoliko
					je on isključen, a račun je poslat na e-mail
				</li>
				<li>
					🐛&nbsp; Ukoliko se vrši akcija na referentnom računu (kopija,
					refundacija, itd.), a nije odabrano prodajno mesto, aplikacija će
					zahtevati odabir prodajnog mesta
				</li>
				<li>⚡&nbsp; Poboljšane performase generisanja računa za štampu</li>
			</ul>
			<strong>1.1.4</strong> - 12.05.2022.
			<br />
			<ul>
				<li>🐛&nbsp; Omogućen unos ":" u polju identifikacije kupca</li>
			</ul>
			<strong>1.1.3</strong> - 12.05.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Ispravljena greška pri refundaciji avansa izdatih po sistemu
					pre verzije 1.1.0
				</li>
				<li>
					🍾&nbsp; Dodat mehanizam za prepoznavanje nedostupnosti sertifikata
					ili problema u radu V-PFR-a
				</li>
			</ul>
			<strong>1.1.2</strong> - 09.05.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Omogućeno ručno unošenje podataka za preduzeća čiji se podaci
					ne nalaze u bazi podataka Narodne Banke Srbije
				</li>
				<li>
					🐛&nbsp; Popravljena sinhronizacija prodaja ukoliko je došlo do
					prekida interneta, ili je server bio nedostupan tokom izdavanja
					računa.
				</li>
			</ul>
			<strong>1.1.1</strong> - 09.05.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Dodat izbor komande koja se koriti za štampanje računa</li>
			</ul>
			<strong>1.1.0</strong> - 09.05.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Avansni računi su izmenjeni prema novom tehničkom uputstvu
					Poreske uprave
				</li>
				<li>
					🍾&nbsp; Poboljšano štampanje logo-a preduzeća. Sada se nijanse sive
					štampaju dither metodom.
				</li>
				<li>
					🍾&nbsp; Dodata pauza između uzastopnih računa (trajanje se može
					konfigurisati)
				</li>
				<li>🐛&nbsp; Popravljen filter računa po datumu</li>
				<li>
					🐛&nbsp; Poboljšana detekcija završetka štampe na štampačima preko
					serijskog porta
				</li>
				<li>
					🐛&nbsp; Popravljena povremena ispadanja teksta van okvira papira na
					nekim štampačima
				</li>
			</ul>
			<strong>1.0.16</strong> - 06.05.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Prilikom odabira PPO-PDV dokumenta, na artiklima se ne
					obračunava PDV
				</li>
			</ul>
			<strong>1.0.15</strong> - 03.05.2022.
			<br />
			<ul>
				<li>🐛&nbsp; Popravljen problem prilikom dodavanja uređaja</li>
			</ul>
			<strong>1.0.14</strong> - 01.05.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Mogućnost biranja poreskog tretmana za dokumenta LNPDV i
					SNPDV
				</li>
				<li>
					🐛&nbsp; Popravljena validacija polja ID kupca i Opciono polje kupca
				</li>
			</ul>
			<strong>1.0.13</strong> - 30.04.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Mogućnost refundacije računa koji su nastali pre početka
					korišćenja aplikacije, ili su izdati po starom modelu fiskalizacije
				</li>
				<li>⚡&nbsp; Poboljšane performanse kase i proizvoda</li>
			</ul>
			<strong>1.0.12</strong> - 28.04.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Mogućnost dodavanja šifre proizvođača uz proizvod</li>
				<li>🐛&nbsp; Popravljeno nekoliko grešaka prilikom uvoza</li>
				<li>🐛&nbsp; Popravljen unos količine prilikom skeniranja bar koda</li>
			</ul>
			<strong>1.0.11</strong> - 28.04.2022.
			<br />
			<ul>
				<li>🐛&nbsp; Popravljeno povezivanje POS terminala</li>
				<li>🐛&nbsp; Popravljena štampa QR koda na A4 formatu</li>
				<li>
					🐛&nbsp; Popravljena greška koja je uzrokovala da neki elementi prvog
					odštampanog računa budu van dimenzija papira
				</li>
			</ul>
			<strong>1.0.10</strong> - 28.04.2022.
			<br />
			<ul>
				<li>⚡&nbsp; Ubrzano generisanje računa za štampu</li>
				<li>
					🍾&nbsp; Mogućnost štampe logo-a preduzeća i dodatnog nefiskalnog
					teksta
				</li>
				<li>🐛&nbsp; Popravljeno povezivanje bar kod čitača</li>
			</ul>
			<strong>1.0.9</strong> - 27.04.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Mogućnost podešavanja rezolucije termalnog štampača</li>
			</ul>
			<strong>1.0.8</strong> - 27.04.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Podrška za otvaranje fioke za novac</li>
				<li>
					💄&nbsp; Štampa na termalnom štampaču sada zauzima potpunu širinu
					papira
				</li>
				<li>🐛&nbsp; Popravljeno dodavanje mrežnih termalnih štampača</li>
				<li>🐛&nbsp; Popravljeno dodavanje korisnika</li>
				<li>
					🐛&nbsp; Popravljena podrška za termalne štampače preko serijskog
					porta
				</li>
				<li>🐛&nbsp; Popravljena pozicija sečenja papira</li>
				<li>
					🐛&nbsp; Popravljena greška da račun nije mogao da se fiskalizuje
					ukoliko se na njemu nalazi uvezen artikal bez GTIN-a
				</li>
			</ul>
			<strong>1.0.7</strong> - 24.04.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Kod naziva kategorija na kasi se sada ispisuje broj
					kategorija i artikala koji se nalaze u njoj
				</li>
				<li>💄&nbsp; Popravljen font na pojedinim web browserima</li>
				<li>
					💄&nbsp; Podešene margine grafikona tako da sadržaj ne bude odsečen
				</li>
				<li>
					🐛&nbsp; Popravljena greška da se preostali iznos avansog računa ne
					izračunava kako treba ukoliko postoji artikal čija je cena u stranoj
					valuti a izabran je drugi datum izdavanja računa
				</li>
			</ul>
			<strong>1.0.6</strong> - 21.04.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Aplikacija je odobrena za upotrebu od strane Poreske Uprave
					Republike Srbije
				</li>
				<li>
					🐛&nbsp; Popravljena greška da prilikom refundacije nekih tipova
					računa nije tražena identifikacija kupca
				</li>
				<li>
					🐛&nbsp; Popravljena greška da prilikom refundacije nije štampano
					referentno vreme na kopiji računa.
				</li>
			</ul>
			<strong>1.0.5</strong> - 21.04.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Kurnsa lista se sada periodično učitava, pa nije više
					potrebno restartovati aplikaciju da bi se učitala nova
				</li>
				<li>🍾&nbsp; Uvoz/Izvoz artikala sada podržava strane valute</li>
				<li>🍾&nbsp; Upozorenje na modulu kasa ukoliko artikal nema cenu</li>
				<li>
					🐛&nbsp; Ispravljena greška koja je uzrokovala da artikli u stranoj
					valuti imaju cenu 0 RSD prilikom dodavanja na račun
				</li>
				<li>
					🐛&nbsp; Ispravljena greška koja je uzrokovala da se prodajno mesto ne
					može sačuvati ukoliko je adresa prazna
				</li>
				<li>
					🐛&nbsp; Popravljen izvoz artikala ukoliko proizvod ima varijante
				</li>
				<li>
					🐛&nbsp; Valuta RSD se sada uvek pojavljuje u padajućem meniju
					prilikom dodavanja/izmene artikla
				</li>
			</ul>
			<strong>1.0.4</strong> - 20.04.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Poreske oznake su izmenjene tako da se može postaviti više
					poreskih oznaka po artiklu
				</li>
			</ul>
			<strong>1.0.3</strong> - 19.04.2022.
			<br />
			<ul>
				<li>
					🐛&nbsp; Ispravljena je greška koja je onemogućavala izbor kategorije
					na artiklu ukoliko u njoj postoje podkategorije
				</li>
				<li>
					🐛&nbsp; Ispravljena greška usled koje samostalna aplikacija nije bila
					u mogućnosti da se poveže na razvojni LPFR
				</li>
				<li>
					🐛&nbsp; Artikli koji više nisu dostupni se uklanjaju iz kase, i nije
					ih moguće otvoriti iz izveštaja.
				</li>
			</ul>
			<strong>1.0.2</strong> - 19.04.2022.
			<br />
			<ul>
				<li>
					🍾&nbsp; Mogućnost izbora dostupnih metoda izdavanja računa po
					prodajnom mestu
				</li>
				<li>
					💄&nbsp; Promenjen prikaz u kalendarskom pregledu računa kako bi dani
					kada je bilo računa bili uočljiviji
				</li>
				<li>
					💄&nbsp; Dodato pojašnjenje u vezi naziva i adrese prodajnog mesta
				</li>
				<li>
					💄&nbsp; Iznos popusta u modulu kasa se sada ne prelama u više redova
				</li>
				<li>
					🐛&nbsp; Unos adrese i broja telefona prodajnog mesta nije više
					obavezan prilikom registracije
				</li>
				<li>
					🐛&nbsp; Prilikom izbora kategorija, više se neće birati cele
					kategorije ukoliko su sve podkategorije izabrane
				</li>
				<li>
					🐛&nbsp; U izboru kategorije u modulu kasa, u korenoj kategoriji se
					sada prikazuju artikli bez kategorije
				</li>
			</ul>
			<strong>1.0.1</strong> - 18.04.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Podrška za webp format</li>
				<li>
					🍾&nbsp; Podrška za termalne štampače povezane putem serijskog porta
				</li>
				<li>
					🍾&nbsp; Mogućnost definisanja cene artikla u stranoj valuti i
					automatska konverzija po srednjem kursu NBS
				</li>
				<li>
					🐛&nbsp; Ispravljena greška prilikom izbora korene kategorije za oca
				</li>
				<li>
					🐛&nbsp; Aplikacija sada izveštava da postoji greška ukoliko je dobije
					od API-ja
				</li>
				<li>
					🐛&nbsp; Sužena površina štampe jer na nekim termalnim štampačima nije
					bilo moguće štampati van određenih granica
				</li>
				<li>
					🐛&nbsp; Popravljena greška usled koje se nije štampalo više od 50
					linija fiskalnog računa
				</li>
				<li>
					🐛&nbsp; Popravljena greška koja je uzrokovala da se prilikom
					dodavanja prodajnog mesta iz modula kasa, pritiskom na numeričke
					karaktere, otvarao prozor za unos šifre proizvoda.
				</li>
				<li>
					🐛&nbsp; Popravljena je validacija GTIN-a prilikom unosa proizvoda.
				</li>
				<li>
					🐛&nbsp; Popravljena je validacija naziva varijante prilikom unosa
					proizvoda.
				</li>
			</ul>
			<strong>1.0.0</strong> - 08.04.2022.
			<br />
			<ul>
				<li>🍾&nbsp; Inicijalna verzija</li>
			</ul>
		</div>
	);
}

export default forwardRef(About);
