import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { t } from '@lingui/macro';
import { Divider, Input, InputNumber, Tag, Typography } from 'antd';
import { round } from 'lodash';
import { useMemo, useRef, useState } from 'react';

import ProductInput from '../../../../../../components/Inputs/ProductInput';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import numberFormat from '../../../../../../lib/numberFormat';
import { deductTax } from '../../../../../../lib/tax';
import stores from '../../../../../../stores/index.mobx';
import { flowResult } from 'mobx';
import { bignumber, evaluate } from 'mathjs';
import { Dayjs } from 'dayjs';
type Props = {
	value?: StockCheckupItem[];
	warehouseId: string;
	date: Dayjs;
};

type StockCheckupItem = {
	productId: string;
	quantity: number;
	purchasePrice: number;
	purchaseAmount: number;
	salePrice: number;
	salePriceWithVat: number;
	saleAmount: number;
};

export default function StockCheckupsItemsInput({
	value,
	warehouseId,
	date,
	...props
}: Props) {
	const {
		stockCheckups: { fetchQuantityAndPrice, isFetchingQuantityAndPrice },
		products: { byId: productsById },
		taxRates,
	} = stores;

	const editableFormRef = useRef<EditableFormInstance>();
	const [initialValue, setInitialValue] = useState<Partial<StockCheckupItem>>(
		{}
	);

	const columns = useMemo(() => {
		return [
			{
				title: t`Артикал`,
				dataIndex: 'productId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (_, { isEditable, record, recordKey }) => {
					return isEditable ? (
						<ProductInput
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[value];

									if (!product) {
										return;
									}

									const taxRate =
										taxRates.byLabel(product?.taxRateLabel || '')?.rate || 0;

									let purchasePrice = 0;

									if (value) {
										const quantityAndPrice = await flowResult(
											fetchQuantityAndPrice(product.id, warehouseId, date)
										);

										if (quantityAndPrice) {
											purchasePrice =
												quantityAndPrice.averagePurchasePrice || 0;
										}
									}

									const salePriceWithVat = Number(
										product?.getPriceForWarehouse?.(record.warehouseId) ||
											product?.price ||
											0
									);
									const salePrice = deductTax(salePriceWithVat, taxRate);

									const purchaseAmount = round(
										evaluate('purchasePrice * quantity', {
											purchasePrice: bignumber(purchasePrice),
											quantity: bignumber(record.quantity || 0),
										}).toNumber(),
										4
									);

									const saleAmountWithVat = round(
										evaluate('salePriceWithVat * quantity', {
											salePriceWithVat: bignumber(salePriceWithVat),
											quantity: bignumber(record.quantity || 0),
										}).toNumber(),
										4
									);
									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										salePrice,
										saleAmountWithVat,
										purchasePrice,
										purchaseAmount,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							<Typography.Text type="secondary">{product.sku}</Typography.Text>
							<Divider type="vertical" />
							{product.parent.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product.variantName}
							</Tag>
						</>
					) : (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.name}
						</>
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'quantity',
				width: 90,
				formItemProps: {
					initialValue: 0,
					rules: [
						{
							required: true,
							message: t`Количина је обавезна`,
						},
						{
							type: 'number',
							min: 0.001,
							message: t`Количина мора бити већа од 0`,
						},
					],
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							step={1}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									editableFormRef.current?.setRowData?.(recordKey, {
										purchaseAmount: round(
											Number(value) * (record.purchasePrice || 0),
											4
										),
										saleAmountWithVat: round(
											Number(value) * (record.salePriceWithVat || 0),
											4
										),
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Набавна цена`,
				dataIndex: 'purchasePrice',
				width: 120,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber type="number" disabled style={{ width: '100%' }} />
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Набавна вредност`,
				dataIndex: 'purchaseAmount',
				width: 150,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber type="number" disabled style={{ width: '100%' }} />
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Продајна цена`,
				dataIndex: 'salePrice',
				width: 120,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber type="number" disabled style={{ width: '100%' }} />
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Продајна цена са ПДВ`,
				dataIndex: 'salePriceWithVat',
				width: 180,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber type="number" disabled style={{ width: '100%' }} />
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Прод. вредност са ПДВ`,
				dataIndex: 'saleAmountWithVat',
				width: 180,
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber type="number" disabled style={{ width: '100%' }} />
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
		];
	}, [date, fetchQuantityAndPrice, productsById, taxRates, warehouseId]);

	return (
		<TableInputProTable<StockCheckupItem>
			editableFormRef={editableFormRef}
			// shouldShowDuplicateButton
			shouldShowIndexColumn
			addButtonText={t`Додај артикал`}
			emptyText={t`Нема ставки`}
			iconPath="/images/icons/new/trolley.svg"
			columns={columns}
			value={value}
			initialValue={initialValue}
			tableProps={{
				loading: isFetchingQuantityAndPrice,
				scroll: {
					x: 1740,
				},
			}}
			{...props}
		/>
	);
}
