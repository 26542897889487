import { t } from '@lingui/macro';
import { Modal, Form, InputNumber, Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useRef } from 'react';

import { LocalSale } from '../../../stores/LocalSale.mobx';
import { flowResult } from 'mobx';

type Props = {
	open: boolean;
	close(): void;
	localSale: LocalSale;
};

function EnterCustomerModal({ open: visible, close, localSale }: Props) {
	const [form] = Form.useForm();
	const inputRef = useRef(null);

	const { isFetchingCustomer } = localSale;

	useEffect(() => {
		if (visible) {
			const intervalId = setInterval(() => {
				if (inputRef.current && inputRef.current !== document.activeElement) {
					inputRef.current?.select();
				}
			}, 100);
			return () => {
				clearInterval(intervalId);
			};
		} else {
			form.resetFields();
		}
	}, [visible, form]);

	const submit = useCallback(() => {
		form.validateFields().then((values) => {
			flowResult(localSale.fetchCustomer(values.code))
				.then(() => {
					close();
				})
				.catch(() => {
					form.setFields([
						{
							name: 'code',
							errors: [t`Купац са унесеним чланским бројем није пронађен`],
						},
					]);
				});
		});
	}, [form, localSale, close]);

	return (
		<Modal
			centered
			width={400}
			visible={visible}
			title={t`Унос купца`}
			onCancel={close}
			onOk={submit}
			okButtonProps={{
				disabled: isFetchingCustomer,
			}}
		>
			<Spin spinning={isFetchingCustomer}>
				<Form layout="vertical" form={form}>
					<Form.Item
						label={t`Унесите члански број купца`}
						name="code"
						rules={[
							{ required: true, message: t`Члански број купца је обавезан` },
							{
								type: 'number',
								validator: async (rule, value) => {
									if (value < 1 || value > 99999999999999) {
										throw new Error(t`Неисправан члански број купца`);
									}
								},
								message: t`Неисправан члански број купца`,
							},
						]}
					>
						<InputNumber
							ref={inputRef}
							onPressEnter={() => {
								submit();
							}}
							style={{ width: '100%' }}
						/>
					</Form.Item>
				</Form>
			</Spin>
		</Modal>
	);
}

export default observer(EnterCustomerModal);
