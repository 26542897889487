import { Trans, t } from '@lingui/macro';
import { Button, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import ExcelJS from 'exceljs';
import cyr2lat from 'cyrillic-to-latin';

import styles from './HeaderComponent.module.less';
import WarehouseSelect from '../../../../../components/Selects/WarehouseSelect';
import stores from '../../../../../stores/index.mobx';
import round from 'lodash/round';
import { saveAs } from 'file-saver';
import { ArrayParam, useQueryParam } from 'use-query-params';
function HeaderComponentComponent() {
	const {
		stocks,
		warehouses: { available },
		partners: { getById: getPartnerById },
		products: { byId: productsById },
	} = stores;
	const [date, setDate] = useState(dayjs());
	const [warehouseId, setWarehouseId] = useState<string>('');
	const [partners] = useQueryParam('partners', ArrayParam);

	useEffect(() => {
		stocks.fetchAll(undefined, undefined, {
			date: date.format('YYYY-MM-DD'),
			warehouseId,
		});
	}, [date, stocks, warehouseId]);

	return (
		<Space>
			{available.length > 1 && (
				<>
					<div>
						<Trans>Складиште</Trans>
					</div>
					<WarehouseSelect
						value={warehouseId}
						style={{ width: 150 }}
						showCreateButton={false}
						showAllOption
						onChange={(value) => {
							setWarehouseId(value);
						}}
					/>
				</>
			)}

			<Button.Group>
				<Button
					icon={<i className="fi fi-rr-angle-left"></i>}
					onClick={() => setDate(dayjs(date).subtract(1, 'day'))}
				/>
				<DatePicker
					className={styles.datePicker}
					onChange={setDate}
					format="L"
					style={{ width: 120 }}
					allowClear={false}
					value={dayjs(date)}
				/>
				<Button
					icon={<i className="fi fi-rr-angle-right"></i>}
					onClick={() => setDate(dayjs(date).add(1, 'day'))}
				/>
			</Button.Group>
			<Button
				type="default"
				onClick={() => {
					const workbook = new ExcelJS.Workbook();
					workbook.creator = t`Бади`;
					workbook.created = new Date();
					workbook.modified = new Date();
					const worksheet = workbook.addWorksheet(t`Стање залиха`);
					productsById; // force mobx to compute

					worksheet.getColumn(1).width = 10;
					worksheet.getColumn(2).width = 50;
					worksheet.getColumn(3).width = 100;
					worksheet.getColumn(4).width = 15;

					worksheet.addTable({
						name: 'stocks',
						ref: 'A1',
						headerRow: true,
						totalsRow: false,
						style: {
							theme: 'TableStyleLight12',
						},
						columns: [
							{ name: t`Шифра`, filterButton: true },
							{ name: t`Производ`, filterButton: true },
							{ name: t`Добављачи`, filterButton: true },
							{ name: t`Количина`, filterButton: true },
						],
						rows: stocks.available
							.filter((s) => {
								if (!partners || partners.length === 0) {
									return true;
								}
								for (const partnerId of partners) {
									if (s.partners.indexOf(partnerId) > -1) {
										return true;
									}
								}
								return false;
							})
							.map((record) => [
								record.product.sku,
								record.product.parent
									? `${record.product.parent.name} ${record.product.variantName}`
									: record.product.name,
								record.partners
									? record.partners
											.map((partnerId) => getPartnerById(partnerId)?.name)
											.join(', ')
									: '',
								round(record.quantity, 3),
							]),
					});

					workbook.xlsx.writeBuffer().then((buffer) => {
						const blob = new Blob([buffer], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						});
						saveAs(
							blob,
							cyr2lat(t`Стање залиха-${new Date().toISOString()}`) + '.xlsx'
						);
					});
				}}
			>
				<Trans>Преузми у XLSX формату</Trans>
			</Button>
		</Space>
	);
}

export const HeaderComponent = observer(HeaderComponentComponent);
