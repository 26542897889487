import { AlignmentEnum } from 'ascii-table3';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

import srCyrlRS from './locale/sr-Cyrl-RS.json';
import numberFormat from '../../../lib/numberFormat';
import { ThermalPrinter } from '../../../lib/thermalPrinter';
import stores from '../../../stores/index.mobx';
import { PeriodicReport } from '../../../stores/Report.mobx';
import round from 'lodash/round';

export class ThermalPeriodic {
	locale: Record<string, string>;
	storeId: string;
	from: Dayjs;
	to: Dayjs;
	invoiceTypes: ('normal' | 'advance')[];
	data: PeriodicReport;
	cashiers: string[] | void;
	getProducts: boolean | void;
	getDaily: boolean | void;
	getByPaymentMethod: boolean | void;
	splitProductsByPaymentMethod: boolean | void;
	getGroupVat: boolean | void;
	printer = null;
	constructor(
		storeId: string,
		from: Dayjs,
		to: Dayjs,
		invoiceTypes: ('normal' | 'advance')[],
		data: PeriodicReport,
		cashiers: string[] | void,
		getProducts: boolean | void,
		getDaily: boolean | void,
		getByPaymentMethod: boolean | void,
		splitProductsByPaymentMethod: boolean | void,
		getGroupVat: boolean | void
	) {
		const {
			company: { tin, name },
			users: { getById: getUserById },
			taxRates,
			stores: { getById: getStoreById },
		} = stores;

		this.locale = srCyrlRS;
		this.storeId = storeId;
		this.from = from;
		this.to = to;
		this.invoiceTypes = invoiceTypes;
		this.data = data;
		this.cashiers = cashiers;
		this.getProducts = getProducts;
		this.getDaily = getDaily;
		this.getByPaymentMethod = getByPaymentMethod;
		this.splitProductsByPaymentMethod = splitProductsByPaymentMethod;
		this.getGroupVat = getGroupVat;

		this.printer = new ThermalPrinter();

		this.printer.addTitle(this.locale['periodicReport']);

		this.printer.addLeftRight(this.locale['tin'], tin, true);
		this.printer.addLeftRight(this.locale['company'], name, true);
		this.printer.addLeftRight(
			this.locale['store'],
			getStoreById(storeId).name,
			true
		);

		this.printer.addSeparator();
		this.printer.addLeftRight(
			this.locale['period'],
			`${this.formatDate(from, true)} - ${this.formatDate(to, true)}`,
			true
		);
		this.printer.addLeftRight(
			this.locale['invoiceTypes'],
			invoiceTypes.map((type) => this.locale[type]).join(', '),
			true
		);
		if (cashiers && cashiers.length) {
			this.printer.addLeftRight(
				this.locale['cashiers'],
				cashiers.map((id) => getUserById(id).fullName).join(', '),
				true
			);
		}
		if (!cashiers || !cashiers.length) {
			this.printer.addLeftRight(
				this.locale['cashiers'],
				this.locale['all'],
				true
			);
		}
		this.printer.addSeparator('-');
		this.printer.addTitle(this.locale['typesReport'], undefined, ' ');
		this.printer.addSeparator('-');

		this.printer.addText(' ');
		this.data.byType.forEach((type) => {
			this.printer.addTitle(this.locale[type.type], undefined, '-');
			this.printer.addTableRow(
				[
					this.locale['label'],
					this.locale['tax'],
					this.locale['volumeWithTaxShort'],
				],
				[-1, 15, 15],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
			);
			type.byLabel.forEach((item: any, index) => {
				this.printer.addTableRow(
					[
						this.printer.maxWidth !== 40
							? `${item.label} (${
									taxRates.byLabel(item.label)?.name
							  } - ${numberFormat(
									taxRates.byLabel(item.label)?.rate,
									false,
									2,
									true
							  )}%)`
							: `${item.label} (${numberFormat(
									taxRates.byLabel(item.label).rate,
									false,
									2,
									true
							  )}%)`,
						round(item.tax, 2),
						round(item.amount, 2),
					],
					[-1, 15, 15],
					[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
				);
			});
			this.printer.addTableRow(
				[
					this.locale['total'],
					numberFormat(type.tax, false, 2, true),
					numberFormat(type.amount, false, 2, true),
				],
				[-1, 15, 15],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
			);
			this.printer.addText(' ');
		});

		this.printer.addTitle(this.locale['total'], undefined, '-');
		this.printer.addTableRow(
			[
				this.locale['label'],
				this.locale['tax'],
				this.locale['volumeWithTaxShort'],
			],
			[-1, 15, 15],
			[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
		);
		data.byLabel.forEach((item: any, index) => {
			this.printer.addTableRow(
				[
					this.printer.maxWidth !== 40
						? `${item.label} (${
								taxRates.byLabel(item.label).name
						  } - ${numberFormat(
								taxRates.byLabel(item.label).rate,
								false,
								2,
								true
						  )}%)`
						: `${item.label} (${numberFormat(
								taxRates.byLabel(item.label).rate,
								false,
								2,
								true
						  )}%)`,
					numberFormat(item.tax, false, 2, true),
					numberFormat(item.amount, false, 2, true),
				],
				[-1, 15, 15],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
			);
		});
		this.printer.addTableRow(
			[
				this.locale['total'],
				numberFormat(data.total.tax, false, 2, true),
				numberFormat(data.total.amount, false, 2, true),
			],
			[-1, 15, 15],
			[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
		);
		this.printer.addText(' ');

		if (this.getDaily) {
			this.printer.addSeparator('-');
			this.printer.addTitle(this.locale['dailyReport'], undefined, ' ');
			this.printer.addSeparator('-');
			this.printer.addText(' ');

			this.printer.addTableRow(
				[
					this.locale['label'],
					this.locale['tax'],
					this.locale['volumeWithTaxShort'],
				],
				[-1, 15, 15],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
			);

			this.data.byDay.forEach((day) => {
				this.printer.addTableRow(
					[
						this.formatDate(day.day, true),
						numberFormat(day.total.tax, false, 2, true),
						numberFormat(day.total.amount, false, 2, true),
					],
					[-1, 15, 15],
					[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
				);
				day.byLabel.forEach((item) => {
					this.printer.addTableRow(
						[
							this.printer.maxWidth !== 40
								? `${item.label} (${
										taxRates.byLabel(item.label).name
								  } - ${numberFormat(
										taxRates.byLabel(item.label).rate,
										false,
										2,
										true
								  )}%)`
								: `${item.label} (${numberFormat(
										taxRates.byLabel(item.label).rate,
										false,
										2,
										true
								  )}%)`,
							numberFormat(item.tax, false, 2, true),
							numberFormat(item.amount, false, 2, true),
						],
						[-1, 15, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
					);
				});
				this.printer.addText(' ');
			});
		}

		if (this.getProducts) {
			this.printer.addSeparator('-');
			this.printer.addTitle(this.locale['products'], undefined, ' ');
			this.printer.addSeparator('-');
			this.printer.addText(' ');

			this.printer.addTableRow(
				[
					this.printer.createLeftAndRight(
						this.locale['name'],
						this.locale['sku'],
						false,
						13
					),
					this.locale['quantity'],
					this.locale['total'],
				],
				[13, 11, -1],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
			);
			this.printer.addText(' ');

			this.data.products.forEach((item) => {
				this.printer.addText(
					`${item.name} ${
						item.unit && item.isPieceUnitOfMeasure ? `/${item.unit}` : ''
					} ${
						this.splitProductsByPaymentMethod
							? ` (${this.locale[item.paymentType]})`
							: ''
					}`
				);
				this.printer.addTableRow(
					[
						item.sku || '',
						numberFormat(item.quantity, false, 3, false),
						numberFormat(item.amount, false, 2, true),
					],
					[13, 11, -1],
					[AlignmentEnum.RIGHT, AlignmentEnum.RIGHT, AlignmentEnum.RIGHT]
				);
				this.printer.addText(' ');
			});
		}

		if (this.getByPaymentMethod) {
			this.printer.addSeparator('-');
			this.printer.addTitle(this.locale['byPaymentMethod'], undefined, ' ');
			this.printer.addSeparator('-');
			this.printer.addText(' ');

			this.printer.addTableRow(
				[this.locale['paymentMethod'], this.locale['total']],
				[-1, 15],
				[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
			);
			this.printer.addText(' ');

			this.data.paymentMethods.forEach((item) => {
				this.printer.addTableRow(
					[
						this.locale[item.paymentType],
						numberFormat(item.amount, false, 2, true),
					],
					[-1, 15],
					[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
				);
				this.printer.addText(' ');
			});
		}

		if (this.getGroupVat) {
			this.printer.addSeparator('-');
			this.printer.addTitle(this.locale['groupVat'], undefined, ' ');
			this.printer.addSeparator('-');
			this.printer.addText(' ');

			this.printer.addText(this.locale['groupVatAdvanceSale']);
			this.printer.addText(' ');

			this.data.groupVat.advanceSales
				.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
				.forEach((item) => {
					this.printer.addText(
						`${this.locale['groupVatAdvanceSaleLabel']} ${numberFormat(
							taxRates.byLabel(item.taxLabel).rate,
							false,
							2,
							true
						)}%`
					);
					this.printer.addText(' ');
					this.printer.addTableRow(
						[
							this.locale['advancePaymentAmount'],
							numberFormat(item.totalAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['baseVolume'],
							numberFormat(item.baseAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['vatAmount'],
							numberFormat(item.taxAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
				});

			this.printer.addText(' ');

			this.printer.addText(this.locale['groupVatNormalSale']);
			this.printer.addText(' ');

			this.data.groupVat.normalSales
				.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
				.forEach((item) => {
					this.printer.addText(
						`${this.locale['groupVatNormalSaleLabel']} ${numberFormat(
							taxRates.byLabel(item.taxLabel).rate,
							false,
							2,
							true
						)}%`
					);
					this.printer.addText(' ');
					this.printer.addTableRow(
						[
							this.locale['baseVolume'],
							numberFormat(item.baseAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['vatAmount'],
							numberFormat(item.taxAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
				});

			this.printer.addText(' ');

			this.printer.addText(this.locale['groupVatNormalRefund']);
			this.printer.addText(' ');

			this.data.groupVat.normalRefunds
				.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
				.forEach((item) => {
					this.printer.addText(
						`${this.locale['groupVatNormalRefundLabel']} ${numberFormat(
							taxRates.byLabel(item.taxLabel).rate,
							false,
							2,
							true
						)}%`
					);
					this.printer.addText(' ');
					this.printer.addTableRow(
						[
							this.locale['baseVolume'],
							numberFormat(item.baseAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['vatAmount'],
							numberFormat(item.taxAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
				});

			this.printer.addText(' ');

			this.printer.addText(this.locale['groupVatAdvanceRefund']);
			this.printer.addText(' ');

			this.data.groupVat.advanceRefunds
				.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
				.forEach((item) => {
					this.printer.addText(
						`${this.locale['groupVatNormalRefundLabel']} ${numberFormat(
							taxRates.byLabel(item.taxLabel).rate,
							false,
							2,
							true
						)}%`
					);
					this.printer.addText(' ');
					this.printer.addTableRow(
						[
							this.locale['baseVolume'],
							numberFormat(item.baseAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['vatAmount'],
							numberFormat(item.taxAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
				});

			this.printer.addText(' ');

			this.printer.addText(this.locale['groupVatNormalVoids']);
			this.printer.addText(' ');

			this.data.groupVat.normalVoids
				.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
				.forEach((item) => {
					this.printer.addText(
						`${this.locale['groupVatNormalRefundLabel']} ${numberFormat(
							taxRates.byLabel(item.taxLabel).rate,
							false,
							2,
							true
						)}%`
					);
					this.printer.addText(' ');
					this.printer.addTableRow(
						[
							this.locale['baseVolume'],
							numberFormat(item.baseAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['vatAmount'],
							numberFormat(item.taxAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
				});

			this.printer.addText(' ');

			this.printer.addText(this.locale['groupVatAdvanceVoids']);
			this.printer.addText(' ');

			this.data.groupVat.advanceVoids
				.filter((item) => taxRates.byLabel(item.taxLabel).rate !== 0)
				.forEach((item) => {
					this.printer.addText(
						`${this.locale['groupVatNormalRefundLabel']} ${numberFormat(
							taxRates.byLabel(item.taxLabel).rate,
							false,
							2,
							true
						)}%`
					);
					this.printer.addText(' ');
					this.printer.addTableRow(
						[
							this.locale['baseVolume'],
							numberFormat(item.baseAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
					this.printer.addTableRow(
						[
							this.locale['vatAmount'],
							numberFormat(item.taxAmount, false, 2, true),
						],
						[-1, 15],
						[AlignmentEnum.LEFT, AlignmentEnum.RIGHT]
					);
				});

			this.printer.addText(' ');
		}

		this.printer.addSeparator();
		this.printer.addLeftRight(
			this.locale['reportGenerated'],
			this.formatDate(dayjs()),
			true
		);
		this.printer.addTitle(this.locale['periodicReportEnd']);

		this.printer.addText(' ');
	}

	formatDate(date: string | Dayjs, dateOnly = false): string {
		if (dateOnly) {
			return dayjs(date).format('DD.MM.YYYY');
		}
		return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
	}

	print() {
		this.printer.print(true);
	}
}
