import { Dayjs } from 'dayjs';
import { flow, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { Invoice } from './Invoice.mobx';
import { Partner } from './Partner.mobx';
import { StockTurnover, StockTurnoverStatus } from './StockTurnover.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';
import { ReferenceTransformer } from './transformers/Reference';
import { Warehouse } from './Warehouse.mobx';
import qs from 'qs';
import { Product } from './Product.mobx';

const { Store, Entity } = CreateStore({
	name: 'calculation',
	paginated: true,
	order: 'desc',
});

export class CalculationItem extends Entity {
	@observable productId?: string;
	@observable warehouseId?: string;
	@observable purchasePrice?: number;
	@observable quantity?: number;
	@observable purchaseAmount?: number;
	@observable taxRate?: number;
	@observable rebate?: number;
	@observable margin?: number;
	@observable productSku?: string;
	@observable productName?: string;
	@observable productUnit?: string;
	@observable unitPurchasePrice?: number;
	@observable priceDifference?: number;
	@observable purchasePriceWithRebate?: number;
	@observable salePriceWithVat?: number;
	@observable saleAmountWithVat?: number;
	@observable taxAmount?: number;
	@observable salePrice?: number;
	@observable invoiceQuantity?: number;
	@observable quantityInPack?: number;
	@observable purchaseTaxRate?: number;

	@observable calculation?: Calculation;

	@ReferenceTransformer('warehouse', 'warehouseId') warehouse?: Warehouse;
	@ReferenceTransformer('product', 'productId') product?: Product;

	constructor(data, parent) {
		super(parent);
		this.init(data);

		if (data.calculation) {
			this.calculation = new Calculation(data.calculation, this);
		}
	}
}

class Calculation extends Entity {
	@observable number?: string;

	@observable stockTurnoverItem?: StockTurnover;

	@observable isConfirming = false;

	@DayjsTransformer
	@observable
	date?: Dayjs;

	@ArrayTypeTransformer(CalculationItem)
	@observable
	items?: CalculationItem[] = [];

	@observable
	invoiceId?: string;

	@observable deliveryNoteNumber?: string;

	@observable
	invoice?: Invoice;

	@observable
	partnerId?: string;

	@ReferenceTransformer('partner', 'partnerId') partner?: Partner;

	@flow.bound
	*confirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/calculations/${this.id}/confirm`);
		} catch (e) {
			//
		} finally {
			this.isConfirming = false;
		}
	}

	@flow.bound
	*connectInvoice(invoiceId: string) {
		this.isUpdating = true;
		try {
			const response = yield this.getClient().patch(
				`/calculations/${this.id}/connect-invoice/${invoiceId}`
			);

			this.replace(response.data);
		} catch (e) {
			//
		} finally {
			this.isUpdating = false;
		}
	}

	@flow.bound
	*unconfirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/calculations/${this.id}/unconfirm`);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	get isDeletable() {
		return this.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Calculations extends Store<Calculation> {
	@observable isFetchingLastCalculationItem = false;

	constructor() {
		super(Calculation);
	}

	get isCreatable() {
		return true;
	}

	@flow.bound
	*fetchAllNotPaginated(filters = {}) {
		try {
			const { data }: any = yield this.getClient()({
				method: 'GET',
				url: `/calculations/all?${qs.stringify({
					...filters,
				})}`,
			});

			return data.map((item) => new Calculation(item, this));
		} catch (e) {
			throw e;
		}
	}

	@flow.bound
	*fetchLastCalculationItem(productId: string, partnerId?: string) {
		this.isFetchingLastCalculationItem = true;

		try {
			const { data } = yield this.getClient().get(
				`/products/${productId}/last-calculation-item/${partnerId || ''}`
			);
			return new CalculationItem(data, null);
		} catch (e) {
			return null;
		} finally {
			this.isFetchingLastCalculationItem = false;
		}
	}
}

export { Calculations, Calculation };
