import { t } from '@lingui/macro';
import { Button, ConfigProvider, Drawer, Empty, Spin, Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDebounce, useWindowSize } from 'react-use';
import cyr2lat from 'cyrillic-to-latin';

import styles from '../../Products.module.less';

import { useDrawer } from '../../../../../../components/Page';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import {
	ArrayParam,
	NumberParam,
	useQueryParam,
	useQueryParams,
	withDefault,
} from 'use-query-params';
import { getScrollbarWidth } from '../../../../../../lib/scrollbar';
import dayjs from 'dayjs';
import { TextFilter } from '../../../../../../components/Filters/Text';
import { DateRangeFilter } from '../../../../../../components/Filters/DateRange';
import { Partner } from '../../../../../../stores/Partner.mobx';
import { Warehouse } from '../../../../../../stores/Warehouse.mobx';
const Purchases = () => {
	const [queryParam, , close, visible] = useDrawer('product-purchases');
	const {
		products: { getOrFetchSingle, single: product, isFetching },
	} = stores;
	const [, openCalculation, , , , CalculationEditDrawer] = useDrawer(
		'edit/stocks/calculation|purchases-view'
	);

	useEffect(() => {
		if (queryParam && visible) {
			getOrFetchSingle(queryParam);
		}
	}, [getOrFetchSingle, queryParam, visible]);

	const { height } = useWindowSize();
	const contentHeight =
		height -
		49 - // header
		39 - // table header
		60 - // pagination
		getScrollbarWidth(); // scrollbar

	const currentPageSize = Math.floor(contentHeight / 49);
	const [pageSize, setPageSize] = useState(currentPageSize);

	useDebounce(
		() => {
			if (visible) {
				setPageSize(currentPageSize);
			}
		},
		1000,
		[currentPageSize, visible]
	);

	const [pageQueryParam, setPageQueryParam] = useQueryParam(
		'product-purchases-page',
		withDefault(NumberParam, 1)
	);

	const memoizedPagination = useMemo(() => {
		return (
			!isFetching && {
				pageSize: pageSize,
				total: product?.purchases?.pagination?.count,
				current: pageQueryParam,
				showSizeChanger: false,
			}
		);
	}, [
		isFetching,
		pageSize,
		product?.purchases?.pagination?.count,
		pageQueryParam,
	]);

	const [filterQueryParams, setFilterQueryParams] = useQueryParams({
		date: ArrayParam,
		calculationNumber: ArrayParam,
		storeId: ArrayParam,
		warehouseId: ArrayParam,
	});

	const handleTableChange = useCallback(
		(tablePagination, filters, sorter) => {
			// TODO: only set query params here, and react to them in useEffect
			setPageQueryParam(tablePagination.current);

			let finalFilters = {};
			const finalSorters = '';
			if (filters) {
				const filtered = Object.entries(filters).reduce(function (acc, curr) {
					acc[curr[0]] = curr[1] || undefined;
					return acc;
				}, {});
				finalFilters = {
					...filtered,
				};
				setFilterQueryParams(finalFilters);
			}
			// if (sorter && sorter.field) {
			// 	finalSorters = `${sorter.field}:${
			// 		sorter.order === 'descend' ? 'desc' : 'asc'
			// 	}`;
			// 	setSorterQueryParam(finalSorters);
			// }

			product.purchases.fetchAll(
				product.id,
				pageSize,
				(tablePagination.current - 1) * pageSize,
				{ ...finalFilters },
				finalSorters
			);
		},
		[setPageQueryParam, product, pageSize, setFilterQueryParams]
	);

	useEffect(() => {
		if (visible) {
			handleTableChange(
				{
					current: pageQueryParam || 1,
					pageSize,
				},
				filterQueryParams,
				{}
			);
		}
	}, [filterQueryParams, handleTableChange, pageQueryParam, pageSize, visible]);

	return (
		<Drawer
			open={visible}
			title={t`Набавке артикла ${product?.name}`}
			width={1200}
			className="noPadding"
			onClose={close}
		>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/calculator.svg" alt="" />}
						description={t`Нема калкулација`}
					/>
				)}
			>
				<Spin spinning={product?.purchases?.isFetching || isFetching}>
					<Table
						size="small"
						dataSource={product?.purchases?.all}
						scroll={{ x: 900 }}
						onChange={handleTableChange}
						columns={[
							{
								title: t`Датум`,
								key: 'date',
								render(record) {
									return dayjs(record.calculation.date).format('DD.MM.YYYY');
								},
								filterDropdown: ({
									setSelectedKeys,
									selectedKeys,
									confirm,
									clearFilters,
								}) => (
									<DateRangeFilter
										setSelectedKeys={setSelectedKeys}
										selectedKeys={selectedKeys}
										confirm={confirm}
										clearFilters={clearFilters}
									/>
								),
								width: 80,
							},
							{
								title: t`Количина`,
								dataIndex: 'quantity',
								key: 'quantity',
								width: 100,
								render(text) {
									return numberFormat(text, false, 3, false);
								},
							},
							{
								title: t`Износ`,
								key: 'purchaseAmount',
								render(record) {
									return numberFormat(record.purchaseAmount, true, 2, true);
								},
								width: 150,
							},
							{
								title: t`Број калкулације`,
								key: 'calculationNumber',
								render: (record) => (
									<Button
										type="link"
										className={styles.columnLink}
										onClick={() => openCalculation(record.calculation.id)}
									>
										{record.calculation.number}
									</Button>
								),
								filterIcon: (filtered) => (
									<i
										className="fi fi-rr-search"
										style={{ color: filtered ? '#7E89C8' : undefined }}
									/>
								),
								filterDropdown: ({
									setSelectedKeys,
									selectedKeys,
									confirm,
									clearFilters,
								}) => (
									<TextFilter
										placeholder={t`Број рачуна`}
										setSelectedKeys={setSelectedKeys}
										selectedKeys={selectedKeys}
										confirm={confirm}
										clearFilters={clearFilters}
									/>
								),
							},
							{
								title: t`Добављач`,
								dataIndex: ['calculation', 'partner', 'name'],
								key: 'partnerId',
								ellipsis: true,
								filterSearch: (input, record) => {
									return cyr2lat(record.text.toLowerCase()).includes(
										cyr2lat(input.toLowerCase())
									);
								},
								filters: stores.partners.available?.map(
									(partnerEntity: Partner) => ({
										text: partnerEntity.name,
										value: partnerEntity.id,
									})
								),
								filterMultiple: true,
								width: 200,
							},
							{
								title: t`Складиште`,
								dataIndex: ['warehouse', 'name'],
								ellipsis: true,
								key: 'warehouseId',
								filterSearch: (input, record) => {
									return cyr2lat(record.text.toLowerCase()).includes(
										cyr2lat(input.toLowerCase())
									);
								},
								filters: stores.warehouses.available?.map(
									(warehouseEntry: Warehouse) => ({
										text: warehouseEntry.name,
										value: warehouseEntry.id,
									})
								),
								filterMultiple: true,
							},
						]}
						pagination={memoizedPagination}
					/>
				</Spin>
				<CalculationEditDrawer location="purchases-view" />
			</ConfigProvider>
		</Drawer>
	);
};

const ObservedPurchases = observer(Purchases);

export default ObservedPurchases;
