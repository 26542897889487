import { Dayjs } from 'dayjs';
import { round } from 'lodash';
import { computed, flow, observable } from 'mobx';

import { Calculation } from './Calculation.mobx';
import { CreateStore } from './Crud.mobx';
import { Invoice } from './Invoice.mobx';
import { Receipt } from './Receipt.mobx';
import { StockTurnover, StockTurnoverStatus } from './StockTurnover.mobx';
import { ArrayTypeTransformer } from './transformers/ArrayType';
import { DayjsTransformer } from './transformers/Dayjs';
import qs from 'qs';
import { ReferenceTransformer } from './transformers/Reference';
import { Product } from './Product.mobx';

const { Store, Entity } = CreateStore({
	name: 'pricechange',
	paginated: true,
	order: 'desc',
});

export enum PricechangeType {
	MANUAL = 'manual',
	CALCULATION = 'calculation',
	RECEIPT = 'receipt',
}

export class PricechangeItem extends Entity {
	@observable productId?: string;
	@observable warehouseId?: string;
	@observable purchasePrice?: number;
	@observable quantity?: number;
	@observable purchaseAmount?: number;
	@observable oldSalePriceWithVat?: number;
	@observable salePriceWithVat?: number;
	@observable saleAmountWithVat?: number;
	@observable priceDifference?: number;
	@observable productUnit?: string;
	@observable taxRate?: number;

	@ReferenceTransformer('product', 'productId') product?: Product;

	@computed
	get amountDifference() {
		return round(this.priceDifference * this.quantity, 4);
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Pricechange extends Entity {
	@observable number?: string;

	@observable stockTurnoverItem?: StockTurnover;

	@observable isConfirming = false;

	@DayjsTransformer
	@observable
	date?: Dayjs;

	@ArrayTypeTransformer(PricechangeItem)
	@observable
	items?: PricechangeItem[] = [];

	type?: PricechangeType;

	@observable
	invoiceId?: string;

	@observable
	invoice?: Invoice;

	@observable
	receiptId?: string;

	@observable
	receipt?: Receipt;

	@observable
	receipts?: Receipt[];

	@observable
	calculationId?: string;

	@observable
	calculation?: Calculation;

	@flow.bound
	*confirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/pricechanges/${this.id}/confirm`);
		} catch (e) {
			//
		} finally {
			this.isConfirming = false;
		}
	}

	@flow.bound
	*unconfirm() {
		this.isConfirming = true;
		try {
			yield this.getClient().patch(`/pricechanges/${this.id}/unconfirm`);
		} catch (e) {
			throw e;
		} finally {
			this.isConfirming = false;
		}
	}

	get isDeletable() {
		return this.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}
}

class Pricechanges extends Store<Pricechange> {
	@observable isFetchingQuantityAndPrice = false;

	constructor() {
		super(Pricechange);
	}

	@flow.bound
	*fetchAllNotPaginated(filters = {}) {
		try {
			const { data }: any = yield this.getClient()({
				method: 'GET',
				url: `/pricechanges/all?${qs.stringify({
					...filters,
				})}`,
			});

			return data.map((item) => new Pricechange(item, this));
		} catch (e) {
			throw e;
		}
	}

	@flow.bound
	*fetchQuantityAndPrice(productId: string, warehouseId: string) {
		this.isFetchingQuantityAndPrice = true;
		try {
			const { data } = yield this.getClient().get(
				`/products/${productId}/quantity/${warehouseId}`
			);

			return data;
		} catch (e) {
			return null;
		} finally {
			this.isFetchingQuantityAndPrice = false;
		}
	}

	get isCreatable() {
		return true;
	}
}

export { Pricechanges, Pricechange };
