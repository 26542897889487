import { t, Trans } from '@lingui/macro';
import {
	Button,
	Col,
	Drawer,
	Form,
	Row,
	Space,
	Spin,
	Table,
	Popconfirm,
	DescriptionsProps,
	Typography,
	Skeleton,
	Grid,
	ConfigProvider,
	Empty,
	Alert,
	TableProps,
} from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import CRC32 from 'crc-32/crc32c';
import get from 'lodash/get';
import { observer } from 'mobx-react-lite';
import pluralize from 'pluralize';
import {
	cloneElement,
	ReactElement,
	ReactNode,
	useContext,
	useEffect,
	useImperativeHandle,
	useMemo,
	useState,
	isValidElement,
	useCallback,
	useRef,
	Fragment,
	FC,
	RefObject,
} from 'react';
import { useBus, useListener } from 'react-bus';
import nl2br from 'react-nl2br';
import { useDebounce, useWindowSize } from 'react-use';
import {
	useQueryParam,
	StringParam,
	NumberParam,
	withDefault,
	useQueryParams,
	ArrayParam,
} from 'use-query-params';

import styles from './Page.module.less';
import globalStyles from '../../assets/styles/global.module.less';
import { DrawerContext } from '../../context/DrawerContext';
import { usePermissions } from '../../hooks/permissions';
import { getScrollbarWidth } from '../../lib/scrollbar';
import stores from '../../stores/index.mobx';
import { LoadingReference } from '../../stores/transformers/Reference';
import DisablePasswordAutocomplete from '../DisablePasswordAutocomplete';
import { SearchInput } from '../SearchInput';
import { registerDeferred, StaticComponents } from '../StaticComponents';
import { Title } from '../Title';
import { flowResult } from 'mobx';
import uniqBy from 'lodash/uniqBy';

interface DrawerProps {
	location?: string;
}

type ViewDrawerProps = DrawerProps;
type EditDrawerProps = DrawerProps;

interface FilterItem {
	text: ReactNode;
	value: string;
}

interface TableColumn {
	title: string;
	dataIndex?: string | string[];
	key?: string;
	searchable?: boolean;
	searchKey?: string;
	width?: number;
	render?:
		| ((text?: string, record?: any, index?: number) => ReactNode)
		| ((record?: any, index?: number) => ReactNode);
	filters?: FilterItem[] | ((stores) => FilterItem[]);
	filterMultiple?: boolean;
	onFilter?: (value: string, record: any) => boolean;
	sorter?: ((a: any, b: any) => number) | boolean;
	defaultSortOrder?: 'ascend' | 'descend';
	defaultFilteredValue?: string[];
	filterDropdown?: (any) => ReactNode;
	shouldLink?: boolean;
	filterIcon?: (filtered: boolean) => ReactNode;
}

interface FormField {
	label?: string;
	key?: string | string[];
	rules?: any[]; // TODO use antd rules
	component: ReactNode;
	span?: number;
	xs?: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
	xxl?: number;
	initialValue?: unknown;
	valuePropName?: string;
	rerenderOnChange?: boolean;
	hidden?: boolean;
}

export interface FormRow {
	key: string;
	label?: string;
	fields: FormField[];
}

interface ViewField {
	label?: ReactNode;
	labelExtra?: ReactNode;
	key?: string;
	dataIndex?: string;
	component?: ReactElement;
	span?: number;
	block?: boolean;
	render?: (text?: string, record?: any) => ReactNode;
}

interface ViewRow {
	key: string;
	label?: ReactNode;
	column: DescriptionsProps['column'];
	fields: ViewField[];
	descriptionsProps?: DescriptionsProps;
}

interface Options {
	module: string;
	submodule: string;
	model: string;
	drawers?: Record<string, any>;
	page: {
		docsLink?: string;
		shouldFetch?: boolean;
		empty?: {
			customEmptyComponent?: ReactNode;
			image?: string;
			text?: string;
			createQueryParam?: string;
		};
		table: {
			columns: TableColumn[] | (() => TableColumn[]);
			actions?: ((record?: any) => ReactNode)[];
			listProp?: string;
			props?: any; // TODO don't use any
			showActions?: boolean;
			showDisabledActions?: boolean;
			initialActionsWidth?: number;
			getRecordId?: (record: any) => string;
			additionalFilters?: Record<
				string,
				typeof ArrayParam | typeof StringParam | typeof NumberParam
			>;
			useMemoizedColumns?: boolean;
			selectable?: boolean;
			selectedActions?: FC<{ selected: string[]; allSelected: boolean }>;
		};
		deletePrompt?: string;
		customDeleteModal?: (record: any) => ReactNode;
		searchable?: boolean;
		searchPlaceholder?: string;
		searchFilter?: (values: any[]) => any[];
		createButtonText?: string;
		additionalButtons?: ReactNode;
		customCreateButton?: (createHandler: () => void) => ReactNode;
		additionalQueryParams?: Record<string, any>;
		additionalContent?: ReactNode;
	};
	edit?: {
		width?: number;
		title?: {
			new?: string;
			existing?: string | ((entity: any) => ReactNode);
		};
		disabled?: boolean | ((entity: any) => boolean);
		disabledReason?: string | ((entity: any) => string);
		shouldFetch?: boolean;
		beforeSave?: (fields) => any;
		afterSave?: (fields, form: FormInstance) => any;
		beforeSetFormFields?: (fields) => any;
		fields?:
			| FormRow[]
			| ((
					record?: any,
					form?: any,
					setFields?: (fields: any) => any
			  ) => FormRow[])
			| ReactNode;
		onError?: (
			error,
			entity,
			form: FormInstance,
			controlerRef?: RefObject<any>
		) => any;
		disablePasswordAutocomplete?: boolean;
		buttons?: FC<{
			entity: any;
			save: (closeDrawer: boolean) => any;
			close: () => void;
			isCreating: boolean;
			isLoading: boolean;
			disabled: boolean;
			canEditPermission: boolean;
			form?: FormInstance;
		}>;
		controllerComponent?: any;
	};
	view?: {
		useEdit?: boolean;
		width?: number;
		title?: string | ((entity: any) => ReactNode);
		shouldFetch?: boolean;
		fields?: ViewRow[] | ((record?: any) => ViewRow[]) | ReactNode;
		footer?: (entity, onEdit, onClose, onDelete) => ReactNode;
		descriptionsProps?: DescriptionsProps;
		additionalContent?: ReactNode;
		customDrawer?: string;
	};
}

export async function addToDrawersRegistry(name, component) {
	await registerDeferred.promise;
	StaticComponents.registerDrawer(name, component);
}

export function CreatePage(options: Options) {
	const { module, model, submodule, drawers: pageDrawers = {} } = options;
	const modelPlural = pluralize(model);

	type RenderPageProps = {
		searchLoading: boolean;
		emptyImage?: string;
		emptyText?: string;
		emptyCreateQueryParam?: string;
		customEmptyComponent?: ReactNode;
		pagination?: TableProps<any>['pagination'];
		handleTableChange?: TableProps<any>['onChange'];
		columns?: TableColumn[];
		isFetching?: boolean;
		data: any[];
		tableProps?: TableProps<any>;
		pageSize?: number;
		createButtonText?: string;
		customCreateButton?: (createHandler: () => void) => ReactNode;
		customDrawer?: string;
		selectable?: boolean;
		setAllSelected?: (boolean) => void;
		setSelected?: (selected: string[]) => void;
	};

	const RenderPage = ({
		searchLoading,
		emptyImage,
		emptyText,
		emptyCreateQueryParam,
		customEmptyComponent,
		pagination,
		handleTableChange,
		columns,
		isFetching,
		data,
		tableProps,
		pageSize,
		createButtonText = t`Додај`,
		customDrawer,
		selectable,
		setAllSelected,
		setSelected,
	}: RenderPageProps) => {
		const emptyData = useMemo(() => {
			return Array(pageSize).fill({});
		}, [pageSize]);

		const modelStore = stores[modelPlural];

		const { isCreatable } = modelStore;

		const canCreatePermission = usePermissions(module, submodule, 'create');
		const [, setCreateQueryParam] = useQueryParam(
			emptyCreateQueryParam || `create/${module}/${submodule}`,
			StringParam
		);

		const { getRegisteredDrawer } = useContext(DrawerContext);

		const ViewDrawer = getRegisteredDrawer(
			customDrawer || `view/${module}/${submodule}`
		);
		const EditDrawer = getRegisteredDrawer(`edit/${module}/${submodule}`);

		const defaultExpandable = useMemo(() => {
			return {};
		}, []);
		return (
			<>
				<ConfigProvider
					renderEmpty={() =>
						searchLoading ? (
							<Empty
								image={<img src="/images/icons/new/search.svg" alt="" />}
								description={t`Претрага је у току...`}
							/>
						) : (
							customEmptyComponent || (
								<Empty
									image={
										<img
											src={`/images/icons/new/${emptyImage || 'empty-set'}.svg`}
											alt=""
										/>
									}
									description={emptyText || t`Нема података`}
								>
									{canCreatePermission && isCreatable && (
										<Button
											type="link"
											className={globalStyles.dropdownCreateButton}
											onClick={() => {
												setCreateQueryParam('true');
											}}
										>
											{createButtonText}
										</Button>
									)}
								</Empty>
							)
						)
					}
				>
					<Table
						rowKey="id"
						size="small"
						pagination={pagination}
						onChange={handleTableChange}
						columns={columns}
						dataSource={isFetching || searchLoading ? emptyData : data}
						expandable={defaultExpandable}
						rowSelection={
							selectable
								? {
										type: 'checkbox',
										onChange: (selectedRowKeys, selectedRows, type) => {
											setSelected(selectedRowKeys);
											if (type.type === 'all') {
												setAllSelected(true);
											} else {
												setAllSelected(false);
											}
										},
								  }
								: undefined
						}
						{...tableProps}
					/>
				</ConfigProvider>
				<ViewDrawer />
				<EditDrawer />
			</>
		);
	};

	function Page(props, ref) {
		const {
			view,
			page: {
				docsLink,
				shouldFetch = true,
				empty,
				table: {
					columns,
					actions,
					listProp = 'list',
					props: tableProps,
					showActions = true,
					showDisabledActions = true,
					initialActionsWidth = 64,
					getRecordId,
					additionalFilters = {},
					useMemoizedColumns = true,
					selectable = false,
					selectedActions,
				},
				deletePrompt = t`Да ли сте сигурни да желите да обришете ову ставку?`,
				customDeleteModal,
				searchable = false,
				searchPlaceholder = t`Претражи`,
				searchFilter,
				createButtonText = t`Додај`,
				additionalButtons,
				customCreateButton,
				additionalQueryParams,
				additionalContent,
			},
		} = options;

		const { height } = useWindowSize();

		const hasMobileHeader = !!document.querySelector(
			'#root.has-mobile-header .screen-xs, #root.has-mobile-header .screen-sm'
		);
		const screens = Grid.useBreakpoint();
		const titleBarHeight =
			window.electron &&
			(!window.electron.platform || window.electron.platform === 'darwin') &&
			(screens.sm || screens.xs) &&
			!screens.lg
				? 28
				: 0;
		const contentHeight =
			height -
			49 - // header
			39 - // table header
			60 - // pagination
			(tableProps?.summary ? 39 : 0) - // table summary
			(hasMobileHeader ? 46 : 0) - // mobile header
			titleBarHeight - // extended titlebar
			getScrollbarWidth(); // scrollbar

		const currentPageSize = Math.floor(contentHeight / 49);
		const [pageSize, setPageSize] = useState(currentPageSize);

		const { isDrawerOpen } = useContext(DrawerContext);

		useDebounce(
			() => {
				if (!isDrawerOpen) {
					setPageSize(currentPageSize);
				}
			},
			1000,
			[currentPageSize, isDrawerOpen]
		);

		const [pageQueryParam, setPageQueryParam] = useQueryParam(
			'page',
			withDefault(NumberParam, 1)
		);

		const [filterQueryParams, setFilterQueryParams] = useQueryParams({
			...(typeof columns === 'function' ? columns() : columns)
				.filter((column) => Boolean(column.filters || column.filterDropdown))
				.map((column) => column.key)
				.reduce((prev, curr) => {
					prev[curr] = ArrayParam;
					return prev;
				}, {}),
			...additionalFilters,
		});
		const [, setSorterQueryParam] = useQueryParam('sort', StringParam);

		const [, setCreateQueryParam] = useQueryParam(
			`create/${module}/${submodule}`,
			StringParam
		);
		const [, setEditQueryParam] = useQueryParam(
			`edit/${module}/${submodule}`,
			StringParam
		);
		const [, setViewQueryParam] = useQueryParam(
			view?.customDrawer || `view/${module}/${submodule}`,
			StringParam
		);

		const [reloadQueryParam, setReloadQueryParam] = useQueryParam(
			'reload',
			StringParam
		);

		const modelStore = stores[modelPlural];

		const { fetchAll, isFetching, pagination, isCreatable, search } =
			modelStore;

		const [searchQueryParam, setSearchQueryParam] = useQueryParam(
			'search',
			StringParam
		);

		const [searchResults, setSearchResults] = useState(undefined);
		const [searchLoading, setSearchLoading] = useState(false);
		const searchTimeout = useRef(null);

		useEffect(() => {
			if (searchQueryParam && searchQueryParam !== '') {
				setSearchLoading(true);
				setSearchResults([]);

				clearTimeout(searchTimeout.current);
				searchTimeout.current = setTimeout(async () => {
					const results = search(searchQueryParam).map(({ item }) => item);
					const uniqueResults = uniqBy(results, 'id');
					if (searchFilter) {
						setSearchResults(searchFilter(uniqueResults));
					} else {
						setSearchResults(uniqueResults);
					}
					setSearchLoading(false);
				}, 500);
			} else {
				setSearchResults(undefined);
			}
		}, [search, searchFilter, searchQueryParam]);

		const data = useMemo(() => {
			if (typeof searchResults !== 'undefined') {
				return searchResults;
			}
			return modelStore[listProp];
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [listProp, modelStore, modelStore?.[listProp], searchResults]);

		const canCreatePermission = usePermissions(module, submodule, 'create');
		const canEditPermission = usePermissions(module, submodule, 'edit');
		const canDeletePermission = usePermissions(module, submodule, 'delete');

		const getColumns = () => {
			return [
				...(typeof columns === 'function' ? columns() : columns).map(
					(column) => ({
						...column,
						filters:
							typeof column.filters === 'function'
								? column.filters(stores)
								: column.filters,
						// ...(column.searchable
						// 	? {
						// 			...this.getColumnSearchProps(
						// 				column.key,
						// 				column.searchKey || column.key
						// 			),
						// 	  }
						// 	: {}),
						render(text, record, index) {
							if (
								text instanceof LoadingReference ||
								isFetching ||
								searchLoading
							) {
								return (
									<Skeleton
										className={styles.skeleton}
										active
										title={{
											width: `${100 - (Math.abs(CRC32.str(`${index}`)) % 50)}%`,
										}}
										paragraph={false}
									/>
								);
							}
							if (column.shouldLink && view) {
								return (
									<Typography.Link
										className={styles.columnLink}
										onClick={() => {
											const recordId = getRecordId
												? getRecordId(record)
												: record.id;
											if (view?.useEdit) {
												return setEditQueryParam(recordId);
											}
											return setViewQueryParam(recordId);
										}}
									>
										{column.render ? column.render(text, record) : text}
									</Typography.Link>
								);
							}
							return column.render
								? column.render(text, record, index)
								: get(
										record,
										typeof column.dataIndex === 'string'
											? column.dataIndex
											: column.dataIndex.join('.')
								  ) || text;
						},
					})
				),
				...(showActions
					? [
							{
								key: 'actions',
								align: 'right',
								fixed: 'right',
								render: (record) => (
									<Button.Group className={styles.actions}>
										{(actions || [])
											.map((action) => action(record))
											.filter(Boolean)}
										{(showDisabledActions ||
											(record.isEditable && canEditPermission)) && (
											<Button
												icon={<i className="fi fi-rr-pencil"></i>}
												onClick={() => {
													setEditQueryParam(record.id);
												}}
												disabled={!record.isEditable || !canEditPermission}
											/>
										)}
										{(showDisabledActions ||
											(record.isDeletable && canDeletePermission)) && (
											<Button
												icon={<i className="fi fi-rr-trash"></i>}
												disabled={!record.isDeletable || !canDeletePermission}
												onClick={() => {
													customDeleteModal
														? customDeleteModal(record)
														: StaticComponents.modal.confirm({
																title: deletePrompt,
																content: t`Ова акција не може бити поништена.`,
																onOk() {
																	record.destroy();
																},
																okText: t`Обриши`,
																cancelText: t`Одустани`,
														  });
												}}
											/>
										)}
									</Button.Group>
								),
								width: 16 + initialActionsWidth + (actions?.length || 0) * 32,
							},
					  ]
					: []),
			];
		};

		const memoizedColumns = useMemo(() => {
			return getColumns();
		}, [
			columns,
			showActions,
			initialActionsWidth,
			actions,
			isFetching,
			searchLoading,
			view,
			setViewQueryParam,
			setEditQueryParam,
			showDisabledActions,
			canEditPermission,
			canDeletePermission,
			customDeleteModal,
			deletePrompt,
		]);

		const handleTableChange = useCallback(
			(tablePagination, filters, sorter) => {
				// TODO: only set query params here, and react to them in useEffect
				setPageQueryParam(tablePagination.current);

				let finalFilters = {};
				let finalSorters = '';
				if (filters) {
					const filtered = Object.entries(filters).reduce(function (acc, curr) {
						acc[curr[0]] = curr[1] || undefined;
						return acc;
					}, {});
					finalFilters = {
						...filtered,
						...Object.keys(additionalQueryParams || {})
							.filter((key) => Boolean(filterQueryParams[key]))
							.reduce((acc, key) => {
								acc[key] = filterQueryParams[key];
								return acc;
							}, {}),
					};
					setFilterQueryParams(finalFilters);
				}
				if (sorter && sorter.field) {
					finalSorters = `${sorter.field}:${
						sorter.order === 'descend' ? 'desc' : 'asc'
					}`;
					setSorterQueryParam(finalSorters);
				}

				if (shouldFetch) {
					if (!pagination?.supported) {
						fetchAll();
					} else {
						fetchAll(
							pageSize,
							(tablePagination.current - 1) * pageSize,
							{ ...additionalQueryParams, ...finalFilters },
							finalSorters
						);
					}
				}
			},
			[
				setPageQueryParam,
				shouldFetch,
				additionalQueryParams,
				setFilterQueryParams,
				filterQueryParams,
				setSorterQueryParam,
				pagination?.supported,
				fetchAll,
				pageSize,
			]
		);

		useEffect(() => {
			if (shouldFetch) {
				handleTableChange(
					{
						current: pageQueryParam || 1,
						pageSize,
					},
					filterQueryParams,
					{}
				);
			}
		}, [
			filterQueryParams,
			handleTableChange,
			pageQueryParam,
			pageSize,
			shouldFetch,
		]);

		const [allSelected, setAllSelected] = useState<boolean>(false);
		const [selected, setSelected] = useState<string[]>([]);

		useImperativeHandle(ref, () => ({
			renderHeader: () =>
				(additionalButtons ||
					searchable ||
					selectedActions ||
					(canCreatePermission && isCreatable)) && (
					<Space>
						{additionalButtons}
						{searchable && (
							<SearchInput searchPlaceholder={searchPlaceholder} />
						)}
						{canCreatePermission &&
							isCreatable &&
							(customCreateButton ? (
								customCreateButton(() => setCreateQueryParam('true'))
							) : (
								<Button
									type="primary"
									onClick={() => {
										setCreateQueryParam('true');
									}}
								>
									{screens.xs ? t`Додај` : createButtonText}
								</Button>
							))}
						{selectedActions &&
							selected.length > 0 &&
							selectedActions({ selected, allSelected })}
					</Space>
				),
			getDocsLink: () => {
				return docsLink;
			},
		}));

		useEffect(() => {
			if (reloadQueryParam) {
				handleTableChange(
					{
						current: pageQueryParam || 1,
						pageSize,
					},
					filterQueryParams,
					{}
				);
			}
			setReloadQueryParam(undefined);
		}, [
			reloadQueryParam,
			filterQueryParams,
			pageQueryParam,
			pageSize,
			setReloadQueryParam,
			handleTableChange,
		]);

		const memoizedPagination = useMemo(() => {
			return (
				!isFetching &&
				!searchLoading && {
					pageSize: pageSize,
					total: pagination?.count,
					current: pageQueryParam,
					showSizeChanger: false,
				}
			);
		}, [isFetching, searchLoading, pagination, pageSize, pageQueryParam]);

		const emptyText = useMemo(() => {
			return empty?.text || t`Нема података`;
		}, [empty]);

		const emptyImage = useMemo(() => {
			return empty?.image;
		}, [empty]);

		const emptyCreateQueryParam = empty?.createQueryParam;

		return (
			<>
				<RenderPage
					searchLoading={searchLoading}
					emptyImage={emptyImage}
					emptyText={emptyText}
					emptyCreateQueryParam={emptyCreateQueryParam}
					customEmptyComponent={empty?.customEmptyComponent}
					pagination={memoizedPagination}
					handleTableChange={handleTableChange}
					columns={useMemoizedColumns ? memoizedColumns : getColumns()}
					isFetching={isFetching}
					data={data}
					tableProps={tableProps}
					pageSize={pageSize}
					createButtonText={createButtonText}
					customCreateButton={customCreateButton}
					getRecordId={getRecordId}
					customDrawer={view?.customDrawer}
					selectable={selectable}
					setAllSelected={setAllSelected}
					setSelected={setSelected}
				/>
				{additionalContent}
			</>
		);
	}

	function EditDrawer({ location }: EditDrawerProps) {
		const {
			edit: {
				width = 500,
				title = {},
				shouldFetch = true,
				fields,
				disabled = false,
				disabledReason,
				buttons,
				beforeSave = (data) => data,
				beforeSetFormFields = (data) => data,
				afterSave = (data, form) => data,
				onError = null,
				disablePasswordAutocomplete = false,
				controllerComponent: ControllerComponent,
			} = {},
		} = options;

		const controllerComponentRef = useRef();

		// Query params
		const [createQueryParam, setCreateQueryParam] = useQueryParam(
			`create/${module}/${submodule}${location ? `|${location}` : ''}`,
			StringParam
		);
		const [editQueryParam, setEditQueryParam] = useQueryParam(
			`edit/${module}/${submodule}${location ? `|${location}` : ''}`,
			StringParam
		);
		const [editPrefillQueryParam, setEditPrefillQueryParam] = useQueryParam(
			`create/${module}/${submodule}${location ? `|${location}` : ''}/prefill`,
			StringParam
		);

		const canEditPermission = usePermissions(
			module,
			submodule,
			'edit',
			editQueryParam
		);
		const canCreatePermission = usePermissions(
			module,
			submodule,
			'create',
			editQueryParam
		);

		const [, , , , editEmitter] = useDrawer(
			`edit/${module}/${submodule}${location ? `|${location}` : ''}`
		);
		const [, , , , createEmitter] = useDrawer(
			`create/${module}/${submodule}${location ? `|${location}` : ''}`
		);

		const modelStore = stores[modelPlural];

		const [form] = Form.useForm();
		const {
			create,
			fetchSingle,
			isCreating,
			single,
			getOrFetchSingle,
			unloadSingle,
		} = modelStore;

		const [entityId, setEntityId] = useState(null);
		const [visible, setVisible] = useState(false);

		const [realVisible, currentEntityId] = useMemo(() => {
			const id = editQueryParam;

			const createModel = typeof createQueryParam !== 'undefined';
			const visible = Boolean(createModel || id);

			return [visible, visible ? id : null];
		}, [createQueryParam, editQueryParam]);

		useEffect(() => {
			if (realVisible) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}, [realVisible]);

		const { setIsDrawerClosed } = useContext(DrawerContext);

		useEffect(() => {
			if (!visible) {
				setIsDrawerClosed(
					`view/${model}/${submodule}${location ? `|${location}` : ''}`
				);
			}
		}, [location, setIsDrawerClosed, visible]);

		useEffect(() => {
			if (visible) {
				if (currentEntityId) {
					setEntityId(currentEntityId);
				} else {
					setTimeout(() => {
						setEntityId(null);
						unloadSingle?.();
					}, 200);
				}
			}
		}, [currentEntityId, unloadSingle, visible]);

		useEffect(() => {
			if (visible && entityId) {
				if (shouldFetch) {
					fetchSingle(entityId);
				} else {
					getOrFetchSingle(entityId);
				}
			}
		}, [entityId, fetchSingle, getOrFetchSingle, shouldFetch, visible]);

		const usedTitle = entityId
			? title.existing || t`Измена`
			: title.new || t`Додавање`;

		const entity = useMemo(() => {
			return entityId ? single : null;
		}, [entityId, single]);

		const [formFields, setFormFields] = useState(null);

		const rerenderOnChangeFields = useMemo(() => {
			const derivedFormFields =
				typeof fields === 'function'
					? (fields as any)(entity, form, setFields)
					: fields || [];
			return Array.isArray(derivedFormFields)
				? derivedFormFields
						.reduce((prev, curr) => {
							return [...prev, ...curr.fields];
						}, [])
						.filter((field) => field.rerenderOnChange)
						.map((field) => field.key)
				: [];
		}, [fields, entity, form, formFields]);

		const setFields = (changedFields = null) => {
			if (isValidElement(fields)) {
				setFormFields(fields);
				return;
			}
			if (
				!changedFields ||
				rerenderOnChangeFields.includes(changedFields?.[0]?.name?.[0])
			) {
				const derivedFormFields =
					typeof fields === 'function'
						? (fields as any)(entity, form, setFields)
						: fields || [];
				setFormFields(derivedFormFields);
			}
		};

		useEffect(() => {
			if (entity) {
				const transformed = beforeSetFormFields({
					...Object.getOwnPropertyNames(entity).reduce((prev, curr) => {
						prev[curr] = entity[curr];
						return prev;
					}, {}),
				});
				form.setFieldsValue(transformed);
			} else if (editPrefillQueryParam) {
				const transformed = beforeSetFormFields(
					JSON.parse(editPrefillQueryParam)
				);
				form.setFieldsValue(transformed);
			} else {
				form.resetFields();
			}
			setFields();
		}, [visible, entity, entity?.isFetching]);

		const isLoading = useMemo(
			() =>
				visible &&
				(isCreating || entity?.isFetching || entity?.isUpdating || false),
			[isCreating, entity, visible]
		);

		const createTitle = useMemo(() => {
			return typeof usedTitle === 'function' ? usedTitle(entity) : usedTitle;
		}, [entity, usedTitle]);

		const close = useCallback(() => {
			setCreateQueryParam(undefined);
			setEditQueryParam(undefined);
			setEditPrefillQueryParam(undefined);
		}, [setCreateQueryParam, setEditPrefillQueryParam, setEditQueryParam]);

		const save = useCallback(
			async (closeDrawer = true) => {
				try {
					const values = await form.validateFields();
					// TODO handle errors
					try {
						if (entity) {
							const response = await entity.update(beforeSave(values));
							afterSave(response, form);
							editEmitter('entity-update', response);
							if (closeDrawer) {
								close();
							}
							return response;
						} else {
							const response = await create(beforeSave(values));
							afterSave(response, form);
							createEmitter('entity-create', response);
							if (closeDrawer) {
								close();
							}
							return response;
						}
					} catch (e) {
						if (onError) {
							try {
								return onError(e, entity, form, controllerComponentRef);
							} catch (e) {
								throw e;
							}
						}
						if (entity) {
							StaticComponents.notification.error({
								message: t`Грешка`,
								description: t`Дошло је до непредвиђене грешке приликом измене.`,
							});
						} else {
							StaticComponents.notification.error({
								message: t`Грешка`,
								description: t`Дошло је до непредвиђене грешке приликом додавања.`,
							});
						}
						throw e;
					}
				} catch (e) {
					throw e;
				}
			},
			[
				afterSave,
				beforeSave,
				close,
				create,
				createEmitter,
				editEmitter,
				entity,
				form,
				onError,
			]
		);
		const focused = useRef(false);
		const Buttons = buttons;
		return (
			<Drawer
				open={visible}
				width={width}
				title={createTitle}
				destroyOnClose
				onClose={close}
				footer={
					!buttons ? (
						<Row>
							<Col flex="auto">
								<Button key="cancel" onClick={close}>
									<Trans>Одустани</Trans>
								</Button>
							</Col>
							<Col flex="none">
								<Button
									key="save"
									type="primary"
									loading={isCreating || isLoading}
									onClick={() => save()}
									disabled={
										(entityId && (!canEditPermission || !entity?.isEditable)) ||
										(!entityId && !canCreatePermission) ||
										isLoading ||
										(typeof disabled === 'function'
											? disabled(entity)
											: disabled)
									}
								>
									<Trans>Сачувај</Trans>
								</Button>
							</Col>
						</Row>
					) : (
						<Buttons
							entity={entity}
							save={save}
							close={close}
							isCreating={isCreating}
							isLoading={isLoading}
							canEditPermission={
								(entityId && (canEditPermission || !entity?.isEditable)) ||
								(!entityId && canCreatePermission)
							}
							disabled={
								typeof disabled === 'function' ? disabled(entity) : disabled
							}
							form={form}
						/>
					)
				}
			>
				<Spin spinning={isLoading}>
					{visible && (
						<Form
							layout="vertical"
							form={form}
							onFinish={save}
							preserve={false}
							scrollToFirstError
							onFieldsChange={setFields}
							disabled={
								(entityId && (!canEditPermission || !entity?.isEditable)) ||
								(!entityId && !canCreatePermission) ||
								(typeof disabled === 'function' ? disabled(entity) : disabled)
							}
						>
							<Button htmlType="submit" style={{ display: 'none' }} />
							{(typeof disabled === 'function' ? disabled(entity) : disabled) &&
								disabledReason && (
									<Form.Item>
										<Alert
											type="warning"
											message={
												typeof disabledReason === 'function'
													? disabledReason(entity)
													: disabledReason
											}
										/>
									</Form.Item>
								)}

							{disablePasswordAutocomplete && <DisablePasswordAutocomplete />}
							{isValidElement(formFields)
								? cloneElement(formFields, {
										form,
								  })
								: (formFields || []).map((row, rowIndex) => (
										<Fragment key={row.key}>
											{row.label && <Title>{row.label}</Title>}
											<Row gutter={8}>
												{row.fields.map((field, fieldIndex) => (
													<Col
														key={field.key}
														span={field.span}
														xs={field.xs}
														sm={field.sm}
														md={field.md}
														lg={field.lg}
														xl={field.xl}
														xxl={field.xxl}
														style={field.hidden && { display: 'none' }}
													>
														<Form.Item {...field} name={field.key}>
															{cloneElement(
																typeof field.component === 'function'
																	? field.component(entity)
																	: field.component,
																{
																	ref: (ref) => {
																		setTimeout(() => {
																			if (
																				ref &&
																				ref?.focus &&
																				rowIndex === 0 &&
																				fieldIndex === 0 &&
																				!focused.current
																			) {
																				try {
																					ref?.focus();
																				} catch (e) {
																					//
																				}
																				focused.current = true;
																			}
																		}, 100);
																	},
																}
															)}
														</Form.Item>
													</Col>
												))}
											</Row>
										</Fragment>
								  ))}
						</Form>
					)}
				</Spin>
				{ControllerComponent && (
					<ControllerComponent ref={controllerComponentRef} />
				)}
			</Drawer>
		);
	}
	function ViewDrawer({ location }: ViewDrawerProps) {
		const {
			page: { deletePrompt },
			view: {
				footer,
				width = 500,
				title = 'Pregled',
				shouldFetch = true,
				fields = [],
				descriptionsProps,
				additionalContent,
				customDrawer,
			} = {},
		} = options;
		const [viewQueryParam, setViewQueryParam] = useQueryParam(
			customDrawer ||
				`view/${module}/${submodule}${location ? `|${location}` : ''}`,
			StringParam
		);
		const [, setEditQueryParam] = useQueryParam(
			`edit/${module}/${submodule}${location ? `|${location}` : ''}`,
			StringParam
		);

		const canEditPermission = usePermissions(module, submodule, 'edit');
		const canDeletePermission = usePermissions(module, submodule, 'delete');

		const modelStore = stores[modelPlural];

		const [single, setSingle] = useState(null);

		const { fetchSingle, getOrFetchSingle } = modelStore;

		const [visible, setVisible] = useState(false);

		const [realVisible, entityId] = useMemo(() => {
			const id = viewQueryParam;
			const visible = Boolean(id);

			return [visible, id];
		}, [viewQueryParam]);

		useEffect(() => {
			if (realVisible) {
				setVisible(true);
			} else {
				setVisible(false);
			}
		}, [realVisible]);

		const { setIsDrawerClosed } = useContext(DrawerContext);

		useEffect(() => {
			if (!visible) {
				setIsDrawerClosed(
					`view/${model}/${submodule}${location ? `|${location}` : ''}`
				);
			}
		}, [location, setIsDrawerClosed, visible]);

		useEffect(() => {
			if (visible && shouldFetch && entityId) {
				flowResult(fetchSingle(entityId)).then((response) => {
					setSingle(response);
				});
			} else if (visible && !shouldFetch && entityId) {
				flowResult(getOrFetchSingle(entityId)).then((response) => {
					setSingle(response);
				});
			}
		}, [entityId, fetchSingle, getOrFetchSingle, shouldFetch, visible]);

		const isLoading = false;

		const viewTitle = useMemo(() => {
			if (!visible) {
				return null;
			}
			return typeof title === 'function' ? title(single) : title;
		}, [visible, title, single]);

		const close = () => {
			setViewQueryParam(undefined);
		};
		const edit = () => {
			setEditQueryParam(viewQueryParam);
			close();
		};

		const onDeleteClick = useCallback(async () => {
			await single.destroy();
			close();
		}, [close, single]);

		if (!single) {
			return null;
		}

		return (
			<Drawer
				title={viewTitle}
				open={visible}
				width={width}
				destroyOnClose
				footerStyle={{ textAlign: 'right' }}
				onClose={close}
				footer={
					footer ? (
						footer(single, edit, close, onDeleteClick)
					) : (
						<>
							<Space className={styles.leftButton}>
								{single?.isEditable && canEditPermission && (
									<Button
										key="edit"
										onClick={edit}
										icon={<i className="fi fi-rr-pencil"></i>}
									>
										<Trans>Измени</Trans>
									</Button>
								)}
								{single?.isDeletable && canDeletePermission && (
									<Popconfirm
										placement="topRight"
										title={deletePrompt}
										onConfirm={onDeleteClick}
										okText={t`Да`}
										cancelText={t`Не`}
									>
										<Button
											key="delete"
											danger
											icon={<i className="fi fi-rr-trash"></i>}
										>
											<Trans>Обриши</Trans>
										</Button>
									</Popconfirm>
								)}
							</Space>
							<Button key="close" type="primary" onClick={close}>
								<Trans>Затвори</Trans>
							</Button>
						</>
					)
				}
			>
				<Spin spinning={isLoading}>
					{single &&
						(isValidElement(fields)
							? cloneElement(fields, {
									record: single,
							  })
							: (typeof fields === 'function'
									? (fields as any)(single)
									: fields
							  ).map((row) => (
									<>
										<Title>{row.label}</Title>
										<Row
											key={row.key}
											className={styles.descriptions}
											gutter={[8, 8]}
										>
											{row.fields.map((field) => {
												const dprops =
													row.descriptionsProps || descriptionsProps;
												const text = field.render
													? field.render(
															get(single, field.dataIndex || field.key),
															single
													  )
													: get(single, field.dataIndex || field.key);
												const viewComponent = field.component
													? cloneElement(field.component, {
															value: text,
															record: single,
													  })
													: null;

												return (
													<Col
														span={
															field.span &&
															field.span * (24 / (row.column || 24))
														}
														xs={
															field.xs && field.xs * (24 / (row.column || 24))
														}
														sm={
															field.sm && field.sm * (24 / (row.column || 24))
														}
														md={
															field.md && field.md * (24 / (row.column || 24))
														}
														lg={
															field.lg && field.lg * (24 / (row.column || 24))
														}
														xl={
															field.xl && field.xl * (24 / (row.column || 24))
														}
														xxl={
															field.xxl && field.xxl * (24 / (row.column || 24))
														}
													>
														<Row gutter={[4, 4]}>
															<Col
																span={
																	dprops?.layout !== 'horizontal'
																		? 24
																		: undefined
																}
															>
																<Row align={'middle'}>
																	<Col flex={1}>
																		<Typography.Text
																			strong
																			className={`${styles.label}`}
																		>
																			{field.label}
																			{field.label ? ':' : ''}
																		</Typography.Text>
																	</Col>
																	{field.labelExtra && (
																		<Col>{field.labelExtra}</Col>
																	)}
																</Row>
															</Col>
															<Col
																flex="auto"
																style={dprops?.contentStyle}
																span={
																	dprops?.layout !== 'horizontal'
																		? 24
																		: undefined
																}
															>
																{viewComponent || nl2br(text) || (
																	<Typography.Text disabled>
																		<Trans>Није доступно</Trans>
																	</Typography.Text>
																)}
															</Col>
														</Row>
													</Col>
												);
											})}
										</Row>
									</>
							  )))}
				</Spin>
				{additionalContent}
			</Drawer>
		);
	}

	const WrappedEditDrawer = observer(EditDrawer);
	const WrappedViewDrawer = observer(ViewDrawer);

	addToDrawersRegistry(`create/${module}/${submodule}`, WrappedEditDrawer);
	addToDrawersRegistry(`edit/${module}/${submodule}`, WrappedEditDrawer);
	addToDrawersRegistry(`view/${module}/${submodule}`, WrappedViewDrawer);

	Object.entries(pageDrawers).forEach(([key, Drawer]) => {
		addToDrawersRegistry(key, Drawer);
	});

	const ObservedPage = observer(Page, { forwardRef: true });
	return {
		Page: ObservedPage,
	};
}

export function useDrawer(
	fullDrawerName,
	listener?: (event: string, data: any, queryParam: any) => void,
	trackVisibility = true
): [
	queryParam: string,
	open: (
		param?: string | number,
		prefill?: Record<string, string | boolean | number | void>,
		extra?: Record<string, string | boolean | number | void>
	) => void,
	close: () => void,
	visible: boolean,
	emitter: (event: string, data: unknown, queryParam?: unknown) => void,
	drawerComponent: any
] {
	const [drawerName] = fullDrawerName.split('|');
	const [drawerQueryParam, setDrawerQueryParam] = useQueryParam(
		fullDrawerName,
		StringParam
	);
	const [, setPrefillQueryParam] = useQueryParam(
		`${fullDrawerName}/prefill`,
		StringParam
	);
	const [, setExtraQueryParam] = useQueryParam(
		`${fullDrawerName}/extra`,
		StringParam
	);
	const bus = useBus();
	const { setIsDrawerClosed, setIsDrawerOpen, getRegisteredDrawer } =
		useContext(DrawerContext);

	const openDrawer = useCallback(
		(
			param?: string | number,
			prefill?: Record<string, string | boolean | number | void>,
			extra?: Record<string, string | boolean | number | void>
		) => {
			setDrawerQueryParam(`${param}`);
			if (prefill) {
				setPrefillQueryParam(JSON.stringify(prefill));
			}
			if (extra) {
				setExtraQueryParam(JSON.stringify(extra));
			}
		},
		[setDrawerQueryParam, setPrefillQueryParam, setExtraQueryParam]
	);

	const closeDrawer = useCallback(() => {
		setDrawerQueryParam(undefined);
		setPrefillQueryParam(undefined);
		setExtraQueryParam(undefined);
		setIsDrawerClosed(fullDrawerName);
	}, [
		fullDrawerName,
		setDrawerQueryParam,
		setExtraQueryParam,
		setIsDrawerClosed,
		setPrefillQueryParam,
	]);

	const emitMessage = useCallback(
		(event: string, data: unknown, queryParam?: unknown) => {
			bus.emit(fullDrawerName, [event, data, queryParam]);
		},
		[bus, fullDrawerName]
	);

	useListener(fullDrawerName, (event: [string, unknown]) => {
		if (listener) {
			listener(...event);
		}
	});

	const visible = useMemo(() => {
		return Boolean(drawerQueryParam);
	}, [drawerQueryParam]);

	useEffect(() => {
		if (!visible) {
			setIsDrawerClosed(fullDrawerName);
		} else {
			setIsDrawerOpen(fullDrawerName);
		}
	}, [fullDrawerName, setIsDrawerClosed, setIsDrawerOpen, visible]);

	const registeredDrawer = getRegisteredDrawer(drawerName);

	return [
		trackVisibility ? drawerQueryParam : undefined,
		openDrawer,
		closeDrawer,
		trackVisibility ? visible : false,
		emitMessage,
		registeredDrawer,
	];
}
