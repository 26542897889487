import { t, Trans } from '@lingui/macro';
import {
	Input,
	Button,
	Row,
	Col,
	Form,
	Steps,
	Checkbox,
	Table,
	Tooltip,
	Spin,
	Card,
	Grid,
	Divider,
} from 'antd';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTitle } from 'react-use';

import styles from './Register.module.less';
import DisablePasswordAutocomplete from '../../components/DisablePasswordAutocomplete';
import { LanguagePicker } from '../../components/LanguagePicker';
import Logo from '../../components/Logo';
import { Title } from '../../components/Title';
import { getApplicationName, PACKAGE_PRICE } from '../../constants/application';
import {
	ERROR_CONFLICT_DUPLICATE_EMAIL,
	ERROR_CONFLICT_DUPLICATE_TIN,
	ERROR_NOT_FOUND_TIN,
} from '../../constants/errors';
import validatePIB from '../../lib/pibValidator';
import stores from '../../stores/index.mobx';

const { Password } = Input;

const { Item: FormItem } = Form;
const { Step } = Steps;

function Available() {
	return <i className={`fi fi-sr-check-circle ${styles.available}`} />;
}
function Unavailable() {
	return <i className={`fi fi-sr-minus-circle ${styles.unavailable}`} />;
}

const STEPS = {
	PACKAGE: '0',
	PAYMENT_TYPE: '1',
	COMPANY: '2',
	FINISH: '3',
};

function Register() {
	const APPLICATION_NAME = getApplicationName();
	useTitle(t`${APPLICATION_NAME} - Регистрација`);
	const [form] = Form.useForm();
	const {
		step = STEPS.PACKAGE,
		package: selectedPackage = undefined,
		paymentTypes = 'all',
	}: {
		step?: string;
		package?: string;
		paymentTypes?: string;
	} = useParams();
	const {
		company: { isFetching, create, createDemo, isCreating },
	} = stores;

	const screens = Grid.useBreakpoint();

	const [manualEntry, setManualEntry] = useState(false);
	const [tinNotFound, setTinNotFound] = useState(false);

	const navigate = useNavigate();

	const selectPackage = useCallback(
		async (pkg) => {
			if (process.env.REACT_APP_IS_DEMO) {
				await flowResult(
					createDemo({
						selectedPackage: pkg,
					})
				);

				navigate(`/register/${STEPS.FINISH}`);
			} else {
				navigate(`/register/${STEPS.PAYMENT_TYPE}/${pkg}`);
			}
		},
		[createDemo, navigate]
	);
	const selectPaymentTypes = useCallback(
		(pt) => {
			navigate(`/register/${STEPS.COMPANY}/${selectedPackage}/${pt}`);
		},
		[navigate, selectedPackage]
	);

	const handleBackButton = useCallback(() => {
		switch (step) {
			case STEPS.PACKAGE:
				navigate('/');
				break;
			case STEPS.PAYMENT_TYPE:
				navigate(`/register/${STEPS.PACKAGE}`);
				break;
			case STEPS.COMPANY:
				navigate(`/register/${STEPS.PAYMENT_TYPE}/${selectedPackage}`);
				break;
		}
	}, [navigate, selectedPackage, step]);

	const handleCompanyRegistration = useCallback(() => {
		setTinNotFound(false);
		form.validateFields().then(async (values) => {
			try {
				await flowResult(
					create({
						...values,
						selectedPackage,
						availablePaymentMethods: paymentTypes,
					})
				);
				if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
					gtag('event', 'conversion', {
						send_to: 'AW-10893059914/HuS-CLbwsbgDEMrOm8oo',
						value: PACKAGE_PRICE[selectedPackage],
						currency: 'RSD',
					});
				}
				navigate(`/register/${STEPS.FINISH}`);
			} catch (error) {
				switch (error.response?.data?.errorCode) {
					case ERROR_CONFLICT_DUPLICATE_TIN:
						form.setFields([
							{
								name: 'tin',
								errors: [t`ПИБ је већ у употреби`],
							},
						]);
						break;
					case ERROR_NOT_FOUND_TIN:
						setTinNotFound(true);
						break;
					case ERROR_CONFLICT_DUPLICATE_EMAIL:
						form.setFields([
							{
								name: 'adminEmail',
								errors: [
									t`Већ постоји корисник са овом адресом електронске поште`,
								],
							},
						]);
						break;
				}
			}
		});
	}, [form, create, selectedPackage, paymentTypes, navigate]);

	const handleSubmit = useCallback(() => {
		return handleCompanyRegistration();
	}, [handleCompanyRegistration]);

	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.top}>
					<Logo color="light" />
				</div>
				<Form
					onFinish={handleSubmit}
					layout="vertical"
					className={styles.form}
					form={form}
					name="register"
					validateTrigger="onSubmit"
					autoComplete="false"
				>
					<Spin spinning={isFetching || isCreating}>
						<Row align="middle">
							<Col flex="1">
								{step !== STEPS.FINISH && (
									<Button
										type="link"
										icon={<i className="fi fi-rr-angle-small-left"></i>}
										onClick={handleBackButton}
									>
										<Trans>Назад</Trans>
									</Button>
								)}
							</Col>
							<Col flex="3">
								{!process.env.REACT_APP_IS_DEMO && (
									<h2 className={styles.panelTitle}>
										{screens.sm && (
											<Trans>Регистрација на {APPLICATION_NAME}</Trans>
										)}
										{screens.xs && <Trans>Регистрација</Trans>}
									</h2>
								)}
								{process.env.REACT_APP_IS_DEMO && (
									<h2 className={styles.panelTitle}>
										{screens.sm && (
											<Trans>{APPLICATION_NAME} демо верзија</Trans>
										)}
										{screens.xs && <Trans>Демо верзија</Trans>}
									</h2>
								)}
							</Col>
							<Col flex="1"></Col>
						</Row>
						{screens.md && (
							<FormItem>
								<Steps
									size={screens.md ? 'small' : 'default'}
									progressDot
									current={parseInt(step, 10)}
								>
									<Step title={t`Пакет`} />
									{!process.env.REACT_APP_IS_DEMO && (
										<Step title={t`Врста промета`} />
									)}
									{!process.env.REACT_APP_IS_DEMO && (
										<Step title={t`Подаци предузећа`} />
									)}
									<Step title={t`Крај`} />
								</Steps>
							</FormItem>
						)}
						{step === STEPS.PACKAGE && (
							<>
								<FormItem className={styles.panelTitle}>
									{!process.env.REACT_APP_IS_DEMO && (
										<Trans>
											Одаберите пакет у зависности од ваших потреба.
											<br /> Сваки од пакета укључује пробни период од 14 дана.
										</Trans>
									)}
									{process.env.REACT_APP_IS_DEMO && (
										<Trans>
											Одаберите пакет за који желите да
											<br /> испробате демо верзију.
										</Trans>
									)}
								</FormItem>
								<Table
									scroll={{ x: 450 }}
									size={screens.md ? 'large' : 'small'}
									pagination={false}
									dataSource={[
										{
											feature: (
												<>
													<Trans>Основне функције</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>
																Артикли, евидентирање продаје, преглед издатих
																рачуна, штампа...
															</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											basic: <Available />,
											standard: <Available />,
											professional: <Available />,
											premium: <Available />,
										},
										{
											feature: <Trans>Подршка за В-ПФР</Trans>,
											basic: <Available />,
											standard: <Available />,
											professional: <Available />,
											premium: <Available />,
										},
										{
											feature: <Trans>Л-ПФР</Trans>,
											basic: <Available />,
											standard: <Available />,
											professional: <Available />,
											premium: <Available />,
										},
										{
											feature: <Trans>Корисници</Trans>,
											basic: 1,
											standard: t`Неограничено`,
											professional: t`Неограничено`,
											premium: t`Неограничено`,
										},
										{
											feature: <Trans>Продајна места</Trans>,
											basic: 1,
											standard: t`Неограничено`,
											professional: t`Неограничено`,
											premium: t`Неограничено`,
										},
										{
											feature: <Trans>Рачуни</Trans>,
											basic: t`10 месечно, сваки следећи 50 РСД`,
											standard: t`Неограничено`,
											professional: t`Неограничено`,
											premium: t`Неограничено`,
										},
										{
											feature: (
												<>
													<Trans>Варијанте</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>
																Груписање артикала у сродне варијанте.
															</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											basic: <Unavailable />,
											standard: <Available />,
											professional: <Available />,
											premium: <Available />,
										},
										{
											feature: (
												<>
													<Trans>Документација</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>Фактуре, књижна одобрења и задужења</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											basic: <Unavailable />,
											standard: <Available />,
											professional: <Available />,
											premium: <Available />,
										},
										{
											feature: (
												<>
													<Trans>Апликације</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>
																Апликације које проширују функционалност
																платформе
															</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											basic: <Unavailable />,
											standard: <Available />,
											professional: <Available />,
											premium: <Available />,
										},
										{
											feature: <Trans>Праћење залиха робе</Trans>,
											basic: <Unavailable />,
											standard: <Unavailable />,
											professional: <Available />,
											premium: <Available />,
										},

										// {
										// 	feature: (
										// 		<>
										// 			<Trans>Књиговодство</Trans>&nbsp;
										// 		</>
										// 	),
										// 	basic: <Unavailable />,
										// 	standard: <Unavailable />,
										// 	professional: <Unavailable />,
										// 	premium: <Available />,
										// },
										{
											feature: <Trans>Напредни извештаји</Trans>,
											basic: <Unavailable />,
											standard: <Unavailable />,
											professional: <Unavailable />,
											premium: <Available />,
										},
										{
											feature: (
												<>
													<Trans>Цена</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>Обрачунава се по продајном месту</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											basic: t`400 РСД месечно`,
											standard: t`1800 РСД месечно`,
											professional: t`2400 РСД месечно`,
											premium: t`3600 РСД месечно`,
										},
										{
											feature: '',
											basic: (
												<Button
													type="primary"
													size={screens.md ? 'large' : 'small'}
													onClick={() => selectPackage('basic')}
												>
													<Trans>Одабери</Trans>
												</Button>
											),
											standard: (
												<Button
													type="primary"
													size={screens.md ? 'large' : 'small'}
													onClick={() => selectPackage('standard')}
												>
													<Trans>Одабери</Trans>
												</Button>
											),
											professional: (
												<Button
													type="primary"
													size={screens.md ? 'large' : 'small'}
													disabled
												>
													<Trans>Ускоро</Trans>
												</Button>
											),
											premium: (
												<Button
													type="primary"
													size={screens.md ? 'large' : 'small'}
													disabled
												>
													<Trans>Ускоро</Trans>
												</Button>
											),
										},
									]}
								>
									<Table.Column dataIndex="feature" align="right" />
									<Table.Column
										dataIndex="basic"
										title={t`Основни`}
										align="center"
										width={!screens.md ? 60 : 80}
									/>
									<Table.Column
										dataIndex="standard"
										title={t`Стандард`}
										align="center"
										width={!screens.md ? 60 : 80}
									/>
									<Table.Column
										dataIndex="professional"
										title={t`Профи`}
										align="center"
										width={!screens.md ? 60 : 80}
									/>
									<Table.Column
										dataIndex="premium"
										title={t`Премијум`}
										align="center"
										width={!screens.md ? 60 : 80}
									/>
								</Table>
							</>
						)}
						{step === STEPS.PAYMENT_TYPE && (
							<>
								<FormItem className={styles.panelTitle}>
									<Trans>
										Одаберите врсту промета која се евидентира на овој
										инсталацији ЕСИР-а. <br />
										Нећете бити у могућности да накнадно сами промените врсту
										промета.
									</Trans>
								</FormItem>
								<FormItem>
									<Row gutter={[16, 16]}>
										<Col span={screens.md ? 12 : 24}>
											<Card
												className={styles.typeCard}
												type="inner"
												actions={[
													<Button
														type="primary"
														onClick={() => {
															selectPaymentTypes('reduced');
														}}
													>
														<Trans>Одабери</Trans>
													</Button>,
												]}
											>
												<div className={styles.centered}>
													<FormItem className={styles.panelTitle}>
														<strong>
															<Trans>
																Храна и пиће за конзумацију на лицу места
															</Trans>
														</strong>
													</FormItem>

													<img
														src="/images/icons/new/burger.svg"
														className={styles.typeIcon}
													/>
												</div>
												<FormItem>
													<Trans>
														Преко овог фискалног уређаја се евидентира промет
														услуге предаје хране и пића за конзумацију на лицу
														места у смислу прописа којим се уређује порез на
														додату вредност и промет хране и пића који се врши у
														објекту пекаре, без обзира на то да ли се преко овог
														фискалног уређаја евидентира и други промет на мало.
														<br />
														<br />
														Дозвољени начини плаћања за ову врсту промета:
														<br />
														<br />
														<ol>
															<li>
																<strong>"готовина"</strong> - ако се плаћање
																врши готовим новцем (новчаницама и кованим
																новцем), инстант трансфером одобрења на
																продајном месту, платном картицом и чеком
															</li>
															<li>
																<strong>"пренос на рачун"</strong> - ако се
																плаћање врши налогом за уплату или налогом за
																пренос
															</li>
															<li>
																<strong>"ваучер"</strong> - ако се плаћање врши
																ваучером, новчаним боновима, интерним картицама
																или сличним средством плаћања
															</li>
															<li>
																<strong>"друго безготовинско плаћање"</strong> -
																ако се плаћање врши безготовински другим
																средством плаћања које се сматра дозвољеним у
																Републици Србији, у складу са законом.
															</li>
														</ol>
													</Trans>
												</FormItem>
											</Card>
										</Col>
										<Col span={screens.md ? 12 : 24}>
											<Card
												className={styles.typeCard}
												type="inner"
												actions={[
													<Button
														type="primary"
														onClick={() => {
															selectPaymentTypes('all');
														}}
													>
														<Trans>Одабери</Trans>
													</Button>,
												]}
											>
												<div className={styles.centered}>
													<FormItem className={styles.panelTitle}>
														<strong>
															<Trans>Све остале врсте промета</Trans>
														</strong>
													</FormItem>

													<img
														src="/images/icons/new/bag.svg"
														className={styles.typeIcon}
													/>
												</div>
												<FormItem>
													<Trans>
														Преко овог фискалног уређаја се евидентира промет
														свих осталих врста.
														<br />
														<br />
														Дозвољени су сви начини плаћања за ову врсту
														промета, односно:
														<br />
														<br />
														<ol>
															<li>
																<strong>"готовина"</strong> - ако се плаћање
																врши готовим новцем (новчаницама и кованим
																новцем)
															</li>
															<li>
																<strong>"инстант плаћање"</strong> - ако се
																плаћање врши инстант трансфером одобрења на
																продајном месту
															</li>
															<li>
																<strong>"платна картица"</strong> - ако се
																плаћање врши платном картицом
															</li>
															<li>
																<strong>"чек"</strong> - ако се плаћање врши
																чеком
															</li>
															<li>
																<strong>"пренос на рачун"</strong> - ако се
																плаћање врши налогом за уплату или налогом за
																пренос
															</li>
															<li>
																<strong>"ваучер"</strong> - ако се плаћање врши
																ваучером, новчаним боновима, интерним картицама
																или сличним средством плаћања
															</li>
															<li>
																<strong>"друго безготовинско плаћање"</strong> -
																ако се плаћање врши безготовински другим
																средством плаћања које се сматра дозвољеним у
																Републици Србији, у складу са законом.
															</li>
														</ol>
													</Trans>
												</FormItem>
											</Card>
										</Col>
									</Row>
								</FormItem>
							</>
						)}
						{step === STEPS.COMPANY && (
							<>
								<DisablePasswordAutocomplete />
								<FormItem>
									<Trans>
										Унесите основне податке о предузећу. На основу Вашег
										пореског идентификационог броја (ПИБ) ће се преузети подаци
										о предузећу, а поред тога је потребно да унесете и податке
										администратора.
									</Trans>
								</FormItem>
								<Title>
									<Trans>Информације о предузећу</Trans>
								</Title>
								<FormItem
									rules={
										!manualEntry
											? [
													{ required: true, message: t`Унесите ПИБ` },
													{
														type: 'string',
														validator: async (rule, value) => {
															return validatePIB(value)
																? Promise.resolve()
																: Promise.reject();
														},
														message: (
															<>
																<Trans>
																	ПИБ који сте унели није исправан. Кликните{' '}
																	<strong>
																		<a
																			href="#"
																			onClick={() => {
																				setManualEntry(true);
																				setTinNotFound(false);
																			}}
																		>
																			овде
																		</a>
																	</strong>{' '}
																	за ручни унос података.
																</Trans>
															</>
														),
													},
											  ]
											: [{ required: true, message: t`Унесите ПИБ` }]
									}
									name="tin"
									label={t`ПИБ`}
									validateStatus={tinNotFound ? 'error' : undefined}
									help={
										tinNotFound ? (
											<Trans>
												ПИБ који сте унели није пронађен у бази података Народне
												банке Србије. Кликните{' '}
												<strong>
													<a
														href="#"
														onClick={() => {
															setManualEntry(true);
															setTinNotFound(false);
														}}
													>
														овде
													</a>
												</strong>{' '}
												за ручни унос података.
											</Trans>
										) : undefined
									}
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="companyRegistrationNumber"
									label={t`Матични број`}
									extra={t`Уколико делатност обављате као физичко лице, и идентификујете се ЈМБГ-ом, унесите га у поље предвиђено за ПИБ, а матични број оставите празан.`}
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="name"
									label={t`Назив предузећа`}
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="address"
									label={t`Адреса предузећа`}
								>
									<Input size="large" />
								</FormItem>
								<FormItem
									hidden={!manualEntry}
									name="zip"
									label={t`Поштански број`}
								>
									<Input size="large" />
								</FormItem>
								<FormItem hidden={!manualEntry} name="city" label={t`Град`}>
									<Input size="large" />
								</FormItem>

								<FormItem
									name="vat"
									valuePropName="checked"
									hidden={!manualEntry}
								>
									<Checkbox>
										<Trans>У систему ПДВ-а</Trans>
									</Checkbox>
								</FormItem>
								<Title>
									<Trans>Информације о администратору</Trans>
								</Title>
								<Row gutter={8}>
									<Col span={12}>
										<FormItem
											rules={[{ required: true, message: t`Унесите име` }]}
											name="adminFirstName"
											label={t`Име`}
										>
											<Input size="large" />
										</FormItem>
									</Col>
									<Col span={12}>
										<FormItem
											rules={[
												{
													required: true,
													message: t`Унесите презиме`,
												},
											]}
											name="adminLastName"
											label={t`Презиме`}
										>
											<Input size="large" />
										</FormItem>
									</Col>
								</Row>
								<FormItem
									rules={[
										{
											required: true,
											message: t`Унесите адресу електронске поште`,
										},
									]}
									name="adminEmail"
									label={t`Адреса електронске поште`}
								>
									<Input
										size="large"
										autoComplete="false"
										role="presentation"
									/>
								</FormItem>
								<FormItem
									name="adminPassword"
									rules={[
										{ required: true, message: t`Унесите лозинку` },
										{
											type: 'string',
											min: 8,
											message: t`Лозинка мора имати најмање 8 карактера`,
										},
									]}
									label={t`Лозинка`}
								>
									<Password
										size="large"
										type="password"
										autoComplete="false"
										role="presentation"
									/>
								</FormItem>
								<FormItem
									name="passwordRepeat"
									rules={[
										{ required: true, message: t`Унесите лозинку` },
										({ getFieldValue }) => ({
											validator(_, value) {
												if (
													!value ||
													getFieldValue('adminPassword') === value
												) {
													return Promise.resolve();
												}
												return Promise.reject(
													new Error(t`Лозинке се не поклапају`)
												);
											},
										}),
									]}
									label={t`Поновите лозинку`}
								>
									<Password size="large" type="password" />
								</FormItem>
								<FormItem>
									<Button
										size="large"
										type="primary"
										htmlType="submit"
										block
										disabled={isFetching || isCreating}
										loading={isFetching || isCreating}
									>
										<Trans>Заврши регистрацију</Trans>
									</Button>
								</FormItem>
							</>
						)}
						{step === STEPS.FINISH && (
							<>
								<FormItem className={styles.centered}>
									<h1 className={styles.panelTitle}>
										<Trans>Честитамо!</Trans>
									</h1>
									<img
										className={styles.successIcon}
										src="/images/icons/new/happy.svg"
										alt={t`Успешна регистрација`}
									/>
									<p>
										<Trans>
											Успешно сте регистровали своје предузеће на{' '}
											{APPLICATION_NAME}
										</Trans>
									</p>
								</FormItem>
								<FormItem>
									<Button
										size="large"
										type="primary"
										htmlType="submit"
										block
										onClick={() => (window.location.href = '/app/home')}
									>
										<Trans>Започни коришћење</Trans>
									</Button>
								</FormItem>
							</>
						)}
					</Spin>
				</Form>
				<div className={styles.copyright}>
					<LanguagePicker />
					<Divider type="vertical" />
					<Trans>
						Copyright © {new Date().getFullYear()} DVSoftware. Сва права
						задржана.
					</Trans>
				</div>
			</div>
		</div>
	);
}

export default observer(Register);
