import { action, flowResult, observable } from 'mobx';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import config from '../config.json';
import { defaultClient, v2Client } from '../store/client';
import { createReceipt } from '../api';

const apiURL = new URL(config.api);
const wsURL = `${apiURL.protocol === 'https:' ? 'wss' : 'ws'}://${
	apiURL.host
}/ws`;

let socket;

const { Entity } = CreateStore({
	name: 'socket',
	type: 'entity',
	paginated: false,
});

class Socket extends Entity {
	@observable status: 'disconnected' | 'connecting' | 'connected' | 'error' =
		'disconnected';
	@observable connectionId?: string;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@action.bound
	setStatus(status: 'disconnected' | 'connecting' | 'connected' | 'error') {
		this.status = status;
	}

	@action.bound
	setConnectionId(connectionId: string) {
		this.connectionId = connectionId;
	}

	@action
	socketSetStoreId = (storeId: string) => {
		socket.send(JSON.stringify({ type: 'setStoreId', storeId }));
	};

	@action
	socketSetClientId = (clientId: string) => {
		socket.send(JSON.stringify({ type: 'setClientId', clientId }));
	};

	@action
	socketSendMessage(message: unknown) {
		socket.send(JSON.stringify(message));
	}

	@action.bound
	async connect() {
		this.setStatus('connecting');

		const {
			users: userStore,
			receipts: receiptStore,
			stores: storesStore,
		} = stores;

		const lastActivity = Object.values(stores).reduce(
			(acc: string | null, store: any) => {
				if (!acc || (store.lastUpdated && store.lastUpdated > acc)) {
					return store.lastUpdated;
				}
				return acc;
			},
			null
		);

		let checkTimeout;
		try {
			socket = new WebSocket(
				`${wsURL}?token=${userStore.token}&lastConnectionTime=${
					lastActivity || ''
				}`
			);

			checkTimeout = setTimeout(() => {
				if (socket.readyState === 3) {
					this.setStatus('error');
					setTimeout(() => {
						this.connect();
					}, 5000);
				}
			}, 10000);

			socket.onopen = async () => {
				clearTimeout(checkTimeout);
				this.setStatus('connected');
				// send which store is currently selected, also needs to be sent when user selects another one
				socket.onmessage = async (event) => {
					const parsed = JSON.parse(event.data);
					if (parsed.type === 'error') {
						this.setStatus('error');
					}
					if (parsed.type === 'handshake') {
						this.socketSetStoreId(storesStore.currentStoreId);
						this.socketSetClientId(stores.application.clientId);
						this.setConnectionId(parsed.connectionId);
						defaultClient.defaults.headers.common['x-connection-id'] =
							parsed.connectionId;
						v2Client.defaults.headers.common['x-connection-id'] =
							parsed.connectionId;
					}
					if (parsed.type === 'events') {
						Object.entries(parsed.data).forEach(([eventType, data]) => {
							if (eventType === 'mojBadiSettings') {
								stores.mojBadi?.updateStatus(data.status, data.rejectedReason);
							} else if (eventType === 'productSaleChannels') {
								stores.products.updateSaleChannel(data);
							} else {
								const store = stores[eventType];
								store?.receiveFromSocket?.(data);
							}
						});
					}
					if (parsed.type === 'fiscalization') {
						try {
							let receiptResponse;

							switch (parsed.command) {
								case 'create':
									receiptResponse = await flowResult(
										receiptStore.apiCreateReceipt(parsed.data)
									);
									break;
								case 'add-item':
									receiptResponse = await flowResult(
										receiptStore.apiAddItem(
											parsed.data.draftId,
											parsed.data.item
										)
									);
									break;
								case 'finalize':
									receiptResponse = await flowResult(
										receiptStore.apiFinalize(
											parsed.data.draftId,
											parsed.data.body
										)
									);
									break;
								case 'normal':
									receiptResponse = await createReceipt(parsed.data);
									break;
								case 'add-advance-payment':
									receiptResponse = await flowResult(
										receiptStore.apiFiscalizeAddAdvancePayment(parsed.data.body)
									);
									break;
								case 'finalize-advance-refund':
									receiptResponse = await flowResult(
										receiptStore.apiFiscalizeFinalizeAdvanceRefund(
											parsed.data.body
										)
									);
									break;
								case 'finalize-advance':
									receiptResponse = await flowResult(
										receiptStore.apiFiscalizeFinalizeAdvance(parsed.data.body)
									);
									break;
								case 'copy':
									receiptResponse = await flowResult(
										receiptStore.apiFiscalizeCopy(parsed.data.body)
									);
									break;
								case 'refund':
									receiptResponse = await flowResult(
										receiptStore.apiFiscalizeRefund(parsed.data.body)
									);
									break;
								default:
									throw new Error('ERROR_INTERNAL_SERVER_UNKNOWN_COMMAND');
							}

							this.socketSendMessage({
								type: 'fiscalization',
								data: receiptResponse,
								requestId: parsed.requestId,
							});
						} catch (error) {
							this.socketSendMessage({
								type: 'fiscalization-error',
								error: error.message,
								variables: error.variables,
								requestId: parsed.requestId,
							});
						}
					}
				};
				socket.onclose = () => {
					if (this.status !== 'error') {
						this.setStatus('disconnected');
					}
					setTimeout(() => {
						this.connect();
					}, 1000);
				};
				socket.onerror = () => {
					this.setStatus('error');
					setTimeout(() => {
						this.connect();
					}, 5000);
				};
			};
		} catch (error) {
			clearTimeout(checkTimeout);
			this.setStatus('error');
			setTimeout(() => {
				this.connect();
			}, 5000);
		}
	}

	@action.bound
	disconnect() {
		socket?.close?.();
		this.setStatus('disconnected');
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			this.connect();
		} else {
			this.disconnect();
		}
	}
}

export { Socket };
