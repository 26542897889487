import { Trans, t } from '@lingui/macro';
import {
	Button,
	Col,
	Divider,
	Empty,
	List,
	Row,
	Select,
	Tag,
	Tooltip,
	Typography,
} from 'antd';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import { useCallback, useEffect, useRef, useState } from 'react';

import styles from './ProductInput.module.less';
import { useBarcodeScanner } from '../../../hooks/useBarcodeScanner';
import stores from '../../../stores/index.mobx';
import HasPermission from '../../HasPermission';
import { NoImage } from '../../NoImage';
import { useDrawer } from '../../Page';
interface Props extends React.ComponentProps<typeof Select> {
	onChange?: (value: number | null) => void;
	warehouseId?: string;
	value?: string;
}

function ProductInput({ onChange, value, warehouseId, ...props }: Props) {
	const {
		products: { search, byId: productsById },
	} = stores;

	useBarcodeScanner((barcode) => {
		const product = stores.products.byEan[barcode];
		if (product) {
			onChange(product.id);
		}
	});

	const [, openCreateProduct, , , , CreateProductDrawer] = useDrawer(
		'create/catalog/products|product-input',
		(event, entity: any) => {
			if (event === 'entity-create') {
				onChange(entity.id);
			}
		}
	);

	const [data, setData] = useState([]);
	const [searchValue, setSearchValue] = useState('');
	const [searchLoading, setSearchLoading] = useState(false);
	const searchTimeout = useRef(null);

	useEffect(() => {
		if (searchValue && searchValue !== '') {
			setSearchLoading(true);
			setData([]);

			clearTimeout(searchTimeout.current);
			searchTimeout.current = setTimeout(async () => {
				const searchResults = search(searchValue, { limit: 20 }).reduce(
					(acc, { item, matches }: any) => {
						if (item.hasVariants) {
							const variantMatches = matches.filter((match) =>
								match.key.startsWith('variants')
							);
							if (variantMatches.length === 0) {
								acc.push(
									...item.variants.map((variant) => ({
										...variant,
										name: item.name,
										coverImage: item.coverImage,
										categories: item.categories,
										product: item,
										variant,
										price: variant.getPriceForWarehouse(warehouseId),
									}))
								);
							} else {
								item.variants.forEach((variant) => {
									const variantMatch = variantMatches.find(
										(match) =>
											`${variant[match.key.split('.')?.[1]]}` === match.value
									);
									if (variantMatch) {
										acc.push({
											...variant,
											name: item.name,
											coverImage: item.coverImage,
											categories: item.categories,
											product: item,
											variant,
											price: variant.getPriceForWarehouse(warehouseId),
										});
									}
								});
							}
						} else {
							acc.push({
								...item,
								product: item,
								variant: null,
								price: item.getPriceForWarehouse(warehouseId),
							});
						}

						return acc;
					},
					[]
				);

				setData(searchResults);
				setSearchLoading(false);
			}, 500);
		} else {
			setData([]);
		}
	}, [search, searchValue, value, warehouseId]);

	useEffect(() => {
		if (value) {
			const product = productsById[value];
			if (product?.parent) {
				setData([
					{
						...product.parent,
						sku: product.sku,
						id: product.id,
						product: product.parent,
						variant: product,
						price: product.getPriceForWarehouse(warehouseId),
					},
				]);
			} else if (product) {
				setData([
					{
						...product,
						product,
						variant: null,
						price: product.getPriceForWarehouse(warehouseId),
					},
				]);
			}
		}
	}, [productsById, value, warehouseId]);

	const handleSearch = useCallback((value) => {
		setSearchValue(value);
	}, []);

	const handleInputKeyDown = useCallback(
		(e) => {
			if (e.key === 'Enter') {
				const product = stores.products.byEan[searchValue];
				if (product) {
					onChange(product.id);
				}
			}
		},
		[onChange, searchValue]
	);

	return (
		<>
			<Select
				popupClassName={styles.popup}
				value={value}
				popupMatchSelectWidth={false}
				showSearch
				defaultActiveFirstOption={true}
				suffixIcon={<i className="fi fi-rr-search"></i>}
				filterOption={false}
				onSearch={handleSearch}
				onChange={onChange}
				onInputKeyDown={handleInputKeyDown}
				notFoundContent={
					<>
						{searchLoading ? (
							<Empty
								imageStyle={{ height: 64 }}
								image={<img src="/images/icons/new/search.svg" alt="" />}
								description={t`Претрага је у току...`}
								className={styles.notFoundContainer}
							/>
						) : (
							<Empty
								image={<img src="/images/icons/new/delivery.svg" />}
								imageStyle={{ height: 64 }}
								description={
									searchValue
										? t`Није пронађен ни један артикал`
										: t`Унесите термин за претрагу`
								}
								className={styles.notFoundContainer}
							>
								<HasPermission
									module="catalog"
									submodule="products"
									action="create"
								>
									<Button type="link" onClick={() => openCreateProduct()}>
										<Trans>Додај нови артикал</Trans>
									</Button>
								</HasPermission>
							</Empty>
						)}
					</>
				}
				options={(data || []).map((d) => ({
					...d,
					value: d.id,
					label: (
						<>
							<Typography.Text type="secondary">{d.sku}</Typography.Text>
							<Divider type="vertical" />
							{d.name}&nbsp;&nbsp;
							{d.variant ? (
								<Tag bordered={false} color="magenta">
									{d.variant.variantName}
								</Tag>
							) : (
								''
							)}
						</>
					),
				}))}
				dropdownRender={(originNode) => {
					return (
						<>
							<List>{originNode}</List>
							{data.length > 0 && (
								<HasPermission
									module="catalog"
									submodule="products"
									action="create"
								>
									<Button type="link" onClick={() => openCreateProduct()}>
										<Trans>Додај нови артикал</Trans>
									</Button>
								</HasPermission>
							)}
						</>
					);
				}}
				optionRender={({ data: { product, variant } }) => {
					return (
						<List.Item>
							<List.Item.Meta
								avatar={
									product.coverImage ? (
										<img
											// className={styles.largeImage}
											src={product.coverImage.urls['64x64']}
											alt={product.name}
											className={styles.listImage}
										/>
									) : (
										<NoImage size="large" name={product.name} />
									)
								}
								title={
									<Tooltip
										title={
											<>
												{product?.name}&nbsp;&nbsp;
												{variant ? (
													<Tag color="black" bordered={false}>
														{variant.variantName}
													</Tag>
												) : (
													''
												)}
											</>
										}
									>
										{product?.name}&nbsp;&nbsp;
										{variant ? (
											<Tag color="magenta" bordered={false}>
												{variant.variantName}
											</Tag>
										) : (
											''
										)}
									</Tooltip>
								}
								description={
									<div className={styles.listDescription}>
										<Row gutter={8}>
											<Col span={12}>#{(variant || product).sku}</Col>
											<Col span={12} style={{ textAlign: 'right' }}>
												{`${numeral(
													(variant || product).getPriceForWarehouse(
														warehouseId
													) ||
														(variant || product).price ||
														0
												).format('0,0.00')}`}
												&nbsp;
												{product.currencyId || 'RSD'}
											</Col>
										</Row>
									</div>
								}
							></List.Item.Meta>
						</List.Item>
					);
				}}
				{...props}
			/>
			<CreateProductDrawer location="product-input" />
		</>
	);
}

export default observer(ProductInput);
