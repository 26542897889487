import Bugsnag from '@bugsnag/js';
import { t, Trans } from '@lingui/macro';
import { Button, Space, Tooltip, Badge, Grid, Dropdown } from 'antd';
import cyr2lat from 'cyrillic-to-latin';
import first from 'lodash/first';
import last from 'lodash/last';
import round from 'lodash/round';
import printjs from 'print-js';
import { useCallback, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';
import serbianTransliteration from 'serbian-transliteration';
import AdvanceList from './Components/AdvanceList';
import ProductsList from './Components/ProductsList';
import QR from './Components/QR';
import { ReferentDocumentLink } from './Components/ReferentDocumentLink';
import { RefundDrawerWrapper } from './Components/RefundDrawerWrapper';
import TaxList from './Components/TaxList';
import styles from './List.module.less';
import { DateRangeFilter } from '../../../../components/Filters/DateRange';
import { NumberRangeFilter } from '../../../../components/Filters/NumberRange';
import { TextFilter } from '../../../../components/Filters/Text';
import { CreatePage, useDrawer } from '../../../../components/Page';
import EmailModal from '../../../../components/PaymentModal/Components/EmailModal';
import PaymentModal from '../../../../components/PaymentModal/PaymentModal';
import { StaticComponents } from '../../../../components/StaticComponents';
import { ReactComponent as Contactless } from '../../../../assets/inline-svg/contactless.svg';
import cc from 'currency-codes';
import {
	InvoiceType,
	INVOICE_TYPE_FROM_STRING,
	INVOICE_TYPE_MAP,
	PaymentType,
	PAYMENT_TYPE_FROM_STRING,
	TransactionType,
	TransactionTypeAPI,
	TRANSACTION_TYPE_FROM_STRING,
	getInvoiceTypeText,
	getTransactionTypeText,
} from '../../../../constants/journal';
import { usePermissions } from '../../../../hooks/permissions';
import { A4Journal } from '../../../../lib/a4Journal';
import numberFormat from '../../../../lib/numberFormat';
import { ThermalJournal } from '../../../../lib/thermalJournal';
import stores from '../../../../stores/index.mobx';
import { Receipt } from '../../../../stores/Receipt.mobx';
import { Store } from '../../../../stores/Store.mobx';
import { User } from '../../../../stores/User.mobx';
import './Drawers/Refund';
import { hex2a, PaymentModalMode } from '../../../../lib/receipt';
import padStart from 'lodash/padStart';
import dayjs from 'dayjs';
// import CustomCreateButton from './Components/CustomCreateButton';

interface ReceiptButtonsProps {
	entity: Receipt;
	compact?: boolean;
}

function ReceiptButtons({ entity, compact = false }: ReceiptButtonsProps) {
	const {
		stores: { currentStore: store },
		devices: { thermalPrinters },
		receipts: { sendEmail: sendEmailAction, download: downloadAction },
	} = stores;
	const [emailModalVisible, setEmailModalVisible] = useState(false);
	const [paymentModalVisible, setPaymentModalVisible] = useState(false);
	const [paymentModalMode, setPaymentModalMode] =
		useState<PaymentModalMode>(null);

	const hasPosPermission = usePermissions('pos', 'pos', 'access');

	const [, openReceiptRefund] = useDrawer('receipt-refund');

	const screens = Grid.useBreakpoint();

	const isAdvanceOld = useMemo(() => {
		if (!entity || !entity.connectedReceipts) {
			return false;
		}

		return (
			TRANSACTION_TYPE_FROM_STRING[
				entity.connectedReceipts[0].transactionType
			] === TransactionType.REFUND &&
			INVOICE_TYPE_FROM_STRING[entity.connectedReceipts[0].invoiceType] ===
				InvoiceType.ADVANCE
		);
	}, [entity]);

	const connectedReceipts = useMemo(
		() =>
			entity?.connectedReceipts
				? [
						...(isAdvanceOld
							? [
									{
										...entity.connectedReceipts[0],
										transactionType: TransactionTypeAPI.SALE,
										invoiceNumber:
											entity.connectedReceipts[0].referentDocumentNumber,
										sdcTime: entity.connectedReceipts[0].referentDocumentDT,
									},
							  ]
							: []),
						...entity.connectedReceipts.filter(
							(r) =>
								INVOICE_TYPE_FROM_STRING[r.invoiceType] !== InvoiceType.COPY &&
								!r.void
						),
				  ]
				: [],
		[entity, isAdvanceOld]
	);

	const isAdvance = useMemo(() => {
		if (!entity || connectedReceipts.length === 0) {
			return false;
		}

		if (
			TRANSACTION_TYPE_FROM_STRING[entity.transactionType] ===
				TransactionType.REFUND ||
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.COPY
		) {
			return false;
		}

		if (
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.NORMAL &&
			TRANSACTION_TYPE_FROM_STRING[entity.transactionType] ===
				TransactionType.SALE
		) {
			return false;
		}

		const firstConnectedReceipt: Receipt = first(connectedReceipts);

		return (
			// firstConnectedReceipt.transactionType ===
			// 	TRANSACTION_TYPE_MAP[TransactionType.SALE] &&
			firstConnectedReceipt.invoiceType ===
			INVOICE_TYPE_MAP[InvoiceType.ADVANCE]
		);
	}, [entity, connectedReceipts]);

	const lastInvoice = useMemo(() => {
		return last(connectedReceipts);
	}, [connectedReceipts]);

	const hasCopyButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		if (
			[InvoiceType.ADVANCE, InvoiceType.NORMAL].includes(
				INVOICE_TYPE_FROM_STRING[entity.invoiceType]
			)
		) {
			return true;
		}

		return false;
	}, [entity]);

	const hasFinalizeButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		return (
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.PROFORMA &&
			TRANSACTION_TYPE_FROM_STRING[entity.transactionType] ===
				TransactionType.SALE
		);
	}, [entity]);

	const hasRefundButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		if (
			(INVOICE_TYPE_FROM_STRING[entity.invoiceType] !== InvoiceType.COPY &&
				TRANSACTION_TYPE_FROM_STRING[entity.transactionType]) ===
				TransactionType.SALE ||
			isAdvance
		) {
			return true;
		}

		return false;
	}, [entity, isAdvance]);

	const hasVoidButton = useMemo(() => {
		if (!entity) {
			return false;
		}

		if (
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.ADVANCE ||
			INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.NORMAL
		) {
			return true;
		}

		return false;
	}, [entity]);

	const totalAdvanceItemsSum = useMemo(() => {
		if (!entity) {
			return 0;
		}
		if (!entity.advanceItems || entity.advanceItems.length === 0) {
			return entity?.payment?.reduce((sum, payment) => {
				return sum + payment.amount;
			}, 0);
		}
		return round(
			(entity?.advanceItems || []).reduce((acc, cur) => {
				return acc + cur.totalAmount;
			}, 0),
			2
		);
	}, [entity]);

	const advanceSum = useMemo(() => {
		return round(
			connectedReceipts
				.filter(
					(cr) =>
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.ADVANCE &&
						!cr.void &&
						!cr.voids
				)
				.reduce((acc, cur) => {
					const paymentTotal =
						TRANSACTION_TYPE_FROM_STRING[cur.transactionType] ===
						TransactionType.SALE
							? cur.paymentTotal
							: -cur.paymentTotal;
					return acc + paymentTotal - cur.paymentChange;
				}, 0),
			2
		);
	}, [connectedReceipts]);

	const [isAddAdvanceButtonEnabled, addAdvanceButtonDisabledReason] =
		useMemo(() => {
			if (!entity || connectedReceipts.length === 0) {
				return [false, null];
			}

			if (
				INVOICE_TYPE_FROM_STRING[lastInvoice.invoiceType] ===
					InvoiceType.NORMAL &&
				TRANSACTION_TYPE_FROM_STRING[lastInvoice.transactionType] ===
					TransactionType.SALE
			) {
				return [
					false,
					t`Није могуће додати авансну уплату јер је аванс већ затворен`,
				];
			}

			if (!entity.advanceItems) {
				return [
					false,
					t`Није могуће додати авансну уплату јер аванс отворен по систему пре измене од 27.04.2022.`,
				];
			}

			if (!entity.unknownAmountAdvance && advanceSum >= totalAdvanceItemsSum) {
				return [
					false,
					t`Није могуће додати авансну уплату јер је укупан износ већ уплаћен`,
				];
			}

			return [true, null];
		}, [
			entity,
			connectedReceipts?.length,
			lastInvoice?.invoiceType,
			lastInvoice?.transactionType,
			advanceSum,
			totalAdvanceItemsSum,
		]);

	const [isCloseAdvanceButtonEnabled, closeAdvanceButtonDisabledReason] =
		useMemo(() => {
			if (!entity || connectedReceipts.length === 0) {
				return [false, null];
			}

			if (
				connectedReceipts.find(
					(cr) =>
						INVOICE_TYPE_FROM_STRING[cr.invoiceType] === InvoiceType.NORMAL &&
						TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
							TransactionType.SALE &&
						!cr.void
				)
			) {
				return [false, t`Аванс је већ затворен`];
			}
			return [true, null];
		}, [entity, connectedReceipts]);

	const [isFinalizeButtonEnabled, finalizeButtonDisabledReason] =
		useMemo(() => {
			if (!entity || connectedReceipts.length === 0) {
				return [false, null];
			}

			const normalSaleReceipt: Receipt = connectedReceipts.find((r) => {
				return (
					INVOICE_TYPE_FROM_STRING[r.invoiceType] === InvoiceType.NORMAL &&
					TRANSACTION_TYPE_FROM_STRING[r.transactionType] ===
						TransactionType.SALE
				);
			});

			if (
				INVOICE_TYPE_FROM_STRING[entity.invoiceType] === InvoiceType.PROFORMA &&
				normalSaleReceipt
			) {
				return [false, t`Није могуће издати коначан рачун јер је већ издат.`];
			}
			const remainingItems =
				entity.connectedReceipts
					.filter(
						(cr) =>
							TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
								TransactionType.REFUND &&
							[InvoiceType.PROFORMA].includes(
								INVOICE_TYPE_FROM_STRING[cr.invoiceType]
							) &&
							!cr.void &&
							!cr.voids
					)
					.reduce(
						(acc, cur) =>
							acc +
							cur.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0),
						0
					) -
				entity.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0);

			if (round(remainingItems, 2) === 0) {
				return [false, t`Предрачун је рефундиран`];
			}

			return [true, null];
		}, [entity, connectedReceipts]);

	const [isRefundButtonEnabled, refundButtonDisabledReason] = useMemo(() => {
		if (!entity || connectedReceipts.length === 0) {
			return [false, null];
		}

		if (entity.void) {
			return [false, t`Рачун је поништен`];
		}

		if (isAdvance) {
			if (!isCloseAdvanceButtonEnabled) {
				return [false, closeAdvanceButtonDisabledReason];
			}

			if (advanceSum == 0) {
				return [false, t`Све авансне уплате су већ рефундиране`];
			}
		}

		const remainingItems =
			entity.connectedReceipts
				.filter(
					(cr) =>
						TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
							TransactionType.REFUND &&
						[
							InvoiceType.NORMAL,
							InvoiceType.PROFORMA,
							InvoiceType.TRAINING,
						].includes(INVOICE_TYPE_FROM_STRING[cr.invoiceType]) &&
						!cr.void &&
						!cr.voids
				)
				.reduce(
					(acc, cur) =>
						acc +
						cur.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0),
					0
				) - entity.receiptItems.reduce((iacc, icur) => iacc + icur.quantity, 0);

		if (round(remainingItems, 2) === 0) {
			return [false, t`Цео рачун је већ рефундиран`];
		}

		return [true, null];
	}, [entity, connectedReceipts, isAdvance, isCloseAdvanceButtonEnabled]);

	const [isVoidButtonEnabled, voidButtonDisabledReason] = useMemo(() => {
		if (!entity || connectedReceipts.length === 0 || !hasVoidButton) {
			return [false, null];
		}

		if (entity.void) {
			return [false, t`Рачун је већ поништен`];
		}

		// if (entity.voids) {
		// 	return [false, t`Референтни рачун је већ поништен`];
		// }

		// filter voiders from connected receipts
		const filteredCR = connectedReceipts.filter(
			(cr) =>
				!entity?.connectedReceipts?.find(
					(ecr) => ecr.invoiceNumber === cr.referentDocumentNumber
				)?.void
		);

		const currentIndex = filteredCR.findIndex((cr) => cr.id === entity.id);

		// if threre is receipt after this one, it can't be voided
		if (currentIndex !== -1 && currentIndex < filteredCR.length - 1) {
			return [
				false,
				t`Рачун не може бити поништен јер постоји рачун након њега`,
			];
		}

		return [true, null];
	}, [entity, connectedReceipts, hasVoidButton]);

	const printThermal = useCallback(() => {
		if (entity) {
			setTimeout(() => {
				const thermalJournal = new ThermalJournal(
					{
						...entity,
						items: entity.receiptItems.map((item) => ({
							...item,
							labels: item.taxLabels,
						})),
						payment: entity.payment.map((payment) => ({
							paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
							amount:
								PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
								PaymentType.CASH
									? round(payment.amount - entity.paymentChange, 2)
									: payment.amount,
						})),
						cashier: entity.cashierName,
						invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
						transactionType:
							TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
						invoiceNumber: entity.posNumber,
						dateAndTimeOfIssue: entity.posTime,
						referentDocumentDT: entity.referentDocumentDT,
					},
					{
						...entity,
						tin: entity.secureElement.tin,
						address: entity.secureElement.address,
						district: entity.secureElement.district,
						businessName: entity.secureElement.businessName,
						locationName: entity.secureElement.locationName,
						sdcDateTime: entity.sdcTime,
					},
					entity.hasSignatureLine,
					entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
					(entity.advanceItems || []).map((ai) => ({
						product: {
							name: ai.name,
							ean: ai.gtin,
							saleUnit: {
								isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
								label: ai.unit,
							},
						},
						quantity: ai.quantity,
						finalPrice: ai.unitPrice,
						labels: ai.taxLabels,
					})),
					entity.lastAdvance,
					entity.additionalText,
					entity.unknownAmountAdvance
				);
				thermalJournal.print();
				StaticComponents.notification.success({
					message: t`Рачун ${entity.invoiceNumber} је послат на штампу`,
					duration: 5,
				});
			});
		}
	}, [entity]);
	const printA4 = useCallback(() => {
		if (entity) {
			setTimeout(async () => {
				const a4Journal = new A4Journal(
					{
						...entity,
						items: entity.receiptItems.map((item) => ({
							...item,
							labels: item.taxLabels,
						})),
						payment: entity.payment.map((payment) => ({
							paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
							amount:
								PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
								PaymentType.CASH
									? round(payment.amount - entity.paymentChange, 2)
									: payment.amount,
						})),
						cashier: entity.cashierName,
						invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
						transactionType:
							TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
						invoiceNumber: entity.posNumber,
						dateAndTimeOfIssue: entity.posTime,
						referentDocumentDT: entity.referentDocumentDT,
					},
					{
						...entity,
						tin: entity.secureElement.tin,
						address: entity.secureElement.address,
						district: entity.secureElement.district,
						businessName: entity.secureElement.businessName,
						locationName: entity.secureElement.locationName,
						sdcDateTime: entity.sdcTime,
					},
					entity.hasSignatureLine,
					entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
					(entity.advanceItems || []).map((ai) => ({
						product: {
							name: ai.name,
							ean: ai.gtin,
							saleUnit: {
								isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
								label: ai.unit,
							},
						},
						quantity: ai.quantity,
						finalPrice: ai.unitPrice,
						labels: ai.taxLabels,
					})),
					entity.lastAdvance,
					entity.additionalText,
					entity.unknownAmountAdvance
				);

				const element = document.createElement('div');
				const root = createRoot(element);
				root.render(a4Journal.getComponent());

				await a4Journal.getRenderDeferred();

				document.querySelector('#a4-temporary').innerHTML = element.innerHTML;

				setTimeout(() => {
					printjs({
						printable: 'a4-temporary',
						type: 'html',
						targetStyles: ['*'],
						font_size: '',
					});
				});
				StaticComponents.notification.success({
					message: t`Рачун ${entity.invoiceNumber} је послат на штампу`,
					duration: 5,
				});

				try {
					root.unmount();
				} catch (unmountError) {
					Bugsnag.notify(unmountError);
				}
			});
		}
	}, [entity]);
	const sendEmail = useCallback(
		({ email }) => {
			if (entity) {
				setTimeout(async () => {
					const a4Journal = new A4Journal(
						{
							...entity,
							items: entity.receiptItems.map((item) => ({
								...item,
								labels: item.taxLabels,
							})),
							payment: entity.payment.map((payment) => ({
								paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
								amount:
									PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
									PaymentType.CASH
										? round(payment.amount - entity.paymentChange, 2)
										: payment.amount,
							})),
							cashier: entity.cashierName,
							invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
							transactionType:
								TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
							invoiceNumber: entity.posNumber,
							dateAndTimeOfIssue: entity.posTime,
							referentDocumentDT: entity.referentDocumentDT,
						},
						{
							...entity,
							tin: entity.secureElement.tin,
							address: entity.secureElement.address,
							district: entity.secureElement.district,
							businessName: entity.secureElement.businessName,
							locationName: entity.secureElement.locationName,
							sdcDateTime: entity.sdcTime,
						},
						entity.hasSignatureLine,
						entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
						(entity.advanceItems || []).map((ai) => ({
							product: {
								name: ai.name,
								ean: ai.gtin,
								saleUnit: {
									isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
									label: ai.unit,
								},
							},
							quantity: ai.quantity,
							finalPrice: ai.unitPrice,
							labels: ai.taxLabels,
						})),
						entity.lastAdvance,
						entity.additionalText,
						entity.unknownAmountAdvance
					);

					const element = document.createElement('div');

					const root = createRoot(element);
					root.render(a4Journal.getComponent());

					await a4Journal.getRenderDeferred();

					const html = element.innerHTML;

					setTimeout(() => {
						sendEmailAction(email, html, entity.invoiceNumber);
					});

					StaticComponents.notification.success({
						message: t`Рачун ${entity.invoiceNumber} је послат на адресу електронске поште ${email}`,
						duration: 5,
					});

					try {
						root.unmount();
					} catch (unmountError) {
						Bugsnag.notify(unmountError);
					}
				});
			}
		},
		[entity, sendEmailAction]
	);
	const download = useCallback(() => {
		if (entity) {
			setTimeout(async () => {
				const a4Journal = new A4Journal(
					{
						...entity,
						items: entity.receiptItems.map((item) => ({
							...item,
							labels: item.taxLabels,
						})),
						payment: entity.payment.map((payment) => ({
							paymentType: PAYMENT_TYPE_FROM_STRING[payment.paymentType],
							amount:
								PAYMENT_TYPE_FROM_STRING[payment.paymentType] ===
								PaymentType.CASH
									? round(payment.amount - entity.paymentChange, 2)
									: payment.amount,
						})),
						cashier: entity.cashierName,
						invoiceType: INVOICE_TYPE_FROM_STRING[entity.invoiceType],
						transactionType:
							TRANSACTION_TYPE_FROM_STRING[entity.transactionType],
						invoiceNumber: entity.posNumber,
						dateAndTimeOfIssue: entity.posTime,
						referentDocumentDT: entity.referentDocumentDT,
					},
					{
						...entity,
						tin: entity.secureElement.tin,
						address: entity.secureElement.address,
						district: entity.secureElement.district,
						businessName: entity.secureElement.businessName,
						locationName: entity.secureElement.locationName,
						sdcDateTime: entity.sdcTime,
					},
					entity.hasSignatureLine,
					entity.showAdvanceSum ? entity.advancePaymentsSum : undefined,
					(entity.advanceItems || []).map((ai) => ({
						product: {
							name: ai.name,
							ean: ai.gtin,
							saleUnit: {
								isPieceUnitOfMeasure: ai.isPieceUnitOfMeasure,
								label: ai.unit,
							},
						},
						quantity: ai.quantity,
						finalPrice: ai.unitPrice,
						labels: ai.taxLabels,
					})),
					entity.lastAdvance,
					entity.additionalText,
					entity.unknownAmountAdvance
				);

				const element = document.createElement('div');

				const root = createRoot(element);

				root.render(a4Journal.getComponent());

				await a4Journal.getRenderDeferred();

				const html = element.innerHTML;

				setTimeout(() => {
					downloadAction(html, entity.invoiceNumber);
				});

				StaticComponents.notification.success({
					message: t`Рачун ${entity.invoiceNumber} ће ускоро почети са преузимањем`,
					duration: 5,
				});

				try {
					root.unmount();
				} catch (unmountError) {
					Bugsnag.notify(unmountError);
				}
			});
		}
	}, [downloadAction, entity]);

	const printMenuItems = useMemo(
		() => [
			...(window.electron &&
			thermalPrinters.length > 0 &&
			store?.printMethod?.thermal
				? [
						{
							key: 'printThermal',
							label: t`Штампај на термалном штампачу`,
							icon: screens.md && <i className="fi fi-rr-print"></i>,
						},
				  ]
				: []),
			...(store?.printMethod?.a4
				? [
						{
							key: 'printA4',
							label: t`Штампај на А4 штампачу`,
							icon: screens.md && <i className="fi fi-rr-print"></i>,
						},
				  ]
				: []),
			...(store?.printMethod?.email
				? [
						{
							key: 'email',
							label: t`Пошаљи путем електронске поште`,
							icon: screens.md && <i className="fi fi-rr-envelope"></i>,
						},
				  ]
				: []),
			{
				key: 'download',
				label: t`Преузми у ПДФ формату`,
				icon: screens.md && <i className="fi fi-rr-download"></i>,
			},
		],
		[
			screens.md,
			store?.printMethod?.a4,
			store?.printMethod?.email,
			store?.printMethod?.thermal,
			thermalPrinters?.length,
		]
	);

	const menuItems = useMemo(() => {
		const optionsMenuItems = [
			...(hasVoidButton
				? [
						{
							key: 'void',
							label: t`Поништи`,
							disabled: !isVoidButtonEnabled || !hasPosPermission,
							danger: true,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: voidButtonDisabledReason,
						},
				  ]
				: []),
			...(hasRefundButton
				? [
						{
							key: 'refund',
							label: t`Рефундирај`,
							disabled: !isRefundButtonEnabled || !hasPosPermission,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: refundButtonDisabledReason,
						},
				  ]
				: []),
			...(hasCopyButton
				? [
						{
							key: 'copy',
							label: t`Направи копију`,
							disabled: !hasPosPermission,
							title: !hasPosPermission ? t`Немате дозволу за ову акцију` : null,
						},
				  ]
				: []),
			...(hasFinalizeButton
				? [
						{
							key: 'finalize',
							label: t`Издај коначан рачун`,
							disabled: !isFinalizeButtonEnabled || !hasPosPermission,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: finalizeButtonDisabledReason,
						},
				  ]
				: []),
			...(isAdvance
				? [
						{
							key: 'addAdvancePayment',
							label: t`Додај авансну уплату`,
							disabled: !isAddAdvanceButtonEnabled || !hasPosPermission,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: addAdvanceButtonDisabledReason,
						},
						{
							key: 'closeAdvance',
							label: t`Затвори аванс`,
							disabled: !isCloseAdvanceButtonEnabled || !hasPosPermission,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: closeAdvanceButtonDisabledReason,
						},
				  ]
				: []),
			...(isAdvanceOld
				? [
						{
							key: 'addAdvancePayment',
							label: t`Додај авансну уплату`,
							disabled: !isAddAdvanceButtonEnabled || !hasPosPermission,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: addAdvanceButtonDisabledReason,
						},
						{
							key: 'closeAdvance',
							label: t`Затвори аванс`,
							disabled: !isCloseAdvanceButtonEnabled || !hasPosPermission,
							title: !hasPosPermission
								? t`Немате дозволу за ову акцију`
								: closeAdvanceButtonDisabledReason,
						},
				  ]
				: []),
		];
		if ((!screens.md || compact) && optionsMenuItems.length > 0) {
			optionsMenuItems.push({ type: 'divider' });
		}
		if (!screens.md || compact) {
			optionsMenuItems.push(...printMenuItems);
		}

		return optionsMenuItems;
	}, [
		hasVoidButton,
		isVoidButtonEnabled,
		hasPosPermission,
		voidButtonDisabledReason,
		hasRefundButton,
		isRefundButtonEnabled,
		refundButtonDisabledReason,
		hasCopyButton,
		hasFinalizeButton,
		isFinalizeButtonEnabled,
		finalizeButtonDisabledReason,
		isAdvance,
		isAddAdvanceButtonEnabled,
		addAdvanceButtonDisabledReason,
		isCloseAdvanceButtonEnabled,
		closeAdvanceButtonDisabledReason,
		isAdvanceOld,
		screens.md,
		compact,
		printMenuItems,
	]);

	const onPaymentModalClose = useCallback(() => {
		setPaymentModalVisible(false);
		setPaymentModalMode(null);
	}, []);

	if (!entity || !entity.payment) {
		return null;
	}

	return (
		<>
			{compact && (
				<Dropdown
					trigger={['click']}
					className={styles.rightButtons}
					menu={{
						items: menuItems,
						onClick: (item) => {
							switch (item.key) {
								case 'void':
									setPaymentModalMode(PaymentModalMode.void);
									setPaymentModalVisible(true);
									break;
								case 'refund':
									if (isAdvance) {
										setPaymentModalMode(PaymentModalMode.refund);
										setPaymentModalVisible(true);
									} else {
										openReceiptRefund(entity.id);
									}
									break;
								case 'copy':
									setPaymentModalMode(PaymentModalMode.copy);
									setPaymentModalVisible(true);
									break;
								case 'finalize':
									setPaymentModalMode(PaymentModalMode.finalizeProforma);
									setPaymentModalVisible(true);
									break;
								case 'addAdvancePayment':
									setPaymentModalMode(PaymentModalMode.addAdvancePayment);
									setPaymentModalVisible(true);
									break;
								case 'closeAdvance':
									setPaymentModalMode(PaymentModalMode.closeAdvance);
									setPaymentModalVisible(true);
									break;
								case 'printThermal':
									printThermal();
									break;
								case 'printA4':
									printA4();
									break;
								case 'email':
									setEmailModalVisible(true);
									break;
								case 'download':
									download();
									break;
							}
						},
					}}
				>
					<Button icon={<i className="fi fi-rr-menu-dots"></i>} />
				</Dropdown>
			)}
			{screens.md && !compact && (
				<Space className={styles.rightButtons}>
					{hasVoidButton && (
						<Tooltip
							title={
								!hasPosPermission
									? t`Немате дозволу за ову акцију`
									: voidButtonDisabledReason
							}
						>
							<Button
								disabled={!isVoidButtonEnabled || !hasPosPermission}
								danger
								onClick={() => {
									setPaymentModalMode(PaymentModalMode.void);
									setPaymentModalVisible(true);
								}}
							>
								<Trans>Поништи</Trans>
							</Button>
						</Tooltip>
					)}
					{hasRefundButton && (
						<Tooltip
							title={
								!hasPosPermission
									? t`Немате дозволу за ову акцију`
									: refundButtonDisabledReason
							}
						>
							<Button
								disabled={!isRefundButtonEnabled || !hasPosPermission}
								onClick={() => {
									if (isAdvance) {
										setPaymentModalMode(PaymentModalMode.refund);
										setPaymentModalVisible(true);
									} else {
										openReceiptRefund(entity.id);
									}
								}}
							>
								<Trans>Рефундирај</Trans>
							</Button>
						</Tooltip>
					)}
					{hasCopyButton && (
						<Button
							onClick={() => {
								setPaymentModalMode(PaymentModalMode.copy);
								setPaymentModalVisible(true);
							}}
							disabled={!hasPosPermission}
							title={!hasPosPermission ? t`Немате дозволу за ову акцију` : null}
						>
							{screens.xs ? t`Копија` : t`Направи копију`}
						</Button>
					)}
					{hasFinalizeButton && (
						<Tooltip
							title={
								!hasPosPermission
									? t`Немате дозволу за ову акцију`
									: finalizeButtonDisabledReason
							}
						>
							<Button
								disabled={!isFinalizeButtonEnabled || !hasPosPermission}
								onClick={() => {
									setPaymentModalMode(PaymentModalMode.finalizeProforma);
									setPaymentModalVisible(true);
								}}
							>
								<Trans>Издај коначан рачун</Trans>
							</Button>
						</Tooltip>
					)}
					{(isAdvance || isAdvanceOld) && (
						<Tooltip
							title={
								!hasPosPermission
									? t`Немате дозволу за ову акцију`
									: addAdvanceButtonDisabledReason
							}
						>
							<Button
								disabled={!isAddAdvanceButtonEnabled || !hasPosPermission}
								onClick={() => {
									setPaymentModalMode(PaymentModalMode.addAdvancePayment);
									setPaymentModalVisible(true);
								}}
							>
								<Trans>Додај авансну уплату</Trans>
							</Button>
						</Tooltip>
					)}
					{isAdvance && (
						<Tooltip
							title={
								!hasPosPermission
									? t`Немате дозволу за ову акцију`
									: closeAdvanceButtonDisabledReason
							}
						>
							<Button
								disabled={!isCloseAdvanceButtonEnabled || !hasPosPermission}
								onClick={() => {
									setPaymentModalMode(PaymentModalMode.closeAdvance);
									setPaymentModalVisible(true);
								}}
							>
								<Trans>Затвори аванс</Trans>
							</Button>
						</Tooltip>
					)}
					{isAdvanceOld && (
						<Tooltip
							title={
								!hasPosPermission
									? t`Немате дозволу за ову акцију`
									: closeAdvanceButtonDisabledReason
							}
						>
							<Button
								disabled={!isCloseAdvanceButtonEnabled || !hasPosPermission}
								onClick={() => {
									setPaymentModalMode(PaymentModalMode.closeAdvance);
									setPaymentModalVisible(true);
								}}
							>
								<Trans>Затвори аванс</Trans>
							</Button>
						</Tooltip>
					)}
					<Dropdown
						trigger={['click']}
						className={styles.rightButtons}
						menu={{
							items: printMenuItems,
							onClick: (item) => {
								switch (item.key) {
									case 'printThermal':
										printThermal();
										break;
									case 'printA4':
										printA4();
										break;
									case 'email':
										setEmailModalVisible(true);
										break;
									case 'download':
										download();
										break;
								}
							},
						}}
					>
						<Button>
							<Space>
								<Trans>Опције</Trans>
								<i className="fi fi-rr-menu-dots"></i>
							</Space>
						</Button>
					</Dropdown>
				</Space>
			)}
			{!screens.md && !compact && (
				<Dropdown
					trigger={['click']}
					className={styles.rightButtons}
					menu={{
						items: menuItems,
						onClick: (item) => {
							switch (item.key) {
								case 'void':
									setPaymentModalMode(PaymentModalMode.void);
									setPaymentModalVisible(true);
									break;
								case 'refund':
									if (isAdvance) {
										setPaymentModalMode(PaymentModalMode.refund);
										setPaymentModalVisible(true);
									} else {
										openReceiptRefund(entity.id);
									}
									break;
								case 'copy':
									setPaymentModalMode(PaymentModalMode.copy);
									setPaymentModalVisible(true);
									break;
								case 'finalize':
									setPaymentModalMode(PaymentModalMode.finalizeProforma);
									setPaymentModalVisible(true);
									break;
								case 'addAdvancePayment':
									setPaymentModalMode(PaymentModalMode.addAdvancePayment);
									setPaymentModalVisible(true);
									break;
								case 'closeAdvance':
									setPaymentModalMode(PaymentModalMode.closeAdvance);
									setPaymentModalVisible(true);
									break;
								case 'printThermal':
									printThermal();
									break;
								case 'printA4':
									printA4();
									break;
								case 'email':
									setEmailModalVisible(true);
									break;
								case 'download':
									download();
									break;
							}
						},
					}}
				>
					<Button>
						<Space>
							<Trans>Опције</Trans>
							<i className="fi fi-rr-menu-dots"></i>
						</Space>
					</Button>
				</Dropdown>
			)}

			<PaymentModal
				initialMode={paymentModalMode}
				open={paymentModalVisible}
				onClose={onPaymentModalClose}
				initialReceipt={entity}
			/>

			<EmailModal
				visible={emailModalVisible}
				onCancel={() => setEmailModalVisible(false)}
				onOk={(email) => {
					setEmailModalVisible(false);
					sendEmail({ email });
				}}
			/>
		</>
	);
}

export default () => {
	const INVOICE_TYPE_TEXT = getInvoiceTypeText();
	const TRANSACTION_TYPE_TEXT = getTransactionTypeText();

	return CreatePage({
		module: 'receipts',
		submodule: 'list',
		model: 'receipt',
		view: {
			additionalContent: <RefundDrawerWrapper />,

			width: 900,
			shouldFetch: true,
			title: (entity) => t`Преглед рачуна ${entity?.invoiceNumber || ''}`,
			descriptionsProps: {
				layout: 'horizontal',
				column: 1,
				contentStyle: {
					alignSelf: 'right',
				},
			},
			fields: (entity: Receipt) => [
				{
					key: 'info',
					label: (
						<div className={`${styles.centeredTitle} ${styles.title}`}>
							<span>
								{[InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
									INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
								)
									? t`Фискални рачун`
									: t`Ово није фискални рачун`}
							</span>
						</div>
					),
					column: 24,
					descriptionsProps: {
						layout: 'horizontal',
						contentStyle: {
							textAlign: 'right',
							display: 'table-cell',
						},
						labelStyle: {
							fontWeight: 'bold',
						},
					},
					fields: [
						{
							label: t`ПИБ`,
							key: 'secureElement.tin',
							span: 24,
						},
						{
							label: t`Предузеће`,
							key: 'secureElement.businessName',
							span: 24,
						},

						{
							label: t`Место продаје`,
							key: 'secureElement.locationName',
							span: 24,
						},
						{
							label: t`Адреса`,
							key: 'secureElement.address',
							span: 24,
						},
						{
							label: t`Општина`,
							key: 'secureElement.district',
							span: 24,
						},
						{
							label: t`Касир`,
							key: 'cashierName',
							span: 24,
						},
						...(entity.buyerId
							? [
									{
										label: t`ИД купца`,
										key: 'buyerId',
										span: 24,
									},
							  ]
							: []),
						...(entity.buyerCostCenterId
							? [
									{
										label: t`Опционо поље купца`,
										key: 'buyerCostCenterId',
										span: 24,
									},
							  ]
							: []),
						{
							label: t`ПОС број`,
							key: 'posNumber',
							span: 24,
						},
						...(entity.referentDocumentNumber
							? [
									{
										label: t`Реф. број`,
										key: 'referentDocumentNumber',
										span: 24,
									},
							  ]
							: []),
						...(entity.referentDocumentDT?.isValid()
							? [
									{
										label: t`Реф. време`,
										key: 'referentDocumentDT',
										span: 24,
										render(date) {
											return date?.format('DD.MM.YYYY HH:mm:ss');
										},
									},
							  ]
							: []),
						...(entity.posTime?.isValid()
							? [
									{
										label: t`Уплаћено на дан`,
										key: 'posTime',
										span: 24,
										render(date) {
											return date?.format('DD.MM.YYYY HH:mm:ss');
										},
									},
							  ]
							: []),
					],
				},
				{
					key: 'info',
					label: (
						<div className={`${styles.centeredTitle} ${styles.subTitle}`}>
							<span>
								{
									INVOICE_TYPE_TEXT[
										INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
									]
								}{' '}
								-{' '}
								{
									TRANSACTION_TYPE_TEXT[
										TRANSACTION_TYPE_FROM_STRING[entity?.transactionType]
									]
								}
							</span>
						</div>
					),
					column: 24,
					descriptionsProps: {
						layout: 'horizontal',
						contentStyle: {
							textAlign: 'right',
							display: 'table-cell',
						},
						labelStyle: {
							fontWeight: 'bold',
						},
					},
					fields: [
						{
							span: 24,
							label: t`Артикли`,
							render() {
								return '';
							},
						},
						{
							// label: 'Preduzeće',
							key: 'receiptItems',
							span: 24,
							render(items) {
								return <ProductsList record={entity} />;
							},
						},
						{
							span: 24,
							label: t`Порез`,
							render() {
								return '';
							},
						},
						{
							// label: 'Preduzeće',
							key: 'taxItems',
							span: 24,
							render(items) {
								return <TaxList record={entity} />;
							},
						},
					],
				},
				...(![InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
					INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
				)
					? [
							{
								key: 'notFiscal',
								label: (
									<div
										className={`${styles.centeredTitle} ${styles.title} ${styles.large}`}
									>
										<span>
											<Trans>Ово није фискални рачун</Trans>
										</span>
									</div>
								),
								column: 24,
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								fields: [],
							},
					  ]
					: []),
				{
					key: 'invoiceInfo',
					column: 24,
					descriptionsProps: {
						layout: 'horizontal',
						contentStyle: {
							textAlign: 'right',
							display: 'table-cell',
						},
						labelStyle: {
							fontWeight: 'bold',
						},
					},
					fields: [
						{
							label: t`ПФР време`,
							key: 'sdcTime',
							span: 24,
							render(date: any) {
								return date?.format('DD.MM.YYYY HH:mm:ss');
							},
						},
						{
							label: t`ПФР број рачуна`,
							key: 'invoiceNumber',
							span: 24,
						},
						{
							label: t`Бројач рачуна`,
							key: 'invoiceCounter',
							span: 24,
						},
						{
							render() {
								return (
									<div className={`${styles.centeredTitle} ${styles.title}`}>
										&nbsp;
									</div>
								);
							},
							key: 'separator',
							span: 24,
						},
						{
							key: 'verificationUrl',
							span: 24,
							render: function (verificationUrl) {
								return (
									<div className={styles.centeredTitle}>
										<QR verificationUrl={verificationUrl || ''} />
									</div>
								);
							},
						},
					],
				},
				{
					key: 'end',
					label: (
						<div className={`${styles.centeredTitle} ${styles.title}`}>
							<span>
								{[InvoiceType.NORMAL, InvoiceType.ADVANCE].includes(
									INVOICE_TYPE_FROM_STRING[entity?.invoiceType]
								)
									? t`Крај фискалног рачуна`
									: t`Ово није фискални рачун`}
							</span>
						</div>
					),
					column: 24,
					descriptionsProps: {
						layout: 'horizontal',
						contentStyle: {
							textAlign: 'right',
							display: 'table-cell',
						},
						labelStyle: {
							fontWeight: 'bold',
						},
					},
					fields: [],
				},
				...(entity.posTerminalResponse
					? [
							{
								key: 'posTerminalResponse',
								label: (
									<div className={`${styles.centeredTitle} ${styles.subTitle}`}>
										<span>
											<Trans>Детаљи картичне трансакције</Trans>
										</span>
									</div>
								),
								column: 24,
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										// display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								fields: [
									// {
									// 	label: t`Банка`,
									// 	key: ['posTerminalResponse', 'DCCProvider'],
									// 	span: 24,
									// },
									{
										label: t`ИД терминала`,
										key: ['posTerminalResponse', 'TIDNumber'],
										span: 24,
									},
									{
										label: t`Акцептант`,
										key: ['posTerminalResponse', 'MIDNumber'],
										span: 24,
									},
								],
							},
							{
								label: (
									<div className={`${styles.medium}`}>
										{entity?.posTerminalResponse.CompanyName}
									</div>
								),
								column: 24,
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										// display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								fields: [
									...(entity.posTerminalResponse.EMVData['84']
										? [
												{
													label: 'AID',
													key: ['posTerminalResponse', 'EMVData', '84'],
													span: 12,
												},
										  ]
										: []),
									...(entity.posTerminalResponse.EMVData['9F12']
										? [
												{
													label: 'APN',
													key: ['posTerminalResponse', 'EMVData', '9F12'],
													span: 12,
													render(value) {
														return hex2a(value);
													},
												},
										  ]
										: []),
									...(entity.posTerminalResponse.EMVData['95'] &&
									entity.posTerminalResponse.EMVData['95'] !== '0000000000'
										? [
												{
													label: 'TVR',
													key: ['posTerminalResponse', 'EMVData', '95'],
													span: 8,
												},
										  ]
										: []),
									...(entity.posTerminalResponse.EMVData['9F34'] &&
									entity.posTerminalResponse.EMVData['9F34'] !== '000000'
										? [
												{
													label: 'CVMR',
													key: ['posTerminalResponse', 'EMVData', '9F34'],
													span: 8,
												},
										  ]
										: []),
									{
										label: t`Број картице`,
										key: ['posTerminalResponse', 'CardNumber'],
										span: 12,
										render(value) {
											// mask card number, show only last 4 digits, replace the rest with *
											return value.replace(/.(?=.{4})/g, '*');
										},
									},
									{
										label: t`Важи до (ММ/ГГ)`,
										key: ['posTerminalResponse', 'ExpirationDate'],
										span: 12,
										render(value) {
											// replace 9 with * and insert / after 2nd digit
											return value.replace(/9/g, '*').replace(/(..)/, '$1/');
										},
									},
									...(entity.posTerminalResponse.CardDataSource === 'C'
										? [
												{
													key: 'contactless',
													render() {
														return (
															<Contactless className={styles.contactless} />
														);
													},
												},
										  ]
										: []),
								],
							},
							{
								label: (
									<div className={`${styles.medium}`}>
										<Trans>ПРОДАЈА</Trans>
									</div>
								),
								column: 24,
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										// display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								fields: [
									{
										label: t`Број промета`,
										key: ['posTerminalResponse', 'BatchNumber'],
										span: 12,
										render(value) {
											return padStart(value, 6, '0');
										},
									},
									{
										label: t`Број потврде`,
										key: ['posTerminalResponse', 'TransactionNumber'],
										span: 12,
										render(value) {
											return padStart(value, 6, '0');
										},
									},
									{
										label: t`Датум`,
										key: ['posTerminalResponse', 'TransactionDate'],
										span: 12,
										render(value) {
											return dayjs(value, 'DDMMYY').format('DD.MM.YY');
										},
									},
									{
										label: t`Време`,
										key: ['posTerminalResponse', 'TransactionTime'],
										span: 12,
										render(value) {
											return dayjs(value, 'HHmmss').format('HH:mm:ss');
										},
									},
									{
										label: 'RRN',
										key: ['posTerminalResponse', 'RRN'],
										span: 12,
									},
									{
										label: t`(${entity.posTerminalResponse.TransactionStatus}) Број одобрења`,
										key: ['posTerminalResponse', 'AuthorizationCode'],
										span: 12,
										render(value) {
											return padStart(value, 6, '0');
										},
									},
									{
										label: 'RESP',
										key: ['posTerminalResponse', 'FullResponseCode'],
										span: 12,
									},
									{
										key: ['posTerminalResponse', 'DisplayMessage'],
										span: 12,
										render(value) {
											return (
												<span style={{ marginLeft: -4 }}>
													{serbianTransliteration.toCyrillic(value)}
												</span>
											);
										},
									},
								],
							},
							{
								label: '',
								column: 24,
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										// display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								fields: [
									{
										label: (
											<span className={styles.medium}>
												<Trans>ИЗНОС</Trans>
											</span>
										),
										key: ['posTerminalResponse', 'TransactionAmount'],
										span: 24,
										render(value) {
											return (
												<span className={styles.medium}>
													{numberFormat(
														value / 100,
														true,
														2,
														true,
														cc.number(
															`${entity.posTerminalResponse.AmountCurrency}`
														).code
													)}
												</span>
											);
										},
									},
								],
							},
							{
								key: 'message',
								column: 24,
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										// display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								fields: [
									{
										key: 'message',
										span: 24,
										render() {
											return (
												<Trans>
													<div className={styles.centeredTitle}>
														ТЕРЕТИТЕ МОЈ РАЧУН ЗА НАВЕДЕНИ ИЗНОС
														<br />
														ХВАЛА
														<br />
														МОЛИМО САЧУВАЈТЕ РАЧУН
														<br />
														** КОПИЈА ЗА АКЦЕПТАНТА **
													</div>
												</Trans>
											);
										},
									},
									...(entity.posTerminalResponse.EMVData['9F27']
										? [
												{
													label:
														entity.posTerminalResponse.EMVData['9F27'] === '80'
															? 'ARQC'
															: 'TC',
													key: 'arqc',
													span: 24,
													render() {
														return `(${entity.posTerminalResponse.EMVData['9F27']}) ${entity.posTerminalResponse.EMVData['9F26']}`;
													},
												},
										  ]
										: []),
								],
							},
					  ]
					: []),
				...(entity.advanceItems && entity.advanceItems.length
					? [
							{
								key: 'advanceItems',
								descriptionsProps: {
									layout: 'horizontal',
									contentStyle: {
										textAlign: 'right',
										display: 'table-cell',
									},
									labelStyle: {
										fontWeight: 'bold',
									},
								},
								column: 24,
								fields: [
									{
										span: 24,
										label: t`Авансно плаћени артикли`,
										render() {
											return '';
										},
									},
									{
										// label: 'Preduzeće',
										key: 'advanceItems',
										span: 24,
										render(items) {
											return <AdvanceList record={entity} />;
										},
									},
								],
							},
					  ]
					: []),
			],
			footer: (entity) => {
				return <ReceiptButtons entity={entity} />;
			},
		},
		edit: {},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/fiskalni-racuni/izdati-racuni/',
			// additionalButtons: <CustomCreateButton />,
			additionalContent: <RefundDrawerWrapper hideIfReceiptIsOpen />,
			empty: {
				image: 'invoice',
				text: t`Нема издатих рачуна`,
			},
			shouldFetch: true,
			table: {
				props: {
					scroll: { x: 1800 },
				},
				showActions: true,
				actions: [(entity) => <ReceiptButtons entity={entity} compact />],
				initialActionsWidth: 0,
				showDisabledActions: false,
				columns: [
					{
						title: t`Број рачуна`,
						// fixed: 'left',
						dataIndex: 'invoiceNumber',
						key: 'invoiceNumber',
						searchable: true,
						filterKey: 'invoiceNumber',
						shouldLink: true,
						ellipsis: true,
						filterIcon: (filtered) => (
							<i
								className="fi fi-rr-search"
								style={{ color: filtered ? '#7E89C8' : undefined }}
							/>
						),
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<TextFilter
								placeholder={t`Број рачуна`}
								setSelectedKeys={setSelectedKeys}
								selectedKeys={selectedKeys}
								confirm={confirm}
								clearFilters={clearFilters}
							/>
						),
					},
					{
						title: t`Датум и време`,
						width: 160,
						// fixed: 'left',
						dataIndex: 'sdcTime',
						key: 'sdcTime',
						filterKey: 'sdcTime',
						render(date) {
							return date?.format('DD.MM.YYYY HH:mm:ss');
						},
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<DateRangeFilter
								setSelectedKeys={setSelectedKeys}
								selectedKeys={selectedKeys}
								confirm={confirm}
								clearFilters={clearFilters}
							/>
						),
					},
					{
						title: t`Број референтног документа`,
						dataIndex: 'referentDocumentNumber',
						key: 'referentDocumentNumber',
						ellipsis: true,
						render: (text, record) => <ReferentDocumentLink record={record} />,
					},
					{
						title: t`Статус`,
						width: 200,
						key: 'status',
						render: (record) => {
							if (record.void) {
								return <Badge status="error" text={t`Поништен`} />;
							}

							if (record.voids) {
								return <Badge status="warning" text={t`Поништава реф. док.`} />;
							}

							if (
								[
									InvoiceType.NORMAL,
									InvoiceType.PROFORMA,
									InvoiceType.TRAINING,
								].includes(INVOICE_TYPE_FROM_STRING[record.invoiceType])
							) {
								const hasRefunds =
									record.connectedReceipts.some(
										(receipt) =>
											TRANSACTION_TYPE_FROM_STRING[receipt.transactionType] ===
												TransactionType.REFUND && !receipt.void
									) &&
									TRANSACTION_TYPE_FROM_STRING[record.transactionType] ===
										TransactionType.SALE;

								if (hasRefunds) {
									const filteredItems = record.connectedReceipts?.filter(
										(cr) =>
											TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
												TransactionType.REFUND &&
											[
												InvoiceType.NORMAL,
												InvoiceType.PROFORMA,
												InvoiceType.TRAINING,
											].includes(INVOICE_TYPE_FROM_STRING[cr.invoiceType]) &&
											!cr.void &&
											!cr.voids
									);
									// 		console.log(filteredItems);
									const refundedItems = (filteredItems || []).reduce(
										(acc, cur) =>
											acc +
											(cur.receiptItems || []).reduce(
												(iacc, icur) => iacc + icur.quantity,
												0
											),
										0
									);
									const items = (record.receiptItems || []).reduce(
										(iacc, icur) => iacc + icur.quantity,
										0
									);

									const remainingItems = refundedItems - items;

									if (round(remainingItems, 2) === 0) {
										return <Badge color="gold" text={t`Рефундиран`} />;
									} else if (refundedItems > 0) {
										return (
											<Badge color="gold" text={t`Делимично рефундиран`} />
										);
									}
								}
							}

							return <Badge status="success" text={t`Издат`} />;
						},
					},

					{
						title: t`Врста рачуна`,
						width: 130,
						ellipsis: true,
						dataIndex: 'invoiceType',
						key: 'invoiceType',
						filterKey: 'invoiceType',
						filterSearch: (input, record) => {
							return cyr2lat(record.text.toLowerCase()).includes(
								cyr2lat(input.toLowerCase())
							);
						},
						render(text) {
							return INVOICE_TYPE_TEXT[INVOICE_TYPE_FROM_STRING[text]];
						},
						filters: (store) =>
							Object.keys(INVOICE_TYPE_FROM_STRING).map((key) => ({
								text: INVOICE_TYPE_TEXT[INVOICE_TYPE_FROM_STRING[key]],
								value: key,
							})),
						filterMultiple: true,
					},
					{
						title: t`Врста трансакције`,
						ellipsis: true,
						width: 170,
						dataIndex: 'transactionType',
						key: 'transactionType',
						filterKey: 'transactionType',
						filterSearch: (input, record) => {
							return cyr2lat(record.text.toLowerCase()).includes(
								cyr2lat(input.toLowerCase())
							);
						},
						render(text) {
							return TRANSACTION_TYPE_TEXT[TRANSACTION_TYPE_FROM_STRING[text]];
						},
						filters: (store) =>
							Object.keys(TRANSACTION_TYPE_FROM_STRING).map((key) => ({
								text: TRANSACTION_TYPE_TEXT[TRANSACTION_TYPE_FROM_STRING[key]],
								value: key,
							})),
						filterMultiple: true,
					},
					{
						title: t`Износ`,
						width: 140,
						dataIndex: 'totalAmount',
						key: 'totalAmount',
						render(text) {
							return numberFormat(text, true, 2, true);
						},
						filterKey: 'totalAmount',
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<NumberRangeFilter
								setSelectedKeys={setSelectedKeys}
								selectedKeys={selectedKeys}
								confirm={confirm}
								clearFilters={clearFilters}
							/>
						),
					},
					{
						title: t`Касир`,
						dataIndex: ['cashierName'],
						key: 'cashierId',
						ellipsis: true,
						filterKey: 'cashierId',
						filterSearch: (input, record) => {
							return cyr2lat(record.text.toLowerCase()).includes(
								cyr2lat(input.toLowerCase())
							);
						},
						filters: () =>
							stores.users.available?.map((user: User) => ({
								text: user.fullName,
								value: user.id,
							})),
						filterMultiple: true,
					},
					{
						title: t`Продајно место`,
						dataIndex: ['store', 'name'],
						ellipsis: true,
						key: 'storeId',
						filterKey: 'storeId',
						filterSearch: (input, record) => {
							return cyr2lat(record.text.toLowerCase()).includes(
								cyr2lat(input.toLowerCase())
							);
						},
						filters: () =>
							stores.stores.available?.map((storeEntity: Store) => ({
								text: storeEntity.name,
								value: storeEntity.id,
							})),
						filterMultiple: true,
					},
				],
			},
		},
	});
};
