import { t } from '@lingui/macro';
import { Badge } from 'antd';

import { StocksTurnoverCreateButton } from './Components/CreateButton';
import { CustomEmpty } from './Components/CustomEmpty';
import { DeleteButton } from './Components/DeleteButton';
import { EditButton } from './Components/EditButton';
import { CreatePage } from '../../../../components/Page';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import {
	StockTurnoverStatus,
	StockTurnoverType,
} from '../../../../stores/StockTurnover.mobx';
import { CheckInitial } from '../Components/CheckInitial';

export default () =>
	CreatePage({
		module: 'stocks',
		submodule: 'turnover',
		model: 'stockTurnover',
		view: {
			useEdit: true,
		},
		edit: {
			width: 1200,
			title: {
				new: t`Додавање промета залиха`,
				existing: (entity) => t`Измена промета залиха ${entity?.name || ''}`,
			},
			shouldFetch: true,
			fields: (record, form, setFields) => {
				return [];
			},
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/katalog/artikli/', //TODO documentation
			empty: {
				customEmptyComponent: <CustomEmpty />,
			},
			shouldFetch: true,
			searchable: false,
			table: {
				props: {
					scroll: { x: 1350 },
				},
				showDisabledActions: false,
				actions: [
					(record) => {
						return <EditButton record={record} />;
					},
					(record) => {
						return <DeleteButton record={record} />;
					},
				],
				columns: () => {
					return [
						{
							title: t`Број`,
							dataIndex: 'number',
							key: 'number',
						},
						{
							title: t`Врста промета`,
							dataIndex: 'type',
							key: 'type',
							render: (type) => {
								if (type === StockTurnoverType.INITIAL) {
									return t`Почетно стање`;
								}
								if (type === StockTurnoverType.CALCULATION) {
									return t`Калкулација`;
								}
								if (type === StockTurnoverType.WRITE_OFF) {
									return t`Отпис`;
								}
								if (type === StockTurnoverType.CONVERSION) {
									return t`Конверзија`;
								}
								if (type === StockTurnoverType.RECEIPT) {
									return t`Фисклани рачун`;
								}
								if (type === StockTurnoverType.PRICE_CHANGE) {
									return t`Нивелација`;
								}
							},
							ellipsis: true,
							width: 130,
						},
						{
							title: t`Статус`,
							dataIndex: 'status',
							key: 'status',
							render: (status) => {
								if (status === StockTurnoverStatus.DRAFT) {
									return <Badge color="lightGrey" text={t`Нацрт`} />;
								}

								if (status === StockTurnoverStatus.CONFIRMED) {
									return <Badge color="green" text={t`Потврђен`} />;
								}
							},
							width: 100,
						},
						{
							title: t`Датум`,
							dataIndex: 'date',
							key: 'date',
							render: (date) => {
								return date.format('L');
							},
							width: 120,
						},
						{
							title: t`Набавна вредност`,
							dataIndex: 'purchaseAmount',
							key: 'purchaseAmount',
							render: (value) => {
								return numberFormat(value, false, 2, true);
							},
							width: 150,
							align: 'right',
						},

						{
							title: t`Продајна вредност`,
							dataIndex: 'saleAmount',
							key: 'saleAmount',
							render: (value) => {
								return numberFormat(value, false, 2, true);
							},
							width: 150,
							align: 'right',
						},
						{
							title: t`Партнер`,
							dataIndex: ['partner', 'name'],
							key: 'partnerId',
							render: (warehouse) => {
								return warehouse?.name;
							},
							ellipsis: true,
							width: 300,
						},
					];
				},
			},
			createButtonText: (() => {
				if (stores.warehouses.available.length === 0) {
					return t`Додај складиште`;
				}

				if (!stores.initialStocks?.hasInitial) {
					return t`Додај почетно стање`;
				}

				return t`Додај запис`;
			})(),
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај промет?`,
			customCreateButton: (createHandler) => (
				<StocksTurnoverCreateButton createButtonHandler={createHandler} />
			),
			additionalContent: <CheckInitial />,
		},
	});
