import { Plural, t, Trans } from '@lingui/macro';
import { useLatest } from 'ahooks';
import {
	Button,
	ConfigProvider,
	Empty,
	Space,
	Alert,
	Typography,
	Row,
	Col,
	Grid,
	List,
	Flex,
	Dropdown,
	Menu,
} from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import { useCallback, useContext, useMemo, useRef, useState } from 'react';
import ShowMoreText from 'react-show-more-text';
import SplitPane, { Pane } from 'react-split-pane';
import { useKey, useMeasure, useWindowSize, useAudio } from 'react-use';

import CategoryPicker from './Components/CategoryPicker';
import EnterQuantityModal from './Components/EnterQuantityModal';
import VariantPicker from './Components/VariantPicker';
import EditModal from './EditModal';
import EnterCustomerModal from './EnterCustomerModal';
import styles from './POS.module.less';
import ProductNotFound from './ProductNotFound';
import SkuEntryModal from './SkuEntryModal';
import { useDrawer } from '../../../components/Page';
import PaymentModal from '../../../components/PaymentModal/PaymentModal';
import { getApplicationName } from '../../../constants/application';
import { PaymentType } from '../../../constants/invoice';
import { PAYMENT_TYPE_MAP } from '../../../constants/journal';
import { DrawerContext } from '../../../context/DrawerContext';
import { useBarcodeScanner } from '../../../hooks/useBarcodeScanner';
import { PaymentModalMode } from '../../../lib/receipt';
import stores from '../../../stores/index.mobx';
import { SaleChannelSelect } from '../../../components/SaleChannelSelect';
import { MojBadiInfo } from './Components/MojBadiInfo';
import UseStickersModal from './Components/UseStickersModal';
import UsePointsModal from './Components/UsePointsModal';
import { ListItem } from './Components/ListItem';

const SOUNDS = {
	ERROR: '/sounds/error.mp3',
	BEEP: '/sounds/beep.mp3',
	DELETE: '/sounds/trash.mp3',
	OK: '/sounds/ok.mp3',
};

type Props = {
	height: number;
	sale: any;
	active: boolean;
	loading: boolean;
};

function Sale({ height, sale, loading, active }: Props) {
	const APPLICATION_NAME = getApplicationName();
	const [warningRef, { height: warningHeight }] = useMeasure();
	const [mojBadiInfoRef, { height: mojBadiInfoHeight }] = useMeasure();
	const [totalRef, { width: totalWidth }] = useMeasure();
	const { width } = useWindowSize();
	const {
		company: { availablePaymentMethods },
		products: { byEan },
		application: {
			posSettings: { paneWidth },
			setPaneWidth,
		},
		localSales: { removeSale },
		exchangeRates: { lastDate },
		devices: { scales },
		sdc: { currentESDCError },
		stores: { currentStore, currentStoreId },
		saleChannels: { available: saleChannels },
		mojBadi,
	} = stores;

	console.log(sale.availableOffers);

	const mojBadiEnabled = mojBadi?.settings?.enabled;
	const loyaltyType = mojBadi?.settings?.loyaltyType;

	const paymentMethods = useMemo(() => {
		if (!currentStoreId || currentStore?.isResolving || !currentStore) {
			return [];
		}

		return (
			availablePaymentMethods === 'all'
				? currentStore.paymentMethodsOrder
				: currentStore.paymentMethodsOrder.filter((pm) => {
						return [
							PaymentType.CASH,
							PaymentType.VOUCHER,
							PaymentType.WIRE_TRANSFER,
							PaymentType.OTHER,
						].includes(pm);
				  })
		).filter((pm) => pm !== -1);
	}, [currentStore, currentStoreId, availablePaymentMethods]);

	const [audioBeep, , { play: playBeepOriginal, seek: seekBeep }] = useAudio({
		src: SOUNDS.BEEP,
		autoPlay: false,
	});
	const [audioError, , { play: playErrorOriginal, seek: seekError }] = useAudio(
		{
			src: SOUNDS.ERROR,
			autoPlay: false,
		}
	);
	const [audioDelete, , { play: playDeleteOriginal, seek: seekDelete }] =
		useAudio({
			src: SOUNDS.DELETE,
			autoPlay: false,
		});
	const [audioOk, , { play: playOkOriginal, seek: seekOk }] = useAudio({
		src: SOUNDS.OK,
		autoPlay: false,
	});

	const playBeep = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekBeep(0);
		playBeepOriginal();
	}, [currentStore?.isSoundEnabled, seekBeep, playBeepOriginal]);

	const playError = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekError(0);
		playErrorOriginal();
	}, [currentStore?.isSoundEnabled, seekError, playErrorOriginal]);

	const playDelete = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekDelete(0);
		playDeleteOriginal();
	}, [currentStore?.isSoundEnabled, seekDelete, playDeleteOriginal]);

	const playOk = useCallback(() => {
		if (!currentStore?.isSoundEnabled) {
			return;
		}
		seekOk(0);
		playOkOriginal();
	}, [currentStore?.isSoundEnabled, seekOk, playOkOriginal]);

	const [enterCustomerModalVisible, setEnterCustomerModalVisible] =
		useState(false);

	const [editModalVisible, setEditModalVisible] = useState(false);
	const [editSaleItem, setEditSaleItem] = useState(null);

	const [variantPickerVisible, setVariantPickerVisible] = useState(false);
	const [variantPickerProduct, setVariantPickerProduct] = useState(null);

	const [notFoundModalVisible, setNotFoundModalVisible] = useState(false);
	const [notFoundModalText, setNotFoundModalText] = useState(null);

	const [enterQuantityModalVisible, setEnterQuantityModalVisible] =
		useState(false);
	const [useStickersModalVisible, setUseStickersModalVisible] = useState(false);
	const [usePointsModalVisible, setUsePointsModalVisible] = useState(false);
	const [enterQuantitySaleItem, setEnterQuantitySaleItem] = useState(null);

	const [skuPopoverVisible, setSkuPopoverVisible] = useState(false);
	const [skuPopoverValue, setSkuPopoverValue] = useState('');

	const [paymentModalVisible, setPaymentModalVisible] = useState(false);
	const [prefillPayment, setPrefillPayment] = useState({});

	const suppressBarcode = useRef(false);

	const scrollIntoView = useCallback(() => {
		setTimeout(() => {
			document
				.querySelector('.ant-tabs-tabpane-active .selectedProduct')
				?.scrollIntoView?.({
					behavior: 'smooth',
					block: 'nearest',
				});
		});
	}, []);

	const menu = useMemo(() => {
		return (
			<Menu
				onClick={(item) => {
					// TODO
				}}
				items={[
					{
						label: <Trans>Издавање аванса без познатих артикала</Trans>,
						key: '1',
						disabled: false,
					},
				]}
			/>
		);
	}, []);

	const addByEan = useCallback(
		(ean, quantity = 1) => {
			const product = byEan[ean];
			if (
				!product ||
				(sale.currentSaleChannelId &&
					product?.saleChannelIds?.includes(sale.currentSaleChannelId))
			) {
				setNotFoundModalText(
					<Trans>
						Артикал са бар кодом <strong>{ean}</strong> није пронађен. <br />
						Покушајте да употребите други метод уноса
					</Trans>
				);
				playError();
				return setNotFoundModalVisible(true);
			}

			sale.addItem(product, quantity);
			scrollIntoView();

			playBeep();
		},
		[byEan, sale, scrollIntoView, playBeep, playError]
	);

	const getScaleWeight = useCallback(
		async (product) => {
			if (scales.length > 0 && product.quantityFromScale) {
				try {
					return window.electron.scale.getWeight();
				} catch (e) {
					return null;
				}
			}

			return 1;
		},
		[scales]
	);

	const selectProduct = useCallback(
		async (product) => {
			if (product.variants.length) {
				setVariantPickerProduct(product);
				setVariantPickerVisible(true);
			} else {
				sale.addItem(product, await getScaleWeight(product));
				playBeep();
			}
			scrollIntoView();
		},
		[getScaleWeight, playBeep, sale, scrollIntoView]
	);

	const [, openSearchDrawer, , isSearchVisible, , ProductSearchDrawer] =
		useDrawer('product-search', async (event, product: any) => {
			if (active) {
				const p = product.variant ? product.variant : product.product;
				if (scales.length > 0 && p.quantityFromScale) {
					const weight = await getScaleWeight(p);
					sale.addItem(p, weight);
					playBeep();
				} else {
					setTimeout(() => {
						setEnterQuantitySaleItem(p);
						setEnterQuantityModalVisible(true);
					}, 100);
				}
			}
		});
	const { isDrawerOpen } = useContext(DrawerContext);

	const handleCloseEnterQuantityModal = useCallback(() => {
		setEnterQuantityModalVisible(false);
	}, []);
	const handleCloseUseStickersModal = useCallback(() => {
		setUseStickersModalVisible(false);
	}, []);
	const handleCloseUsePointsModal = useCallback(() => {
		setUsePointsModalVisible(false);
	}, []);

	const handleSubmitEnterQuantityModal = useCallback(
		(item, quantity: number) => {
			sale.addItem(item, quantity);
			scrollIntoView();

			playBeep();
		},
		[playBeep, sale, scrollIntoView]
	);

	const handleEnterLoyaltyUsageQuantity = useCallback(
		(type: 'stickers' | 'points', quantity: number) => {
			sale.customer?.createLoyaltyUsageIntent?.(type, quantity);
		},
		[sale?.customer]
	);

	const shouldReactToKeyWithoutSkuEntry = useMemo(
		() =>
			active &&
			!enterCustomerModalVisible &&
			!isDrawerOpen &&
			!variantPickerVisible &&
			!notFoundModalVisible &&
			!enterQuantityModalVisible &&
			!useStickersModalVisible &&
			!usePointsModalVisible &&
			!editModalVisible &&
			!paymentModalVisible &&
			!isSearchVisible &&
			!currentESDCError,
		[
			active,
			enterCustomerModalVisible,
			isDrawerOpen,
			variantPickerVisible,
			notFoundModalVisible,
			enterQuantityModalVisible,
			useStickersModalVisible,
			usePointsModalVisible,
			editModalVisible,
			paymentModalVisible,
			isSearchVisible,
			currentESDCError,
		]
	);
	const shouldReactToKey = useMemo(
		() => shouldReactToKeyWithoutSkuEntry && !skuPopoverVisible,
		[shouldReactToKeyWithoutSkuEntry, skuPopoverVisible]
	);

	const latestShouldReactToKey = useLatest(shouldReactToKey);

	useBarcodeScanner((ean) => {
		if (latestShouldReactToKey.current && !suppressBarcode.current) {
			// TODO: handle 042 barcode (employee card)
			if (ean.startsWith('042')) {
			} else if (ean.startsWith('041')) {
				// Moj Badi
			} else {
				addByEan(ean);
				scrollIntoView();
			}
		}
	});

	const characters = useRef('');
	const charactersTimeout = useRef(null);

	useKey(
		(e) => {
			if (
				e.key === '.' ||
				e.key === ',' ||
				e.key === '*' ||
				e.key === 'x' ||
				isFinite(parseInt(e.key, 10))
			) {
				return true;
			}

			return false;
		},
		(e) => {
			if (!shouldReactToKeyWithoutSkuEntry) {
				return;
			}
			characters.current += e.key;
			setSkuPopoverValue(characters.current);

			if (!shouldReactToKey) {
				return;
			}

			clearTimeout(charactersTimeout.current);

			charactersTimeout.current = setTimeout(() => {
				if (characters.current.length >= 8) {
					setSkuPopoverVisible(false);
					setSkuPopoverValue('');
					addByEan(characters.current);
					characters.current = '';
				} else {
					setSkuPopoverVisible(true);
				}
			}, 100);
		},
		{
			event: 'keydown',
		},
		[shouldReactToKey, shouldReactToKeyWithoutSkuEntry, addByEan]
	);

	useKey(
		'ArrowUp',
		(e) => {
			if (!shouldReactToKey) {
				return;
			}
			sale.selectPreviousItem();
			scrollIntoView();
			e.preventDefault();
		},
		undefined,
		[shouldReactToKey]
	);

	useKey(
		'ArrowDown',
		(e) => {
			if (!shouldReactToKey) {
				return;
			}
			sale.selectNextItem();
			scrollIntoView();
			e.preventDefault();
		},
		undefined,
		[shouldReactToKey]
	);

	useKey(
		(e) => {
			return (
				e.key === 'f3' ||
				((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'f')
			);
		},
		(e) => {
			if (!shouldReactToKey) {
				return;
			}
			openSearchDrawer(undefined, undefined, {
				currentSaleChannelId: sale.currentSaleChannelId,
			});
			e.preventDefault();
		},
		undefined,
		[shouldReactToKey]
	);

	useKey(
		'Backspace',
		(e) => {
			if (!shouldReactToKey) {
				return;
			}
			if (sale.itemsAsArray.length === 0) {
				return;
			}

			sale.removeItem(sale.activeProductId);
			playDelete();
			e.preventDefault();
			scrollIntoView();
			return false;
		},
		undefined,
		[shouldReactToKey, sale.activeProductId]
	);

	useKey(
		'Delete',
		() => {
			if (!shouldReactToKey) {
				return;
			}
			playDelete();
			sale.removeItem(sale.activeProductId);
			scrollIntoView();
		},
		undefined,
		[shouldReactToKey, sale.activeProductId]
	);

	useKey(
		'+',
		() => {
			if (!shouldReactToKey || characters.current.length > 0) {
				return;
			}
			setEditSaleItem(sale.activeSaleItem);
			setEditModalVisible(true);
		},
		undefined,
		[shouldReactToKey, sale.activeProductId]
	);
	useKey(
		'F10',
		(event) => {
			if (!shouldReactToKey) {
				return;
			}

			setPrefillPayment({
				[PAYMENT_TYPE_MAP[paymentMethods[0]]]: sale.total,
			});
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F16',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment({
				[PAYMENT_TYPE_MAP[paymentMethods[0]]]: sale.total,
			});
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F11',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment({
				[PAYMENT_TYPE_MAP[paymentMethods[1]]]: sale.total,
			});
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F17',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment({
				[PAYMENT_TYPE_MAP[paymentMethods[1]]]: sale.total,
			});
			setPaymentModalVisible(true);

			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F12',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment({
				[PAYMENT_TYPE_MAP[paymentMethods[2]]]: sale.total,
			});
			setPaymentModalVisible(true);
			event.preventDefault();
			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);
	useKey(
		'F18',
		(event) => {
			if (!shouldReactToKey || !sale.total) {
				return;
			}

			setPrefillPayment({
				[PAYMENT_TYPE_MAP[paymentMethods[2]]]: sale.total,
			});
			setPaymentModalVisible(true);
			event.preventDefault();

			return false;
		},
		undefined,
		[shouldReactToKey, sale.total]
	);

	const screens = Grid.useBreakpoint();

	const titleBarHeight =
		window.electron &&
		(!window.electron.platform || window.electron.platform === 'darwin') &&
		(screens.sm || screens.xs) &&
		!screens.lg
			? 28
			: 0;

	return (
		<div
			style={{
				height:
					height -
					95 +
					(!screens.md ? 38 : 0) -
					(!screens.sm ? 47 : 0) -
					titleBarHeight,
			}}
		>
			{audioBeep}
			{audioDelete}
			{audioError}
			<div className={styles.wrap}>
				<Row className={styles.subTotal} gutter={[8, 8]} ref={totalRef}>
					{!screens.xs && (
						<Col flex="none" className={styles.subtotalText}>
							<Trans>
								Износ
								<br />
								рачуна
							</Trans>
						</Col>
					)}
					<Col flex="none">
						<h2>
							<strong>{numeral(sale.total).format('0,0.00')}</strong>
						</h2>
					</Col>
					<Col
						flex="auto"
						className={styles.buttons}
						xs={{ span: 24, offset: 0 }}
					>
						<Flex justify="flex-end">
							<Space>
								{saleChannels.length > 0 && (
									<>
										{screens.sm && (
											<div>
												<Trans>Канал продаје</Trans>
											</div>
										)}
										<SaleChannelSelect
											value={sale.currentSaleChannelId}
											onChange={(value) => {
												sale.setCurrentSaleChannelId(value);
											}}
										/>
									</>
								)}
								{mojBadiEnabled && !sale.customer && (
									<Button
										loading={sale.isFetchingCustomer}
										disabled={sale.isFetchingCustomer}
										onClick={() => {
											setEnterCustomerModalVisible(true);
										}}
									>
										<Trans>Купац</Trans>
									</Button>
								)}
								{mojBadiEnabled && sale.customer && (
									<Button
										loading={sale.isFetchingCustomer}
										disabled={sale.isFetchingCustomer}
										onClick={() => {
											sale.removeCustomer();
										}}
									>
										<Trans>Уклони купца</Trans>
									</Button>
								)}
								<Button
									onClick={() => {
										setPrefillPayment({});
										setPaymentModalVisible(true);
									}}
									type="primary"
									disabled={sale.itemsAsArray.length === 0}
								>
									<Trans>Плаћање</Trans>
								</Button>
								{/* <Dropdown overlay={menu} trigger={['click']}>
									<Button
										icon={<i className="fi fi-rr-menu-dots"></i>}
									></Button>
								</Dropdown> */}
							</Space>
						</Flex>
					</Col>
				</Row>
			</div>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/barcode.svg" alt="" />}
						description={
							<Trans>
								Нема артикала на рачуну. <br />
								<br />
								Користите бар код читач,{' '}
								<Typography.Link
									onClick={() =>
										openSearchDrawer(undefined, undefined, {
											currentSaleChannelId: sale.currentSaleChannelId,
										})
									}
								>
									претрагу
								</Typography.Link>{' '}
								или{' '}
								<Typography.Link
									onClick={() => {
										setSkuPopoverValue('');
										setSkuPopoverVisible(true);
									}}
								>
									унесите шифру
								</Typography.Link>{' '}
								како бисте додали артикле на рачун.
							</Trans>
						}
					/>
				)}
			>
				{screens.sm && (
					<SplitPane
						split="vertical"
						defaultSize={paneWidth < 230 ? 230 : paneWidth}
						minSize={230}
						style={{ height: 'auto' }}
						onChange={setPaneWidth}
					>
						<Pane>
							<CategoryPicker
								currentSaleChannelId={sale.currentSaleChannelId}
								openSearchDrawer={openSearchDrawer}
								height={height - 139 + (!screens.md ? 38 : 0) - titleBarHeight}
								width={paneWidth < 230 ? 230 : paneWidth}
								selectProduct={selectProduct}
							/>
						</Pane>
						<div
							style={{
								width: totalWidth + 24 - (paneWidth < 230 ? 230 : paneWidth),
								height: height - 139 + (!screens.md ? 38 : 0) - titleBarHeight,
							}}
						>
							{sale.hasForeignCurrency &&
								!lastDate.isSame(dayjs().startOf('day')) && (
									<div ref={warningRef}>
										<Alert
											className={styles.rateWarning}
											type="warning"
											message={
												<ShowMoreText
													lines={1}
													more={t`Више`}
													less={t`Мање`}
													expanded={false}
													width={
														totalWidth +
														32 -
														(paneWidth < 230 ? 230 : paneWidth)
													}
												>
													<Trans>
														Постоје артикли чија је цена у страној валути, али
														курсна листа за данашњи дан није доступна. Народна
														банка Србије курсну листу објављује радним данима у
														8:00, а {APPLICATION_NAME} курсну листу преузима
														најкасније 5 минута од њеног објављивања. Примењена
														је курсна листа дана {lastDate.format('LL')}
													</Trans>
												</ShowMoreText>
											}
										/>
									</div>
								)}
							<MojBadiInfo
								customer={sale.customer}
								ref={mojBadiInfoRef}
								setUseStickersModalVisible={setUseStickersModalVisible}
								setUsePointsModalVisible={setUsePointsModalVisible}
								sale={sale}
							/>
							<div
								className={styles.scrollableList}
								style={{
									height:
										height -
										139 +
										(!screens.md ? 36 : 0) -
										(sale.hasForeignCurrency ? warningHeight || 0 : 0) -
										(mojBadiInfoHeight || 0) -
										titleBarHeight,
								}}
							>
								<List
									className={styles.list}
									size="small"
									dataSource={
										loading
											? []
											: [...sale.itemsAsArray, ...sale.availableOffers]
									}
									rowKey="id"
									renderItem={(item) => (
										<ListItem
											item={item}
											sale={sale}
											setEditSaleItem={setEditSaleItem}
											setEditModalVisible={setEditModalVisible}
											playDelete={playDelete}
										/>
									)}
								/>
							</div>
						</div>
					</SplitPane>
				)}
				{!screens.sm && (
					<div
						className={styles.horizontalWrapper}
						style={{
							height:
								height -
								140 -
								titleBarHeight -
								(screens.xs && !screens.sm ? 40 : 0),
						}}
					>
						<SplitPane
							split="horizontal"
							defaultSize={paneWidth}
							minSize={260}
							onChange={setPaneWidth}
							primary="second"
						>
							<div style={{ width }}>
								{sale.hasForeignCurrency &&
									!lastDate.isSame(dayjs().startOf('day')) && (
										<div ref={warningRef}>
											<Alert
												className={styles.rateWarning}
												type="warning"
												message={
													<ShowMoreText
														lines={1}
														more={t`Више`}
														less={t`Мање`}
														expanded={false}
														width={totalWidth + 18}
													>
														<Trans>
															Постоје артикли чија је цена у страној валути, али
															курсна листа за данашњи дан није доступна. Народна
															банка Србије курсну листу објављује радним данима
															у 8:00, а {APPLICATION_NAME} курсну листу преузима
															најкасније 5 минута од њеног објављивања.
															Примењена је курсна листа дана{' '}
															{lastDate.format('LL')}
														</Trans>
													</ShowMoreText>
												}
											/>
										</div>
									)}
								<MojBadiInfo
									customer={sale.customer}
									ref={mojBadiInfoRef}
									setUseStickersModalVisible={setUseStickersModalVisible}
									setUsePointsModalVisible={setUsePointsModalVisible}
									sale={sale}
								/>

								<div
									className={styles.scrollableList}
									style={{
										height:
											height -
											140 -
											paneWidth -
											(warningHeight || 0) -
											(mojBadiInfoHeight || 0) -
											titleBarHeight,
									}}
								>
									<List
										className={styles.list}
										size="small"
										dataSource={
											loading
												? []
												: [...sale.itemsAsArray, ...sale.availableOffers]
										}
										rowKey="id"
										renderItem={(item) => (
											<ListItem
												item={item}
												sale={sale}
												setEditSaleItem={setEditSaleItem}
												setEditModalVisible={setEditModalVisible}
												playDelete={playDelete}
											/>
										)}
									/>
								</div>
							</div>
							<Pane>
								<CategoryPicker
									currentSaleChannelId={sale.currentSaleChannelId}
									openSearchDrawer={openSearchDrawer}
									height={paneWidth}
									width={width}
									selectProduct={selectProduct}
								/>
							</Pane>
						</SplitPane>
					</div>
				)}
			</ConfigProvider>
			{paymentModalVisible && (
				<PaymentModal
					open={paymentModalVisible}
					initialMode={PaymentModalMode.normal}
					sale={sale}
					onClose={(remove) => {
						setPaymentModalVisible(false);
						sale.setDate(null);
						sale.setTaxFree(false);
						if (remove) {
							removeSale(sale, true);
						}
					}}
					payments={prefillPayment}
				/>
			)}
			<ProductNotFound
				visible={notFoundModalVisible}
				text={notFoundModalText}
				onCancel={() => {
					setNotFoundModalVisible(false);
				}}
				onOk={() => {
					setNotFoundModalVisible(false);
				}}
			/>
			<SkuEntryModal
				currentSaleChannelId={sale.currentSaleChannelId}
				visible={skuPopoverVisible}
				onCancel={() => {
					suppressBarcode.current = true;
					setSkuPopoverVisible(false);
					setTimeout(() => {
						suppressBarcode.current = false;
					});
					characters.current = '';
				}}
				value={skuPopoverValue}
				onConfirm={function (sku: number, multiplier: number): void {
					sale.addItem(sku, multiplier);
					playBeep();
					scrollIntoView();

					setTimeout(() => {
						suppressBarcode.current = false;
					});

					characters.current = '';
					setSkuPopoverVisible(false);
				}}
				addByEan={addByEan}
			></SkuEntryModal>
			<EditModal
				visible={editModalVisible}
				saleItem={editSaleItem}
				sale={sale}
				closeEdit={() => {
					setEditModalVisible(false);

					setEditSaleItem(null);
				}}
			></EditModal>
			<EnterCustomerModal
				open={enterCustomerModalVisible}
				localSale={sale}
				close={() => {
					setEnterCustomerModalVisible(false);
				}}
			></EnterCustomerModal>
			<EnterQuantityModal
				item={enterQuantitySaleItem}
				visible={enterQuantityModalVisible && !isSearchVisible}
				onClose={handleCloseEnterQuantityModal}
				onSubmit={handleSubmitEnterQuantityModal}
			/>
			{loyaltyType === 'stickers' && (
				<UseStickersModal
					visible={useStickersModalVisible}
					onClose={handleCloseUseStickersModal}
					onSubmit={handleEnterLoyaltyUsageQuantity}
					customer={sale.customer}
				/>
			)}
			{loyaltyType === 'points' && (
				<UsePointsModal
					visible={usePointsModalVisible}
					onClose={handleCloseUsePointsModal}
					onSubmit={handleEnterLoyaltyUsageQuantity}
					customer={sale.customer}
				/>
			)}
			<VariantPicker
				currentSaleChannelId={sale.currentSaleChannelId}
				visible={variantPickerVisible}
				product={variantPickerProduct}
				onCancel={() => {
					setVariantPickerVisible(false);
				}}
				onSelect={async (variant) => {
					setVariantPickerVisible(false);
					sale.addItem(variant, await getScaleWeight(variant));
					scrollIntoView();

					playBeep();
				}}
			/>
			{active && <ProductSearchDrawer />}
		</div>
	);
}

export default observer(Sale);
