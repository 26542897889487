/* eslint-disable react-hooks/rules-of-hooks */
import { t, Trans } from '@lingui/macro';
import {
	Badge,
	Button,
	Checkbox,
	Col,
	DatePicker,
	Dropdown,
	Form,
	Grid,
	Input,
	Menu,
	Modal,
	Row,
	Select,
	Space,
	Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import { sortBy } from 'lodash';
import round from 'lodash/round';
import uniq from 'lodash/uniq';
import { observer } from 'mobx-react-lite';
import {
	forwardRef,
	useCallback,
	useImperativeHandle,
	useMemo,
	useState,
} from 'react';
import { Fragment } from 'react';

import AdvanceInvoicesInput from './Components/AdvanceInvoicesInput';
import CancelModal from './Components/CancelModal';
import CreditDebitNoteReferencesInput from './Components/CreditDebitNoteReferencesInput';
import InvoiceItemsInput from './Components/InvoiceItemsInput';
import PartnerLocationSelect from './Components/PartnerLocationSelect';
import PayButton from './Components/PayButton';
import ReferenceInput from './Components/ReferenceInput';
import ReferenceView from './Components/ReferenceView';
import { TaxExemptionInput } from './Components/TaxExemptionInput';
import AttachmentsEdit from '../../../../components/AttachmentsEdit';
import CurrencySelect from '../../../../components/CurrencySelect';
import InvoiceItemsView from '../../../../components/InvoiceCommon/InvoiceItemsView';
import PartnerView from '../../../../components/InvoiceCommon/PartnerView';
import InvoiceSummaryView from '../../../../components/InvoiceSummaryView';
import { CreatePage, useDrawer } from '../../../../components/Page';
import pageStyles from '../../../../components/Page/Page.module.less';
import PaymentMeansSelect from '../../../../components/PaymentMeansSelect';
import PartnerSelect from '../../../../components/Selects/PartnerSelect';
import { StaticComponents } from '../../../../components/StaticComponents';
import config from '../../../../config.json';
import {
	DOCUMENT_TAX_INVOICE_PERIOD,
	DOCUMENT_TYPE,
	getDocumentCreditDebitNoteTypeMap,
	getDocumentTaxInvoicePeriodMap,
	getDocumentTypeMap,
	getEfakturaErrorCode,
	getInvoiceStatusMap,
	INVOICE_STATUS,
} from '../../../../constants/documentInvoice';
import {
	ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR,
	ERROR_BAD_REQUEST_PARTNER_MISSING_CODE,
	ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL,
} from '../../../../constants/errors';
import { usePermissions } from '../../../../hooks/permissions';
import { defaultLocale } from '../../../../lib/i18n';
import numberFormat from '../../../../lib/numberFormat';
import { v2Client } from '../../../../store/client';
import stores from '../../../../stores/index.mobx';
import {
	AdvancePayment,
	Invoice,
	TaxBreakdown,
} from '../../../../stores/Invoice.mobx';
import { Partner } from '../../../../stores/Partner.mobx';

interface Props {
	record: Invoice;
	close: () => void;
}

const InvoiceButtons = observer(({ record, close }: Props) => {
	const EFAKTURA_ERROR_CODE = getEfakturaErrorCode();

	const [emailModalVisible, setEmailModalVisible] = useState(false);
	const [emailModalMode, setEmailModalMode] = useState(null);
	const [cancelModalVisible, setCancelModalVisible] = useState(false);
	const [cancelModalData, setCancelModalData] = useState(null);
	const [overrideSending, setOverrideSending] = useState(false);
	const [form] = Form.useForm();

	const emailModal = useMemo(
		() => (
			<Modal
				title={t`Унесите адресу електронске поште`}
				open={emailModalVisible}
				confirmLoading={record?.isSending}
				onOk={form.submit}
				onCancel={() => {
					setEmailModalVisible(false);
				}}
				destroyOnClose
			>
				<Form
					preserve={false}
					form={form}
					layout="vertical"
					onFinish={async (values) => {
						if (emailModalMode === 'send') {
							await record.send(values.email, values.storeEmail);
							StaticComponents.notification.success({
								message: t`Документ је успешно послат`,
							});
						} else if (emailModalMode === 'cancel') {
							await record.cancel({
								...cancelModalData,
								email: values.email,
								storeEmail: values.storeEmail,
							});
							StaticComponents.notification.success({
								message: t`Документ је успешно сторниран`,
							});
						}
						setEmailModalVisible(false);

						close();
					}}
				>
					<p>
						<Trans>
							За купца <strong>{record?.partner?.name}</strong>, не постоји
							сачувана адреса електронске поште. Молимо вас да унесете адресу
							електронске поште на коју желите да пошаљете документ.
						</Trans>
					</p>
					<Form.Item
						name="email"
						label={t`Адреса електронске поште`}
						rules={[
							{
								required: true,
								message: t`Адреса електронске поште је обавезна`,
							},
							{
								type: 'email',
								message: t`Адреса електронске поште није валидна`,
							},
						]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						initialValue={true}
						valuePropName="checked"
						name="storeEmail"
					>
						<Checkbox>
							<Trans>Сачувај адресу електронске поште за купца</Trans>
						</Checkbox>
					</Form.Item>
				</Form>
			</Modal>
		),
		[cancelModalData, close, emailModalMode, emailModalVisible, form, record]
	);

	const send = useCallback(async () => {
		try {
			setOverrideSending(true);

			await record.send();
			StaticComponents.notification.success({
				message: t`Документ је успешно послат`,
			});
			close();
		} catch (e) {
			if (
				e?.response?.data?.errorCode === ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL
			) {
				setEmailModalMode('send');
				setEmailModalVisible(true);
			}
			if (
				e?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR
			) {
				StaticComponents.notification.error({
					message: t`Грешка приликом слања е-фактуре`,
					description:
						EFAKTURA_ERROR_CODE[e?.response?.data?.variables?.ErrorCode] ||
						e?.response?.data?.variables?.Message ||
						t`Непозната грешка`,
					duration: 0,
				});
			}
		} finally {
			setOverrideSending(false);
		}
	}, [EFAKTURA_ERROR_CODE, close, record]);

	const canEditPermission = usePermissions(
		'documents',
		'outgoing-invoices',
		'edit'
	);

	const screens = Grid.useBreakpoint();

	const menuItems = useMemo(() => {
		if (!record) {
			return [];
		}
		const items = [];

		if (record.file) {
			items.push({
				type: 'item',
				label: t`Преузми ПДФ`,
				key: 'pdf',
				icon: <i className="fi fi-rr-file-pdf"></i>,
				onClick: () => {
					window.open(
						`${config.apiV2}/files/${record.file.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				},
			});
		}

		if (record.xmlFile) {
			items.push({
				type: 'item',
				label: t`Преузми XML`,
				key: 'xml',
				icon: <i className="fi fi-rr-file-code"></i>,
				onClick: () => {
					window.open(
						`${config.apiV2}/files/${record.xmlFile.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
					);
				},
			});
		}

		if ((record.attachments?.length || 0) > 0) {
			items.push({
				label: t`Прилози`,
				key: 'attachments',
				type: 'divider',
				onClick: null,
				disabled: null,
				icon: null,
			});

			record.attachments.forEach((attachment) => {
				let attachmentIcon = <i className="fi fi-rr-document"></i>;
				if (attachment.mimetype === 'application/xml') {
					attachmentIcon = <i className="fi fi-rr-file-code"></i>;
				} else if (attachment.mimetype === 'application/pdf') {
					attachmentIcon = <i className="fi fi-rr-file-pdf"></i>;
				} else if (attachment.mimetype.startsWith('image/')) {
					attachmentIcon = <i className="fi fi-rr-file-jpg"></i>;
				}

				items.push({
					type: 'item',
					label: t`Преузми прилог ${
						attachment.originalFilename || attachment.filename
					}`,
					key: attachment.id,
					icon: attachmentIcon,
					disabled: false,
					onClick: () => {
						window.open(
							`${config.apiV2}/files/${attachment.id}?token=${v2Client.defaults.headers.common['x-access-token']}`
						);
					},
				});
			});
		}

		if (record.status === INVOICE_STATUS.DRAFT) {
			if (items.length > 0) {
				items.push({
					type: 'divider',
					label: null,
					key: 'divider',
					icon: null,
					disabled: null,
					showAsButton: true,
					onClick: null,
				});
			}
			items.push({
				type: 'item',
				label: t`Пошаљи`,
				key: 'send',
				icon: <i className="fi fi-rr-paper-plane-top"></i>,
				disabled: overrideSending,
				showAsButton: true,
				onClick: async () => {
					if (
						record?.status === INVOICE_STATUS.SENT ||
						record?.status === INVOICE_STATUS.SENDING
					) {
						return StaticComponents.modal.confirm({
							title: t`Документ је већ послат`,
							content: t`Да ли сте сигурни да желите да га пошаљете поново?`,
							onOk: async () => {
								await send();
							},
						});
					}
					await send();
				},
			});
		}
		if (
			[
				INVOICE_STATUS.APPROVED,
				INVOICE_STATUS.REJECTED,
				INVOICE_STATUS.SENT,
			].includes(record.status)
		) {
			if (items.length > 0) {
				items.push({
					type: 'divider',
					label: null,
					key: 'divider',
					icon: null,
					disabled: null,
					showAsButton: true,
					onClick: null,
				});
			}
			items.push({
				type: 'item',
				label: t`Сторнирај`,
				danger: true,
				key: 'cancel',
				icon: <i className="fi fi-rr-cross"></i>,
				disabled: overrideSending,
				showAsButton: true,
				onClick: () => {
					setCancelModalVisible(true);
				},
			});
		}
		return items;
	}, [record, overrideSending, send]);

	const menu = useMemo(() => {
		if (menuItems.length === 0) {
			return null;
		}

		const [firstMenuItem, ...otherMenuItems] = menuItems;

		if (otherMenuItems.length > 0 && otherMenuItems[0].type === 'divider') {
			otherMenuItems.shift();
		}

		const subMenuItems =
			screens.xs && !screens.sm
				? otherMenuItems
				: otherMenuItems.filter((item) => !item.showAsButton);

		const buttons = screens.sm
			? otherMenuItems.filter(
					(item) => item.showAsButton && item.type !== 'divider'
			  )
			: [];

		return (
			<>
				<Space className={pageStyles.leftButton}>
					{subMenuItems.filter((item) => item.type !== 'divider').length ===
					0 ? (
						<Button onClick={firstMenuItem.onClick} icon={firstMenuItem.icon}>
							{firstMenuItem.label}
						</Button>
					) : (
						<Dropdown.Button
							overlay={
								<Menu
									onClick={({ key }) => {
										otherMenuItems.find((item) => item.key === key)?.onClick();
									}}
									items={subMenuItems}
								/>
							}
							onClick={firstMenuItem.onClick}
						>
							{firstMenuItem.icon} {firstMenuItem.label}
						</Dropdown.Button>
					)}
					{buttons.map((item) => {
						return (
							<Button
								key={item.key}
								danger={item.danger}
								icon={item.icon}
								onClick={item.onClick}
								disabled={item.disabled}
							>
								{item.label}
							</Button>
						);
					})}
				</Space>
				{emailModal}
				<CancelModal
					visible={cancelModalVisible}
					close={() => {
						setCancelModalVisible(false);
					}}
					onOk={async (values) => {
						try {
							await record.cancel(values);
							StaticComponents.notification.success({
								message: t`Документ је успешно сторниран`,
							});
						} catch (e) {
							if (
								e?.response?.data?.errorCode ===
								ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL
							) {
								setEmailModalMode('cancel');
								setCancelModalData(values);

								setEmailModalVisible(true);
							}
							if (
								e?.response?.data?.errorCode ===
								ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR
							) {
								StaticComponents.notification.error({
									message: t`Грешка приликом сторнирања е-фактуре`,
									description:
										EFAKTURA_ERROR_CODE[
											e?.response?.data?.variables?.ErrorCode
										] ||
										e?.response?.data?.variables?.Message ||
										t`Непозната грешка`,
									duration: 0,
								});
							}
						}
					}}
					record={record}
				/>
			</>
		);
	}, [
		menuItems,
		screens.xs,
		screens.sm,
		emailModal,
		cancelModalVisible,
		record,
		EFAKTURA_ERROR_CODE,
	]);

	if (!record) {
		return null;
	}

	return (
		<>
			{menu}

			<Button key="close" type="primary" onClick={close}>
				<Trans>Затвори</Trans>
			</Button>

			{emailModal}
		</>
	);
});

export default () => {
	const DOCUMENT_TAX_INVOICE_PERIOD_MAP = getDocumentTaxInvoicePeriodMap();
	const DOCUMENT_CREDIT_DEBIT_NOTE_TYPE_MAP =
		getDocumentCreditDebitNoteTypeMap();
	const DOCUMENT_TYPE_MAP = getDocumentTypeMap();
	const EFAKTURA_ERROR_CODE = getEfakturaErrorCode();
	const INVOICE_STATUS_MAP = getInvoiceStatusMap();

	return CreatePage({
		module: 'documents',
		submodule: 'outgoing-invoices',
		model: 'invoice',
		view: {
			width: 1200,
			shouldFetch: true,
			footer: (single, edit, close) => (
				<InvoiceButtons record={single} close={close} />
			),
			title: (entity) => t`Преглед документа ${entity?.number || ''}`,
			// fields: <ViewInvoice />,
			fields: (record) => [
				{
					key: 'info',
					label: t`Информације`,
					column: 12,
					fields: [
						{
							key: 'documentType',
							label: t`Тип документа`,
							span: 3,
							render: (text) => (text ? DOCUMENT_TYPE_MAP[text] : t`Непознат`),
						},
						{
							key: 'partner',
							label: t`Купац`,
							span: 9,
							component: <PartnerView />,
						},
						{
							key: 'date',
							label: t`Датум промета`,
							span: 3,
							render: (text) => dayjs(text).format('LL'),
						},
						{
							key: 'dueDate',
							label: t`Датум валуте`,
							span: 3,
							render: (text) => (text ? dayjs(text).format('LL') : ''),
						},
						{
							key: 'number',
							label: t`Број документа`,
							span: 3,
						},
						{
							key: 'reference',
							label: t`Позив на број`,
							span: 3,
							component: <ReferenceView />,
						},
					],
				},
				{
					key: 'payment',
					label: t`Плаћање`,
					column: 12,
					fields: [
						{
							key: 'payableAmount',
							label: t`Износ`,
							span: 3,
							render: (text) => numberFormat(text, true),
						},
						// {
						// 	key: 'amountWithCassaSconto',
						// 	label: 'Iznos za plaćanje u roku',
						// 	span: 3,
						// 	render: (text) => numberFormat(text, true),
						// },
						{
							key: 'paidAmount',
							label: t`Плаћено`,
							span: 3,
							render: (text) => numberFormat(text, true),
						},
						{
							label: t`Преостало`,
							span: 3,
							render: (text, record) =>
								numberFormat(
									record.amountWithCassaSconto - record.paidAmount,
									true
								),
						},
						// {
						// 	key: 'cassaSconto',
						// 	label: 'Iznosi za plaćanje u roku',
						// 	span: 12,
						// 	component: <CassaScontoView />,
						// },
					],
				},
				...(record.invoiceItems?.length > 0
					? [
							{
								key: 'items',
								label: t`Ставке фактуре`,
								column: 12,

								fields: [
									{
										key: 'invoiceItems',
										span: 12,
										component: <InvoiceItemsView />,
									},
									{
										key: 'taxBreakdown',
										span: 12,
										component: <InvoiceSummaryView record={record} />,
									},
								],
							},
					  ]
					: []),
			],
		},
		edit: {
			controllerComponent: forwardRef((props, ref) => {
				const [, openEditPartnerDrawer, , , , EditPartnerDrawer] = useDrawer(
					'edit/settings/partners|partner-select'
				);

				useImperativeHandle(ref, () => ({
					openEditPartnerDrawer,
				}));

				return <EditPartnerDrawer location="partner-select" />;
			}),
			width: 1600,
			buttons: ({
				entity,
				save,
				close,
				isCreating,
				isLoading,
				disabled,
				canEditPermission,
			}) => {
				const [overrideSending, setOverrideSending] = useState(false);
				const [emailModalVisible, setEmailModalVisible] = useState(false);
				const [currentSavedEntity, setCurrentSavedEntity] = useState(null);

				const [form] = Form.useForm();

				const send = useCallback(async () => {
					try {
						setOverrideSending(true);
						let savedEntity = entity;
						if (
							!entity?.status ||
							entity?.status === INVOICE_STATUS.DRAFT ||
							entity?.status === INVOICE_STATUS.MISTAKE
						) {
							savedEntity = await save(false);
						}
						setCurrentSavedEntity(savedEntity);
						await savedEntity.send();
						setCurrentSavedEntity(null);
						StaticComponents.notification.success({
							message: t`Документ је успешно послат`,
						});
						close();
					} catch (e) {
						if (
							e?.response?.data?.errorCode ===
							ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL
						) {
							setEmailModalVisible(true);
						}
						if (
							e?.response?.data?.errorCode ===
							ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR
						) {
							StaticComponents.notification.error({
								message: t`Грешка приликом слања е-фактуре`,
								description:
									EFAKTURA_ERROR_CODE[
										e?.response?.data?.variables?.ErrorCode
									] ||
									e?.response?.data?.variables?.Message ||
									t`Непозната грешка`,
								duration: 0,
							});
						}
					} finally {
						setOverrideSending(false);
					}
				}, [save, close]);
				return (
					<Row>
						<Col flex="auto">
							<Button
								key="cancel"
								onClick={close}
								disabled={
									isLoading ||
									isCreating ||
									entity?.isUpdating ||
									entity?.isSending
								}
							>
								<Trans>Одустани</Trans>
							</Button>
						</Col>
						<Col flex="none">
							<Space>
								<Button
									disabled={
										isLoading ||
										isCreating ||
										entity?.isUpdating ||
										entity?.isSending ||
										!canEditPermission ||
										entity?.status === INVOICE_STATUS.CANCELLED ||
										entity?.status === INVOICE_STATUS.STORNO
									}
									loading={overrideSending || entity?.isSending}
									onClick={async () => {
										if (
											entity?.status === INVOICE_STATUS.SENT ||
											entity?.status === INVOICE_STATUS.SENDING
										) {
											return StaticComponents.modal.confirm({
												title: t`Документ је већ послат`,
												content: t`Да ли сте сигурни да желите да га пошаљете поново?`,
												onOk: async () => {
													await send();
												},
											});
										}

										await send();
									}}
								>
									<Trans>Пошаљи</Trans>
								</Button>
								<Button
									key="save"
									type="primary"
									loading={
										(isCreating || entity?.isUpdating) && !overrideSending
									}
									onClick={save}
									disabled={
										isLoading ||
										disabled ||
										isCreating ||
										entity?.isUpdating ||
										entity?.isSending ||
										!canEditPermission
									}
								>
									<Trans>Сачувај нацрт</Trans>
								</Button>
								<Modal
									title={t`Унесите адресу електронске поште`}
									open={emailModalVisible}
									confirmLoading={currentSavedEntity?.isSending}
									onOk={form.submit}
									onCancel={() => {
										setEmailModalVisible(false);
										setCurrentSavedEntity(null);
									}}
									destroyOnClose
								>
									<Form
										preserve={false}
										form={form}
										layout="vertical"
										onFinish={async (values) => {
											await currentSavedEntity.send(
												values.email,
												values.storeEmail
											);
											setEmailModalVisible(false);
											StaticComponents.notification.success({
												message: t`Документ је успешно послат`,
											});
											close();
										}}
									>
										<p>
											<Trans>
												За купца <strong>{entity?.partner?.name}</strong>, не
												постоји сачувана адреса електронске поште. Молимо вас да
												унесете адресу електронске поште на коју желите да
												пошаљете документ.
											</Trans>
										</p>
										<Form.Item
											name="email"
											label={t`Адреса електронске поште`}
											rules={[
												{
													required: true,
													message: t`Адреса електронске поште је обавезна`,
												},
												{
													type: 'email',
													message: t`Адреса електронске поште није валидна`,
												},
											]}
										>
											<Input />
										</Form.Item>
										<Form.Item
											initialValue={true}
											valuePropName="checked"
											name="storeEmail"
										>
											<Checkbox>
												<Trans>Сачувај адресу електронске поште за купца</Trans>
											</Checkbox>
										</Form.Item>
									</Form>
								</Modal>
							</Space>
						</Col>
					</Row>
				);
			},
			title: {
				new: t`Додавање излазног документа`,
				existing: (entity) =>
					t`Измена излазног документа ${entity?.number || ''}`,
			},
			shouldFetch: true,
			beforeSetFormFields: (entity) => {
				entity.creditDebitNotePeriod = (entity.creditDebitNotePeriod || []).map(
					(date) => dayjs(date)
				);
				return entity;
			},
			onError(error, entity, form, controller) {
				if (
					error?.response?.data?.errorCode ===
					ERROR_BAD_REQUEST_PARTNER_MISSING_CODE
				) {
					StaticComponents.modal.confirm({
						title: t`Грешка приликом креирања документа`,
						content: t`Формат броја документа садржи шифру партнера, али одабрани купац нема постављену шифру. Да ли желите да измените купца и поставите шифру?`,
						okText: t`Измени одабраног купца`,
						onOk: () => {
							(controller.current as any).openEditPartnerDrawer(
								form.getFieldValue('partnerId')
							);
						},
					});
				}
			},
			beforeSave: (entity) => {
				entity.storeId = stores.stores.currentStoreId;
				entity.invoiceItems = [
					...entity.invoiceItems.map((item) => ({
						...item,
						allowances: item.discountPercentage
							? [
									{
										amount: round(
											item.unitPrice *
												item.quantity *
												(item.discountPercentage / 100),
											2
										),
										baseAmount: round(item.unitPrice * item.quantity, 2),
										currencyId: item.currencyId,
										percentage: item.discountPercentage,
										reason: 'Popust',
										indicator: 'discount',
										taxCategoryCode: item.taxCode,
									},
							  ]
							: [],
					})),
				];

				return {
					...entity,
					attachments: entity.attachments
						? entity.attachments.map(
								(attachment) => attachment.response?.id || attachment?.id
						  )
						: [],
				};
			},
			disabled: (entity) => {
				if (
					entity?.direction === 'outgoing' &&
					entity?.status !== INVOICE_STATUS.DRAFT
				) {
					return true;
				}
				return entity?.externalId;
			},
			disabledReason: (entity) => {
				if (
					entity?.direction === 'outgoing' &&
					entity?.status !== INVOICE_STATUS.DRAFT
				) {
					return t`Документ је већ послат и није га могуће мењати`;
				}
				return t`Документ је увезен из другог система и није га могуће мењати`;
			},
			fields: (record, form, setFields) => {
				const amount = round(
					form
						.getFieldValue('invoiceItems')
						?.reduce(
							(acc, item) =>
								acc +
								(item.quantity * item.unitPrice - item.discountAmount) +
								(item.quantity * item.unitPrice - item.discountAmount) *
									((item.taxPercentage || 0) / 100),
							0
						),
					2
				);

				const taxBreakdown = Object.values(
					form.getFieldValue('invoiceItems')?.reduce((acc, item) => {
						acc[item.taxCode] = acc[item.taxCode] || {
							taxRate: item.taxPercentage,
							taxCategory: item.taxCode,
							taxableAmount: 0,
							taxAmount: 0,
							currencyId: form.getFieldValue('currencyId'),
						};

						acc[item.taxCode].taxableAmount = round(
							acc[item.taxCode].taxableAmount +
								item.quantity * item.unitPrice -
								item.discountAmount,
							2
						);

						acc[item.taxCode].taxAmount = round(
							acc[item.taxCode].taxAmount +
								(item.quantity * item.unitPrice - item.discountAmount) *
									((item.taxPercentage || 0) / 100),
							2
						);

						return acc;
					}, {}) || {}
				) as TaxBreakdown[];

				const advancePayments = Object.values(
					form.getFieldValue('advanceInvoices')?.reduce((acc, item) => {
						['S10', 'S20', 'AE', 'O', 'E', 'R', 'Z', 'SS', 'OE', 'N'].forEach(
							(taxCode) => {
								if (!item[`amount${taxCode}`]) {
									return;
								}

								acc[taxCode] = acc[taxCode] || {
									taxRate: ['S10', 'S20'].includes(taxCode)
										? Number(taxCode.replace('S', ''))
										: 0,
									taxCategory: ['S10', 'S20'].includes(taxCode) ? 'S' : taxCode,
									taxableAmount: 0,
									taxAmount: 0,
									currencyId: form.getFieldValue('currencyId'),
								};

								acc[taxCode].taxableAmount = round(
									acc[taxCode].taxableAmount + item[`amount${taxCode}`],
									2
								);

								acc[taxCode].taxAmount = round(
									acc[taxCode].taxAmount +
										(['S10', 'S20'].includes(taxCode)
											? item[`vat${taxCode}`]
											: 0),
									2
								);
							}
						);

						return acc;
					}, {}) || {}
				) as AdvancePayment[];

				const totalAdvancePayments = round(
					advancePayments.reduce(
						(acc, item) => acc + item.taxableAmount + item.taxAmount,
						0
					),
					2
				);

				const payableAmount = round(amount - totalAdvancePayments, 2);

				const categories = uniq(
					form.getFieldValue('invoiceItems')?.map((item) => item.taxCode)
				);

				return [
					{
						key: 'row1',
						label: t`Основне информације`,
						fields: [
							{
								key: 'direction',
								hidden: true,
								initialValue: 'outgoing',
								component: <Input />,
							},
							{
								key: 'documentType',
								label: t`Тип документа`,
								initialValue: 'invoice',
								rules: [
									{
										required: true,
										message: t`Тип документа је обавезан`,
									},
								],
								component: (
									<Select
										onChange={(value) => {
											if (value === DOCUMENT_TYPE.ADVANCE_INVOICE) {
												form.setFieldsValue({
													taxInvoicePeriod:
														DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE,
												});
											}
										}}
									>
										{Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => (
											<Select.Option value={key}>{value}</Select.Option>
										))}
									</Select>
								),
								xs: 24,
								sm: 6,
								rerenderOnChange: true,
							},
							{
								key: 'partnerId',
								label: t`Купац`,
								rules: [
									{
										required: true,
										message: t`Купац је обавезан`,
									},
								],
								component: <PartnerSelect />,
								rerenderOnChange: true,
								xs: 24,
								sm: 10,
							},
							{
								key: 'partnerLocationId',
								label: t`Локација`,

								component: (
									<PartnerLocationSelect
										partnerId={form.getFieldValue('partnerId')}
									/>
								),
								xs: 24,
								sm: 8,
							},

							{
								key: 'number',
								label: t`Број документа`,
								xs: 24,
								sm: 12,
								rules:
									stores.company.invoiceNumbering === 'custom'
										? [
												{
													required: true,
													message: t`Број документа је обавезан`,
												},
										  ]
										: [],
								component: (
									<Input
										disabled={stores.company.invoiceNumbering !== 'custom'}
										placeholder={
											stores.company.invoiceNumbering !== 'custom'
												? t`Број ће бити аутоматски генерисан`
												: ''
										}
									/>
								),
							},
							{
								key: 'reference',
								label: t`Позив на број`,
								xs: 24,
								sm: 12,
								rules: [],
								component: (
									<ReferenceInput
										disabled={stores.company.invoiceNumbering !== 'custom'}
										placeholder={
											stores.company.invoiceNumbering !== 'custom'
												? t`Позив на број ће бити аутоматски генерисан`
												: ''
										}
									/>
								),
							},
						],
					},
					...([DOCUMENT_TYPE.CREDIT_NOTE, DOCUMENT_TYPE.DEBIT_NOTE].includes(
						form.getFieldValue('documentType')
					)
						? [
								{
									key: 'row2',
									fields: [
										{
											key: 'creditDebitNoteType',
											label:
												form.getFieldValue('documentType') ===
												DOCUMENT_TYPE.CREDIT_NOTE
													? t`Књижно одобрење се односи на`
													: t`Књижно задужење се односи на`,
											xs: 24,
											sm: 10,
											md: 8,
											initialValue: 'singleInvoice',
											rerenderOnChange: true,
											component: (
												<Select popupMatchSelectWidth={false}>
													{Object.entries(
														DOCUMENT_CREDIT_DEBIT_NOTE_TYPE_MAP
													).map(([key, value]) => (
														<Select.Option value={key}>{value}</Select.Option>
													))}
												</Select>
											),
										},
										...(form.getFieldValue('creditDebitNoteType') ===
										'singleInvoice'
											? [
													{
														key: 'creditDebitNoteReferences',
														label: t`Изворне фактуре`,
														component: (
															<CreditDebitNoteReferencesInput
																partnerId={form.getFieldValue('partnerId')}
															/>
														),
														rules: [
															{
																required: true,
																message: t`Изворне фактуре су обавезне`,
															},
														],
														span: 24,
													},
											  ]
											: [
													{
														key: 'creditDebitNotePeriod',
														label: t`Период`,
														xs: 24,
														sm: 14,
														md: 8,
														component: (
															<DatePicker.RangePicker
																style={{ width: '100%' }}
																format="L"
															/>
														),
														rules: [
															{
																required: true,
																message: t`Период је обавезан`,
															},
														],
													},
											  ]),
									],
								},
						  ]
						: []),
					{
						key: 'contract',
						fields: [
							{
								key: 'contractNumber',
								label: t`Број уговора`,
								xs: 24,
								sm: 24,
								md: 8,
								rules:
									stores.partners.getById(form.getFieldValue('partnerId'))
										?.jbkjs !== ''
										? [
												{
													required: true,
													message: t`Број уговора је обавезан`,
												},
										  ]
										: undefined,
								component: <Input />,
							},
							{
								key: 'orderNumber',
								label: t`Број наруџбенице/фактуре/понуде`,
								xs: 24,
								sm: 24,
								md: 8,
								component: <Input />,
							},
							{
								key: 'lotNumber',
								label: t`Број оквирног споразума`,
								xs: 24,
								sm: 24,
								md: 8,
								component: <Input />,
							},
						],
					},
					...([DOCUMENT_TYPE.INVOICE].includes(
						form.getFieldValue('documentType')
					)
						? [
								{
									key: 'row3',
									fields: [
										{
											key: 'date',
											label: t`Датум промета`,
											initialValue: dayjs(),
											xs: 24,
											sm: 8,
											rules: [
												{
													required: true,
													message: t`Датум промета фактуре је обавезан`,
												},
											],
											component: (
												<DatePicker format="L" style={{ width: '100%' }} />
											),
										},

										{
											key: 'dueDate',
											label: t`Датум доспећа`,
											xs: 24,
											sm: 8,
											initialValue: dayjs().add(
												stores.company.defaultDueDate,
												'days'
											),
											rules:
												form.getFieldValue('taxInvoicePeriod') ===
												DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE
													? [
															{
																required: true,
																message: t`Датум доспећа је обавезан`,
															},
													  ]
													: [],
											component: (
												<DatePicker style={{ width: '100%' }} format="L" />
											),
										},
										{
											key: 'taxInvoicePeriod',
											label: t`Датум обрачуна ПДВ-а`,
											xs: 24,
											sm: 8,
											initialValue: DOCUMENT_TAX_INVOICE_PERIOD.ISSUE_DATE,
											rerenderOnChange: true,
											component: (
												<Select popupMatchSelectWidth={false}>
													{Object.entries(DOCUMENT_TAX_INVOICE_PERIOD_MAP).map(
														([key, value]) => (
															<Select.Option value={key}>{value}</Select.Option>
														)
													)}
												</Select>
											),
										},
									],
								},
						  ]
						: []),
					...([DOCUMENT_TYPE.ADVANCE_INVOICE].includes(
						form.getFieldValue('documentType')
					)
						? [
								{
									key: 'row3',
									fields: [
										{
											key: 'dueDate',
											label: t`Датум плаћања`,
											xs: 24,
											sm: 8,
											rules: [
												{
													required: true,
													message: t`Датум плаћања је обавезан`,
												},
											],
											component: (
												<DatePicker style={{ width: '100%' }} format="L" />
											),
										},
										{
											key: 'taxInvoicePeriod',
											label: t`Датум обрачуна ПДВ-а`,
											xs: 24,
											sm: 8,
											initialValue: DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE,
											rerenderOnChange: true,
											component: (
												<Select popupMatchSelectWidth={false} disabled>
													<Select.Option
														value={DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE}
													>
														{
															DOCUMENT_TAX_INVOICE_PERIOD_MAP[
																DOCUMENT_TAX_INVOICE_PERIOD.PAYMENT_DATE
															]
														}
													</Select.Option>
												</Select>
											),
										},
									],
								},
						  ]
						: []),

					{
						key: 'row4',
						label: t`Плаћање`,
						fields: [
							{
								key: 'currencyId',
								label: t`Валута`,
								component: (
									<CurrencySelect
										onChange={(value) => {
											form.setFieldsValue({
												exchangeRate:
													round(
														stores.exchangeRates.lastRates?.[value as string]
															?.rate,
														6
													) || '',
											});
										}}
									/>
								),
								rerenderOnChange: true,
								initialValue: 'RSD',
								hidden: true,
								xs: 24,
								sm: 5,
								// TODO: if currency is not RSD, add exchange rate field, which is automatically populated with the latest exchange rate
							},
							// ...(form.getFieldValue('currencyId') !== 'RSD'
							// 	? [
							// 			{
							// 				key: 'exchangeRate',
							// 				label: 'Kurs',
							// 				component: (
							// 					<Input
							// 						prefix={`1 ${form.getFieldValue('currencyId')} = `}
							// 						suffix="RSD"
							// 						style={{ textAlign: 'right' }}
							// 					/>
							// 				),
							// 				xs: 24,
							// 				sm: 8,
							// 				md: 7,
							// 			},
							// 	  ]
							// 	: []),
							{
								key: 'paymentMeans',
								label: t`Начини плаћања`,
								component: <PaymentMeansSelect />,
								// xs: 24,
								initialValue: sortBy(stores.company?.bankAccounts, (account) =>
									account.primary ? 0 : 1
								)
									.filter((account) => account.invoice)
									.map((account) => `30:${account.number}`),
								span: 24,
								rules: [
									{
										required: true,
										message: t`Начин плаћања је обавезан`,
									},
								],
							},
						],
					},

					{
						key: 'row6',
						label: t`Ставке документа`,
						fields: [
							{
								key: 'invoiceItems',
								component: (
									<InvoiceItemsInput
										currencyId={form.getFieldValue('currencyId')}
										exchangeRate={form.getFieldValue('exchangeRate')}
										documentType={form.getFieldValue('documentType')}
									/>
								),
								span: 24,
								rerenderOnChange: true,
							},
						],
					},
					...((form.getFieldValue('invoiceItems') || []).find((item) => {
						return (
							item.taxCode !== null &&
							item.taxCode !== 'S' &&
							item.taxCode !== ''
						);
					})
						? [
								{
									key: 'row7',
									label: t`Основ за ослобађање/изузеће од ПДВ-а`,
									fields: [
										{
											key: 'taxExemption',
											component: <TaxExemptionInput categories={categories} />,
											span: 24,
											extra: !['sr-latn', 'sr-cyrl'].includes(
												stores.users.authenticatedUser?.locale || defaultLocale
											)
												? t`ПДВ категорије су приказане на српском језику`
												: '',
										},
									],
								},
						  ]
						: []),
					...([DOCUMENT_TYPE.INVOICE].includes(
						form.getFieldValue('documentType')
					)
						? [
								{
									key: 'advances',
									label: t`Авансни рачуни`,
									fields: [
										{
											key: 'advanceInvoices',
											component: (
												<AdvanceInvoicesInput
													partnerId={form.getFieldValue('partnerId')}
												/>
											),
											span: 24,
											rerenderOnChange: true,
										},
									],
								},
						  ]
						: []),
					{
						key: 'summary',
						fields: [
							{
								key: 'invoiceSummary',
								component: (
									<>
										<InvoiceSummaryView
											record={{
												currencyId: form.getFieldValue('currencyId'),
												taxCurrencyId: form.getFieldValue('currencyId'),
												payableAmount,
												amount,
												taxBreakdown,
												advancePayments,
											}}
										/>
									</>
								),
								span: 24,
								rerenderOnChange: true,
							},
						],
					},
					{
						key: 'other',
						label: t`Напомена`,
						fields: [
							{
								key: 'note',
								component: <Input.TextArea rows={4} />,
								span: 24,
							},
						],
					},
					{
						key: 'attachments',
						label: t`Прилози`,
						fields: [
							{
								key: 'attachments',
								component: <AttachmentsEdit />,
								span: 24,
							},
						],
					},
				];
			},
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/dokumenti/izlazni-dokumenti/',
			empty: {
				image: 'upload',
				text: t`Нема излазних докумената`,
			},
			additionalQueryParams: {
				direction: 'outgoing',
			},
			shouldFetch: true,
			table: {
				props: {
					scroll: { x: 1900 },
				},
				showDisabledActions: true,
				actions: [(record) => <PayButton record={record} />],
				columns: () => [
					{
						title: t`Број документа`,
						dataIndex: 'number',
						key: 'number',
						width: 220,
						shouldLink: true,
						ellipsis: true,
						filterKey: 'number',
						render: (text, record) => (
							<>
								{text}
								{record.externalId && (
									<img
										src="/images/icons/efaktura-logo.png"
										style={{ float: 'right', marginTop: '4px' }}
										title="e-Faktura"
									/>
								)}
							</>
						),
						filterIcon: (filtered) => (
							<i
								className="fi fi-rr-search"
								style={{ color: filtered ? '#7E89C8' : undefined }}
							></i>
						),
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<Input
										placeholder={t`Број документа`}
										value={selectedKeys[0]}
										onPressEnter={() => confirm({ closeDropdown: true })}
										onChange={(event) => setSelectedKeys([event.target.value])}
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					{
						title: t`Тип документа`,
						dataIndex: 'documentType',
						key: 'documentType',
						ellipsis: true,
						width: 150,
						render: (text) => {
							return DOCUMENT_TYPE_MAP[text] || t`Непознат`;
						},
						filters: Object.entries(DOCUMENT_TYPE_MAP).map(([key, value]) => ({
							text: value,
							value: key,
						})),
						filterMultiple: true,
						filterKey: 'documentType',
					},
					// {
					// 	title: 'Poziv na broj',
					// 	key: 'reference',
					// 	ellipsis: true,
					// 	render: (record) =>
					// 		record.reference &&
					// 		`${record.reference.model ? `(${record.reference.model})` : ''} ${
					// 			record.reference.number
					// 		}`,
					// },
					{
						title: t`Статус`,
						dataIndex: 'status',
						key: 'status',
						filterKey: 'status',
						width: 120,
						filters: Object.entries(INVOICE_STATUS_MAP).map(([key, value]) => ({
							text: value,
							value: key,
						})),
						filterMultiple: true,
						render: (text) => {
							return INVOICE_STATUS_MAP[text] || t`Непознат`;
						},
					},
					{
						title: t`Купац`,
						dataIndex: ['partner', 'name'],
						key: 'partnerId',
						filterKey: 'partnerId',
						filters: () =>
							stores.partners.list?.map((partner: Partner) => ({
								text: partner.name,
								value: partner.id,
							})),
						filterMultiple: true,
						ellipsis: true,
						filterSearch: true,
					},
					{
						title: t`Износ`,
						width: 160,
						key: 'amountWithCassaSconto',
						dataIndex: 'amountWithCassaSconto',
						render: (text) => numberFormat(text, true),
					},
					{
						title: t`Преостало`,
						width: 160,
						key: 'remainingAmount',
						dataIndex: 'remainingAmount',
						render: (text, record) =>
							numberFormat(record.remainingAmount, true),
					},
					{
						title: t`Датум промета`,
						width: 170,
						key: 'date',
						dataIndex: 'date',
						render: (text) => (text ? dayjs(text).format('LL') : ''),
						filterKey: 'date',
						sorter: true,
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<DatePicker.RangePicker
										value={[
											selectedKeys[0]
												? dayjs(selectedKeys[0]).startOf('day')
												: undefined,
											selectedKeys[1]
												? dayjs(selectedKeys[1]).endOf('day')
												: undefined,
										]}
										onChange={(values) =>
											setSelectedKeys([
												dayjs(values[0]).startOf('day').toISOString(),
												dayjs(values[1]).endOf('day').toISOString(),
											])
										}
										format="L"
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					{
						title: t`Датум издавања`,
						width: 170,
						key: 'issueDate',
						dataIndex: 'issueDate',
						render: (text) => (text ? dayjs(text).format('LL') : '-'),
						filterKey: 'issueDate',
						sorter: true,
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<DatePicker.RangePicker
										value={[
											selectedKeys[0]
												? dayjs(selectedKeys[0]).startOf('day')
												: undefined,
											selectedKeys[1]
												? dayjs(selectedKeys[1]).endOf('day')
												: undefined,
										]}
										onChange={(values) =>
											setSelectedKeys([
												dayjs(values[0]).startOf('day').toISOString(),
												dayjs(values[1]).endOf('day').toISOString(),
											])
										}
										format="L"
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					{
						title: t`Датум плаћања`,
						width: 170,
						key: 'dueDate',
						dataIndex: 'dueDateWithCassaSconto',
						render: (text, record) => {
							if (!text) {
								return '';
							}
							const badgeColor =
								record.cassaSconto && record.cassaSconto.length
									? 'yellow'
									: 'green';
							return (
								<Tooltip
									title={
										record.cassaSconto && record.cassaSconto.length
											? [
													...record.cassaSconto,
													{
														dueDate: record.dueDate,
														amount: record.payableAmount,
													},
											  ].map((cassa) => (
													<Fragment key={cassa.dueDate}>
														{dayjs(cassa.dueDate).format('LL')}:{' '}
														{numberFormat(cassa.amount, true)}
														<br />
													</Fragment>
											  ))
											: undefined
									}
								>
									<Badge
										color={
											dayjs().endOf('day').isAfter(dayjs(text).endOf('day'))
												? 'red'
												: badgeColor
										}
										text={dayjs(text).format('LL')}
									/>
								</Tooltip>
							);
						},
						sorter: true,
						filterKey: 'dueDate',
						filterDropdown: ({
							setSelectedKeys,
							selectedKeys,
							confirm,
							clearFilters,
						}) => (
							<div>
								<div style={{ padding: '8px' }}>
									<DatePicker.RangePicker
										value={[
											selectedKeys[0]
												? dayjs(selectedKeys[0]).startOf('day')
												: undefined,
											selectedKeys[1]
												? dayjs(selectedKeys[1]).endOf('day')
												: undefined,
										]}
										onChange={(values) =>
											setSelectedKeys([
												dayjs(values[0]).startOf('day').toISOString(),
												dayjs(values[1]).endOf('day').toISOString(),
											])
										}
										format="L"
									/>
								</div>
								<div className="ant-table-filter-dropdown-btns">
									<Button
										onClick={() => clearFilters()}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Поништи</Trans>
									</Button>
									<Button
										type="primary"
										onClick={() => confirm({ closeDropdown: true })}
										size="small"
										style={{ width: 90 }}
									>
										<Trans>Потврди</Trans>
									</Button>
								</div>
							</div>
						),
					},
					{
						title: t`Плаћено`,
						width: 175,
						key: 'paid',
						filters: [
							{
								text: <Badge color="green" text={t`Плаћено`} />,
								value: 'yes',
							},
							{
								text: <Badge color="orange" text={t`Делимично плаћено`} />,
								value: 'partial',
							},
							{
								text: <Badge color="red" text={t`Није плаћено`} />,
								value: 'no',
							},
						],
						defaultFilteredValue: ['no', 'partial'],
						filterKey: 'paid',
						filterMultiple: true,
						render: (record) => {
							if (!record.paid && record.partiallyPaid) {
								return <Badge color="orange" text={t`Делимично плаћено`} />;
							}

							return record.paid ? (
								<Badge color="green" text={t`Плаћено`} />
							) : (
								<Badge color="red" text={t`Није плаћено`} />
							);
						},
					},
				],
			},
			createButtonText: t`Додај излазни документ`,
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај документ?`,
		},
	});
};
