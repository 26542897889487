import { Trans, t } from '@lingui/macro';
import { Row, Col, Button } from 'antd';
import { MojBadiAdministrationCompany } from '../../../../../../../stores/MojBadiAdministrationCompany';
import { observer } from 'mobx-react-lite';
import RejectModal from '../RejectModal';
import { useState } from 'react';

interface Props {
	entity: MojBadiAdministrationCompany;
}

function FooterBase({ entity }: Props) {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			<Row gutter={8}>
				{(entity.status === 'pending' || entity.status === 'rejected') && (
					<Col>
						<Button
							onClick={() => entity.approve()}
							loading={entity.isApproving}
							disabled={entity.isApproving || entity.isRejecting}
						>
							<Trans>Одобри</Trans>
						</Button>
					</Col>
				)}
				{(entity.status === 'pending' || entity.status === 'active') && (
					<Col>
						<Button
							disabled={entity.isRejecting || entity.isApproving}
							onClick={() => setIsModalOpen(true)}
						>
							<Trans>Одбиј</Trans>
						</Button>
					</Col>
				)}
			</Row>
			<RejectModal
				open={isModalOpen}
				close={() => setIsModalOpen(false)}
				onOk={(reason) => {
					entity.reject(reason.comment);
				}}
				okText={t`Одбиј`}
				cancelText={t`Откажи`}
				title={t`Одбијање`}
				isCommentRequired
				record={entity}
			/>
		</>
	);
}

export const Footer = observer(FooterBase);
