import { observable, flow } from 'mobx';

import { CreateStore } from './Crud.mobx';
import { DayjsTransformer } from './transformers/Dayjs';
import { Product } from './Product.mobx';
import { Company } from './Company.mobx';

export type LoyaltyType = 'none' | 'points' | 'stickers' | 'tiers';

const { Store, Entity } = CreateStore({
	name: 'mojBadiAdministrationOffer',
	resourcePrefix: 'moj-badi/administration/',
	resourceName: 'offers',
	paginated: true,
	persistFields: ['all'],
});

class MojBadiAdministrationOffer extends Entity {
	@observable name?: string;
	@observable description?: string;
	@observable type?: 'percentage' | 'fixed-price' | 'buy-n-get-m';
	@observable percentage?: number;
	@observable fixedPrice?: number;
	@observable requiredForPurchase?: number;
	@observable promotedProductPrice?: number;
	@observable promotedProductQuantity?: number;
	@DayjsTransformer
	beginAt?: Dayjs;
	@DayjsTransformer
	endAt?: Dayjs;
	@observable status?: 'active' | 'pending' | 'rejected';
	@observable rejectedReason?: string;

	@observable productId?: string;
	@observable promotedProductId?: string;

	@observable
	company?: Company;

	@observable
	product?: Product;
	@observable
	promotedProduct?: Product;

	@observable isApproving = false;
	@observable isRejecting = false;

	get isEditable(): boolean {
		return false;
	}
	get isDeletable(): boolean {
		return false;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	replace(data: any): void {
		super.replace(data);
		this.product = new Product(data.product, this);
		this.promotedProduct = new Product(data.promotedProduct, this);
		this.company = new Company(data.company, this);
	}

	@flow.bound
	*approve() {
		this.isApproving = true;
		try {
			const response = yield this.getClient().patch(
				`/moj-badi/administration/offers/${this.id}`,
				{
					status: 'active',
				}
			);
			this.status = response.data.status;
		} catch (e) {
			console.error(e);
		} finally {
			this.isApproving = false;
		}
	}

	@flow.bound
	*reject(reason: string) {
		this.isRejecting = true;
		try {
			const response = yield this.getClient().patch(
				`/moj-badi/administration/offers/${this.id}`,
				{
					status: 'rejected',
					rejectedReason: reason,
				}
			);
			this.status = response.data.status;
		} catch (e) {
			console.error(e);
		} finally {
			this.isRejecting = false;
		}
	}
}

class MojBadiAdministrationOffers extends Store<MojBadiAdministrationOffer> {
	get isCreatable(): boolean {
		return false;
	}

	constructor() {
		super(MojBadiAdministrationOffer);
	}
}

export { MojBadiAdministrationOffers, MojBadiAdministrationOffer };
