import { t } from '@lingui/macro';
import { Form, Input, Modal } from 'antd';
import { useCallback, useState } from 'react';

interface Props {
	visible: boolean;
	onCancel: () => void;
	onOk: (string) => void;
}

export default function EmailModal({
	visible,
	onCancel = () => null,
	onOk = () => null,
}: Props) {
	const [form] = Form.useForm();
	const [isSubmitting, setIsSubmitting] = useState(false);

	const submit = useCallback(async () => {
		setIsSubmitting(true);
		try {
			const values = await form.validateFields();
			onOk(values.email);
		} catch (e) {
			//
		} finally {
			setIsSubmitting(false);
		}
	}, [form, onOk]);

	return (
		<Modal
			centered
			open={visible}
			width={600}
			title={t`Слање фискалног рачуна путем електронске поште`}
			onCancel={onCancel}
			onOk={submit}
		>
			<Form form={form} onFinish={submit}>
				<Form.Item
					label={t`Адреса електронске поште`}
					name="email"
					rules={[
						{
							required: true,
							message: t`Обавезан је унос адресе електронске поште`,
						},
						{
							type: 'email',
							message: t`Адреса електронске поште није исправна`,
						},
					]}
				>
					<Input />
				</Form.Item>
				<input type="submit" style={{ display: 'none' }}></input>
			</Form>
		</Modal>
	);
}
