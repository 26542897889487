import { t, Trans } from '@lingui/macro';
import { Checkbox, Input, Tag, Tooltip, Select, InputNumber } from 'antd';
import { BarcodeInput } from './Components/BarcodeInput';
import CategoriesView from './Components/CategoriesView';
import CustomCreateButton from './Components/CustomCreateButton';
import DimensionsInput from './Components/DimensionsInput';
import { SaleChannelsCell } from './Components/SaleChannelsCell';
import SaleChannelsInput from './Components/SaleChannelsInput';
import VariantsEdit from './Components/VariantsEdit';
import Search from './Drawers/Search';
import styles from './Products.module.less';
import CategorySelect from '../../../../components/CategorySelect';
import CurrencySelect from '../../../../components/CurrencySelect';
import Editor from '../../../../components/Editor';
import { NoImage } from '../../../../components/NoImage';
import { CreatePage } from '../../../../components/Page';
import PhotosEdit from '../../../../components/PhotosEdit';
import { StaticComponents } from '../../../../components/StaticComponents';
import TaxRateSelect from '../../../../components/TaxRateSelect';
import UnitSelect from '../../../../components/UnitSelect';
import { ERROR_BAD_REQUEST_SKU_ALREADY_USED } from '../../../../constants/errors';
import numberFormat from '../../../../lib/numberFormat';
import stores from '../../../../stores/index.mobx';
import PartnersView from './Components/PartnersView';
import { Partner } from '../../../../stores/Partner.mobx';
import { SalesStatsView } from './Components/SalesStatsView';
import { TurnoverStatsView } from './Components/TurnoverStatsView';
import { PurchaseStatsView } from './Components/PurcaseStatsView';
import { StockStatsView } from './Components/StockStatsView';
import { SalesView } from './Components/SalesView';
import { PurchasesView } from './Components/PurchasesView';
import Sales from './Drawers/Sales';
import { ShowAll } from './Components/ShowAll';
import Purchases from './Drawers/Purchases';

export default () =>
	CreatePage({
		module: 'catalog',
		submodule: 'products',
		model: 'product',
		view: {
			width: 842,
			useEdit: false,
			title: (entity) => t`Преглед артикла`,
			fields: (record) => {
				return [
					{
						key: 'info',
						label: t`Основне информације`,
						fields: [
							{
								key: 'productType',
								label: t`Тип артикла`,
								render: (value) => {
									if (value === 'product') {
										return t`Физички`;
									}
									if (value === 'digital') {
										return t`Дигитални`;
									}
									if (value === 'service') {
										return t`Услуга`;
									}
									return value;
								},
								xs: 24,
								sm: 4,
							},
							{
								key: 'hasVariants',
								label: t`Варијанте`,
								render: (value) => {
									return value ? t`Артикал има варијанте` : t`Без варијанти`;
								},
								xs: 24,
								sm: 6,
							},
							{
								key: 'name',
								label: t`Назив`,
								xs: 24,
								sm:
									stores.company?.hasFeature('variants') ||
									record?.variants?.length > 0
										? 14
										: 20,
							},
						],
					},
					...(record?.hasVariants
						? [
								{
									label: t`Варијанте`,
									key: 'variants',
									fields: [
										{
											key: 'variants',
											render: (value) => (
												<VariantsEdit
													productType={record.productType}
													mode="view"
													value={value}
												/>
											),
											span: 24,
										},
									],
								},
						  ]
						: [
								{
									key: 'novariants',
									fields: [
										{
											key: 'sku',
											label: t`Шифра`,
											xs: 8,
											sm: 4,
											md: 4,
										},
										...(record.productType === 'product'
											? [
													{
														key: 'manufacturerSku',
														label: t`Шифра произвођача`,
														responsive: ['sm'],
														xs: 16,
														sm: 6,
														md: 6,
													},
													{
														key: 'ean',
														label: t`Бар код (ГТИН)`,
														xs: 12,
														sm: 6,
														md: 5,
													},
											  ]
											: []),
										{
											key: 'price',
											label: t`Продајна цена`,
											render: (value) =>
												numberFormat(
													value,
													true,
													2,
													true,
													record.currencyId || 'RSD'
												),
											xs: 12,
											md: 4,
											sm: 10,
										},
										{
											key: 'taxRateLabel',
											label: t`Пореска ознака`,
											render: (value) => {
												return `${
													stores.taxRates.byLabel(value).name
												} - ${value} (${numberFormat(
													stores.taxRates.byLabel(value).rate,
													false
												)}%)`;
											},
											xs: 24,
											md: 4,
											sm: 14,
										},
									],
								},
						  ]),
					{
						key: 'additional',
						label: t`Додатне информације`,
						fields: [
							{
								key: 'categories',
								label: t`Категорије`,
								render: (value, record) =>
									record.categories.length > 0 ? (
										<CategoriesView record={record} />
									) : undefined,
								span: 24,
							},
							{
								key: 'subtitle',
								label: t`Кратак опис`,
								xs: 24,
								sm: 24,
							},
							{
								key: 'description',
								label: t`Детаљан опис`,
								xs: 24,
								render: (value) =>
									value && (
										<div
											className={styles.descriptionView}
											dangerouslySetInnerHTML={{ __html: value }}
										/>
									),
							},
						],
					},
					{
						key: 'images',
						label: t`Фотографије`,
						fields: [
							{
								key: 'images',
								render: (value) =>
									value?.length > 0 && <PhotosEdit value={value} mode="view" />,
								span: 24,
							},
						],
					},
					...(!record.hasVariants
						? [
								...(stores.saleChannels.all.length > 0
									? [
											{
												key: 'saleChannels',
												label: t`Додатни канали продаје`,
												fields: [
													{
														key: 'saleChannels',
														render: (value) => (
															<SaleChannelsInput
																value={value}
																mode="view"
																price={
																	record?.productType === 'product' &&
																	typeof record?.prices?.[
																		stores.stores.currentStore?.warehouseId
																	] !== 'undefined'
																		? record?.currentStorePrice
																		: record?.price
																}
																currencyId={record.currencyId}
															/>
														),
														span: 24,
													},
												],
											},
											{
												key: 'measurements',
												label: t`Мере`,
												fields: [
													...(record.productType === 'product'
														? [
																{
																	key: 'quantityPerBaseUnit',
																	label: t`Количина у ЈМ`,
																	render: (value) => {
																		return (
																			value &&
																			`${numberFormat(
																				value,
																				false,
																				3,
																				false
																			)} ${record.unit?.name || ''}`
																		);
																	},
																	xs: 12,
																	md: 6,
																},
																{
																	key: 'saleUnitId',
																	label: t`Продајна ЈМ`,
																	render: () => {
																		return record.saleUnit?.name;
																	},
																	xs: 12,
																	md: 6,
																},
														  ]
														: []),
													...(record.productType === 'product'
														? [
																{
																	key: 'weight',
																	label: t`Бруто маса`,
																	render: (value) => {
																		return value ? t`${value} кг` : undefined;
																	},
																	xs: 6,
																	md: 4,
																},
																{
																	key: 'dimensions',
																	label: t`Димензије`,
																	render: (value) => {
																		return value ? t`${value} цм` : undefined;
																	},
																	xs: 18,
																	md: 8,
																},
														  ]
														: []),
												],
											},
											{
												key: 'receipt',
												label: t`Фискални рачун`,
												fields: [
													{
														label: t`Дозвољен више пута на једном рачуну`,
														key: 'multiplePerReceipt',
														render: (value) => (value ? t`Да` : t`Не`),
														span: 24,
													},
												],
											},
									  ]
									: []),
								{
									key: 'stats',
									label: t`Статистика`,
									fields: [
										{
											key: 'salesStats',
											label: t`Статистика продаје`,
											render: () => {
												return <SalesStatsView product={record} />;
											},
											xs: 24,
											sm: 12,
										},
										{
											key: 'turnoverStats',
											label: t`Статистика промета`,
											render: () => {
												return <TurnoverStatsView product={record} />;
											},
											xs: 24,
											sm: 12,
										},
										...(stores.company.hasFeature('stock')
											? [
													{
														key: 'purchaseStats',
														label: t`Статистика набавке`,
														render: () => {
															return <PurchaseStatsView product={record} />;
														},
														xs: 24,
														sm: 12,
													},
													{
														key: 'stockStats',
														label: t`Статистика залиха`,
														render: () => {
															return <StockStatsView product={record} />;
														},
														xs: 24,
														sm: 12,
													},
											  ]
											: []),
										{
											key: 'sales',
											label: t`Последње продаје`,
											labelExtra: <ShowAll product={record} mode="sales" />,
											render: () => {
												return <SalesView product={record} />;
											},
										},
										...(stores.company.hasFeature('stock')
											? [
													{
														key: 'purchases',
														label: t`Последње набавке`,
														labelExtra: (
															<ShowAll product={record} mode="purchases" />
														),
														render: () => {
															return <PurchasesView product={record} />;
														},
													},
											  ]
											: []),
									],
								},
						  ]
						: []),
				];
			},
		},
		edit: {
			width: 842,
			title: {
				new: t`Додавање артикла`,
				existing: (entity) => t`Измена артикла ${entity?.name || ''}`,
			},
			shouldFetch: true,
			beforeSave: (values) => {
				return {
					...values,
					dimensions: values.dimensions
						? values.dimensions.map((d) => d || 0).join('x')
						: null,
					images: values.images
						? values.images.map((image) => image.response?.id || image?.id)
						: [],
					ean: values.ean === '' ? null : values.ean,
					categories: (values.categories || []).map(
						(category) => category.value || category
					),
					saleChannels: (values.saleChannels || []).map((saleChannel) => ({
						...saleChannel,
						metadata: {
							...saleChannel.metadata,
						},
					})),
				};
			},
			beforeSetFormFields: (entity) => {
				return {
					...entity,
					dimensions: (entity.dimensions || '0x0x0')
						.split('x')
						.map((d) => Number(d)),
				};
			},
			onError: (error, entity) => {
				if (
					error?.response?.data?.errorCode ===
					ERROR_BAD_REQUEST_SKU_ALREADY_USED
				) {
					return StaticComponents.notification.error({
						message: t`Грешка`,
						description: t`Шифра је већ у употреби за други артикал`,
					});
				}
			},
			fields: (record, form, setFields) => {
				return [
					{
						key: 'info',
						label: t`Основне информације`,
						fields: [
							{
								key: 'productType',
								label: t`Тип артикла`,
								rules: [
									{ required: true, message: t`Тип арткила је обавезан` },
								],
								rerenderOnChange: true,
								initialValue: 'product',
								component: (
									<Select>
										<Select.Option value="product">
											<Trans>Физички</Trans>
										</Select.Option>
										<Select.Option value="digital">
											<Trans>Дигитални</Trans>
										</Select.Option>
										<Select.Option value="service">
											<Trans>Услуга</Trans>
										</Select.Option>
									</Select>
								),
								xs: 24,
								sm: 4,
							},
							...(stores.company?.hasFeature('variants') ||
							record?.variants?.length > 0
								? [
										{
											key: 'hasVariants',
											label: (
												<>
													<Trans>Варијанте</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>
																Уколико желите да групишете различите варијанте
																артикла у један артикал, омогућите ову опцију.{' '}
																<br />
																<br />
																Као варијанте можете дефинисати на пример
																чоколаду од 80г и 300г.
															</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											initialValue: false,
											component: (
												<Select popupMatchSelectWidth={false}>
													<Select.Option value={false}>
														<Trans>Без варијанти</Trans>
													</Select.Option>
													<Select.Option value={true}>
														<Trans>Артикал има варијанте</Trans>
													</Select.Option>
												</Select>
											),
											rerenderOnChange: true,
											xs: 24,
											sm: 6,
										},
								  ]
								: []),
							{
								key: 'name',
								label: t`Назив`,
								rules: [
									{ required: true, message: t`Назив арткила је обавезан` },
									{
										max: 255,
										message: t`Максимална дужина је 255 карактера`,
										type: 'string',
									},
								],
								component: <Input />,
								xs: 24,
								sm:
									stores.company?.hasFeature('variants') ||
									record?.variants?.length > 0
										? 14
										: 20,
							},
						],
					},
					...(form.getFieldValue('hasVariants')
						? [
								{
									key: 'variants',
									label: t`Варијанте`,
									fields: [
										...(form.getFieldValue('hasVariants')
											? [
													{
														key: 'variants',
														component: (
															<VariantsEdit
																productType={form.getFieldValue('productType')}
															/>
														),
														validateTrigger: 'onBlur',
														rules: [
															{
																validator: (_, value) => {
																	if (
																		value.find(
																			(variant) =>
																				typeof variant.variantName ===
																					'undefined' ||
																				variant.variantName === '' ||
																				((variant.ean || '') !== '' &&
																					((variant.ean || '').length < 8 ||
																						(variant.ean || '').length > 14))
																		)
																	) {
																		return Promise.reject();
																	}
																	return Promise.resolve();
																},
																message: t`Неисправан унос варијанти, проверите да ли на неком пољу постоји грешка`,
															},
														],
														span: 24,
													},
											  ]
											: []),
									],
								},
						  ]
						: [
								{
									key: 'novariants',
									fields: [
										{
											key: 'sku',
											rules: [
												{
													type: 'number',
													validator: async (rule, value) => {
														if (value > 9999999) {
															throw new Error(t`Максимална шифра је 9999999`);
														}
													},
													message: t`Максимална шифра је 9999999`,
												},
											],
											label: (
												<>
													<Trans>Шифра</Trans>&nbsp;
													<Tooltip
														title={
															<Trans>
																Шифру можете користити да откуцате артикал
																уколико он нема бар код
																<br />
																<br />
																Уколико ово поље оставите празно, артиклу ће се
																доделити прва слободна шифра.
															</Trans>
														}
													>
														<i className="fi fi-rr-info"></i>
													</Tooltip>
												</>
											),
											component: <Input type="number" />,
											xs: 8,
											sm: 4,
											md: 3,
										},
										...(form.getFieldValue('productType') === 'product'
											? [
													{
														key: 'manufacturerSku',
														label: t`Шифра произвођача`,
														component: <Input />,
														responsive: ['sm'],
														xs: 16,
														sm: 8,
														md: 6,
													},
											  ]
											: []),
										...(form.getFieldValue('productType') === 'product'
											? [
													{
														key: 'ean',
														label: t`Бар код (ГТИН)`,
														rules: [
															{
																type: 'string',
																validator: async (rule, value) => {
																	if (
																		typeof value === 'undefined' ||
																		value === null ||
																		value === ''
																	) {
																		return;
																	}
																	if (value.length < 8 || value.length > 14) {
																		throw new Error(
																			t`ГТИН не може бити краћи од 8 и дужи од 14 карактера`
																		);
																	}
																},
															},
														],
														component: <BarcodeInput type="number" />,
														xs: 12,
														sm: 12,
														md: 5,
													},
											  ]
											: []),
										...(stores.company.allowCurrencyConversion
											? [
													{
														key: 'currencyId',
														label: t`Валута`,
														hidden: true,
														component: <CurrencySelect onlyPreferred />,
														rerenderOnChange: true,
													},
											  ]
											: []),
										{
											key: 'price',
											label: (
												<Trans>
													Продајна цена &nbsp;
													{form.getFieldValue('productType') === 'product' &&
													typeof record?.prices?.[
														stores.stores.currentStore?.warehouseId
													] !== 'undefined' ? (
														<Tooltip
															title={t`Уколико је за тренутно продајно место
																		укључено праћење залиха, цену је могуће
																		променити само израдом калкулација и
																		нивелација.`}
														>
															<i className="fi fi-rr-info"></i>
														</Tooltip>
													) : null}
												</Trans>
											),
											component:
												form.getFieldValue('productType') === 'product' &&
												typeof record?.prices?.[
													stores.stores.currentStore?.warehouseId
												] !== 'undefined' ? (
													<div>
														<Input
															disabled
															addonAfter="RSD"
															value={record?.currentStorePrice}
														/>
													</div>
												) : (
													<InputNumber
														type="number"
														addonAfter={
															stores.company.allowCurrencyConversion ? (
																<CurrencySelect
																	style={{ width: 75 }}
																	onlyPreferred
																	value={
																		form.getFieldValue('currencyId') || 'RSD'
																	}
																	optionLabelProp="value"
																	onChange={(value) => {
																		form.setFieldsValue({ currencyId: value });
																		setFields([{ name: ['currencyId'] }]);
																	}}
																/>
															) : (
																'RSD'
															)
														}
														step={0.01}
													/>
												),
											xs: 12,
											md: 5,
											sm: 12,
										},
										{
											key: 'taxRateLabel',
											label: t`Пореска ознака`,
											initialValue: stores.company?.vat
												? stores.company?.defaultTaxRateLabel
													? stores.company?.defaultTaxRateLabel
													: null
												: stores.taxRates.noVat?.[0]?.label,
											rules: [
												{
													required: true,
													message: t`Пореска ознака је обавезна`,
												},
											],
											component: <TaxRateSelect />,
											xs: 24,
											md: 5,
											sm: 12,
										},
									],
								},
						  ]),
					{
						key: 'additional',
						label: t`Додатне информације`,
						fields: [
							{
								key: 'categories',
								label: t`Категорије`,
								component: (
									<CategorySelect
										placeholder={t`Изаберите категорије`}
										treeCheckable
										showCheckedStrategy="SHOW_CHILD"
										treeCheckStrictly
										showCreateButton
									/>
								),
								span: 24,
							},
							{
								key: 'subtitle',
								label: t`Кратак опис`,
								component: <Input />,
								rules: [
									{
										max: 255,
										message: t`Максимална дужина је 255 карактера`,
										type: 'string',
									},
								],
								xs: 24,
								sm: 24,
							},
							{
								key: 'description',
								label: t`Детаљан опис`,
								component: <Editor />,
								span: 24,
							},
							// {
							// 	key: 'active',
							// 	label: '',
							// 	component: <Checkbox>Proizvod je aktivan</Checkbox>,
							// 	valuePropName: 'checked',
							// 	span: 24,
							// },
						],
					},
					{
						key: 'images',
						label: t`Фотографије`,
						fields: [
							{
								key: 'images',
								component: <PhotosEdit />,
								span: 24,
								rerenderOnChange: true,
							},
						],
					},

					...(!form.getFieldValue('hasVariants')
						? [
								...(stores.saleChannels.all.length > 0
									? [
											{
												key: 'saleChannels',
												label: t`Додатни канали продаје`,
												fields: [
													{
														key: 'saleChannels',
														component: (
															<SaleChannelsInput
																mode="edit"
																price={
																	form.getFieldValue('productType') ===
																		'product' &&
																	typeof record?.prices?.[
																		stores.stores.currentStore?.warehouseId
																	] !== 'undefined'
																		? record?.currentStorePrice
																		: form.getFieldValue('price')
																}
															/>
														),
														span: 24,
													},
												],
											},
									  ]
									: []),
								// {
								// 	key: 'inventory',
								// 	label: t`Инвентар`,
								// 	fields: [
								// 		// ...(form.getFieldValue('productType') === 'product'
								// 		// 	? [
								// 		// 			{
								// 		// 				key: 'trackStocks',
								// 		// 				component: <Checkbox>Prati zalihe robe</Checkbox>,
								// 		// 				valuePropName: 'checked',
								// 		// 				rerenderOnChange: true,
								// 		// 				span: 24,
								// 		// 			},
								// 		// 	  ]
								// 		// 	: []),
								// 		...(form.getFieldValue('trackStocks')
								// 			? [
								// 					{
								// 						key: 'variants',
								// 						component: <QuantitiesEdit />,
								// 						span: 24,
								// 					},
								// 			  ]
								// 			: []),
								// 		...(form.getFieldValue('trackStocks')
								// 			? [
								// 					{
								// 						key: 'trackStocks',
								// 						component: (
								// 							<Checkbox>
								// 								<Trans>
								// 									Дозволи продају уколико артикал није на стању
								// 								</Trans>
								// 							</Checkbox>
								// 						),
								// 						rerenderOnChange: true,
								// 						span: 24,
								// 					},
								// 			  ]
								// 			: []),
								// 	],
								// },
						  ]
						: []),

					...(!form.getFieldValue('hasVariants')
						? [
								{
									key: 'measurements',
									label: t`Мере`,
									fields: [
										{
											key: 'baseUnitId',
											component: <UnitSelect />,
											rerenderOnChange: true,
											hidden: true,
										},
										...(form.getFieldValue('productType') === 'product'
											? [
													{
														key: 'quantityPerBaseUnit',
														label: (
															<>
																<Trans>Количина у ЈМ</Trans>&nbsp;
																<Tooltip
																	title={
																		<Trans>
																			Јединица мере у којој је исказана количина
																			на амбалажи, на пример: уколико је у
																			питању чоколада која има 100г, онда је
																			јединица мере килограм, а количина је 0.1
																		</Trans>
																	}
																>
																	<i className="fi fi-rr-info"></i>
																</Tooltip>
															</>
														),
														component: (
															<InputNumber
																controls={false}
																type="number"
																addonAfter={
																	<>
																		<UnitSelect
																			value={form.getFieldValue('baseUnitId')}
																			onChange={(value) => {
																				form.setFieldsValue({
																					baseUnitId: value,
																				});
																				setFields([{ name: ['baseUnitId'] }]);
																			}}
																			placeholder={t`ЈМ`}
																		/>
																	</>
																}
															/>
														),
														xs: 12,
														md: 6,
													},
													{
														key: 'saleUnitId',
														label: t`Продајна ЈМ`,
														component: <UnitSelect />,
														xs: 12,
														md: 6,
													},
											  ]
											: []),
										...(form.getFieldValue('productType') === 'product'
											? [
													{
														key: 'weight',
														label: t`Бруто маса`,
														component: (
															<InputNumber
																controls={false}
																type="number"
																addonAfter={t`кг`}
															/>
														),
														xs: 6,
														md: 4,
													},
													{
														key: 'dimensions',
														label: t`Димензије`,
														component: <DimensionsInput />,
														xs: 18,
														md: 8,
													},
											  ]
											: []),
										...(stores.devices.scales.length > 0 &&
										form.getFieldValue('productType') === 'product'
											? [
													{
														key: 'quantityFromScale',
														valuePropName: 'checked',
														component: (
															<Checkbox>
																<Trans>Користи тежину са ваге</Trans>&nbsp;
																<Tooltip
																	title={
																		<Trans>
																			Уколико је вага конфигурисана, приликом
																			додавања артикла на рачун, као количина ће
																			се користити тежина са ваге
																		</Trans>
																	}
																>
																	<i className="fi fi-rr-info"></i>
																</Tooltip>
															</Checkbox>
														),
														span: 24,
													},
											  ]
											: []),
									],
								},
						  ]
						: []),

					...(!form.getFieldValue('hasVariants')
						? [
								{
									key: 'receipt',
									label: t`Фискални рачун`,
									fields: [
										{
											key: 'multiplePerReceipt',
											valuePropName: 'checked',
											component: (
												<Checkbox>
													<Trans>Дозволи више пута на једном рачуну</Trans>
												</Checkbox>
											),
											span: 24,
										},
									],
								},
						  ]
						: []),
				];
			},
		},
		page: {
			docsLink: 'koriscenje/moduli-aplikacije/katalog/artikli/',
			empty: {
				image: 'delivery',
				text: t`Нема артикала`,
			},
			shouldFetch: false,
			searchable: true,
			searchPlaceholder: t`Претражи артикле`,
			searchFilter:
				stores.company.productListType === 'parents'
					? undefined
					: (values) => {
							return values.reduce((acc, value) => {
								if (value.hasVariants) {
									return [
										...acc,
										...value.variants.map((variant) => ({
											...variant,
											parent: value,
										})),
									];
								} else {
									return [...acc, value];
								}
							}, []);
					  },
			table: {
				props: {
					scroll: { x: 1400 },
				},
				getRecordId: (record) => record.parent?.id || record.id,
				listProp:
					stores.company.productListType === 'parents'
						? 'sorted'
						: 'sortedVariants',
				columns: () => {
					return [
						{
							title: '',
							dataIndex: 'coverImage',
							key: 'coverImage',
							width: 50,
							render: (_, record) => {
								const image = record.parent?.coverImage || record.coverImage;
								return image ? (
									<>
										<img
											src={image.urls['64x64']}
											alt=""
											className={styles.image}
										/>
									</>
								) : (
									<NoImage
										size="small"
										name={
											record.parent
												? `${record.parent.name} ${record.variantName}`
												: record.name
										}
									/>
								);
							},
							shouldLink: true,
						},
						...(stores.company.productListType === 'variants'
							? [
									{
										title: t`Шифра`,
										dataIndex: 'sku',
										key: 'sku',
										width: 100,
										shouldLink: true,
									},
							  ]
							: []),

						{
							title: t`Назив`,
							dataIndex: 'name',
							key: 'name',
							shouldLink: true,
							ellipsis: true,
							render(text, record) {
								return !record.parent ? (
									text
								) : (
									<>
										{record.parent?.name}&nbsp;&nbsp;
										<Tag bordered={false} color="magenta">
											{record.variantName}
										</Tag>
									</>
								);
							},
						},
						...(stores.saleChannels.available.length > 0 &&
						stores.company.productListType === 'variants'
							? [
									{
										title: t`Канали продаје`,
										key: 'productSaleChannels',
										width: Math.max(
											130,
											20 * stores.saleChannels.available.length + 16
										),
										render: (record) => {
											return (
												<SaleChannelsCell
													productSaleChannels={record.saleChannels}
												/>
											);
										},
									},
							  ]
							: []),
						{
							title: t`Продајна цена`,
							dataIndex: 'price',
							key: 'price',
							width: 170,
							render: (value, record) => {
								if (record.variants.length === 0) {
									return numberFormat(
										record.currentStorePrice || 0,
										true,
										2,
										true,
										record.currencyId || 'RSD'
									);
								}
								if (record.variants.length === 1) {
									return numberFormat(
										record.variants[0].currentStorePrice,
										true,
										2,
										true,
										record.currencyId || 'RSD'
									);
								}

								return `${numberFormat(
									record.minPricedVariant?.price,
									(record.minPricedVariant?.currencyId || 'RSD') !==
										(record.maxPricedVariant?.currencyId || 'RSD'),
									2,
									true,
									record.minPricedVariant?.currencyId || 'RSD'
								)} - ${numberFormat(
									record.maxPricedVariant?.currentStorePrice,
									true,
									2,
									true,
									record.maxPricedVariant?.currencyId || 'RSD'
								)}`;
							},
							responsive: ['md'],
						},
						...(stores.company.hasFeature('stock')
							? [
									{
										title: t`Добављачи`,
										key: 'partners',
										dataIndex: 'partners',
										filters: () =>
											stores.partners.list?.map((partner: Partner) => ({
												text: partner.name,
												value: partner.id,
											})),
										filterMultiple: true,
										ellipsis: true,
										filterSearch: true,
										onFilter: (value: string, record) => {
											return (record.partners || [])
												.map((c) => c.id)
												.includes(value);
										},
										render: (text, record) => {
											return <PartnersView record={record} />;
										},
									},
							  ]
							: []),
						{
							title: t`Категорије`,
							dataIndex: 'categories',
							key: 'categories',
							responsive: ['md'],
							filters: () => {
								return stores.categories.tree;
							},
							// filterMultiple: true,
							filterMode: 'tree',
							onFilter: (value: string, record) =>
								record.categories.map((c) => c.id).includes(value),
							render: (text, record) => {
								return <CategoriesView record={record.parent || record} />;
							},
						},
						...((stores.products.hasVariants ||
							stores.company.hasFeature('variants')) &&
						stores.company.productListType === 'parents'
							? [
									{
										title: t`Варијанте`,
										dataIndex: 'variants',
										key: 'variants',
										responsive: ['md'],
										ellipsis: true,
										render: (text, record) => {
											if (!record.hasVariants) {
												return (
													<Tag bordered={false} color="orange">
														<Trans>Нема варијанти</Trans>
													</Tag>
												);
											}
											return record.variants?.map((variant) => (
												<Tag bordered={false} color="magenta">
													{variant.variantName}
												</Tag>
											));
										},
									},
							  ]
							: []),
					];
				},
			},
			deletePrompt: t`Да ли сте сигурни да желите да обришете овај артикал?`,
			customCreateButton: (createHandler) => (
				<CustomCreateButton createButtonHandler={createHandler} />
			),
			createButtonText: t`Додај артикал`,
		},
		drawers: {
			'product-search': Search,
			'product-sales': Sales,
			'product-purchases': Purchases,
		},
	});
