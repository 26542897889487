import { t } from '@lingui/macro';

export const ERROR_NOT_FOUND_TIN = 40401501;
export const ERROR_CONFLICT_DUPLICATE_TIN = 40901001;
export const ERROR_CONFLICT_DUPLICATE_EMAIL = 40902001;
export const ERROR_BAD_REQUEST_SKU_ALREADY_USED = 40003001;
export const ERROR_CONFLICT_DUPLICATE_RECEIPT = 40900001;
export const ERROR_CONFLICT_DUPLICATE_INTERNAL_CODE = 40903001;
export const ERROR_CONFLICT_DUPLICATE_PARTNER_CODE = 40903002;
export const ERROR_BAD_REQUEST_PARTNER_MISSING_EMAIL = 40005002;
export const ERROR_BAD_REQUEST_PARTNER_MISSING_CODE = 40005001;
export const ERROR_BAD_REQUEST_INVOICE_EFAKTURA_ERROR = 40004005;
export const ERROR_BAD_REQUEST_INVALID_CREDENTIALS = 40090002;
export const ERROR_BAD_REQUEST_CANNOT_UNCONFIRM_STOCK_TURNOVER_USED = 40006002;
export const ERROR_UNPROCESSABLE_CONTENT_STOCK_CHECKUP_DIFFERENT_THAN_ACTUAL = 42200001;

export const getSdcValidationErrorsMap = () => ({
	'2310': t`Неисправна пореска ознака`,
	'2801': t`Вредност поља је предугачка`,
	'2802': t`Вредност поља је прекратка`,
	'2803': t`Дужина вредности поља није исправна`,
	'2804': t`Вредност поља је ван очекиваног опсега`,
	'2805': t`Поље садржи неисправну вредност`,
	'2806': t`Формат датума није исправан`,
	'2807': t`Листа мора садржати најмање један елемент`,
	'2808': t`Листа садржи више елемената него што је дозвољено`,
});

export const getSdcFieldNamesMap = () => ({
	dateAndTimeOfIssue: `ЕСИР време`,
	invoiceType: `Врста рачуна`,
	transactionType: `Врста трансакције`,
	payment: `Плаћање`,
	'payment.amount': `износ плаћања`,
	'payment.paymentType': `начин плаћања`,
	cashier: `Касир`,
	buyerId: `ИД купца`,
	buyerCostCenterId: `Опционо поље купца`,
	invoiceNumber: `Број рачуна`,
	referentDocumentNumber: `Референтни број рачуна`,
	referentDocumentDT: `Датум референтног рачуна`,
	items: `Ставке рачуна`,
	'items.gtin': `ГТИН`,
	'items.name': `назив`,
	'items.quantity': `количина`,
	'items.labels': `пореске ознаке`,
	'items.unitPrice': `цена`,
	'items.totalAmount': `укупан износ`,
	options: `Опције`,
	'options.omitTextualRepresentation': `искључивање текстуалног приказа`,
	'options.omitQRCodeGen': `искључивање генерисања QР кода`,
});
