import { Trans, t } from '@lingui/macro';
import { Button, Col, Row, Space } from 'antd';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useInterval } from 'react-use';

import { StaticComponents } from '../../../../../../components/StaticComponents';
import {
	ERROR_BAD_REQUEST_CANNOT_UNCONFIRM_STOCK_TURNOVER_USED,
	ERROR_UNPROCESSABLE_CONTENT_STOCK_CHECKUP_DIFFERENT_THAN_ACTUAL,
} from '../../../../../../constants/errors';
import { StockCheckup } from '../../../../../../stores/StockCheckup.mobx';
import { StockTurnoverStatus } from '../../../../../../stores/StockTurnover.mobx';
import DifferenceItems from '../DifferenceItems';

type Props = {
	entity: StockCheckup;
	save: (closeDrawer: boolean) => Promise<StockCheckup>;
	close: () => void;
	isCreating: boolean;
	isLoading: boolean;
	disabled: boolean;
	canEditPermission: boolean;
};

function BaseEditButtons({
	entity,
	isLoading,
	isCreating,
	save,
	close,
	disabled,
	canEditPermission,
}: Props) {
	const handleConfirm = useCallback(async () => {
		await save(false);
		try {
			await flowResult(entity?.confirm());
			close();
		} catch (error) {
			if (
				error.response?.data?.errorCode ===
				ERROR_UNPROCESSABLE_CONTENT_STOCK_CHECKUP_DIFFERENT_THAN_ACTUAL
			) {
				StaticComponents.modal.confirm({
					width: 1200,
					title: t`Грешка при потврђивању пописа`,
					content: (
						<DifferenceItems
							warehouseId={entity.warehouseId}
							items={error.response?.data?.variables?.difference}
						/>
					),
					okType: 'danger',
					okText: t`Ипак потврди`,
					cancelText: t`Откажи`,
					onOk: async () => {
						await flowResult(entity?.confirm(true));
						close();
					},
				});
			}
		}
	}, [save, entity, close]);

	const handleSave = useCallback(async () => {
		if (!entity || entity?.status === StockTurnoverStatus.DRAFT) {
			try {
				await save(false);
			} catch (error) {
				//
			}
		}
	}, [entity, save]);

	useInterval(handleSave, 60000);

	return (
		<Row>
			<Col flex="auto">
				<Space>
					<Button
						key="cancel"
						onClick={close}
						disabled={
							isLoading ||
							isCreating ||
							entity?.isUpdating ||
							entity?.isConfirming
						}
					>
						<Trans>Одустани</Trans>
					</Button>
				</Space>
			</Col>
			<Col flex="none">
				<Space>
					{entity?.status === StockTurnoverStatus.DRAFT && (
						<Button
							disabled={
								isLoading ||
								isCreating ||
								entity?.isUpdating ||
								entity?.isConfirming
							}
							loading={entity?.isConfirming}
							onClick={handleConfirm}
						>
							<Trans>Потврди</Trans>
						</Button>
					)}
					<Button
						key="save"
						type="primary"
						loading={isCreating || entity?.isUpdating}
						onClick={handleSave}
						disabled={
							isLoading ||
							disabled ||
							isCreating ||
							entity?.isUpdating ||
							!canEditPermission
						}
					>
						<Trans>Сачувај нацрт</Trans>
					</Button>
				</Space>
			</Col>
		</Row>
	);
}

export const EditButtons = observer(BaseEditButtons);
