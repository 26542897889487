import { t, Trans } from '@lingui/macro';
import { TreeSelect, Button, ConfigProvider, Empty } from 'antd';
import { observer } from 'mobx-react-lite';

import { Category } from '../../stores/Category.mobx';
import stores from '../../stores/index.mobx';
import { useDrawer } from '../Page';
import globalStyles from '../../assets/styles/global.module.less';
interface Props extends Omit<React.ComponentProps<typeof TreeSelect>, 'value'> {
	showRoot?: boolean;
	treeCheckable?: boolean;
	value?: Category[] | void;
	showCreateButton?: boolean;
}

function CategorySelect({
	onChange,
	value,
	showRoot,
	treeCheckable,
	showCreateButton = false,
	...rest
}: Props) {
	const {
		categories: { isFetching, tree },
	} = stores;

	const [, openCreateCategory, , , , CreateCategoryDrawer] = useDrawer(
		'create/catalog/categories|category-select',
		(event, entity: any) => {
			if (event === 'entity-create') {
				onChange([...(value || []), entity.id], [], null);
			}
		}
	);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/folder.svg" />}
						imageStyle={{ height: 64 }}
						description={t`Нема категорија`}
					></Empty>
				)}
			>
				<TreeSelect
					loading={isFetching}
					treeLine
					treeDefaultExpandAll
					treeCheckable={treeCheckable}
					popupMatchSelectWidth={false}
					treeData={
						showRoot
							? [
									{
										title: t`Корен`,
										value: 'root',
										children: tree,
									},
							  ]
							: tree
					}
					onChange={onChange}
					value={value}
					dropdownRender={
						showCreateButton && tree.length > 0
							? (menu) => (
									<>
										{menu}
										<Button
											type="link"
											className={globalStyles.dropdownCreateButton}
											onClick={() => openCreateCategory()}
										>
											<Trans>Додај нову категорију</Trans>
										</Button>
									</>
							  )
							: null
					}
					{...rest}
				/>
			</ConfigProvider>
			{showCreateButton && <CreateCategoryDrawer location="category-select" />}
		</>
	);
}

export default observer(CategorySelect);
