import { observer } from 'mobx-react-lite';
import { Customer, LocalSale } from '../../../../stores/LocalSale.mobx';
import { Alert, Button, Col, Row } from 'antd';
import styles from '../POS.module.less';
import { Plural, Trans } from '@lingui/macro';
import stores from '../../../../stores/index.mobx';
import { useCallback } from 'react';
import { bignumber, evaluate } from 'mathjs';
import { round } from 'lodash';
import numberFormat from '../../../../lib/numberFormat';
interface Props {
	customer: Customer;
	setUseStickersModalVisible: (visible: boolean) => void;
	setUsePointsModalVisible: (visible: boolean) => void;
	sale: LocalSale;
}

const MojBadiInfoBase = (props: Props, ref) => {
	const {
		customer,
		setUseStickersModalVisible,
		setUsePointsModalVisible,
		sale,
	} = props;
	const {
		mojBadi: {
			settings: { loyaltyType, loyaltyConfiguration },
		},
	} = stores;

	const showModal = useCallback(() => {
		setUseStickersModalVisible(loyaltyType === 'stickers');
		setUsePointsModalVisible(loyaltyType === 'points');
	}, [setUseStickersModalVisible, setUsePointsModalVisible, loyaltyType]);

	if (!customer) {
		return null;
	}

	const pointsAmount = round(
		evaluate('points * pointWorth', {
			points: bignumber(customer.loyaltyUsageIntent?.quantity || 0),
			pointWorth: bignumber(loyaltyConfiguration.pointWorth || 0),
		}),
		2
	);

	const pointsWarning = sale.pointsDiscountAmount !== pointsAmount && (
		<>
			{'. '}
			<Trans>
				Вредност поена је већа од максималног износа који је могуће умањити,
				рачун ће бити умањен за{' '}
				<strong>
					{numberFormat(sale.pointsDiscountAmount, true, 2, true, 'RSD')}
				</strong>
			</Trans>
		</>
	);

	return (
		<div ref={ref}>
			<Alert
				className={styles.mojBadiInfo}
				message={
					<Row gutter={8} wrap={false}>
						<Col>
							<i className="fi fi-rr-gift" />
						</Col>
						<Col flex={1}>
							{loyaltyType === 'stickers' && (
								<>
									<Plural
										value={customer.totalStickers}
										zero={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, нема
												сакупљених налепница.
											</span>
										}
										one={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, има
												сакупљену <strong>{customer.totalStickers}</strong>{' '}
												налепницу.
											</span>
										}
										two={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, има
												сакупљене <strong>{customer.totalStickers}</strong>{' '}
												налепнице.
											</span>
										}
										other={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, има
												сакупљених <strong>{customer.totalStickers}</strong>{' '}
												налепница.
											</span>
										}
									/>{' '}
									{customer.loyaltyUsageIntent && (
										<Plural
											value={customer.loyaltyUsageIntent?.quantity}
											zero={
												<span>
													Жели да искористи{' '}
													<strong>
														{customer.loyaltyUsageIntent?.quantity}
													</strong>{' '}
													налепница за{' '}
													<strong>
														{
															loyaltyConfiguration.stickerRules.find(
																(sticker) =>
																	sticker.stickerCount ===
																	customer.loyaltyUsageIntent?.quantity
															)?.discount
														}
														%
													</strong>{' '}
													попуста (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
											one={
												<span>
													Жели да искористи{' '}
													<strong>
														{customer.loyaltyUsageIntent?.quantity}
													</strong>{' '}
													налепницу за{' '}
													<strong>
														{
															loyaltyConfiguration.stickerRules.find(
																(sticker) =>
																	sticker.stickerCount ===
																	customer.loyaltyUsageIntent?.quantity
															)?.discount
														}
														%
													</strong>{' '}
													попуста (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
											two={
												<span>
													Жели да искористи{' '}
													<strong>
														{customer.loyaltyUsageIntent?.quantity}
													</strong>{' '}
													налепнице за{' '}
													<strong>
														{
															loyaltyConfiguration.stickerRules.find(
																(sticker) =>
																	sticker.stickerCount ===
																	customer.loyaltyUsageIntent?.quantity
															)?.discount
														}
														%
													</strong>{' '}
													попуста (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
											other={
												<span>
													Жели да искористи{' '}
													<strong>
														{customer.loyaltyUsageIntent?.quantity}
													</strong>{' '}
													налепница за{' '}
													<strong>
														{
															loyaltyConfiguration.stickerRules.find(
																(sticker) =>
																	sticker.stickerCount ===
																	customer.loyaltyUsageIntent?.quantity
															)?.discount
														}
														%
													</strong>{' '}
													попуста (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
										/>
									)}
									{!customer.loyaltyUsageIntent && (
										<Trans>
											Не жели да искористи налепнице за попуст (
											<Button
												type="link"
												size="small"
												className={styles.useLink}
												onClick={showModal}
											>
												промени избор
											</Button>
											).
										</Trans>
									)}
								</>
							)}
							{loyaltyType === 'points' && (
								<>
									<Plural
										value={customer.totalPoints}
										zero={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, нема
												сакупљених поена.
											</span>
										}
										one={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, има
												сакупљен{' '}
												<strong>
													{numberFormat(customer.totalPoints, false, 0, false)}
												</strong>{' '}
												поен.
											</span>
										}
										two={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, има
												сакупљена{' '}
												<strong>
													{numberFormat(customer.totalPoints, false, 0, false)}
												</strong>{' '}
												поена.
											</span>
										}
										other={
											<span>
												Купац <strong>{customer.fullNameShort}</strong>, има
												сакупљених{' '}
												<strong>
													{numberFormat(customer.totalPoints, false, 0, false)}
												</strong>{' '}
												поена.
											</span>
										}
									/>{' '}
									{customer.loyaltyUsageIntent && (
										<Plural
											value={customer.loyaltyUsageIntent?.quantity}
											zero={
												<span>
													Жели да искористи{' '}
													<strong>
														{numberFormat(
															customer.loyaltyUsageIntent?.quantity,
															false,
															0,
															false
														)}
													</strong>{' '}
													поена и умањи рачун за{' '}
													<strong>
														{numberFormat(
															round(
																evaluate('quantity * pointWorth', {
																	quantity: bignumber(
																		customer.loyaltyUsageIntent?.quantity
																	),
																	pointWorth: bignumber(
																		loyaltyConfiguration.pointWorth
																	),
																}).toNumber(),
																2
															),
															true,
															2,
															true,
															'RSD'
														)}
													</strong>
													{pointsWarning} (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
											one={
												<span>
													Жели да искористи{' '}
													<strong>
														{numberFormat(
															customer.loyaltyUsageIntent?.quantity,
															false,
															0,
															false
														)}
													</strong>{' '}
													поен и умањи рачун за{' '}
													<strong>
														{numberFormat(
															round(
																evaluate('quantity * pointWorth', {
																	quantity: bignumber(
																		customer.loyaltyUsageIntent?.quantity
																	),
																	pointWorth: bignumber(
																		loyaltyConfiguration.pointWorth
																	),
																}).toNumber(),
																2
															),
															true,
															2,
															true,
															'RSD'
														)}
													</strong>{' '}
													RSD{pointsWarning} (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
											two={
												<span>
													Жели да искористи{' '}
													<strong>
														{numberFormat(
															customer.loyaltyUsageIntent?.quantity,
															false,
															0,
															false
														)}
													</strong>{' '}
													поена и умањи рачун за{' '}
													<strong>
														{numberFormat(
															round(
																evaluate('quantity * pointWorth', {
																	quantity: bignumber(
																		customer.loyaltyUsageIntent?.quantity
																	),
																	pointWorth: bignumber(
																		loyaltyConfiguration.pointWorth
																	),
																}).toNumber(),
																2
															),
															true,
															2,
															true,
															'RSD'
														)}
													</strong>{' '}
													RSD{pointsWarning} (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
											other={
												<span>
													Жели да искористи{' '}
													<strong>
														{numberFormat(
															customer.loyaltyUsageIntent?.quantity,
															false,
															0,
															false
														)}
													</strong>{' '}
													поена и умањи рачун за{' '}
													<strong>
														{numberFormat(
															round(
																evaluate('quantity * pointWorth', {
																	quantity: bignumber(
																		customer.loyaltyUsageIntent?.quantity
																	),
																	pointWorth: bignumber(
																		loyaltyConfiguration.pointWorth
																	),
																}).toNumber(),
																2
															),
															true,
															2,
															true,
															'RSD'
														)}
													</strong>{' '}
													RSD{pointsWarning} (
													<Button
														type="link"
														size="small"
														className={styles.useLink}
														onClick={showModal}
													>
														промени избор
													</Button>
													).
												</span>
											}
										/>
									)}
									{!customer.loyaltyUsageIntent && (
										<Trans>
											Не жели да искористи поене за умањење рачуна (
											<Button
												type="link"
												size="small"
												className={styles.useLink}
												onClick={showModal}
											>
												промени избор
											</Button>
											).
										</Trans>
									)}
								</>
							)}
							{loyaltyType === 'tiers' && (
								<>
									{customer.currentTier && (
										<Trans>
											Купац <strong>{customer.fullNameShort}</strong>, припада
											нивоу чланства{' '}
											<strong>{customer.currentTier.tierName}</strong>, чиме
											остварује{' '}
											<strong>{customer.currentTier.discount}%</strong> попуста.
										</Trans>
									)}
									{!customer.currentTier && (
										<Trans>
											Купац <strong>{customer.fullNameShort}</strong>, још увек
											не припада ни једном нивоу чланства.
										</Trans>
									)}
								</>
							)}
						</Col>
					</Row>
				}
				type="success"
			/>
		</div>
	);
};

export const MojBadiInfo = observer(MojBadiInfoBase, { forwardRef: true });
