import { Trans, t } from '@lingui/macro';

import { CreatePage } from '../../../../../components/Page';
import { Button, Col, List, Row, Table, Tooltip, Typography } from 'antd';
import styles from './Companies.module.less';
import StoresEdit from '../../../../../components/Inputs/StoresEdit';
import { MojBadiAdministrationCompany } from '../../../../../stores/MojBadiAdministrationCompany';
import { Observer } from 'mobx-react-lite';
import { Footer } from './Components/Footer';

export default () =>
	CreatePage({
		module: 'administration',
		submodule: 'mojBadiCompanies',
		model: 'mojBadiAdministrationCompanies',
		view: {
			useEdit: false,
			title: t`Преглед продавнице`,
			shouldFetch: false,
			footer: (entity: MojBadiAdministrationCompany) => {
				return <Footer entity={entity} />;
			},
			fields: (record) => [
				{
					label: t`Основне информације`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							dataIndex: 'name',
						},
						{
							key: 'description',
							label: t`Опис`,
							dataIndex: 'description',
						},
						{
							key: 'coverImageFile',
							label: t`Насловна фотографија`,
							dataIndex: 'coverImageFile',
							render: (coverImageFile) => {
								if (!coverImageFile) return null;
								return (
									<div className={styles.imageContainer}>
										<img
											src={coverImageFile?.urls?.['1440x960']}
											alt={coverImageFile.filename}
											style={{ maxWidth: '100%', display: 'block' }}
										/>
										<div className={styles.imageOverlay}></div>
									</div>
								);
							},
						},
						{
							key: 'profileImageFile',
							label: t`Профилна фотографија`,
							dataIndex: 'profileImageFile',
							render: (profileImageFile) => {
								if (!profileImageFile) return null;
								return (
									<img
										src={profileImageFile?.urls?.['1024x1024']}
										alt={profileImageFile.filename}
										style={{ maxWidth: 256 }}
									/>
								);
							},
						},
					],
				},
				{
					label: t`Продајна места`,
					fields: [
						{
							key: 'stores',
							dataIndex: 'stores',
							span: 24,
							render(value, record) {
								return (
									<List
										dataSource={record.stores}
										renderItem={(store) => (
											<List.Item key={store.id}>
												<List.Item.Meta
													title={store.displayName}
													description={store.fullAddress}
												/>
											</List.Item>
										)}
									/>
								);
							},
						},
					],
				},
				{
					label: t`Програм лојалности`,
					span: 24,
					fields: [
						{
							key: 'loyaltyType',
							span: 24,
							label: t`Врста програма лојалности`,
							dataIndex: 'loyaltyType',
							render: (loyaltyType) => {
								if (loyaltyType === 'none') {
									return <Trans>Без програма лојалности</Trans>;
								}

								if (loyaltyType === 'points') {
									return <Trans>Сакупљање поена</Trans>;
								}

								if (loyaltyType === 'stickers') {
									return <Trans>Сакупљање налепница</Trans>;
								}

								if (loyaltyType === 'tiers') {
									return <Trans>Нивои чланства</Trans>;
								}
							},
						},
						...(record.loyaltyType === 'points'
							? [
									{
										key: 'purchaseAmount',
										dataIndex: ['loyaltyConfiguration', 'purchaseAmount'],
										span: 12,
										label: t`На потрошених`,
										render(value) {
											return `${value} RSD`;
										},
									},
									{
										key: 'rewardPoints',
										dataIndex: ['loyaltyConfiguration', 'rewardPoints'],
										span: 12,
										label: t`Купац добија`,
										render(value) {
											return t`${value} поена`;
										},
									},
									{
										key: 'maxPointsPerPurchase',
										dataIndex: ['loyaltyConfiguration', 'maxPointsPerPurchase'],
										span: 24,
										label: t`Максималан утрошак поена`,
										render(value) {
											return t`${value}% вредности рачуна`;
										},
									},
							  ]
							: []),
						...(record.loyaltyType === 'stickers'
							? [
									{
										key: 'stickerAwardMode',
										dataIndex: ['loyaltyConfiguration', 'stickerAwardMode'],
										span: 12,
										label: t`Начин добијања налепница`,
										render(value) {
											if (value === 'once') {
												return (
													<Trans>
														Једна налепница по куповини након достигнутог износа
													</Trans>
												);
											}
											if (value === 'every') {
												return (
													<Trans>
														По једна налепница за сваки достигнути износ
													</Trans>
												);
											}
										},
									},
									{
										key: 'purchaseAmount',
										dataIndex: ['loyaltyConfiguration', 'purchaseAmount'],
										span: 12,
										label:
											record?.stickerAwardMode === 'once'
												? t`Купац добија налепницу након потрошених`
												: t`Купац добија налепницу на сваких потрошених`,
										render(value) {
											return `${value} RSD`;
										},
									},
									{
										key: 'sticker',
										dataIndex: ['loyaltyConfiguration', 'sticker'],
										span: 24,
										label: t`Налепница`,
										render(value) {
											return (
												<img
													src={`/images/icons/loyalty/${value}.png`}
													width={32}
													height={32}
												/>
											);
										},
									},
									{
										key: 'stickerRules',
										dataIndex: ['loyaltyConfiguration', 'stickerRules'],
										span: 24,
										label: t`Правила за коришћење налепница`,
										render(value) {
											return (
												<Table
													size="small"
													bordered
													pagination={false}
													dataSource={value}
													columns={[
														{
															title: t`Број налепница`,
															dataIndex: 'stickerCount',
															key: 'stickerCount',
														},
														{
															title: t`Попуст`,
															dataIndex: 'discount',
															key: 'discount',
															render: (discount) => `${discount}%`,
														},
													]}
												/>
											);
										},
									},
							  ]
							: []),
						...(record.loyaltyType === 'tiers'
							? [
									{
										key: 'tiersRules',
										dataIndex: ['loyaltyConfiguration', 'tiersRules'],
										span: 24,
										label: t`Нивои чланства`,
										render(value) {
											return (
												<Table
													size="small"
													bordered
													pagination={false}
													dataSource={value}
													columns={[
														{
															title: t`Назив нивоа`,
															dataIndex: 'tierName',
															key: 'tierName',
														},
														{
															title: t`Укупан потрошени износ`,
															dataIndex: 'totalSpentAmount',
															key: 'totalSpentAmount',
															render: (value) => `${value} RSD`,
														},
														{
															title: t`Попуст`,
															dataIndex: 'discount',
															key: 'discount',
															render: (discount) => `${discount}%`,
														},
													]}
												/>
											);
										},
									},
							  ]
							: []),
						{
							key: 'expiry',
							dataIndex: ['loyaltyConfiguration', 'expiry'],
							span: 24,
							label: t`Рок важења`,
							render(value) {
								if (value === 'never') {
									return <Trans>Неограничено</Trans>;
								}
								if (value === 'month') {
									return <Trans>1 месец</Trans>;
								}
								if (value === 'quarter') {
									return <Trans>3 месеца</Trans>;
								}
								if (value === 'halfyear') {
									return <Trans>6 месеци</Trans>;
								}
								if (value === 'year') {
									return <Trans>1 година</Trans>;
								}
								if (value === 'custom') {
									return <Trans>Прилагођено</Trans>;
								}
							},
						},
						...(record.loyaltyConfiguration?.expiry === 'custom'
							? [
									{
										key: 'expiryCustom',
										dataIndex: ['loyaltyConfiguration', 'expiryCustom'],
										span: 24,
										label: t`Прилагођен рок важења поена`,
										render(value, record) {
											if (
												record.loyaltyConfiguration?.expiryCustomUnit === 'days'
											) {
												return t`${value} дана`;
											}
											if (
												record.loyaltyConfiguration?.expiryCustomUnit ===
												'months'
											) {
												return t`${value} месеци`;
											}
											if (
												record.loyaltyConfiguration?.expiryCustomUnit ===
												'years'
											) {
												return t`${value} година`;
											}
										},
									},
							  ]
							: []),
					],
				},
			],
		},
		page: {
			// docsLink: 'koriscenje/moduli-aplikacije/podesavanja/korisnici/',
			shouldFetch: true,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: t`Опис`,
						dataIndex: 'description',
						key: 'description',
						ellipsis: true,
					},
					{
						title: t`Статус`,
						dataIndex: 'status',
						key: 'status',
						shouldLink: true,
						ellipsis: true,
						width: 100,
						render: (status, record) => {
							if (record.enabled === false) {
								return (
									<Typography.Text type="secondary">
										<i className="fi fi-rr-pause-circle"></i>{' '}
										<Trans>Онемогућен</Trans>
									</Typography.Text>
								);
							}
							if (status === 'active') {
								return (
									<Typography.Text type="success">
										<i className="fi fi-rr-check-circle"></i>{' '}
										<Trans>Активан</Trans>
									</Typography.Text>
								);
							}

							if (status === 'pending') {
								return (
									<Typography.Text type="secondary">
										<i className="fi fi-rr-pause-circle"></i>{' '}
										<Trans>На чекању</Trans>
									</Typography.Text>
								);
							}

							if (status === 'rejected') {
								return (
									<Tooltip title={record.rejectedReason}>
										<Typography.Text type="danger">
											<i className="fi fi-rr-cross-circle"></i>{' '}
											<Trans>Одбијен</Trans>
										</Typography.Text>
									</Tooltip>
								);
							}
						},
					},
				],
				showDisabledActions: false,
			},
		},
	});
