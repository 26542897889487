import { t, Trans } from '@lingui/macro';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { flowResult } from 'mobx';
import { useCallback } from 'react';

import { useDrawer } from '../../../../../../components/Page';
import { usePermissions } from '../../../../../../hooks/permissions';
import stores from '../../../../../../stores/index.mobx';

export default function PricechangeCreateButton() {
	const {
		conversions: { create, isCreating },
	} = stores;

	const [, openEditPricechange] = useDrawer('edit/stocks/conversion');

	const createButtonHandler = useCallback(async () => {
		const response = await flowResult(
			create({
				date: dayjs(),
				items: [],
			})
		);

		openEditPricechange(response.id);
	}, [create, openEditPricechange]);

	const canCreatePricechangePermission = usePermissions(
		'stocks',
		'conversion',
		'create'
	);

	if (!canCreatePricechangePermission) {
		return null;
	}

	return (
		<Button
			type="primary"
			onClick={createButtonHandler}
			disabled={isCreating}
			loading={isCreating}
		>
			<Trans>Додај конверзију</Trans>
		</Button>
	);
}
