import Line from '@ant-design/plots/es/components/line';
import { t, Trans } from '@lingui/macro';
import {
	Button,
	Card,
	Col,
	Divider,
	Empty,
	Grid,
	Progress,
	Row,
	Space,
	Spin,
	Statistic,
	Table,
	Tag,
	Typography,
} from 'antd';
import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import { useMedia, useUpdateEffect } from 'react-use';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';

import StoreSelect from './Components/StoreSelect';
import styles from './Home.module.less';
import { useDrawer } from '../../../components/Page';
import { StaticComponents } from '../../../components/StaticComponents';
import { Title } from '../../../components/Title';
import {
	getPaymentTypeTextShort,
	getTransactionTypeText,
	PAYMENT_TYPE_FROM_STRING,
	TRANSACTION_TYPE_FROM_STRING,
} from '../../../constants/journal';
import { usePermissions } from '../../../hooks/permissions';
import numberFormat from '../../../lib/numberFormat';
import stores from '../../../stores/index.mobx';

import { FLAT_RATE_LIMIT, VAT_LIMIT } from '../../../constants/tax';

function Home(undefined, ref) {
	const PAYMENT_TYPE_TEXT_SHORT = getPaymentTypeTextShort();
	const TRANSACTION_TYPE_TEXT = getTransactionTypeText();

	const isDarkMode = useMedia('(prefers-color-scheme: dark)');
	const screens = Grid.useBreakpoint();
	const [, openReceipt, , , , ReceiptViewDrawer] = useDrawer(
		'view/receipts/list|home'
	);
	const [, openProduct, , , , ProductEditDrawer] = useDrawer(
		'view/catalog/products|home'
	);
	const [, openReceiptsDay, , , , ReceiptsDayDrawer] =
		useDrawer('receipts-day');

	const {
		dailyReceipts: { byDay, fetchAllChart, fetchAll, isFetching, chartData },
		products: { getById },
		stores: { currentStoreId, available, currentStore: storesCurrentStore },
		company: { flatRateTax },
		kpos: {
			fetchStats,
			currentFlatRateAmount,
			currentVatAmount,
			isFetchingStats,
		},
	} = stores;

	const { paymentMethodsOrder } = storesCurrentStore || {};

	const StoreQueryParam = withDefault(StringParam, currentStoreId || 'all');
	const [currentStore, setCurrentStore] = useQueryParam(
		'store',
		StoreQueryParam
	);

	const singleDay = useMemo(() => {
		return !isFetching ? byDay?.[dayjs().format('YYYY-MM-DD')] : null;
	}, [byDay, isFetching]);

	const hasReceiptsAccessPermission = usePermissions(
		'receipts',
		'receipts',
		'access'
	);

	useEffect(() => {
		try {
			fetchAllChart(dayjs().toISOString(), currentStore)
				.then(() => {
					fetchAll(dayjs().toISOString(), currentStore).catch(() => {
						//
					});
				})
				.catch(() => {
					//
				});
		} catch (error) {
			//
		}
	}, [currentStore, fetchAll, fetchAllChart]);

	useEffect(() => {
		if (flatRateTax) {
			fetchStats();
		}
	}, [fetchStats, flatRateTax]);

	useUpdateEffect(() => {
		if (singleDay) {
			try {
				singleDay?.fetchDetails?.(currentStore);
			} catch (error) {
				//
			}
		}
	}, [singleDay]);

	useImperativeHandle(ref, () => ({
		renderHeader: () =>
			available.length > 1 && (
				<Space>
					<div>
						<Trans>Продајно место</Trans>
					</div>
					<StoreSelect
						value={currentStore}
						onChange={(value) => {
							setCurrentStore(value);
						}}
					/>
				</Space>
			),
		getDocsLink: () => 'koriscenje/moduli-aplikacije/home/',
	}));

	const sortPaymentMethods = useCallback(
		(a, b) => {
			const order = paymentMethodsOrder.filter((method) => method !== -1);
			return (
				order.indexOf(PAYMENT_TYPE_FROM_STRING[a[0]]) -
				order.indexOf(PAYMENT_TYPE_FROM_STRING[b[0]])
			);
		},
		[paymentMethodsOrder]
	);
	const sortPaymentMethodsKeys = useCallback(
		(a, b) => {
			const order = paymentMethodsOrder.filter((method) => method !== -1);
			return (
				order.indexOf(PAYMENT_TYPE_FROM_STRING[a]) -
				order.indexOf(PAYMENT_TYPE_FROM_STRING[b])
			);
		},
		[paymentMethodsOrder]
	);

	const sales = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.sales || [])
				.filter(([, value]) => value > 0)
				.sort(sortPaymentMethods),
		[singleDay?.details?.stats?.sales, sortPaymentMethods]
	);

	const salesSum = useMemo(
		() => sales.reduce((sum, [, value]) => sum + value, 0),
		[sales]
	);

	const refunds = useMemo(
		() =>
			Object.entries(singleDay?.details?.stats?.refunds || [])
				.filter(([, value]) => value > 0)
				.sort(sortPaymentMethods),
		[singleDay?.details?.stats?.refunds, sortPaymentMethods]
	);

	const refundsSum = useMemo(
		() => refunds.reduce((sum, [, value]) => sum + value, 0),
		[refunds]
	);

	const totals = useMemo(
		() =>
			Object.keys(PAYMENT_TYPE_FROM_STRING)
				.filter(
					(key) =>
						singleDay?.details?.stats?.sales?.[key] ||
						0 > 0 ||
						singleDay?.details?.stats?.refunds?.[key] ||
						0 > 0
				)
				.sort(sortPaymentMethodsKeys),
		[
			singleDay?.details?.stats?.refunds,
			singleDay?.details?.stats?.sales,
			sortPaymentMethodsKeys,
		]
	);

	const totalSum = useMemo(() => salesSum - refundsSum, [salesSum, refundsSum]);

	const lastTenReceipts = useMemo(() => {
		return orderBy(
			singleDay?.details?.receipts || [],
			(receipt) => dayjs(receipt.sdcTime).unix(),
			'desc'
		).slice(0, 10);
	}, [singleDay?.details?.receipts]);

	const topItems = useMemo(() => {
		return orderBy(singleDay?.receiptItems || [], 'quantity', 'desc').slice(
			0,
			10
		);
	}, [singleDay?.receiptItems]);

	// TODO: add forecasting https://www.npmjs.com/package/holtwinters
	return (
		<>
			<Spin spinning={isFetching || isFetchingStats}>
				<div className={styles.wrapper}>
					<Row gutter={[8, 8]}>
						<Col lg={8} xs={24}>
							<Card className={styles.card}>
								<>
									<Statistic
										title={
											<Title>
												<Trans>Продаје</Trans>
											</Title>
										}
										value={numberFormat(salesSum, false, 2, true)}
										valueStyle={{ color: '#52c41a' }}
										style={{ marginBottom: sales.length ? 8 : 0 }}
										prefix={
											<Typography.Text type="success" style={{ fontSize: 24 }}>
												<i className="fi fi-rr-arrow-up" />
											</Typography.Text>
										}
									/>
									{sales.map(([key, amount]) => (
										<Row key={key}>
											<Col flex="none">
												<Typography.Text>
													{
														PAYMENT_TYPE_TEXT_SHORT[
															PAYMENT_TYPE_FROM_STRING[key]
														]
													}
												</Typography.Text>
											</Col>
											<Col flex="auto" style={{ textAlign: 'right' }}>
												<Divider
													orientation="right"
													plain
													dashed
													orientationMargin={16}
												>
													{numberFormat(amount, false, 2, true)}
												</Divider>
											</Col>
										</Row>
									))}
								</>
							</Card>
						</Col>
						<Col lg={8} xs={24}>
							<Card className={styles.card}>
								<>
									<Statistic
										title={
											<Title>
												<Trans>Рефундације</Trans>
											</Title>
										}
										value={numberFormat(refundsSum, false, 2, true)}
										valueStyle={{ color: '#ff4d4f' }}
										style={{ marginBottom: refunds.length ? 8 : 0 }}
										prefix={
											<Typography.Text type="danger" style={{ fontSize: 24 }}>
												<i className="fi fi-rr-arrow-down" />
											</Typography.Text>
										}
									/>
									{refunds.map(([key, amount]) => (
										<Row key={key}>
											<Col flex="none">
												<Typography.Text>
													{
														PAYMENT_TYPE_TEXT_SHORT[
															PAYMENT_TYPE_FROM_STRING[key]
														]
													}
												</Typography.Text>
											</Col>
											<Col flex="auto" style={{ textAlign: 'right' }}>
												<Divider
													orientation="right"
													plain
													dashed
													orientationMargin={16}
												>
													{numberFormat(amount, false, 2, true)}
												</Divider>
											</Col>
										</Row>
									))}
								</>
							</Card>
						</Col>
						<Col lg={8} xs={24}>
							<Card className={styles.card}>
								<>
									<Statistic
										title={
											<Title>
												<Trans>Укупно</Trans>
											</Title>
										}
										value={numberFormat(totalSum, false, 2, true)}
										style={{ marginBottom: totals.length ? 8 : 0 }}
										prefix={
											<Typography.Text style={{ fontSize: 24 }}>
												＝
											</Typography.Text>
										}
									/>
									{totals.map((key) => (
										<Row key={key}>
											<Col flex="none">
												<Typography.Text>
													{
														PAYMENT_TYPE_TEXT_SHORT[
															PAYMENT_TYPE_FROM_STRING[key]
														]
													}
												</Typography.Text>
											</Col>
											<Col flex="auto" style={{ textAlign: 'right' }}>
												<Divider
													orientation="right"
													plain
													dashed
													orientationMargin={16}
												>
													{numberFormat(
														(singleDay?.details?.stats?.sales?.[key] || 0) -
															(singleDay?.details?.stats?.refunds?.[key] || 0),
														false,
														2,
														true
													)}
												</Divider>
											</Col>
										</Row>
									))}
								</>
							</Card>
						</Col>
					</Row>
					{flatRateTax && (
						<Row gutter={[8, 8]}>
							<Col lg={12} xs={24}>
								<Card
									title={t`Лимит за паушално опорезивање`}
									className={styles.chart}
								>
									<Row gutter={8}>
										<Col flex="auto">
											<Progress
												strokeColor={
													currentFlatRateAmount > FLAT_RATE_LIMIT * 0.9
														? '#c87e7e'
														: '#7E89C8'
												}
												percent={
													(currentFlatRateAmount / FLAT_RATE_LIMIT) * 100
												}
												showInfo={false}
											/>
										</Col>
										<Col flex="none">
											{`${numberFormat(
												currentFlatRateAmount,
												false,
												2,
												true
											)} / ${numberFormat(FLAT_RATE_LIMIT, false, 2, true)}`}
										</Col>
									</Row>
								</Card>
							</Col>
							<Col lg={12} xs={24}>
								<Card title={t`Лимит за ПДВ`} className={styles.chart}>
									<Row gutter={8}>
										<Col flex="auto">
											<Progress
												strokeColor={
													currentVatAmount > VAT_LIMIT * 0.9
														? '#c87e7e'
														: '#7E89C8'
												}
												percent={(currentVatAmount / VAT_LIMIT) * 100}
												showInfo={false}
											/>
										</Col>
										<Col flex="none">
											{`${numberFormat(
												currentVatAmount,
												false,
												2,
												true
											)} / ${numberFormat(VAT_LIMIT, false, 2, true)}`}
										</Col>
									</Row>
								</Card>
							</Col>
						</Row>
					)}
					<Row gutter={8} className={styles.chart}>
						<Col span={24}>
							<Card title={t`Промет`}>
								<Line
									// smooth
									animation={false}
									theme={{
										styleSheet: {
											fontFamily: 'Montserrat',
										},
										defaultColor: '#7e89c8',
										components: isDarkMode
											? {
													tooltip: {
														marker: {
															stroke: '#131516',
														},
														crosshairs: {
															line: {
																style: {
																	stroke: '#303030',
																},
															},
														},
													},
													axis: {
														common: {
															tickLine: {
																style: {
																	stroke: '#303030',
																},
															},
															line: {
																style: {
																	stroke: '#303030',
																},
															},
														},
													},
													slider: {
														common: {
															textStyle: {
																fill: '#fff',
																opacity: 0.45,
															},
															backgroundStyle: {
																fill: '#7e89c8',
																opacity: 0.05,
															},
														},
													},
											  }
											: {
													slider: {
														common: {
															backgroundStyle: {
																fill: '#7e89c8',
																opacity: 0.05,
															},
														},
													},
											  },
									}}
									className={'home-chart'}
									color={['#F38CA0', '#FFBA93', '#7e89c8']}
									lineStyle={({ typeLocalized }) => {
										if (typeLocalized === t`Текући месец`) {
											return {
												stroke: isDarkMode
													? 'l(90) 0:#7e89c8 1:#2f386e'
													: 'l(90) 0:#7e89c8 1:#cbd0e9',
												lineWidth: 2,
											};
										}
										if (typeLocalized === t`Претходни месец`) {
											return {
												stroke: 'l(90) 0:#FFBA93 1:#FFD2B8',
												lineWidth: 2,
												lineDash: [4, 4],
												strokeOpacity: isDarkMode ? 0.2 : 0.5,
											};
										}

										return {
											stroke: 'l(90) 0:#F38CA0 1:#F9B4C2',
											lineWidth: 2,
											lineDash: [4, 4],
											strokeOpacity: isDarkMode ? 0.2 : 0.5,
										};
									}}
									point={{
										size: 3,
										shape: 'point',
										style: ({ typeLocalized }) => {
											if (typeLocalized === t`Текући месец`) {
												return {
													fill: '#7e89c8',
													stroke: isDarkMode ? '#131516' : '#ffffff',
													lineWidth: 2,
												};
											}
											if (typeLocalized === t`Претходни месец`) {
												return {
													fill: '#FFBA93',
													fillOpacity: isDarkMode ? 0.2 : 0.5,
													stroke: isDarkMode ? '#131516' : '#ffffff',
													lineWidth: 2,
												};
											}

											return {
												fill: '#F38CA0',
												fillOpacity: isDarkMode ? 0.2 : 0.5,
												stroke: isDarkMode ? '#131516' : '#ffffff',
												lineWidth: 2,
											};
										},
										state: {
											active: {
												style: {
													// size: 4,
													lineWidth: 2,
													stroke: isDarkMode ? '#131516' : '#ffffff',
												},
											},
										},
									}}
									padding={[
										32,
										32,
										(screens.sm && !screens.md) || screens.xs ? 64 : 24,
										128,
									]}
									height={200}
									xField="day"
									// smooth
									seriesField="typeLocalized"
									yField="summedAmount"
									data={chartData.map((r) => ({
										...r,
										typeLocalized: ((type) => {
											switch (type) {
												case 'Tekući mesec':
													return t`Текући месец`;
												case 'Prethodni mesec':
													return t`Претходни месец`;
												case 'Prethodna godina':
													return t`Претходна година`;
												default:
													return '';
											}
										})(r.type),
										day: dayjs(r.day)
											.set('month', dayjs().month())
											.set('year', dayjs().year())
											.format('YYYY-MM-DD'),
									}))}
									xAxis={{
										alias: t`Датум`,
										label: {
											formatter: (value) => dayjs(value).format('DD. MMM'),
										},
									}}
									yAxis={{
										grid: {
											line: {
												style: {
													stroke: isDarkMode ? '#303030' : '#f0f0f0',
												},
											},
										},
										label: {
											formatter: (value) => numberFormat(value, true, 2, true),
										},
									}}
									slider={
										(screens.sm && !screens.md) || screens.xs
											? { start: 0.75, end: 1 }
											: false
									}
									tooltip={{
										formatter: ({ summedAmount, typeLocalized }) => ({
											name: typeLocalized,
											value: numberFormat(summedAmount, true, 2, true),
										}),
										title: (value) => dayjs(value).format('DD. MMMM'),
									}}
								/>
							</Card>
						</Col>
					</Row>
					{hasReceiptsAccessPermission && (
						<Row gutter={[8, 8]} className={styles.chart}>
							<Col xl={12} xs={24}>
								<Card
									className={`${styles.card} ${
										lastTenReceipts.length > 0 && styles.tableCard
									} ${styles.receiptsCard}`}
									title={t`Последњи данашњи рачуни`}
									extra={
										<>
											{screens.sm && (
												<Button
													onClick={() => {
														openReceiptsDay(
															`${dayjs().toISOString()},receipts,${currentStore}`
														);
													}}
													disabled={!lastTenReceipts.length}
												>
													<Trans>Сви данашњи рачуни</Trans>
												</Button>
											)}
											{screens.xs && (
												<Button
													block
													onClick={() => {
														openReceiptsDay(
															`${dayjs().toISOString()},receipts,${currentStore}`
														);
													}}
													disabled={!lastTenReceipts.length}
												>
													<Trans>Сви</Trans>
												</Button>
											)}
										</>
									}
								>
									{lastTenReceipts.length > 0 && (
										<Table
											size="small"
											dataSource={lastTenReceipts}
											scroll={{ x: 800 }}
											columns={[
												{
													title: t`Број рачуна`,
													key: 'invoiceNumber',
													render: (record) => (
														<Button
															type="link"
															className={styles.columnLink}
															onClick={() => openReceipt(record.id)}
														>
															{record.invoiceNumber}
														</Button>
													),
												},
												{
													title: t`Време`,
													key: 'sdcTime',
													render(record) {
														return dayjs(record.sdcTime).format('HH:mm:ss');
													},
													width: 80,
												},
												{
													title: t`Врста трансакције`,
													ellipsis: true,
													key: 'transactionType',
													dataIndex: 'transactionType',
													render(text) {
														return TRANSACTION_TYPE_TEXT[
															TRANSACTION_TYPE_FROM_STRING[text]
														];
													},
													width: 150,
												},
												{
													title: t`Начини плаћања`,
													key: 'paymentType',
													width: 200,
													render(record) {
														return record.payment.map((payment) => (
															<Tag
																bordered={false}
																title={numberFormat(
																	payment.amount,
																	false,
																	2,
																	true
																)}
															>
																{
																	PAYMENT_TYPE_TEXT_SHORT[
																		PAYMENT_TYPE_FROM_STRING[
																			payment.paymentType
																		]
																	]
																}
															</Tag>
														));
													},
												},
												{
													title: t`Износ`,
													dataIndex: 'totalAmount',
													key: 'totalAmount',
													render(text) {
														return numberFormat(text, true, 2, true);
													},
													width: 150,
												},
											]}
											pagination={false}
										/>
									)}
									{lastTenReceipts.length === 0 && (
										<Empty
											image={
												<img
													src="/images/icons/new/receipt.svg"
													alt=""
													className="anticon"
												/>
											}
											description={t`Данас нема издатих рачуна`}
										/>
									)}
								</Card>
							</Col>
							<Col xl={12} xs={24}>
								<Card
									className={`${styles.card} ${
										topItems.length > 0 && styles.tableCard
									}  ${styles.receiptsCard}`}
									title={t`Најпродаваније данас`}
									extra={
										<>
											{screens.sm && (
												<Button
													onClick={() => {
														openReceiptsDay(
															`${dayjs().toISOString()},items,${currentStore}`
														);
													}}
													disabled={!topItems.length}
												>
													<Trans>Сви продати артикли</Trans>
												</Button>
											)}
											{screens.xs && (
												<Button
													block
													onClick={() => {
														openReceiptsDay(
															`${dayjs().toISOString()},items,${currentStore}`
														);
													}}
													disabled={!topItems.length}
												>
													<Trans>Сви</Trans>
												</Button>
											)}
										</>
									}
								>
									{topItems.length > 0 && (
										<Table
											scroll={{ x: 800 }}
											size="small"
											dataSource={topItems}
											columns={[
												{
													title: t`Шифра`,
													key: 'sku',
													render: (record) => (
														<Button
															type="link"
															className={styles.columnLink}
															onClick={() => {
																const product = getById(record.id);
																const parent = product.parentId
																	? getById(product.parentId)
																	: null;
																if (
																	!product ||
																	product.deletedAt ||
																	(parent && parent.deletedAt)
																) {
																	return StaticComponents.notification.error({
																		message: t`Грешка`,
																		description: t`Артикал ${record.name} је обрисан`,
																	});
																}
																openProduct(
																	product.parentId
																		? product.parentId
																		: record.id
																);
															}}
														>
															{record.sku}
														</Button>
													),
													width: 80,
												},
												{
													title: t`Назив`,
													key: 'name',
													render: (record) => (
														<Button
															type="link"
															className={styles.columnLink}
															onClick={() => {
																const product = getById(record.id);
																const parent = product.parentId
																	? getById(product.parentId)
																	: null;
																if (
																	!product ||
																	product.deletedAt ||
																	(parent && parent.deletedAt)
																) {
																	return StaticComponents.notification.error({
																		message: t`Грешка`,
																		description: t`Артикал ${record.name} је обрисан`,
																	});
																}
																openProduct(
																	product.parentId
																		? product.parentId
																		: record.id
																);
															}}
														>
															{record.name}
														</Button>
													),
												},
												{
													title: t`Количина`,
													key: 'quantity',
													render(record) {
														return `${numberFormat(
															record.quantity,
															false,
															3,
															false
														)}${` ${record.unit || ''}`}`;
													},
													width: 90,
												},
												{
													title: t`Цена`,
													key: 'unitPrice',
													dataIndex: 'unitPrice',
													render(text) {
														return numberFormat(text, true, 2, true);
													},
													width: 150,
												},
												{
													title: t`Вредност`,
													key: 'totalAmount',
													render(record) {
														return numberFormat(
															record.unitPrice * record.quantity,
															true,
															2,
															true
														);
													},
													width: 150,
												},
											]}
											pagination={false}
										/>
									)}
									{topItems.length === 0 && (
										<Empty
											image={
												<img src="/images/icons/new/groceries.svg" alt="" />
											}
											description={t`Данас нема продатих артикала`}
										/>
									)}
								</Card>
							</Col>
						</Row>
					)}
				</div>
				<ReceiptViewDrawer location="home" />
				<ProductEditDrawer location="home" />
				<ReceiptsDayDrawer />
			</Spin>
		</>
	);
}

export default () => ({
	Page: observer(Home, { forwardRef: true }),
});
