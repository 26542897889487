import { t, Trans } from '@lingui/macro';
import {
	Button,
	Checkbox,
	Col,
	Form,
	Input,
	Row,
	Spin,
	Select,
	Upload,
	Progress,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import styles from './MojBadiSettings.module.less';
import { StaticComponents } from '../../../../../components/StaticComponents';
import { Title } from '../../../../../components/Title';
import { usePermissions } from '../../../../../hooks/permissions';
import store from '../../../../../stores/index.mobx';
import { StickersRulesInput } from './Components/StickersRules';
import { TiersRulesInput } from './Components/TiersRules';
import { flowResult } from 'mobx';
import { HeaderStatus } from '../Components/HeaderStatus';
import { v2Client } from '../../../../../store/client';
import StoresEdit from '../../../../../components/Inputs/StoresEdit';

function MojBadi(undefined, ref) {
	const hasSettingsMojBadiEditPermission = usePermissions(
		'settings',
		'mojbadi',
		'edit'
	);

	const [form] = Form.useForm();

	const [coverUploadState, setCoverUploadState] = useState({
		status: 'empty',
		percentage: 0,
		url: '',
	});
	const [profileUploadState, setProfileUploadState] = useState({
		status: 'empty',
		percentage: 0,
		url: '',
	});

	const enabled = Form.useWatch('enabled', form);
	const loyaltyType = Form.useWatch('loyaltyType', form);
	const expiry = Form.useWatch(['loyaltyConfiguration', 'expiry'], form);
	const expiryCustomUnit = Form.useWatch(
		['loyaltyConfiguration', 'expiryCustomUnit'],
		form
	);
	const stickerAwardMode = Form.useWatch(
		['loyaltyConfiguration', 'stickerAwardMode'],
		form
	);

	const [isFormTouched, setIsFormTouched] = useState(false);
	const { mojBadi } = store;
	const { isFetchingSettings, isUpdatingSettings, settings, updateSettings } =
		mojBadi;

	useImperativeHandle(ref, () => ({
		getDocsLink: () => 'koriscenje/moduli-aplikacije/podesavanja/preduzece/',
		renderHeader: () => <HeaderStatus />,
	}));

	useEffect(() => {
		if (settings) {
			if (settings.coverImageFile) {
				setCoverUploadState({
					status: 'done',
					url: settings.coverImageFile.urls['1440x960'],
					percentage: 100,
				});
				form.setFieldValue('coverImageFileId', settings.coverImageFile.id);
			}

			if (settings.profileImageFile) {
				setProfileUploadState({
					status: 'done',
					url: settings.profileImageFile.urls['1024x1024'],
					percentage: 100,
				});
				form.setFieldValue('profileImageFileId', settings.profileImageFile.id);
			}
		}
	}, [form, settings, settings.name]);

	async function onSubmit() {
		try {
			const values = await form.validateFields();

			values.coverImageFileId =
				values.coverImageFile?.file?.response?.id ||
				form.getFieldValue('coverImageFileId');

			delete values.coverImageFile;

			values.profileImageFileId =
				values.profileImageFile?.file?.response?.id ||
				form.getFieldValue('profileImageFileId');

			delete values.profileImageFile;

			await flowResult(updateSettings(values));
			StaticComponents.notification.success({
				message: t`Подешавања су успешно сачувана.`,
				description: t`Ваша пријава је на чекању и биће обрађена у наредних 24 сата.`,
			});

			setIsFormTouched(false);
		} catch (error) {
			console.error(error);
			StaticComponents.notification.error({
				message: t`Дошло је до грешке приликом чувања подешавања`,
			});
		}
	}

	const onValuesChange = useCallback(() => {
		setIsFormTouched(true);
	}, []);

	return (
		<Spin spinning={isFetchingSettings || isUpdatingSettings}>
			<div className={styles.wrapper}>
				<Form
					layout="vertical"
					className={styles.form}
					initialValues={settings}
					onFinish={onSubmit}
					form={form}
					onValuesChange={onValuesChange}
					disabled={!hasSettingsMojBadiEditPermission || !enabled}
				>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								initialValue={false}
								name="enabled"
								valuePropName="checked"
								extra={t`Излиставање предузећа на апликацији Мој Бади је бесплатно. Неке од додатних могућности попут истицања предузећа или понуда, као и продаје путем апликације подлежу додатној наплати.`}
							>
								<Checkbox disabled={false}>
									<Trans>
										Желим да моје предузеће буде део <strong>Мој Бади</strong>{' '}
										програма
									</Trans>
								</Checkbox>
							</Form.Item>
						</Col>
					</Row>
					<Title>
						<Trans>Основне информације</Trans>
					</Title>
					<Row gutter={8}>
						<Col span={24}>
							<Form.Item
								name="name"
								label={t`Назив`}
								extra={t`Назив предузећа који ће бити приказан на апликацији Мој Бади`}
								rules={[
									{
										required: true,
										message: t`Морате унети назив предузећа`,
									},
								]}
							>
								<Input />
							</Form.Item>
							<Form.Item
								name="description"
								label={t`Опис`}
								rules={[
									{
										required: true,
										message: t`Морате унети опис предузећа`,
									},
								]}
							>
								<Input.TextArea />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item name="coverImageFileId" hidden>
								<Input />
							</Form.Item>
							<Form.Item
								name="coverImageFile"
								label={t`Насловна фотографија`}
								valuePropName="file"
								className={styles.coverImageFileWrapper}
							>
								<Upload.Dragger
									showUploadList={false}
									headers={{
										'x-access-token': String(
											v2Client.defaults.headers.common['x-access-token']
										),
									}}
									action={v2Client.defaults.baseURL + '/files/coverImage'}
									className={styles.upload}
									onChange={(info) => {
										// TODO: use deferred to wait for upload
										const { status } = info.file;
										if (status === 'uploading') {
											setCoverUploadState({
												status,
												percentage: info.file.percent,
												url: '',
											});
										}
										if (status === 'done') {
											setIsFormTouched(true);
											setCoverUploadState({
												status,
												percentage: 100,
												url: info.file.response.urls['1440x960'],
											});
											form.setFieldValue('coverImageFile', info);
										} else if (status === 'error') {
											StaticComponents.notification.error({
												message: t`Грешка`,
												description: t`Грешка приликом отпремања фајла ${info.file.name}`,
											});
										}
									}}
									onDrop={(e) => {
										// console.log('Dropped files', e.dataTransfer.files);
									}}
								>
									{coverUploadState.status === 'empty' && (
										<>
											<p
												className={`ant-upload-drag-icon ${styles.uploadIcon}`}
											>
												<img
													src="/images/icons/new/cloud-computing.svg"
													alt=""
												/>
											</p>
											<p className="ant-upload-hint">
												<Trans>
													Отпреми насловну фотографију.
													<br />
													<br />
													Однос димензија мора бити 3:2 (ширина:висина), а
													најмања резолуција 1440x960 пиксела.
												</Trans>
											</p>
										</>
									)}
									{coverUploadState.status === 'uploading' && (
										<Progress
											percent={coverUploadState.percentage}
											size="small"
											status="active"
											strokeColor="#7E89C8"
										/>
									)}
									{coverUploadState.status === 'done' && (
										<img
											src={coverUploadState.url}
											alt="CoverImageFile"
											className={styles.coverImageFilePreview}
										/>
									)}
								</Upload.Dragger>
								{coverUploadState.status === 'done' && (
									<Button
										className={styles.removeCoverImageFile}
										onClick={() => {
											form.setFieldValue('coverImageFile', null);
											form.setFieldValue('coverImageFileId', null);
											setIsFormTouched(true);
											setCoverUploadState({
												status: 'empty',
												percentage: 0,
												url: '',
											});
										}}
										icon={<i className="fi fi-rr-trash"></i>}
									/>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							<Form.Item name="profileImageFileId" hidden>
								<Input />
							</Form.Item>
							<Form.Item
								name="profileImageFile"
								label={t`Профилна фотографија`}
								valuePropName="file"
								className={styles.profileImageFileWrapper}
							>
								<Upload.Dragger
									showUploadList={false}
									headers={{
										'x-access-token': String(
											v2Client.defaults.headers.common['x-access-token']
										),
									}}
									action={v2Client.defaults.baseURL + '/files/profileImage'}
									className={styles.uploadProfile}
									onChange={(info) => {
										// TODO: use deferred to wait for upload
										const { status } = info.file;
										if (status === 'uploading') {
											setProfileUploadState({
												status,
												percentage: info.file.percent,
												url: '',
											});
										}
										if (status === 'done') {
											setIsFormTouched(true);
											setProfileUploadState({
												status,
												percentage: 100,
												url: info.file.response.urls['1024x1024'],
											});
											form.setFieldValue('profileImageFile', info);
										} else if (status === 'error') {
											StaticComponents.notification.error({
												message: t`Грешка`,
												description: t`Грешка приликом отпремања фајла ${info.file.name}`,
											});
										}
									}}
									onDrop={(e) => {
										// console.log('Dropped files', e.dataTransfer.files);
									}}
								>
									{profileUploadState.status === 'empty' && (
										<>
											<p
												className={`ant-upload-drag-icon ${styles.uploadIcon}`}
											>
												<img
													src="/images/icons/new/cloud-computing.svg"
													alt=""
												/>
											</p>
											<p className="ant-upload-hint">
												<Trans>
													Отпреми профилну фотографију.
													<br />
													<br />
													Однос димензија мора бити 1:1 (ширина:висина), а
													најмања резолуција 1024x1024 пиксела.
												</Trans>
											</p>
										</>
									)}
									{profileUploadState.status === 'uploading' && (
										<Progress
											percent={profileUploadState.percentage}
											size="small"
											status="active"
											strokeColor="#7E89C8"
										/>
									)}
									{profileUploadState.status === 'done' && (
										<img
											src={profileUploadState.url}
											alt="ProfileImageFile"
											className={styles.profileImageFilePreview}
										/>
									)}
								</Upload.Dragger>
								{profileUploadState.status === 'done' && (
									<Button
										className={styles.removeProfileImageFile}
										onClick={() => {
											form.setFieldValue('profileImageFile', null);
											form.setFieldValue('profileImageFileId', null);
											setIsFormTouched(true);
											setProfileUploadState({
												status: 'empty',
												percentage: 0,
												url: '',
											});
										}}
										icon={<i className="fi fi-rr-trash"></i>}
									/>
								)}
							</Form.Item>
						</Col>
					</Row>
					<Title>
						<Trans>Продајна места</Trans>
					</Title>
					<Row>
						<Col span={24}>
							<Form.Item name="storeIds">
								<StoresEdit />
							</Form.Item>
						</Col>
					</Row>
					<Title>
						<Trans>Програм лојалности</Trans>
					</Title>
					<Row>
						<Col span={24}>
							<Form.Item
								name="loyaltyType"
								label={t`Врста програма лојалности`}
							>
								<Select>
									<Select.Option value="none">
										<Trans>Без програма лојалности</Trans>
									</Select.Option>
									<Select.Option value="points">
										<Trans>Сакупљање поена</Trans>
									</Select.Option>
									<Select.Option value="stickers">
										<Trans>Сакупљање налепница</Trans>
									</Select.Option>
									<Select.Option value="tiers">
										<Trans>Нивои чланства</Trans>
									</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					{loyaltyType === 'points' && (
						<Row gutter={8}>
							<Col xs={24} sm={5}>
								<Form.Item
									name={['loyaltyConfiguration', 'purchaseAmount']}
									label={t`На потрошених`}
									rules={[
										{
											required: true,
											message: t`Морате унети износ`,
										},
									]}
								>
									<Input type="number" addonAfter="RSD" />
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item
									name={['loyaltyConfiguration', 'rewardPoints']}
									label={t`Купац добија`}
									rules={[
										{
											required: true,
											message: t`Морате унети број поена`,
										},
									]}
								>
									<Input type="number" addonAfter={t`поена`} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={5}>
								<Form.Item
									name={['loyaltyConfiguration', 'pointWorth']}
									label={t`Вредност поена`}
									rules={[
										{
											required: true,
											message: t`Морате унети вредност`,
										},
									]}
								>
									<Input type="number" addonAfter="RSD" />
								</Form.Item>
							</Col>
							<Col xs={24} sm={9}>
								<Form.Item
									name={['loyaltyConfiguration', 'maxPointsPerPurchase']}
									label={t`Максималан утрошак поена`}
									rules={[
										{
											required: true,
											message: t`Морате унети вредност`,
										},
									]}
								>
									<Input type="number" addonAfter={t`% вредности рачуна`} />
								</Form.Item>
							</Col>
							<Col xs={24} sm={12}>
								<Form.Item
									name={['loyaltyConfiguration', 'expiry']}
									label={t`Рок важења поена`}
									initialValue={'never'}
								>
									<Select>
										<Select.Option value="never">
											<Trans>Поени не истичу</Trans>
										</Select.Option>
										<Select.Option value="month">
											<Trans>1 месец</Trans>
										</Select.Option>
										<Select.Option value="quarter">
											<Trans>3 месеца</Trans>
										</Select.Option>
										<Select.Option value="halfyear">
											<Trans>6 месеци</Trans>
										</Select.Option>
										<Select.Option value="year">
											<Trans>1 година</Trans>
										</Select.Option>
										<Select.Option value="custom">
											<Trans>Прилагођено</Trans>
										</Select.Option>
									</Select>
								</Form.Item>
								<Form.Item
									name={['loyaltyConfiguration', 'expiryCustomUnit']}
									hidden
									initialValue={'days'}
								></Form.Item>
							</Col>
							{expiry === 'custom' && (
								<Col xs={24} sm={12}>
									<Form.Item
										label=" "
										name={['loyaltyConfiguration', 'expiryCustom']}
									>
										<Input
											type="number"
											addonAfter={
												<Select
													style={{ width: 100 }}
													defaultValue="days"
													value={expiryCustomUnit}
													onChange={(value) => {
														form.setFieldValue(
															['loyaltyConfiguration', 'expiryCustomUnit'],
															value
														);
													}}
												>
													<Select.Option value="days">
														<Trans>дана</Trans>
													</Select.Option>
													<Select.Option value="months">
														<Trans>месеци</Trans>
													</Select.Option>
													<Select.Option value="years">
														<Trans>година</Trans>
													</Select.Option>
												</Select>
											}
										/>
									</Form.Item>
								</Col>
							)}
						</Row>
					)}
					{loyaltyType === 'stickers' && (
						<>
							<Row gutter={8}>
								<Col xs={24} sm={13}>
									<Form.Item
										name={['loyaltyConfiguration', 'stickerAwardMode']}
										label={t`Начин добијања налепница`}
										initialValue={'once'}
									>
										<Select>
											<Select.Option value="once">
												<Trans>
													Једна налепница по куповини након достигнутог износа
												</Trans>
											</Select.Option>
											<Select.Option value="every">
												<Trans>
													По једна налепница за сваки достигнути износ
												</Trans>
											</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col xs={24} sm={11}>
									<Form.Item
										name={['loyaltyConfiguration', 'purchaseAmount']}
										label={
											stickerAwardMode === 'once'
												? t`Купац добија налепницу након потрошених`
												: t`Купац добија налепницу на сваких потрошених`
										}
										rules={[
											{
												required: true,
												message: t`Морате унети износ`,
											},
										]}
									>
										<Input type="number" addonAfter="RSD" />
									</Form.Item>
								</Col>
								<Col xs={24} sm={12}>
									<Form.Item
										name={['loyaltyConfiguration', 'sticker']}
										label={t`Налепница`}
										rules={[
											{
												required: true,
												message: t`Морате одабрати налепницу`,
											},
										]}
									>
										<Select
											options={[
												{
													value: 'chicken-leg',
													label: t`Батак`,
												},
												{
													value: 'burger',
													label: t`Бургер`,
												},
												{
													value: 'clover',
													label: t`Детелина са четири листа`,
												},
												{
													value: 'diamond',
													label: t`Дијамант`,
												},
												{
													value: 'coffee-beans',
													label: t`Зрна кафе`,
												},
												{
													value: 'apple',
													label: t`Јабука`,
												},
												{
													value: 'key',
													label: t`Кључ`,
												},
												{
													value: 'basketball',
													label: t`Кошаркашка лопта`,
												},
												{
													value: 'croissant',
													label: t`Кроасан`,
												},
												{
													value: 'coin',
													label: t`Новчић`,
												},
												{
													value: 'beer',
													label: t`Пиво`,
												},
												{
													value: 'giftbox',
													label: t`Поклон`,
												},
												{
													value: 'ice-cream',
													label: t`Сладолед`,
												},
												{
													value: 'cleaning-spray',
													label: t`Средство за чишћење`,
												},
												{
													value: 'heart',
													label: t`Срце`,
												},
												{
													value: 'dumbbell',
													label: t`Тег`,
												},
												{
													value: 'cake',
													label: t`Торта`,
												},
												{
													value: 'camera',
													label: t`Фото апарат`,
												},
												{
													value: 'dress',
													label: t`Хаљина`,
												},
												{
													value: 'flower',
													label: t`Цвет`,
												},
												{
													value: 'carrot',
													label: t`Шаргарепа`,
												},
												{
													value: 'light-bulb',
													label: t`Сијалица`,
												},
												{
													value: 'lipstick',
													label: t`Руж`,
												},
												{
													value: 'martini',
													label: t`Мартини`,
												},
												{
													value: 'mortarboard',
													label: t`Академска капа`,
												},
												{
													value: 'music-note',
													label: t`Нота`,
												},
												{
													value: 'orange-juice',
													label: t`Сок од наранџе`,
												},
												{
													value: 'pacifier',
													label: t`Цуцла`,
												},
												{
													value: 'paw',
													label: t`Шапа`,
												},
												{
													value: 'plane',
													label: t`Авион`,
												},
												{
													value: 'plush-toy',
													label: t`Плишана играчка`,
												},
												{
													value: 'popcorn',
													label: t`Кокице`,
												},
												{
													value: 'glasses',
													label: t`Наочаре`,
												},
												{
													value: 'screwdriver',
													label: t`Одвијач`,
												},
												{
													value: 'smiley',
													label: t`Смајли`,
												},
												{
													value: 'soccer-ball',
													label: t`Фудбалска лопта`,
												},
												{
													value: 'star',
													label: t`Звезда`,
												},
												{
													value: 'thumb-up',
													label: t`Палац горе`,
												},
												{
													value: 'tooth',
													label: t`Зуб`,
												},
												{
													value: 'wrench',
													label: t`Француски кључ`,
												},
											]
												.sort((a, b) => a.label.localeCompare(b.label))
												.map((item) => ({
													...item,
													label: (
														<>
															<img
																src={`/images/icons/loyalty/${item.value}.png`}
																width={16}
																height={16}
																style={{
																	marginRight: 8,
																	verticalAlign: 'middle',
																}}
															/>{' '}
															{item.label}
														</>
													),
												}))}
										/>
									</Form.Item>
								</Col>
							</Row>
							<Row>
								<Col xs={24} sm={12}>
									<Form.Item
										name={['loyaltyConfiguration', 'expiry']}
										label={t`Рок важења налепница`}
										initialValue={'never'}
									>
										<Select>
											<Select.Option value="never">
												<Trans>Налепнице не истичу</Trans>
											</Select.Option>
											<Select.Option value="month">
												<Trans>1 месец</Trans>
											</Select.Option>
											<Select.Option value="quarter">
												<Trans>3 месеца</Trans>
											</Select.Option>
											<Select.Option value="halfyear">
												<Trans>6 месеци</Trans>
											</Select.Option>
											<Select.Option value="year">
												<Trans>1 година</Trans>
											</Select.Option>
											<Select.Option value="custom">
												<Trans>Прилагођено</Trans>
											</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item
										name={['loyaltyConfiguration', 'expiryCustomUnit']}
										hidden
									></Form.Item>
								</Col>
								{expiry === 'custom' && (
									<Col xs={24} sm={12}>
										<Form.Item
											label=" "
											name={['loyaltyConfiguration', 'expiryCustom']}
										>
											<Input
												type="number"
												addonAfter={
													<Select
														style={{ width: 100 }}
														defaultValue="days"
														value={expiryCustomUnit}
														onChange={(value) => {
															form.setFieldValue(
																['loyaltyConfiguration', 'expiryCustomUnit'],
																value
															);
														}}
													>
														<Select.Option value="days">
															<Trans>дана</Trans>
														</Select.Option>
														<Select.Option value="months">
															<Trans>месеци</Trans>
														</Select.Option>
														<Select.Option value="years">
															<Trans>година</Trans>
														</Select.Option>
													</Select>
												}
											/>
										</Form.Item>
									</Col>
								)}
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										name={['loyaltyConfiguration', 'stickerRules']}
										label={t`Правила за коришћење налепница`}
									>
										<StickersRulesInput />
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
					{loyaltyType === 'tiers' && (
						<>
							<Row gutter={8}>
								<Col xs={24} sm={12}>
									<Form.Item
										name={['loyaltyConfiguration', 'expiry']}
										label={t`Период обрачуна потрошеног износа`}
										initialValue={'never'}
									>
										<Select>
											<Select.Option value="never">
												<Trans>Заувек</Trans>
											</Select.Option>
											<Select.Option value="month">
												<Trans>1 месец</Trans>
											</Select.Option>
											<Select.Option value="quarter">
												<Trans>3 месеца</Trans>
											</Select.Option>
											<Select.Option value="halfyear">
												<Trans>6 месеци</Trans>
											</Select.Option>
											<Select.Option value="year">
												<Trans>1 година</Trans>
											</Select.Option>
											<Select.Option value="custom">
												<Trans>Прилагођено</Trans>
											</Select.Option>
										</Select>
									</Form.Item>
									<Form.Item
										name={['loyaltyConfiguration', 'expiryCustomUnit']}
										hidden
									></Form.Item>
								</Col>
								{expiry === 'custom' && (
									<Col xs={24} sm={12}>
										<Form.Item
											label=" "
											name={['loyaltyConfiguration', 'expiryCustom']}
										>
											<Input
												type="number"
												addonAfter={
													<Select
														style={{ width: 100 }}
														defaultValue="days"
														value={expiryCustomUnit}
														onChange={(value) => {
															form.setFieldValue(
																['loyaltyConfiguration', 'expiryCustomUnit'],
																value
															);
														}}
													>
														<Select.Option value="days">
															<Trans>дана</Trans>
														</Select.Option>
														<Select.Option value="months">
															<Trans>месеци</Trans>
														</Select.Option>
														<Select.Option value="years">
															<Trans>година</Trans>
														</Select.Option>
													</Select>
												}
											/>
										</Form.Item>
									</Col>
								)}
							</Row>
							<Row>
								<Col span={24}>
									<Form.Item
										name={['loyaltyConfiguration', 'tiersRules']}
										label={t`Нивои чланства`}
									>
										<TiersRulesInput />
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
					<Form.Item>
						<Button
							size="large"
							type="primary"
							htmlType="submit"
							disabled={
								isFetchingSettings || isUpdatingSettings || !isFormTouched
							}
							loading={isFetchingSettings || isUpdatingSettings}
						>
							<Trans>Сачувај</Trans>
						</Button>
					</Form.Item>
				</Form>
			</div>
		</Spin>
	);
}

export default observer(MojBadi, { forwardRef: true });
