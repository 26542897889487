import { observable, flow, runInAction, flowResult } from 'mobx';

import { CreateStore } from './Crud.mobx';
import type { File } from './shared/File.mobx';
import type { LoyaltyConfiguration } from './MojBadi.mobx';
import { Company } from './Company.mobx';
import { Store as StoreEntity } from './Store.mobx';

export type LoyaltyType = 'none' | 'points' | 'stickers' | 'tiers';

const { Store, Entity } = CreateStore({
	name: 'mojBadiAdministrationCompany',
	resourcePrefix: 'moj-badi/administration/',
	resourceName: 'companies',
	paginated: true,
	persistFields: ['all'],
});

class MojBadiAdministrationCompany extends Entity {
	@observable enabled?: boolean;
	@observable name?: string;
	@observable description?: string;
	@observable coverImageFileId?: string;
	@observable coverImageFile?: File;
	@observable profileImageFileId?: string;
	@observable profileImageFile?: File;
	@observable loyaltyType?: LoyaltyType = 'none';
	@observable loyaltyConfiguration?: LoyaltyConfiguration;
	@observable status?: 'active' | 'pending' | 'rejected';
	@observable rejectedReason?: string;
	@observable storeIds?: string[];
	@observable stores?: StoreEntity[];
	@observable company?: Company;

	@observable isApproving = false;
	@observable isRejecting = false;

	get isEditable(): boolean {
		return false;
	}
	get isDeletable(): boolean {
		return false;
	}

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	replace(data: any): void {
		super.replace(data);
		this.stores = data.stores.map((store) => new StoreEntity(store, this));
	}

	@flow.bound
	*approve() {
		this.isApproving = true;
		try {
			const response = yield this.getClient().patch(
				`/moj-badi/administration/companies/${this.company.id}`,
				{
					status: 'active',
				}
			);
			this.status = response.data.status;
		} catch (e) {
			console.error(e);
		} finally {
			this.isApproving = false;
		}
	}

	@flow.bound
	*reject(reason: string) {
		this.isRejecting = true;
		try {
			const response = yield this.getClient().patch(
				`/moj-badi/administration/companies/${this.company.id}`,
				{
					status: 'rejected',
					rejectedReason: reason,
				}
			);
			this.status = response.data.status;
		} catch (e) {
			console.error(e);
		} finally {
			this.isRejecting = false;
		}
	}
}

class MojBadiAdministrationCompanies extends Store<MojBadiAdministrationCompany> {
	get isCreatable(): boolean {
		return false;
	}

	constructor() {
		super(MojBadiAdministrationCompany);
	}
}

export { MojBadiAdministrationCompanies, MojBadiAdministrationCompany };
