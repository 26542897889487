import { flow, observable } from 'mobx';
import qs from 'qs';

import { CreateStore } from './Crud.mobx';

const { Entity } = CreateStore({
	name: 'report',
	type: 'entity',
	paginated: false,
});

export type PeriodicReportProduct = {
	name: string;
	sku?: number;
	gtin?: string;
	unitPrice: number;
	unit?: string;
	isPieceUnitOfMeasure?: boolean;
	quantity: number;
	amount: number;
	paymentType?: string;
};

export type PeriodicReportPaymentMethod = {
	paymentType: string;
	amount: number;
};

export type PeriodicReportAmounts = {
	label?: string;
	amount: number;
	tax: number;
	count: number;
};

export type PeriodicReportDay = {
	day: string;
	byLabel: PeriodicReportAmounts[];
	total: PeriodicReportAmounts;
};

export type PeriodicReportType = {
	type: string;
	byLabel: PeriodicReportAmounts[];
	amount: number;
	tax: number;
	count: number;
};

export type GroupVatItem = {
	taxLabel: string;
	baseAmount: number;
	taxAmount: number;
	totalAmount: number;
};

export type GroupVat = {
	normalSales: GroupVatItem[];
	advanceSales: GroupVatItem[];
	normalRefunds: GroupVatItem[];
	advanceRefunds: GroupVatItem[];
	normalVoids: GroupVatItem[];
	advanceVoids: GroupVatItem[];
};

export type PeriodicReport = {
	byDay: PeriodicReportDay[];
	byType: PeriodicReportType[];
	byLabel: PeriodicReportAmounts[];
	total: PeriodicReportAmounts;
	products: PeriodicReportProduct[];
	paymentMethods: PeriodicReportPaymentMethod[];
	groupVat: GroupVat;
};

class Report extends Entity {
	@observable periodicReport?: PeriodicReport;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@flow.bound
	*fetchPeriodicReport(query) {
		try {
			this.isFetching = true;
			this.periodicReport = {
				byDay: [],
				byLabel: [],
				byType: [],
				total: { amount: 0, tax: 0, count: 0 },
				products: [],
				paymentMethods: [],
			};
			const { data } = yield this.getClient().get(
				`/reports/periodic/?${qs.stringify(query)}`
			);
			this.periodicReport = data;
		} catch (error) {
			throw error;
		} finally {
			this.isFetching = false;
		}
		return this.periodicReport;
	}
}

export { Report };
