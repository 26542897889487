import { CreateStore } from './Crud.mobx';
import { Product } from './Product.mobx';
import { ReferenceTransformer } from './transformers/Reference';

const { Store, Entity } = CreateStore({
	name: 'stock',
	paginated: true,
	searchFields: [
		'product.fullName',
		'product.sku',
		'product.ean',
		'product.manufacturerSku',
		'product.parent.manufacturerSku',
	],
});

class Stock extends Entity {
	quantity?: number;
	productId?: string;
	partners?: string[];

	@ReferenceTransformer('product', 'productId') product?: Product;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	get isDeletable() {
		return false;
	}

	get isEditable() {
		return false;
	}
}

class Stocks extends Store<Stock> {
	constructor() {
		super(Stock);
	}

	get isCreatable() {
		return true;
	}
}

export { Stocks, Stock };
