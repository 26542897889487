import { t } from '@lingui/macro';
import {
	Badge,
	Button,
	ConfigProvider,
	Drawer,
	Empty,
	Spin,
	Table,
	Tag,
} from 'antd';
import { observer } from 'mobx-react-lite';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDebounce, useWindowSize } from 'react-use';
import cyr2lat from 'cyrillic-to-latin';

import styles from '../../Products.module.less';

import { useDrawer } from '../../../../../../components/Page';
import numberFormat from '../../../../../../lib/numberFormat';
import stores from '../../../../../../stores/index.mobx';
import {
	ArrayParam,
	NumberParam,
	useQueryParam,
	useQueryParams,
	withDefault,
} from 'use-query-params';
import {
	INVOICE_TYPE_FROM_STRING,
	PAYMENT_TYPE_FROM_STRING,
	TRANSACTION_TYPE_FROM_STRING,
	getPaymentTypeTextShort,
	getTransactionTypeText,
} from '../../../../../../constants/journal';
import { getScrollbarWidth } from '../../../../../../lib/scrollbar';
import dayjs from 'dayjs';
import { bignumber, evaluate } from 'mathjs';
import {
	InvoiceType,
	TransactionType,
} from '../../../../../../constants/invoice';
import round from 'lodash/round';
import { TextFilter } from '../../../../../../components/Filters/Text';
import { DateRangeFilter } from '../../../../../../components/Filters/DateRange';
import { User } from '../../../../../../stores/User.mobx';
import { Store } from '../../../../../../stores/Store.mobx';
const Sales = () => {
	const PAYMENT_TYPE_TEXT_SHORT = getPaymentTypeTextShort();
	const TRANSACTION_TYPE_TEXT = getTransactionTypeText();
	const [queryParam, , close, visible] = useDrawer('product-sales');
	const {
		products: { getOrFetchSingle, single: product, isFetching },
	} = stores;
	const [, openReceipt, , , , ReceiptViewDrawer] = useDrawer(
		'view/receipts/list|sales-view'
	);

	useEffect(() => {
		if (queryParam && visible) {
			getOrFetchSingle(queryParam);
		}
	}, [getOrFetchSingle, queryParam, visible]);

	const { height } = useWindowSize();
	const contentHeight =
		height -
		49 - // header
		39 - // table header
		60 - // pagination
		getScrollbarWidth(); // scrollbar

	const currentPageSize = Math.floor(contentHeight / 49);
	const [pageSize, setPageSize] = useState(currentPageSize);

	useDebounce(
		() => {
			if (visible) {
				setPageSize(currentPageSize);
			}
		},
		1000,
		[currentPageSize, visible]
	);

	const [pageQueryParam, setPageQueryParam] = useQueryParam(
		'product-sales-page',
		withDefault(NumberParam, 1)
	);

	const memoizedPagination = useMemo(() => {
		return (
			!isFetching && {
				pageSize: pageSize,
				total: product?.sales?.pagination?.count,
				current: pageQueryParam,
				showSizeChanger: false,
			}
		);
	}, [isFetching, pageSize, product?.sales?.pagination?.count, pageQueryParam]);

	const [filterQueryParams, setFilterQueryParams] = useQueryParams({
		sdcTime: ArrayParam,
		invoiceNumber: ArrayParam,
		transactionType: ArrayParam,
		storeId: ArrayParam,
		cashierId: ArrayParam,
	});

	const handleTableChange = useCallback(
		(tablePagination, filters, sorter) => {
			// TODO: only set query params here, and react to them in useEffect
			setPageQueryParam(tablePagination.current);

			let finalFilters = {};
			const finalSorters = '';
			if (filters) {
				const filtered = Object.entries(filters).reduce(function (acc, curr) {
					acc[curr[0]] = curr[1] || undefined;
					return acc;
				}, {});
				finalFilters = {
					...filtered,
				};
				setFilterQueryParams(finalFilters);
			}
			// if (sorter && sorter.field) {
			// 	finalSorters = `${sorter.field}:${
			// 		sorter.order === 'descend' ? 'desc' : 'asc'
			// 	}`;
			// 	setSorterQueryParam(finalSorters);
			// }

			product.sales.fetchAll(
				product.id,
				pageSize,
				(tablePagination.current - 1) * pageSize,
				{ ...finalFilters },
				finalSorters
			);
		},
		[setPageQueryParam, product, pageSize, setFilterQueryParams]
	);

	useEffect(() => {
		if (visible) {
			handleTableChange(
				{
					current: pageQueryParam || 1,
					pageSize,
				},
				filterQueryParams,
				{}
			);
		}
	}, [filterQueryParams, handleTableChange, pageQueryParam, pageSize, visible]);

	return (
		<Drawer
			open={visible}
			title={t`Продаје артикла ${product?.name}`}
			width={1200}
			className="noPadding"
			onClose={close}
		>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/invoice.svg" alt="" />}
						description={t`Нема издатих рачуна`}
					/>
				)}
			>
				<Spin spinning={product?.sales?.isFetching || isFetching}>
					<Table
						size="small"
						dataSource={product?.sales?.all}
						scroll={{ x: 1800 }}
						onChange={handleTableChange}
						columns={[
							{
								title: t`Датум и време`,
								key: 'sdcTime',
								render(record) {
									return dayjs(record.receipt.sdcTime).format(
										'DD.MM.YYYY HH:mm:ss'
									);
								},
								filterDropdown: ({
									setSelectedKeys,
									selectedKeys,
									confirm,
									clearFilters,
								}) => (
									<DateRangeFilter
										setSelectedKeys={setSelectedKeys}
										selectedKeys={selectedKeys}
										confirm={confirm}
										clearFilters={clearFilters}
									/>
								),
								width: 160,
							},
							{
								title: t`Количина`,
								dataIndex: 'quantity',
								key: 'quantity',
								width: 100,
								render(text) {
									return numberFormat(text, false, 3, false);
								},
							},
							{
								title: t`Износ`,
								key: 'totalAmount',
								render(record) {
									return numberFormat(
										evaluate('quantity * unitPrice', {
											quantity: bignumber(record.quantity),
											unitPrice: bignumber(record.unitPrice),
										}).toNumber(),
										true,
										2,
										true
									);
								},
								width: 150,
							},
							{
								title: t`Број рачуна`,
								key: 'invoiceNumber',
								render: (record) => (
									<Button
										type="link"
										className={styles.columnLink}
										onClick={() => openReceipt(record.receipt.id)}
									>
										{record.receipt.invoiceNumber}
									</Button>
								),
								filterIcon: (filtered) => (
									<i
										className="fi fi-rr-search"
										style={{ color: filtered ? '#7E89C8' : undefined }}
									/>
								),
								filterDropdown: ({
									setSelectedKeys,
									selectedKeys,
									confirm,
									clearFilters,
								}) => (
									<TextFilter
										placeholder={t`Број рачуна`}
										setSelectedKeys={setSelectedKeys}
										selectedKeys={selectedKeys}
										confirm={confirm}
										clearFilters={clearFilters}
									/>
								),
							},
							{
								title: t`Статус`,
								width: 200,
								key: 'status',
								render: (receiptItem) => {
									const record = receiptItem.receipt;
									if (record.void) {
										return <Badge status="error" text={t`Поништен`} />;
									}

									if (record.voids) {
										return (
											<Badge status="warning" text={t`Поништава реф. док.`} />
										);
									}

									if (
										[
											InvoiceType.NORMAL,
											InvoiceType.PROFORMA,
											InvoiceType.TRAINING,
										].includes(INVOICE_TYPE_FROM_STRING[record.invoiceType])
									) {
										const hasRefunds =
											record.connectedReceipts.some(
												(receipt) =>
													TRANSACTION_TYPE_FROM_STRING[
														receipt.transactionType
													] === TransactionType.REFUND && !receipt.void
											) &&
											TRANSACTION_TYPE_FROM_STRING[record.transactionType] ===
												TransactionType.SALE;

										if (hasRefunds) {
											const filteredItems = record.connectedReceipts?.filter(
												(cr) =>
													TRANSACTION_TYPE_FROM_STRING[cr.transactionType] ===
														TransactionType.REFUND &&
													[
														InvoiceType.NORMAL,
														InvoiceType.PROFORMA,
														InvoiceType.TRAINING,
													].includes(
														INVOICE_TYPE_FROM_STRING[cr.invoiceType]
													) &&
													!cr.void &&
													!cr.voids
											);
											// 		console.log(filteredItems);
											const refundedItems = (filteredItems || []).reduce(
												(acc, cur) =>
													acc +
													(cur.receiptItems || []).reduce(
														(iacc, icur) => iacc + icur.quantity,
														0
													),
												0
											);
											const items = (record.receiptItems || []).reduce(
												(iacc, icur) => iacc + icur.quantity,
												0
											);

											const remainingItems = refundedItems - items;

											if (round(remainingItems, 2) === 0) {
												return <Badge color="gold" text={t`Рефундиран`} />;
											} else if (refundedItems > 0) {
												return (
													<Badge color="gold" text={t`Делимично рефундиран`} />
												);
											}
										}
									}

									return <Badge status="success" text={t`Издат`} />;
								},
							},

							{
								title: t`Врста трансакције`,
								ellipsis: true,
								key: 'transactionType',
								dataIndex: ['receipt', 'transactionType'],
								render(text) {
									return TRANSACTION_TYPE_TEXT[
										TRANSACTION_TYPE_FROM_STRING[text]
									];
								},
								filterSearch: (input, record) => {
									return cyr2lat(record.text.toLowerCase()).includes(
										cyr2lat(input.toLowerCase())
									);
								},
								filters: Object.keys(TRANSACTION_TYPE_FROM_STRING).map(
									(key) => ({
										text: TRANSACTION_TYPE_TEXT[
											TRANSACTION_TYPE_FROM_STRING[key]
										],
										value: key,
									})
								),
								filterMultiple: true,
								width: 150,
							},
							{
								title: t`Начини плаћања`,
								key: 'receipt.paymentType',
								width: 200,
								render(record) {
									return record.receipt.payment.map((payment) => (
										<Tag
											bordered={false}
											title={numberFormat(payment.amount, false, 2, true)}
										>
											{
												PAYMENT_TYPE_TEXT_SHORT[
													PAYMENT_TYPE_FROM_STRING[payment.paymentType]
												]
											}
										</Tag>
									));
								},
							},

							{
								title: t`Касир`,
								dataIndex: ['receipt', 'cashier', 'fullName'],
								key: 'cashierId',
								ellipsis: true,
								filterSearch: (input, record) => {
									return cyr2lat(record.text.toLowerCase()).includes(
										cyr2lat(input.toLowerCase())
									);
								},
								filters: stores.users.available?.map((user: User) => ({
									text: user.fullName,
									value: user.id,
								})),
								filterMultiple: true,
							},
							{
								title: t`Продајно место`,
								dataIndex: ['receipt', 'store', 'name'],
								ellipsis: true,
								key: 'storeId',
								filterSearch: (input, record) => {
									return cyr2lat(record.text.toLowerCase()).includes(
										cyr2lat(input.toLowerCase())
									);
								},
								filters: stores.stores.available?.map((storeEntity: Store) => ({
									text: storeEntity.name,
									value: storeEntity.id,
								})),
								filterMultiple: true,
							},
						]}
						pagination={memoizedPagination}
					/>
				</Spin>
				<ReceiptViewDrawer location="sales-view" />
			</ConfigProvider>
		</Drawer>
	);
};

const ObservedSales = observer(Sales);

export default ObservedSales;
