import { t, Trans } from '@lingui/macro';
import { Button, ConfigProvider, Empty, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useRef, useState } from 'react';

import { usePermissions } from '../../../hooks/permissions';
import stores from '../../../stores/index.mobx';
import { useDrawer } from '../../Page';

interface Props extends Omit<React.ComponentProps<typeof Select>, 'value'> {
	onChange?: (value: any, option: any) => void;
	value?: string | void;
	showCreateButton?: boolean;
	showAllOption?: boolean;
	onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
}

const WarehouseSelect = function WarehouseSelect({
	onChange,
	value,
	showCreateButton = true,
	showAllOption = false,
	onKeyDown,
	...props
}: Props) {
	const selectRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [, openCreateWarehouseDrawer, , , , EditWarehouseModal] = useDrawer(
		'create/settings/warehouses|warehouse-select',
		(event, entity: any) => {
			if (event === 'entity-create') {
				onChange(entity.id, {
					key: entity.id,
					label: entity.name,
					value: entity.id,
				});
			}
		}
	);

	const openCreateWarehouse = useCallback(() => {
		setOpen(false);
		openCreateWarehouseDrawer();
	}, [openCreateWarehouseDrawer]);

	const onDropdownVisibleChange = useCallback((visible) => {
		setOpen(visible);
	}, []);

	const hasWarehouseCreatePermisssion = usePermissions(
		'settings',
		'warehouses',
		'create'
	);

	return (
		<>
			<ConfigProvider
				renderEmpty={() => (
					<Empty
						image={<img src="/images/icons/new/warehouse.svg" />}
						imageStyle={{ height: 64 }}
						description={t`Нема складишта`}
					></Empty>
				)}
			>
				<Select
					ref={selectRef}
					open={open}
					onDropdownVisibleChange={onDropdownVisibleChange}
					showSearch
					filterOption={(input, option) =>
						option.props.children.toLowerCase().includes(input.toLowerCase())
					}
					loading={stores.warehouses.isFetching}
					onChange={onChange}
					onKeyDown={onKeyDown}
					dropdownRender={
						showCreateButton &&
						hasWarehouseCreatePermisssion &&
						stores.warehouses.available.length > 0
							? (menu) => (
									<>
										{menu}
										<Button type="link" onClick={() => openCreateWarehouse()}>
											<Trans>Додај ново складиште</Trans>
										</Button>
									</>
							  )
							: null
					}
					value={value}
					{...props}
				>
					{showAllOption && (
						<Select.Option key="all" value={''}>
							<Trans>Сва складишта</Trans>
						</Select.Option>
					)}
					{stores.warehouses.available.map((warehouse) => (
						<Select.Option key={warehouse.id} value={warehouse.id}>
							{warehouse.name}
						</Select.Option>
					))}
				</Select>
				{showCreateButton && hasWarehouseCreatePermisssion && (
					<EditWarehouseModal location="warehouse-select" />
				)}
			</ConfigProvider>
		</>
	);
};

export default observer(WarehouseSelect);
