import { Trans, t } from '@lingui/macro';
import { Button, Col, Row, Space } from 'antd';
import { FormInstance } from 'antd/lib';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import printjs from 'print-js';
import { useCallback, useRef, useState } from 'react';
import { flushSync } from 'react-dom';

import styles from './EditButtons.module.less';
import { StaticComponents } from '../../../../../../components/StaticComponents';
import { ERROR_BAD_REQUEST_CANNOT_UNCONFIRM_STOCK_TURNOVER_USED } from '../../../../../../constants/errors';
import { Conversion } from '../../../../../../stores/Conversion.mobx';
import { StockTurnoverStatus } from '../../../../../../stores/StockTurnover.mobx';
import ConversionPrint from '../ConversionPrint';

type Props = {
	entity: Conversion;
	save: (closeDrawer: boolean) => Promise<Conversion>;
	close: () => void;
	isCreating: boolean;
	isLoading: boolean;
	disabled: boolean;
	canEditPermission: boolean;
	form?: FormInstance;
};

function BaseEditButtons({
	entity,
	isLoading,
	isCreating,
	save,
	close,
	disabled,
	canEditPermission,
}: Props) {
	const printAreaRef = useRef<HTMLDivElement>(null);
	const [printData, setPrintData] = useState<any>(null);
	const handlePrint = useCallback(() => {
		flushSync(() => {
			setPrintData({
				conversion: entity,
			});
		});
		printjs({
			printable: 'printarea',
			type: 'html',
			targetStyles: ['*'],
			font_size: '',
		});
	}, [entity]);
	const handleConfirm = useCallback(async () => {
		await save(false);
		await flowResult(entity?.confirm());
		close();
	}, [save, entity, close]);
	const handleUnconfirm = useCallback(async () => {
		try {
			await flowResult(entity?.unconfirm());
		} catch (error) {
			console.log(error);
			if (
				error?.response?.data?.errorCode ===
				ERROR_BAD_REQUEST_CANNOT_UNCONFIRM_STOCK_TURNOVER_USED
			) {
				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Није могуће отказати потврђивање нивелације јер су неки артикли са залиха већ део другог промета.`,
				});
			}
		}
	}, [entity]);

	const handleSave = useCallback(async () => {
		if (
			!entity ||
			entity?.stockTurnoverItem?.status === StockTurnoverStatus.DRAFT
		) {
			try {
				await save(false);
			} catch (error) {
				//
			}
		}
	}, [entity, save]);

	return (
		<>
			<Row>
				<Col flex="auto">
					<Space>
						<Button
							key="cancel"
							onClick={close}
							disabled={
								isLoading ||
								isCreating ||
								entity?.isUpdating ||
								entity?.isConfirming
							}
						>
							<Trans>Одустани</Trans>
						</Button>
					</Space>
				</Col>
				<Col flex="none">
					<Space>
						{/* <Button
							disabled={
								entity?.stockTurnoverItem?.status ===
									StockTurnoverStatus.DRAFT || isLoading
							}
							onClick={handlePrint}
						>
							Штампај
						</Button> */}
						{entity?.stockTurnoverItem?.status ===
							StockTurnoverStatus.DRAFT && (
							<Button
								disabled={
									isLoading ||
									isCreating ||
									entity?.isUpdating ||
									entity?.isConfirming
								}
								loading={entity?.isConfirming}
								onClick={handleConfirm}
							>
								<Trans>Потврди</Trans>
							</Button>
						)}
						{/* {entity?.stockTurnoverItem?.status ===
							StockTurnoverStatus.CONFIRMED && (
							<Button
								disabled={
									isLoading ||
									isCreating ||
									entity?.isUpdating ||
									entity?.isConfirming
								}
								loading={entity?.isConfirming}
								onClick={handleUnconfirm}
							>
								<Trans>Откажи потврђивање</Trans>
							</Button>
						)} */}
						<Button
							key="save"
							type="primary"
							loading={isCreating || entity?.isUpdating}
							onClick={handleSave}
							disabled={
								isLoading ||
								disabled ||
								isCreating ||
								entity?.isUpdating ||
								!canEditPermission
							}
						>
							<Trans>Сачувај нацрт</Trans>
						</Button>
					</Space>
				</Col>
			</Row>

			<div className={styles.printArea}>
				<div ref={printAreaRef} id="printarea">
					{printData && <ConversionPrint {...printData} />}
				</div>
			</div>
		</>
	);
}

export const EditButtons = observer(BaseEditButtons);
