import { Trans, t } from '@lingui/macro';

import { CreatePage } from '../../../../../components/Page';
import { Tooltip, Typography } from 'antd';
import { Footer } from './Components/Footer';
import { MojBadiAdministrationOffer } from '../../../../../stores/MojBadiAdministrationOffer';

export default () =>
	CreatePage({
		module: 'administration',
		submodule: 'mojBadiOffers',
		model: 'mojBadiAdministrationOffers',
		view: {
			useEdit: false,
			title: t`Преглед понуде`,
			shouldFetch: false,
			footer: (entity: MojBadiAdministrationOffer) => {
				return <Footer entity={entity} />;
			},
			fields: (record) => [
				{
					label: t`Основне информације`,
					fields: [
						{
							key: 'name',
							label: t`Назив`,
							dataIndex: 'name',
							span: 24,
						},
						{
							key: 'description',
							label: t`Опис`,
							dataIndex: 'description',
							span: 24,
						},
						{
							key: 'type',
							label: t`Тип`,
							dataIndex: 'type',
							span: 6,
							render: (type) => {
								if (type === 'percentage') {
									return t`Проценат`;
								}

								if (type === 'fixed-price') {
									return t`Фиксна цена`;
								}

								if (type === 'buy-n-get-m') {
									return t`Купи N добиј M`;
								}
							},
						},
						...(record.type === 'percentage'
							? [
									{
										key: 'percentage',
										label: t`Проценат`,
										dataIndex: 'percentage',
										span: 6,
										render: (percentage) => `${percentage}%`,
									},
							  ]
							: []),
						...(record.type === 'fixed-price'
							? [
									{
										key: 'fixedPrice',
										label: t`Фиксна цена`,
										dataIndex: 'fixedPrice',
										span: 6,
										render: (fixedPrice) => `${fixedPrice} RSD`,
									},
							  ]
							: []),
						...(record.type === 'buy-n-get-m'
							? [
									{
										key: 'requiredForPurchase',
										label: t`Купи`,
										dataIndex: 'requiredForPurchase',
										span: 6,
									},
									{
										key: 'promotedProductQuantity',
										label: t`Добиј`,
										dataIndex: 'promotedProductQuantity',
										span: 6,
									},
									{
										key: 'promotedProductPrice',
										label: t`Цена`,
										dataIndex: 'promotedProductPrice',
										span: 6,
										render: (promotedProductPrice) =>
											`${promotedProductPrice} RSD`,
									},
							  ]
							: []),
					],
				},
				{
					label: t`Артикли`,
					key: 'products',
					fields: [
						{
							key: 'product',
							label: t`Артикал`,
							dataIndex: ['product', 'name'],
							span: 24,
						},
						{
							key: 'productImage',
							label: t`Слика`,
							dataIndex: ['product', 'coverImage'],
							span: 24,
							render: (coverImage, record) => (
								<img
									src={record.product?.coverImage?.urls?.['1024x1024']}
									style={{ width: '100%' }}
								/>
							),
						},
						...(record.type === 'buy-n-get-m'
							? [
									{
										key: 'promotedProduct',
										label: t`Промовисани артикал`,
										dataIndex: ['promotedProduct', 'name'],
										span: 24,
									},
									{
										key: 'promotedProductImage',
										label: t`Слика`,
										dataIndex: ['promotedProduct', 'coverImage'],
										span: 24,
										render: (coverImage, record) => (
											<img
												src={
													record.promotedProduct?.coverImage?.urls?.[
														'1024x1024'
													]
												}
												style={{ width: '100%' }}
											/>
										),
									},
							  ]
							: []),
					],
				},
				{
					label: t`Интервал`,
					fields: [
						{
							key: 'interval',
							span: 24,
							render: (text, record) => {
								if (record.beginAt && record.endAt) {
									return (
										<Trans>
											Од {record.beginAt.format('DD.MM.YYYY HH:mm')} до{' '}
											{record.endAt.format('DD.MM.YYYY HH:mm')}
										</Trans>
									);
								}

								if (record.beginAt) {
									return (
										<Trans>
											Од {record.beginAt.format('DD.MM.YYYY HH:mm')}
										</Trans>
									);
								}

								if (record.endAt) {
									return (
										<Trans>До {record.endAt.format('DD.MM.YYYY HH:mm')}</Trans>
									);
								}

								return <Trans>Неограничено</Trans>;
							},
						},
					],
				},
			],
		},
		page: {
			// docsLink: 'koriscenje/moduli-aplikacije/podesavanja/korisnici/',
			shouldFetch: true,
			table: {
				columns: [
					{
						title: t`Назив`,
						dataIndex: 'name',
						key: 'name',
						shouldLink: true,
						ellipsis: true,
					},
					{
						title: t`Опис`,
						dataIndex: 'description',
						key: 'description',
						ellipsis: true,
					},
					{
						title: t`Предузеће`,
						dataIndex: ['company', 'name'],
						key: 'company',
						ellipsis: true,
					},
					{
						title: t`Статус`,
						dataIndex: 'status',
						key: 'status',
						shouldLink: true,
						ellipsis: true,
						width: 100,
						render: (status, record) => {
							if (status === 'active') {
								return (
									<Typography.Text type="success">
										<i className="fi fi-rr-check-circle"></i>{' '}
										<Trans>Активна</Trans>
									</Typography.Text>
								);
							}

							if (status === 'pending') {
								return (
									<Typography.Text type="secondary">
										<i className="fi fi-rr-pause-circle"></i>{' '}
										<Trans>На чекању</Trans>
									</Typography.Text>
								);
							}

							if (status === 'rejected') {
								return (
									<Tooltip title={record.rejectedReason}>
										<Typography.Text type="danger">
											<i className="fi fi-rr-cross-circle"></i>{' '}
											<Trans>Одбијена</Trans>
										</Typography.Text>
									</Tooltip>
								);
							}
						},
					},
				],
				showDisabledActions: false,
			},
		},
	});
