import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { Trans, t } from '@lingui/macro';
import {
	Col,
	Divider,
	Input,
	InputNumber,
	Row,
	Table,
	Tag,
	Typography,
} from 'antd';
import { round } from 'lodash';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './CalculationItems.module.less';
import TaxRateSelect from './TaxRateSelect';
import ProductInput from '../../../../../../components/Inputs/ProductInput';
import WarehouseSelect from '../../../../../../components/Selects/WarehouseSelect';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import { Title } from '../../../../../../components/Title';
import numberFormat from '../../../../../../lib/numberFormat';
import { deductTax } from '../../../../../../lib/tax';
import { CalculationItem } from '../../../../../../stores/Calculation.mobx';
import stores from '../../../../../../stores/index.mobx';
type Props = {
	value?: CalculationItem[];
	partnerId?: string;
};

function CalculationsItemsInput({ value, partnerId, ...props }: Props) {
	const {
		calculations: { isFetchingLastCalculationItem },
		warehouses: { byId: storesById, available: availableWarehouses },
		products: { byId: productsById },
		taxRates,
	} = stores;

	const editableFormRef = useRef<EditableFormInstance>();
	const [initialValue, setInitialValue] = useState<Partial<CalculationItem>>(
		{}
	);

	useEffect(() => {
		if (availableWarehouses?.length === 1) {
			setInitialValue({
				warehouseId: availableWarehouses[0]?.id,
			});
		}
	}, [availableWarehouses]);

	const [totalsDataSource, taxesDetailsDataSource] = useMemo(() => {
		const totalPurchaseAmountWithRebate = round(
			value?.reduce((acc, item) => acc + Number(item.purchaseAmount || 0), 0),
			4
		);

		const totalPurchaseAmount = round(
			value?.reduce(
				(acc, item) =>
					acc +
					Number(item.purchasePrice || 0) * Number(item.invoiceQuantity || 0),
				0
			),
			4
		);

		const purchaseAmountWithVat = round(
			value?.reduce(
				(acc, item) =>
					acc +
					Number(item.purchaseAmount || 0) *
						(1 + Number(item.purchaseTaxRate || 0) / 100),
				0
			),
			4
		);

		const totalSaleAmount = round(
			value?.reduce(
				(acc, item) =>
					acc + Number(item.salePrice || 0) * Number(item.quantity || 0),
				0
			),
			4
		);

		const totalSaleAmountWithVat = round(
			value?.reduce(
				(acc, item) => acc + Number(item.saleAmountWithVat || 0),
				0
			),
			4
		);

		const taxes = value?.reduce((acc, item) => {
			acc[item.purchaseTaxRate] = acc[item.purchaseTaxRate] || 0;
			acc[item.purchaseTaxRate] = round(
				acc[item.purchaseTaxRate] +
					item.purchaseAmount * (item.purchaseTaxRate / 100),
				4
			);

			return acc;
		}, {});

		const taxesDetails = value?.reduce((acc, item) => {
			acc[item.taxRate] = acc[item.taxRate] || {
				purchaseAmount: 0,
				priceDifference: 0,
				saleAmount: 0,
				taxAmount: 0,
				saleAmountWithVat: 0,
			};

			acc[item.taxRate].purchaseAmount = round(
				acc[item.taxRate].purchaseAmount + item.purchaseAmount,
				4
			);

			acc[item.taxRate].priceDifference = round(
				acc[item.taxRate].priceDifference +
					(item.salePrice * item.quantity - item.purchaseAmount),
				4
			);

			acc[item.taxRate].saleAmount = round(
				acc[item.taxRate].saleAmount + item.salePrice * item.quantity,
				4
			);

			acc[item.taxRate].taxAmount = round(
				acc[item.taxRate].taxAmount +
					(item.salePriceWithVat - item.salePrice) * item.quantity,
				4
			);

			acc[item.taxRate].saleAmountWithVat = round(
				acc[item.taxRate].saleAmountWithVat +
					item.salePriceWithVat * item.quantity,
				4
			);

			return acc;
		}, {}) as Record<
			number,
			{
				purchaseAmount: number;
				priceDifference: number;
				saleAmount: number;
				taxAmount: number;
				saleAmountWithVat: number;
			}
		>;

		const taxesRows = [];

		Object.keys(taxes || {}).forEach((tax) => {
			taxesRows.push({
				title: t`Порез ${numberFormat(tax, false, 2, true)}%:`,
				taxAmount: `+${numberFormat(taxes[tax], false, 2, true)}`,
				key: `tax${tax}`,
			});
		});

		const taxesDetailsRows = [];

		Object.keys(taxesDetails || {}).forEach((tax) => {
			taxesDetailsRows.push({
				title: t`Порез на додату вредност ${numberFormat(
					tax,
					false,
					2,
					true
				)}%:`,
				amount: numberFormat(taxesDetails[tax].purchaseAmount, false, 2, true),
				priceDifference: numberFormat(
					taxesDetails[tax].priceDifference,
					false,
					2,
					true
				),
				saleAmount: numberFormat(taxesDetails[tax].saleAmount, false, 2, true),
				taxAmount: numberFormat(taxesDetails[tax].taxAmount, false, 2, true),
				saleAmountWithVat: numberFormat(
					taxesDetails[tax].saleAmountWithVat,
					false,
					2,
					true
				),
				key: `tax${tax}`,
			});
		});

		const totals = Object.values(taxesDetails || {}).reduce(
			(acc, item) => {
				acc.purchaseAmount = round(acc.purchaseAmount + item.purchaseAmount, 4);
				acc.priceDifference = round(
					acc.priceDifference + item.priceDifference,
					4
				);
				acc.saleAmount = round(acc.saleAmount + item.saleAmount, 4);
				acc.taxAmount = round(acc.taxAmount + item.taxAmount, 4);
				acc.saleAmountWithVat = round(
					acc.saleAmountWithVat + item.saleAmountWithVat,
					4
				);

				return acc;
			},
			{
				purchaseAmount: 0,
				priceDifference: 0,
				saleAmount: 0,
				taxAmount: 0,
				saleAmountWithVat: 0,
			}
		);

		taxesDetailsRows.push({
			title: t`Укупно:`,
			amount: numberFormat(totals.purchaseAmount, false, 2, true),
			priceDifference: numberFormat(totals.priceDifference, false, 2, true),
			saleAmount: numberFormat(totals.saleAmount, false, 2, true),
			taxAmount: numberFormat(totals.taxAmount, false, 2, true),
			saleAmountWithVat: numberFormat(totals.saleAmountWithVat, false, 2, true),
			key: `taxTotals`,
			className: styles.heading,
		});

		return [
			[
				{
					title: t`Набавна вредност:`,
					amount: numberFormat(totalPurchaseAmount, false, 2, true),
				},
				{
					title: t`Рабат:`,
					amount: numberFormat(
						totalPurchaseAmountWithRebate - totalPurchaseAmount,
						false,
						2,
						true
					),
				},
				...taxesRows,
				{
					title: t`Вредност без ПДВ / ПДВ:`,
					amount: numberFormat(totalPurchaseAmountWithRebate, false, 2, true),
					taxAmount: numberFormat(
						purchaseAmountWithVat - totalPurchaseAmountWithRebate,
						false,
						2,
						true
					),
				},
				{
					title: t`Укупна набавна вредност:`,
					amount: numberFormat(purchaseAmountWithVat, false, 2, true),
					className: styles.heading,
				},
				{
					title: t`Разлика у цени:`,
					amount: numberFormat(
						totalSaleAmount - totalPurchaseAmountWithRebate,
						false,
						2,
						true
					),
				},
				{
					title: t`Продајна вредност без ПДВ / ПДВ:`,
					amount: numberFormat(totalSaleAmount, false, 2, true),
					taxAmount: numberFormat(
						totalSaleAmountWithVat - totalSaleAmount,
						false,
						2,
						true
					),
				},
				{
					title: t`Укупна продајна вредност:`,
					amount: numberFormat(totalSaleAmountWithVat, false, 2, true),
					className: styles.heading,
				},
			],
			taxesDetailsRows,
		];
	}, [value]);

	const onSelectWarehouse = useCallback((warehouseId) => {
		setInitialValue({
			warehouseId,
		});
	}, []);

	const columns = useMemo(() => {
		return [
			{
				title: t`Складиште`,
				width: 150,
				dataIndex: 'warehouseId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Складиште је обавезно`,
						},
					],
				},
				renderFormItem: ({ handleKeyDown }, { recordKey, isEditable }) => {
					return isEditable ? (
						<WarehouseSelect
							popupMatchSelectWidth={false}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onSelect={onSelectWarehouse}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					return storesById[value]?.name;
				},
			},
			{
				title: t`Артикал`,
				dataIndex: 'productId',
				formItemProps: {
					rules: [
						{
							required: true,
							message: t`Артикал је обавезан`,
						},
					],
				},
				renderFormItem: (
					{ handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<ProductInput
							warehouseId={record.warehouseId}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onChange={(value) => {
								setTimeout(async () => {
									const product = productsById[value];

									if (!product) {
										return;
									}

									const taxRate =
										taxRates.byLabel(product?.taxRateLabel || '')?.rate || 0;

									const purchaseTaxRate = taxRate;

									const salePriceWithVat = Number(
										product?.getPriceForWarehouse?.(record.warehouseId) ||
											product?.price ||
											0
									);
									const salePrice = deductTax(salePriceWithVat, taxRate);
									const saleAmountWithVat = round(
										salePriceWithVat * Number(record.quantity || 0),
										4
									);

									const lastCalculationItem = await flowResult(
										stores.calculations.fetchLastCalculationItem(
											product.id,
											partnerId
										)
									);

									const productUnit = product?.unit?.name || `kom`;

									const invoiceQuantity = 0;

									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										salePrice,
										saleAmountWithVat,
										productUnit,
										invoiceQuantity,
										taxRate,
										purchaseTaxRate,
									});

									if (lastCalculationItem) {
										let { purchasePrice, quantityInPack, rebate } =
											lastCalculationItem;

										purchasePrice = Number(purchasePrice || 0);
										quantityInPack = Number(quantityInPack || 1);
										rebate = Number(rebate || 0);

										const purchasePriceWithRebate = round(
											purchasePrice * (1 - rebate / 100),
											4
										);

										const unitPurchasePrice = round(
											purchasePriceWithRebate / quantityInPack,
											4
										);

										const purchaseAmount = round(
											unitPurchasePrice * Number(record.quantity || 0),
											4
										);

										const purchasePriceWithVat = round(
											unitPurchasePrice * (1 + taxRate / 100),
											4
										);

										const quantity = round(
											Number(record.quantity || 0) *
												Number(quantityInPack || 0),
											3
										);

										let margin = round(
											((salePriceWithVat - purchasePriceWithVat) /
												purchasePriceWithVat) *
												100,
											4
										);

										if (margin === Infinity || Number.isNaN(margin)) {
											margin = null;
										}

										editableFormRef.current?.setRowData?.(recordKey, {
											purchasePrice,
											purchaseAmount,
											quantity,
											quantityInPack,
											rebate,
											margin,
										});
									}
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value, record, index) => {
					const product = productsById[value];
					return product?.parent ? (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.parent?.name}&nbsp;&nbsp;
							<Tag color="magenta" bordered={false}>
								{product?.variantName}
							</Tag>
						</>
					) : (
						<>
							<Typography.Text type="secondary">{product?.sku}</Typography.Text>
							<Divider type="vertical" />
							{product?.name}
						</>
					);
				},
			},
			{
				title: t`Количина`,
				dataIndex: 'invoiceQuantity',
				width: 90,
				align: 'right',
				formItemProps: {
					initialValue: 0,
					rules: [
						{
							required: true,
							message: t`Количина је обавезна`,
						},
						{
							type: 'number',
							min: 0.001,
							message: t`Количина мора бити већа од 0`,
						},
					],
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const quantityInPack = Number(record.quantityInPack || 1);
									const rebate = Number(record.rebate || 0);
									const purchasePrice = Number(record.purchasePrice || 0);
									const quantity = round(Number(value) * quantityInPack, 3);

									const purchasePriceWithRebate = round(
										purchasePrice * (1 - rebate / 100),
										4
									);

									const unitPurchasePrice = round(
										Number(purchasePriceWithRebate || 0) / quantityInPack,
										4
									);

									const purchaseAmount = round(
										Number(purchasePriceWithRebate || 0) * Number(value),
										4
									);

									const purchasePriceWithVat = round(
										unitPurchasePrice * (1 + record.taxRate / 100),
										4
									);

									let margin = round(
										((record.salePriceWithVat - purchasePriceWithVat) /
											purchasePriceWithVat) *
											100,
										4
									);

									const saleAmountWithVat = round(
										record.salePriceWithVat * Number(quantity || 0),
										4
									);

									if (margin === Infinity || Number.isNaN(margin)) {
										margin = null;
									}

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity,
										purchaseAmount,
										unitPurchasePrice,
										margin,
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 3, false);
				},
			},
			{
				title: t`Кол. у пак.`,
				dataIndex: 'quantityInPack',
				width: 90,
				align: 'right',
				formItemProps: {
					initialValue: 1,
					rules: [
						{
							required: true,
							message: t`Количина у паковању је обавезна`,
						},
						{
							type: 'number',
							min: 0.001,
							message: t`Количина у паковању мора бити већа од 0`,
						},
					],
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const quantityInPack = Number(value || 1);
									const invoiceQuantity = Number(record.invoiceQuantity || 0);
									const purchasePrice = Number(record.purchasePrice || 0);
									const rebate = Number(record.rebate || 0);

									const purchasePriceWithRebate = round(
										purchasePrice * (1 - rebate / 100),
										4
									);

									const quantity = round(invoiceQuantity * quantityInPack, 3);

									const unitPurchasePrice = round(
										purchasePriceWithRebate / quantityInPack,
										4
									);

									const purchaseAmount = round(
										purchasePriceWithRebate * invoiceQuantity,
										4
									);

									const purchasePriceWithVat = round(
										unitPurchasePrice * (1 + record.taxRate / 100),
										4
									);

									let margin = round(
										((record.salePriceWithVat - purchasePriceWithVat) /
											purchasePriceWithVat) *
											100,
										4
									);

									if (margin === Infinity || Number.isNaN(margin)) {
										margin = null;
									}

									const saleAmountWithVat = round(
										record.salePriceWithVat * Number(quantity || 0),
										4
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity,
										purchaseAmount,
										unitPurchasePrice,
										margin,
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 3, false);
				},
			},
			{
				title: t`Улазна кол.`,
				dataIndex: 'quantity',
				align: 'right',
				width: 90,
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 3, false);
				},
			},
			{
				title: t`ЈМ`,
				dataIndex: 'productUnit',
				width: 70,
				renderFormItem: () => <Input disabled />,
			},
			{
				title: t`Цена`,
				dataIndex: 'purchasePrice',
				width: 100,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const purchasePrice = Number(value || 0);
									const quantityInPack = Number(record.quantityInPack || 1);
									const invoiceQuantity = Number(record.invoiceQuantity || 0);
									const quantity = Number(record.quantity || 0);
									const rebate = Number(record.rebate || 0);

									const purchasePriceWithRebate = round(
										purchasePrice * (1 - rebate / 100),
										4
									);

									const unitPurchasePrice = round(
										purchasePriceWithRebate / quantityInPack,
										4
									);

									const purchaseAmount = round(
										purchasePriceWithRebate * invoiceQuantity,
										4
									);

									const purchasePriceWithVat = round(
										unitPurchasePrice * (1 + record.taxRate / 100),
										4
									);

									let margin = round(
										((record.salePriceWithVat - purchasePriceWithVat) /
											purchasePriceWithVat) *
											100,
										4
									);

									if (margin === Infinity || Number.isNaN(margin)) {
										margin = null;
									}

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity,
										purchaseAmount,
										unitPurchasePrice,
										margin,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},

			{
				title: t`Рабат %`,
				dataIndex: 'rebate',
				width: 80,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							// max={100}
							min={0}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const purchasePrice = Number(record.purchasePrice || 0);
									const quantityInPack = Number(record.quantityInPack || 1);
									const invoiceQuantity = Number(record.invoiceQuantity || 0);
									const quantity = Number(record.quantity || 0);
									const rebate = Number(value || 0);

									const purchasePriceWithRebate = round(
										purchasePrice * (1 - rebate / 100),
										4
									);

									const unitPurchasePrice = round(
										purchasePriceWithRebate / quantityInPack,
										4
									);

									const purchaseAmount = round(
										purchasePriceWithRebate * invoiceQuantity,
										4
									);

									const purchasePriceWithVat = round(
										unitPurchasePrice * (1 + record.taxRate / 100),
										4
									);

									let margin = round(
										((record.salePriceWithVat - purchasePriceWithVat) /
											purchasePriceWithVat) *
											100,
										4
									);

									if (margin === Infinity || Number.isNaN(margin)) {
										margin = null;
									}

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity,
										purchaseAmount,
										unitPurchasePrice,
										margin,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Наб. цена`,
				dataIndex: 'unitPurchasePrice',
				width: 100,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`ПДВ %`,
				dataIndex: 'purchaseTaxRate',
				align: 'right',
				width: 100,
				renderFormItem: () => {
					return <TaxRateSelect />;
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Наб. вредност`,
				dataIndex: 'purchaseAmount',
				width: 120,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Маржа %`,
				dataIndex: 'margin',
				width: 80,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const purchasePrice = Number(record.purchasePrice || 0);
									const quantityInPack = Number(record.quantityInPack || 1);
									const invoiceQuantity = Number(record.invoiceQuantity || 0);
									const quantity = Number(record.quantity || 0);
									const rebate = Number(record.rebate || 0);

									const purchasePriceWithRebate = round(
										purchasePrice * (1 - rebate / 100),
										4
									);

									const unitPurchasePrice = round(
										purchasePriceWithRebate / quantityInPack,
										4
									);

									const purchaseAmount = round(
										purchasePriceWithRebate * invoiceQuantity,
										4
									);

									const margin = Number(value || 0);

									const salePrice = round(
										unitPurchasePrice * (1 + margin / 100),
										4
									);

									const salePriceWithVat = round(
										salePrice * (1 + record.taxRate / 100),
										4
									);

									const saleAmountWithVat = round(
										salePriceWithVat * quantity,
										4
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										quantity,
										purchaseAmount,
										unitPurchasePrice,
										salePrice,
										salePriceWithVat,
										saleAmountWithVat,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Прод. цена`,
				dataIndex: 'salePrice',
				width: 100,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const quantity = Number(record.quantity || 0);
									const salePrice = Number(value || 0);

									const unitPurchasePrice = Number(
										record.unitPurchasePrice || 0
									);

									const purchasePriceWithVat = round(
										unitPurchasePrice * (1 + record.taxRate / 100),
										4
									);

									const salePriceWithVat = round(
										salePrice * (1 + record.taxRate / 100),
										4
									);

									let margin = round(
										((salePriceWithVat - purchasePriceWithVat) /
											purchasePriceWithVat) *
											100,
										4
									);

									if (margin === Infinity || Number.isNaN(margin)) {
										margin = null;
									}

									const saleAmountWithVat = round(
										salePriceWithVat * quantity,
										4
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										salePriceWithVat,
										saleAmountWithVat,
										margin,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},

			{
				title: t`Прод. цена са ПДВ`,
				dataIndex: 'salePriceWithVat',
				width: 150,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: (
					{ handlePressEnter, handleKeyDown },
					{ isEditable, record, recordKey }
				) => {
					return isEditable ? (
						<InputNumber
							type="number"
							onPressEnter={(e) => handlePressEnter(e, recordKey)}
							onKeyDown={(e) => handleKeyDown(e, recordKey)}
							controls={false}
							className={styles.rightAlignNumberInput}
							style={{ width: '100%' }}
							onFocus={(event) => {
								event.currentTarget.select();
							}}
							onChange={(value) => {
								setTimeout(() => {
									const quantity = Number(record.quantity || 0);

									const unitPurchasePrice = Number(
										record.unitPurchasePrice || 0
									);

									const purchasePriceWithVat = round(
										unitPurchasePrice * (1 + record.taxRate / 100),
										4
									);

									const salePriceWithVat = Number(value || 0);
									const salePrice = deductTax(salePriceWithVat, record.taxRate);

									let margin = round(
										((salePriceWithVat - purchasePriceWithVat) /
											purchasePriceWithVat) *
											100,
										4
									);

									if (margin === Infinity || Number.isNaN(margin)) {
										margin = null;
									}

									const saleAmountWithVat = round(
										salePriceWithVat * quantity,
										4
									);

									editableFormRef.current?.setRowData?.(recordKey, {
										salePrice,
										saleAmountWithVat,
										margin,
									});
								}, 100);
							}}
						/>
					) : (
						<Input />
					);
				},
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
			{
				title: t`Прод. вр. са ПДВ`,
				dataIndex: 'saleAmountWithVat',
				width: 130,
				align: 'right',
				formItemProps: {
					initialValue: 0,
				},
				renderFormItem: () => (
					<InputNumber
						disabled
						controls={false}
						className={styles.rightAlignNumberInput}
						style={{ width: '100%' }}
						onFocus={(event) => {
							event.currentTarget.select();
						}}
					/>
				),
				render: (value: string) => {
					return numberFormat(value, false, 2, true);
				},
			},
		];
	}, [onSelectWarehouse, partnerId, productsById, storesById, taxRates]);

	return (
		<>
			<TableInputProTable<CalculationItem>
				editableFormRef={editableFormRef}
				// shouldShowDuplicateButton
				shouldShowIndexColumn
				addButtonText={t`Додај артикал`}
				emptyText={t`Нема ставки`}
				iconPath="/images/icons/new/calculator.svg"
				columns={columns}
				value={value}
				initialValue={initialValue}
				tableProps={{
					loading: isFetchingLastCalculationItem,
					scroll: {
						x: 1900,
					},
				}}
				{...props}
			/>
			<div className={styles.title}>
				<Title>
					<Trans>Рекапитулација калкулације</Trans>
				</Title>
			</div>
			<Row gutter={[8, 8]}>
				<Col xs={24} sm={7}>
					<div className={styles.container}>
						<Table
							rowClassName={(record) => record.className || ''}
							size="small"
							pagination={false}
							dataSource={totalsDataSource}
							showHeader={false}
							columns={[
								{
									dataIndex: 'title',
									align: 'right',
									className: styles.heading,
								},
								{
									dataIndex: 'amount',
								},
								{
									dataIndex: 'taxAmount',
								},
							]}
						></Table>
					</div>
				</Col>
				<Col xs={24} sm={17}>
					<div className={styles.container}>
						<Table
							rowClassName={(record) => record.className || ''}
							size="small"
							pagination={false}
							dataSource={taxesDetailsDataSource}
							columns={[
								{
									dataIndex: 'title',
									align: 'right',
									className: styles.heading,
								},
								{
									title: t`Набавна вредност`,
									dataIndex: 'amount',
								},
								{
									title: t`Продајна вредност без ПДВ`,
									dataIndex: 'saleAmount',
								},
								{
									title: t`Разлика у цени`,
									dataIndex: 'priceDifference',
								},
								{
									title: t`ПДВ`,
									dataIndex: 'taxAmount',
								},
								{
									title: t`Продајна вредност`,
									dataIndex: 'saleAmountWithVat',
								},
							]}
						></Table>
					</div>
				</Col>
			</Row>
		</>
	);
}

export default observer(CalculationsItemsInput);
