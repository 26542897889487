import type { EditableFormInstance } from '@ant-design/pro-table/es';
import { Trans, t } from '@lingui/macro';
import { Divider, Input, InputNumber, Table, Tag, Typography } from 'antd';
import { round } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import ProductInput from '../../../../../../components/Inputs/ProductInput';
import WarehouseSelect from '../../../../../../components/Selects/WarehouseSelect';
import { TableInputProTable } from '../../../../../../components/TableInputProTable';
import numberFormat from '../../../../../../lib/numberFormat';
import { deductTax } from '../../../../../../lib/tax';
import stores from '../../../../../../stores/index.mobx';
import { flowResult } from 'mobx';
import { bignumber, evaluate } from 'mathjs';
import dayjs from 'dayjs';
type Props = {
	warehouseId: string;
	items: DifferenceItem[];
};

type DifferenceItem = {
	productId: string;
	checkupQuantity: number;
	stockQuantity: number;
	difference: number;
};

export default function DifferenceItems({ warehouseId, items }: Props) {
	return (
		<>
			<Typography.Text>
				<Trans>
					Попис није могуће потврдити јер постоје разлике у количинама између
					пописа и тренутног стања на складишту.
				</Trans>
			</Typography.Text>
			<br />
			<br />
			<Table
				dataSource={items}
				columns={[
					{
						title: t`Артикал`,
						dataIndex: 'productId',
						render: (productId) => {
							const product = stores.products.getById(productId);
							return (
								<>
									<Typography.Text type="secondary">
										{product?.sku}
									</Typography.Text>
									<Divider type="vertical" />
									{product?.parent ? (
										<>
											{product.parent.name}{' '}
											<Tag bordered={false} color="magenta">
												{product.variantName}
											</Tag>
										</>
									) : (
										product.name
									)}
								</>
							);
						},
					},
					{
						title: t`Количина на попису`,
						dataIndex: 'checkupQuantity',
						width: 200,
						align: 'right',
						render: (checkupQuantity) =>
							numberFormat(checkupQuantity, false, 3, true),
					},
					{
						title: t`Количина на складишту`,
						dataIndex: 'stockQuantity',
						width: 200,
						align: 'right',
						render: (stockQuantity) =>
							numberFormat(stockQuantity, false, 3, true),
					},
					{
						title: t`Разлика`,
						dataIndex: 'difference',
						width: 100,
						align: 'right',
						render: (difference) => numberFormat(difference, false, 3, true),
					},
				]}
				bordered
				size="small"
				rowKey={'productId'}
			/>
		</>
	);
}
