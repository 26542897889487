import { Select } from 'antd';
import { observer } from 'mobx-react-lite';

import stores from '../../stores/index.mobx';
import { Trans } from '@lingui/macro';

type StoreSelectProps = {
	showAllOption?: boolean;
};

function StoreSelect(props: StoreSelectProps) {
	const {
		stores: { isFetching, available },
	} = stores;

	return (
		<Select
			showSearch
			filterOption={(input, option) => {
				return (
					(option.children as unknown as string)
						.toLowerCase()
						.indexOf(input.toLowerCase()) !== -1
				);
			}}
			loading={isFetching}
			popupMatchSelectWidth={false}
			{...props}
		>
			{props.showAllOption && (
				<Select.Option value={null}>
					<Trans>Сва продајна места</Trans>
				</Select.Option>
			)}
			{available.map((store) => (
				<Select.Option key={store.id} value={store.id}>
					{store.displayName}
				</Select.Option>
			))}
		</Select>
	);
}

export default observer(StoreSelect);
