import { Plural, Trans, t } from '@lingui/macro';
import {
	List,
	Button,
	Skeleton,
	Tag,
	Row,
	Col,
	Tooltip,
	Typography,
	Alert,
} from 'antd';
import { evaluate, bignumber } from 'mathjs';
import { NoImage } from '../../../../components/NoImage';
import numberFormat from '../../../../lib/numberFormat';
import numeral from 'numeral';
import { Offer } from '../../../../stores/Offer.mobx';
import { LocalSale, LocalSaleItem } from '../../../../stores/LocalSale.mobx';

import styles from '../POS.module.less';
import { observer } from 'mobx-react-lite';

interface Props {
	item: LocalSaleItem | Offer;
	sale: LocalSale;
	setEditSaleItem: (item: LocalSaleItem) => void;
	setEditModalVisible: (visible: boolean) => void;
	playDelete: () => void;
}

export const ListItemBase = ({
	item,
	sale,
	setEditSaleItem,
	setEditModalVisible,
	playDelete,
}: Props) => {
	if (item instanceof Offer) {
		return (
			<List.Item key={item.id}>
				<Alert
					type="success"
					message={
						<Row gutter={8} wrap={false}>
							<Col>
								<i className="fi fi-rr-badge-percent" />
							</Col>
							<Col flex={1}>
								{item.promotedProductPrice === 0 ? (
									<Plural
										value={item.promotedProductQuantity}
										one={
											<span>
												Купац је остварио право на гратис артикал{' '}
												<strong>{item.promotedProduct?.name}</strong>
											</span>
										}
										two={
											<span>
												Купац је остварио право на{' '}
												{item.promotedProductQuantity} гратис артикла{' '}
												<strong>{item.promotedProduct?.name}</strong>
											</span>
										}
										few={
											<span>
												Купац је остварио право на{' '}
												{item.promotedProductQuantity} гратис артикла{' '}
												<strong>{item.promotedProduct?.name}</strong>
											</span>
										}
										other={
											<span>
												Купац је остварио право на{' '}
												{item.promotedProductQuantity} гратис артикала{' '}
												<strong>{item.promotedProduct?.name}</strong>
											</span>
										}
									/>
								) : (
									<Plural
										value={item.promotedProductQuantity}
										one={
											<span>
												Купац је остварио право на артикал{' '}
												<strong>{item.promotedProduct?.name}</strong> по цени од{' '}
												{numberFormat(
													item.promotedProductPrice,
													true,
													2,
													true,
													'RSD'
												)}
											</span>
										}
										two={
											<span>
												Купац је остварио право на{' '}
												{item.promotedProductQuantity} артикла{' '}
												<strong>{item.promotedProduct?.name}</strong> по цени од{' '}
												{numberFormat(
													item.promotedProductPrice,
													true,
													2,
													true,
													'RSD'
												)}
											</span>
										}
										few={
											<span>
												Купац је остварио право на{' '}
												{item.promotedProductQuantity} артикла{' '}
												<strong>{item.promotedProduct?.name}</strong> по цени од{' '}
												{numberFormat(
													item.promotedProductPrice,
													true,
													2,
													true,
													'RSD'
												)}
											</span>
										}
										other={
											<span>
												Купац је остварио право на{' '}
												{item.promotedProductQuantity} артикaла{' '}
												<strong>{item.promotedProduct?.name}</strong> по цени од{' '}
												{numberFormat(
													item.promotedProductPrice,
													true,
													2,
													true,
													'RSD'
												)}
											</span>
										}
									/>
								)}
							</Col>
							<Col>
								<Button
									type="default"
									onClick={() => {
										sale.addItem(
											item.promotedProduct,
											item.promotedProductQuantity,
											item.promotedProductPrice
										);
									}}
								>
									<Trans>Додај</Trans>
								</Button>
							</Col>
						</Row>
					}
					className={styles.availableOffer}
				/>
			</List.Item>
		);
	}

	return (
		<List.Item
			key={item.key}
			actions={[
				<Button.Group>
					<Button
						icon={<i className="fi fi-rr-pencil"></i>}
						onClick={() => {
							(document.activeElement as HTMLElement).blur();

							setEditSaleItem(item);
							setEditModalVisible(true);
						}}
					/>
					<Button
						icon={<i className="fi fi-rr-trash"></i>}
						onClick={() => {
							sale.removeItem(item.key);
							playDelete();
						}}
					/>
				</Button.Group>,
			]}
			className={item.key === sale.activeProductId ? 'selectedProduct' : ''}
		>
			{item.variant?.isResolving || item.product?.isResolving ? (
				<Skeleton />
			) : (
				<>
					<List.Item.Meta
						avatar={
							item.product?.coverImage ? (
								<>
									<img
										src={item.product.coverImage.urls['64x64']}
										alt=""
										className={styles.listImage}
									/>
								</>
							) : (
								<NoImage name={item.product?.name || ''} />
							)
						}
						title={
							<>
								{item.product?.name}&nbsp;&nbsp;
								{item.variant ? (
									<Tag color="magenta" bordered={false}>
										{item.variant.variantName}
									</Tag>
								) : (
									''
								)}
							</>
						}
						description={
							<div className={styles.listDescription}>
								<Row gutter={8}>
									<Col span={8}>
										#{item.variant ? item.variant.sku : item.product?.sku}
									</Col>
									<Col flex={1}>
										{item.totalDiscount > 0 && (
											<>
												{(item.originalPrice || 0) === 0 &&
													item.finalPrice === 0 && (
														<>
															<Tooltip title={t`Артикал нема унету цену`}>
																<Typography.Text type="danger">
																	<i className="fi fi-rr-triangle-warning"></i>
																</Typography.Text>
															</Tooltip>
															&nbsp;
														</>
													)}
												<span className={styles.strikethrough}>
													{numberFormat(item.quantity, false, 3, false)}
													&nbsp;x&nbsp;
													{`${numeral(item.originalPrice).format('0,0.00')}`}
													&nbsp;{item.currencyId || 'RSD'}
												</span>
												<br />
												{numberFormat(item.quantity, false, 3, false)}
												&nbsp;x&nbsp;
												{`${numeral(item.finalPrice).format('0,0.00')}`}
												&nbsp;RSD&nbsp;(-
												{item.totalDiscount}%){' '}
												{item.offerId && (
													<Tooltip title={t`Понуда ${item.offer?.name}`}>
														<i className="fi fi-rr-ticket" />
													</Tooltip>
												)}
												{item.areStickersUsed && (
													<Tooltip title={t`Попуст за искоришћене налепнице`}>
														<i className="fi fi-rr-badge-percent" />
													</Tooltip>
												)}
												{item.arePointsUsed && (
													<Tooltip title={t`Попуст за искоришћене поене`}>
														<i className="fi fi-rr-badge-percent" />
													</Tooltip>
												)}
												{sale.customer?.currentTier && (
													<Tooltip title={t`Попуст за ниво чланства`}>
														<i className="fi fi-rr-badge-percent" />
													</Tooltip>
												)}
												{(item.currencyId || 'RSD') !== 'RSD' && (
													<Tooltip
														title={t`Извршена је конверзија према средњем курсу НБС (${numberFormat(
															item.exchangeRate,
															false,
															4,
															false
														)})`}
													>
														<i className="fi fi-rr-money-transfer-coin-arrow" />
													</Tooltip>
												)}
											</>
										)}
										{!item.totalDiscount &&
											item.originalPrice !== item.finalPrice && (
												<>
													{(item.originalPrice || 0) === 0 &&
														item.finalPrice === 0 && (
															<>
																<Tooltip title={t`Артикал нема унету цену`}>
																	<Typography.Text type="danger">
																		<i className="fi fi-rr-triangle-warning"></i>
																	</Typography.Text>
																</Tooltip>
																&nbsp;
															</>
														)}
													<span className={styles.strikethrough}>
														{numberFormat(item.quantity, false, 3, false)}
														&nbsp;x&nbsp;
														{`${numeral(item.originalPrice).format('0,0.00')}`}
														&nbsp;{item.currencyId || 'RSD'}
													</span>
													<br />
													{numberFormat(item.quantity, false, 3, false)}
													&nbsp;x&nbsp;
													{`${numeral(item.finalPrice).format('0,0.00')}`}
													&nbsp;RSD&nbsp;
													{item.offerId && (
														<Tooltip title={t`Понуда ${item.offer?.name}`}>
															<i className="fi fi-rr-ticket" />
														</Tooltip>
													)}
													{item.areStickersUsed && (
														<Tooltip title={t`Попуст за искоришћене налепнице`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{item.arePointsUsed && (
														<Tooltip title={t`Попуст за искоришћене поене`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{sale.customer?.currentTier && (
														<Tooltip title={t`Попуст за ниво чланства`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{(item.currencyId || 'RSD') !== 'RSD' && (
														<Tooltip
															title={t`Извршена је конверзија према средњем курсу НБС (${numberFormat(
																item.exchangeRate,
																false,
																4,
																false
															)})`}
														>
															<i className="fi fi-rr-money-transfer-coin-arrow" />
														</Tooltip>
													)}
												</>
											)}
										{!item.totalDiscount &&
											item.originalPrice === item.finalPrice && (
												<>
													{(item.originalPrice || 0) === 0 &&
														item.finalPrice === 0 && (
															<>
																<Tooltip title={t`Артикал нема унету цену`}>
																	<Typography.Text type="danger">
																		<i className="fi fi-rr-triangle-warning"></i>
																	</Typography.Text>
																</Tooltip>
																&nbsp;
															</>
														)}
													{numberFormat(item.quantity, false, 3, false)}
													&nbsp;x&nbsp;
													{`${numeral(item.finalPrice).format('0,0.00')} RSD`}
												</>
											)}
									</Col>
									<Col flex={1} className={styles.listItemTotalPrice}>
										{item.totalDiscount > 0 &&
											item.originalPrice !== item.finalPrice && (
												<>
													<span className={styles.strikethrough}>
														{`${numeral(
															evaluate('priceWithoutDiscount * quantity', {
																priceWithoutDiscount: bignumber(
																	item.originalPrice
																),
																quantity: bignumber(item.quantity),
															}).toNumber()
														).format('0,0.00')}`}
														&nbsp;{item.currencyId || 'RSD'}
													</span>
													<br />
													{`${numeral(
														evaluate('finalPrice * quantity', {
															finalPrice: bignumber(item.finalPrice),
															quantity: bignumber(item.quantity),
														}).toNumber()
													).format('0,0.00')}`}
													&nbsp;RSD&nbsp;(-
													{item.totalDiscount}%){' '}
													{item.offerId && (
														<Tooltip title={t`Понуда ${item.offer?.name}`}>
															<i className="fi fi-rr-ticket" />
														</Tooltip>
													)}
													{item.areStickersUsed && (
														<Tooltip title={t`Попуст за искоришћене налепнице`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{item.arePointsUsed && (
														<Tooltip title={t`Попуст за искоришћене поене`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{sale.customer?.currentTier && (
														<Tooltip title={t`Попуст за ниво чланства`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{(item.currencyId || 'RSD') !== 'RSD' && (
														<Tooltip
															title={t`Извршена је конверзија према средњем курсу НБС (${numberFormat(
																item.exchangeRate,
																false,
																4,
																false
															)})`}
														>
															<i className="fi fi-rr-money-transfer-coin-arrow" />
														</Tooltip>
													)}
												</>
											)}
										{!item.totalDiscount &&
											item.originalPrice !== item.finalPrice && (
												<>
													<span className={styles.strikethrough}>
														{`${numeral(
															evaluate('originalPrice * quantity', {
																originalPrice: bignumber(item.originalPrice),
																quantity: bignumber(item.quantity),
															}).toNumber()
														).format('0,0.00')}`}
														&nbsp;{item.currencyId || 'RSD'}
													</span>
													<br />
													{`${numeral(
														evaluate('finalPrice * quantity', {
															finalPrice: bignumber(item.finalPrice),
															quantity: bignumber(item.quantity),
														}).toNumber()
													).format('0,0.00')}`}
													&nbsp;RSD&nbsp;
													{item.offerId && (
														<Tooltip title={t`Понуда ${item.offer?.name}`}>
															<i className="fi fi-rr-ticket" />
														</Tooltip>
													)}
													{item.areStickersUsed && (
														<Tooltip title={t`Попуст за искоришћене налепнице`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{item.arePointsUsed && (
														<Tooltip title={t`Попуст за искоришћене поене`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{sale.customer?.currentTier && (
														<Tooltip title={t`Попуст за ниво чланства`}>
															<i className="fi fi-rr-badge-percent" />
														</Tooltip>
													)}
													{(item.currencyId || 'RSD') !== 'RSD' && (
														<Tooltip
															title={t`Извршена је конверзија према средњем курсу НБС (${numberFormat(
																item.exchangeRate,
																false,
																4,
																false
															)})`}
														>
															<i className="fi fi-rr-money-transfer-coin-arrow" />
														</Tooltip>
													)}
												</>
											)}

										{!item.totalDiscount &&
											item.originalPrice === item.finalPrice &&
											`${numeral(
												evaluate('finalPrice * quantity', {
													finalPrice: bignumber(item.finalPrice),
													quantity: bignumber(item.quantity),
												}).toNumber()
											).format('0,0.00')} RSD`}
									</Col>
								</Row>
							</div>
						}
					/>
				</>
			)}
		</List.Item>
	);
};

export const ListItem = observer(ListItemBase);
