import Bugsnag from '@bugsnag/browser';
import { Trans, t } from '@lingui/macro';
import { message } from 'antd';
import axios from 'axios';
import { Dayjs } from 'dayjs';
import { action, flow, observable } from 'mobx';
import Deferred from 'promise-deferred';

import { CreateStore } from './Crud.mobx';
import stores from './index.mobx';
import { StaticComponents } from '../components/StaticComponents';
import {
	getSdcFieldNamesMap,
	getSdcValidationErrorsMap,
} from '../constants/errors';
import { vsdcUrl } from '../constants/esdc';
import {
	InvoiceType,
	PaymentType,
	TransactionType,
} from '../constants/invoice';
import { v2Client } from '../store/client';
let statusInterval;

const request = window['electron']
	? async (config) => {
			const response = await window['electron'].esdcRequest(config);
			if (response.error) {
				return Promise.reject({ response: { data: response.error } });
			}

			return response;
	  }
	: axios;

const { Entity } = CreateStore({
	name: 'sdc',
	type: 'entity',
	paginated: false,
	persistFields: ['pfx', 'uid', 'password', 'pac', 'useVsdc', 'url'],
});

export type Payment = {
	amount: number;
	paymentType: PaymentType;
};

export type Item = {
	gtin?: string;
	name: string;
	quantity: number;
	unitPrice: number;
	labels?: string[];
	totalAmount: number;
};

export type InvoiceData = {
	dateAndTimeOfIssue?: Dayjs;
	invoiceType: InvoiceType;
	transactionType: TransactionType;
	payment: Payment[];
	cashier?: string;
	buyerId?: string;
	buyerCostCenterId?: string;
	invoiceNumber?: string;
	referentDocumentNumber?: string;
	referentDocumentDT?: Dayjs;
	items: Item[];
	options?: {
		omitQRCodeGen: '0' | '1';
		omitTextualRepresentation: '0' | '1';
	};
};

export type ESDCStatus = {
	available?: boolean;
	isPinRequired?: boolean;
	auditRequired?: boolean;
	lastInvoiceNumber?: string;
	protocolVersion?: string;
	softwareVersion?: string;
	hardwareVersion?: string;
	deviceSerialNumber?: string;
	make?: string;
	model?: string;
	uid?: string;
	secureElementVersion?: string;
	mssc?: string[];
	gsc?: string[];
	supportedLanguages?: string[];
};

let retryDeferred = new Deferred();

retryDeferred.resolve();

function parseError(data) {
	const SDC_VALIDATION_ERRORS_MAP = getSdcValidationErrorsMap();
	const SDC_FIELD_NAMES_MAP = getSdcFieldNamesMap();

	if (data === 'You have to provide valid PAC value') {
		return [
			t`ПАК који сте унели није исправан. Проверите да ли сте унели исправан ПАК у подешавањима виртуалног процесора фискалних рачуна.`,
		];
	}
	if (data?.modelState) {
		return data.modelState.map((item) => {
			const [, property, , propertyIndex, subProperty, , subPropertyIndex] =
				item.property.match(/^(\w+)(\[(\d+)\])?\.?(\w+)?(\[(\d+)\])?$/);

			const errors = item.errors.map((error) => {
				return SDC_VALIDATION_ERRORS_MAP[error] || error;
			});
			const propertyName = SDC_FIELD_NAMES_MAP[property] || property;

			if (!subProperty) {
				if (typeof propertyIndex === 'undefined') {
					return `${propertyName}: ${errors.join(', ')}`;
				}

				return `${propertyName} (${Number(propertyIndex) + 1}): ${errors.join(
					', '
				)}`;
			}

			const subPropertyName =
				SDC_FIELD_NAMES_MAP[`${property}.${subProperty}`] ||
				`${property}.${subProperty}`;
			if (typeof subPropertyIndex !== 'undefined') {
				return `${propertyName} (${
					Number(propertyIndex) + 1
				}), ${subPropertyName}: ${errors.join(', ')}`;
			}

			return `${propertyName} (${
				Number(propertyIndex) + 1
			}), ${subPropertyName} (${Number(subPropertyIndex) + 1}): ${errors.join(
				', '
			)}`;
		});
	}

	Bugsnag.addMetadata('vsdc', { data: JSON.stringify(data) });
	Bugsnag.notify('Greška prilikom komunikacije sa PFR-om.');

	return [t`Грешка приликом комуникације са процесором фискалних рачуна.`];
}

class Sdc extends Entity {
	@observable enterPinModalVisible = false;
	@observable statusModalVisible = false;
	@observable currentStatus = '';
	@observable pfx?: string;
	@observable uid?: string;
	@observable password?: string;
	@observable pac?: string;
	@observable url?: string;
	@observable useVsdc = false;
	@observable esdcStatus?: ESDCStatus;
	@observable currentESDCError?: string | null;
	@observable loginShown = false;

	constructor(data, parent) {
		super(parent);
		this.init(data);
	}

	@action.bound
	setESDCError(error: string | null) {
		this.currentESDCError = error;
	}

	@action.bound
	setLoginShown(loginShown: boolean) {
		this.loginShown = loginShown;
	}

	@action.bound
	resolveDeferred() {
		retryDeferred.resolve();
	}

	@action.bound
	rejectDeferred() {
		retryDeferred.reject();
	}

	@flow.bound
	*pinVerify(pin: string) {
		let response;
		try {
			response = yield request({
				url: `${this.url}/api/v3/pin`,
				data: pin,
				method: 'POST',
			});
		} catch (e) {
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: t`Локални процесор фискалних рачуна није доступан. Проверите да ли је исправно конфигурисан УРЛ до локалног процесора фискалних рачуна и да је он покренут.`,
			});
			throw e;
		}

		if (response.data !== '0100') {
			this.setESDCError(response.data);
		} else {
			this.setESDCError(null);
			this.resolveDeferred();
		}
	}

	@flow.bound
	*sdcRequest(method, url: string, data: Record<string, unknown>) {
		const language =
			['sr-Cyrl-RS', 'sr-Latn-RS'].includes(
				stores.stores.currentStore?.language
			) || !stores.stores.currentStore?.language
				? 'sr-Cyrl-RS'
				: 'en-US';

		let useVsdc = false;
		let useDemo = false;
		const invoiceRequest = document
			? document.querySelector('#invoiceRequest')
			: null;
		const button = document
			? (document.querySelector('#taxcore_sign_element') as HTMLButtonElement)
			: null;
		try {
			if (process.env.REACT_APP_IS_DEMO) {
				useDemo = true;
			} else if (this.useVsdc) {
				if (window['electron']) {
					yield window['electron'].vsdcRequest({
						url: `${vsdcUrl}/api/v3/attention`,
						method: 'get',
					});
				} else {
					if (!invoiceRequest) {
						throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE');
					}
				}
				useVsdc = true;
			} else if (this.url) {
				try {
					yield request({
						url: `${this.url}/api/v3/attention`,
						method: 'get',
					});
				} catch (e) {
					throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE');
				}
			} else {
				throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_NOT_CONFIGURED');
			}
		} catch (e) {
			if (this.url) {
				try {
					yield request({
						url: `${this.url}/api/v3/attention`,
						method: 'get',
					});
				} catch (e) {
					StaticComponents.notification.error({
						message: t`Грешка`,
						description: t`Локални процесор фискалних рачуна није доступан. Проверите да ли је исправно конфигурисан УРЛ до локалног процесора фискалних рачуна и да је он покренут.`,
					});
					throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE');
				}
			} else {
				StaticComponents.notification.error({
					message: t`Грешка`,
					description: t`Ни локални ни виртуални процесор фискалних рачуна нису конфигурисани. Да бисте били у могућности да издајете рачуне, морате конфигурисати локални и/или виртуални процесор фискалних рачуна.`,
				});
				throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_NOT_CONFIGURED');
			}
		}

		if (useDemo) {
			try {
				const response = yield v2Client({
					url: `/fiscalization/demo/se-proxy${url}`,
					method,
					data,
					headers: {
						'Accept-Language': language,
					},
				});

				if (response.error) {
					const errors = parseError(response.error);

					StaticComponents.notification.error({
						message: t`Грешка`,
						description: (
							<>
								<Trans>
									Виртуални процесор фискалних рачуна је вратио следеће грешке:
								</Trans>
								<br />
								<br />
								{errors.join(<br />)}
							</>
						),
						key: 'vsdc-error',
					});
					throw new Error('ERROR_BAD_REQUEST_SDC_ERROR');
				}

				return response.data;
			} catch (e) {
				throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE');
			}
		} else if (useVsdc) {
			if (window['electron']) {
				try {
					const response = yield window['electron'].vsdcRequest({
						url: `${vsdcUrl}${url}`,
						method,
						headers: {
							PAC: this.pac,
							'Accept-Language': language,
						},
						data: JSON.stringify(data),
					});

					if (response.error) {
						const errors = parseError(response.error);

						StaticComponents.notification.error({
							message: t`Грешка`,
							description: (
								<>
									<Trans>
										Виртуални процесор фискалних рачуна је вратио следеће
										грешке:
									</Trans>
									<br />
									<br />
									{errors.join(<br />)}
								</>
							),
							key: 'vsdc-error',
						});
						throw new Error('ERROR_BAD_REQUEST_SDC_ERROR');
					}

					return response;
				} catch (e) {
					throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE');
				}
			} else {
				if (!invoiceRequest || !button) {
					throw new Error('vsdc failed to load');
				}

				(invoiceRequest as HTMLTextAreaElement).value = JSON.stringify({
					...data,
					lang: language,
					PAC: this.pac,
				});
				if (document) {
					return new Promise((resolve, reject) => {
						// let timeout;
						const el = document.createElement('script');
						el.src = `${vsdcUrl}/api/v3/attention`;
						el.style.position = 'absolute';
						el.style.left = '-9999px';
						document.body.appendChild(el);
						el.onload = function () {
							document.body.removeChild(el);
							function listener(e) {
								(invoiceRequest as HTMLTextAreaElement).value = '';
								if (e.origin !== vsdcUrl) {
									return;
								}

								try {
									const { data } = e;
									let parsed;
									try {
										parsed = JSON.parse(data);
									} catch (err) {
										Bugsnag.addMetadata('vsdc', { data: JSON.stringify(data) });
										Bugsnag.notify(err);
										StaticComponents.notification.error({
											message: t`Грешка`,
											description: (
												<Trans>
													Догодила се грешка приликом комуникације са виртуалним
													процесором фискалних рачуна. Молимо Вас да покушате
													поново.
												</Trans>
											),
											key: 'vsdc-error',
										});
										Bugsnag.clearMetadata('vsdc');
										return reject(new Error('ERROR_BAD_REQUEST_SDC_ERROR'));
									}
									if (parsed?.response?.sdcDateTime) {
										resolve(parsed.response);
										window.removeEventListener('message', listener);
									} else {
										window.removeEventListener('message', listener);
										const errors = parseError(parsed?.response);
										StaticComponents.notification.error({
											message: t`Грешка`,
											description: (
												<>
													<Trans>
														Виртуални процесор фискалних рачуна је вратио
														следеће грешке:
													</Trans>{' '}
													<br />
													<br />
													{errors.join(<br />)}
												</>
											),
											key: 'vsdc-error',
										});
										return reject(new Error('ERROR_BAD_REQUEST_SDC_ERROR'));
									}
								} catch (e) {
									console.error('error', e);
								}
							}

							window.addEventListener('message', listener);
							button.click();
						};
						el.onerror = function (error) {
							Bugsnag.addMetadata('vsdc', {
								error: JSON.stringify(error),
								url,
								method,
								data,
							});
							StaticComponents.notification.error({
								message: t`Грешка`,
								description: t`Виртуални процесор фискалних рачуна није доступан. Проверите да ли је исправно инсталиран и одабран сертификат, и да ли је ПАК исправан.`,
								key: 'vsdc-error',
							});
							document.body.removeChild(el);
							reject(new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE'));
						};
					});
				}
			}
		}

		let response;

		try {
			response = yield request({
				url: `${this.url}${url}`,
				data: JSON.stringify(data),
				method,
				headers: {
					'Accept-Language': language,
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			});
		} catch (e) {
			const errors = parseError(e.response.data);
			StaticComponents.notification.error({
				message: t`Грешка`,
				description: (
					<>
						<Trans>
							Локални процесор фискалних рачуна је вратио следеће грешке:
						</Trans>
						<br />
						<br />
						{errors.join(<br />)}
					</>
				),
				key: 'sdc-error',
			});
			throw new Error('ERROR_BAD_REQUEST_SDC_ERROR');
		}

		if (typeof response.data === 'string') {
			this.setESDCError(response.data);
			retryDeferred = new Deferred();
			try {
				yield retryDeferred.promise;
				this.setESDCError(null);
				return (this as any).sdcRequest(method, url, data);
			} catch (e) {
				this.setESDCError(null);
				throw new Error('ERROR_SERVICE_UNAVAILABLE_SDC_UNAVAILABLE');
			}
		}
		return response.data;
	}

	@flow.bound
	*createInvoice(data: InvoiceData) {
		return yield this.sdcRequest('post', `/api/v3/invoices`, {
			...data,
			referentDocumentDT: data.referentDocumentDT
				? data.referentDocumentDT.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
				: undefined,
			dateAndTimeOfIssue: data.dateAndTimeOfIssue
				? data.dateAndTimeOfIssue.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
				: undefined,
			paymentChange: undefined,
			items: data.items.map((item) => ({
				...item,
				unit: undefined,
				id: undefined,
				isPieceUnitOfMeasure: undefined,
				sku: undefined,
			})),
		});
	}

	@flow.bound
	*getESDCStatus() {
		try {
			if (this.url) {
				const response = yield request({
					url: `${this.url}/api/v3/status`,
					method: 'get',
				});

				const newStatus = {
					available: true,
					isPinRequired: response.data.isPinRequired,
					auditRequired: response.data.auditRequired,
					lastInvoiceNumber: response.data.lastInvoiceNumber,
					protocolVersion: response.data.protocolVersion,
					softwareVersion: response.data.softwareVersion,
					hardwareVersion: response.data.hardwareVersion,
					deviceSerialNumber: response.data.deviceSerialNumber,
					make: response.data.make,
					model: response.data.model,
					uid: response.data.uid,
					secureElementVersion: response.data.secureElementVersion,
					mssc: response.data.mssc,
					gsc: response.data.gsc,
					supportedLanguages: response.data.supportedLanguages,
				};

				if (
					response.data.isPinRequired &&
					response.data.uid &&
					!this.loginShown
				) {
					this.setLoginShown(true);
					this.setESDCError('1500');
				} else if (
					this.loginShown &&
					!response.data.isPinRequired &&
					response.data.uid
				) {
					this.setLoginShown(false);
				}

				this.esdcStatus = newStatus;
			}
		} catch (e) {
			if (this.esdcStatus?.available) {
				this.esdcStatus = { available: false };
			}
		} finally {
			return this.esdcStatus;
		}
	}

	@flow.bound
	*saveConfiguration(data) {
		this.isUpdating = true;
		if (window['electron']) {
			if (data.pfx) {
				try {
					const response = yield window['electron'].checkPfx(data);
					this.pfx = data.pfx;
					this.uid = response.uid;
				} catch (e) {
					this.isUpdating = false;
					throw new Error(t`PFX фајл не постоји или није исправан`);
				}
			} else {
				this.pfx = null;
			}

			this.password = data.password;
			this.useVsdc = data.useVsdc;
			this.pac = data.pac;

			if (typeof data.url !== 'undefined') {
				this.url = data.url;
			}
		} else {
			this.url = data.url;
			this.pfx = data.pfx;
			this.pac = data.pac;
			this.useVsdc = data.useVsdc;
		}
		this.getESDCStatus();

		this.isUpdating = false;
	}

	@flow.bound
	*browsePfx() {
		try {
			const pfx = yield window['electron'].browsePfx();
			return pfx.path;
		} catch (e) {
			throw new Error(t`PFX фајл не постоји или није исправан`);
		}
	}

	async afterAuth(authenticated: boolean) {
		if (authenticated) {
			if (window['electron']) {
				window['electron'].initPfx(this.toPlain());
			}

			statusInterval = setInterval(() => {
				this.getESDCStatus();
			}, 5000);
		} else {
			clearInterval(statusInterval);
		}
	}
}

export { Sdc };
