import { Trans, t } from '@lingui/macro';
import { Col, Row } from 'antd';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import round from 'lodash/round';
import { useMemo } from 'react';

import styles from './PricechangePrint.module.less';
import numberFormat from '../../../../../../lib/numberFormat';
import { deductTax } from '../../../../../../lib/tax';
import stores from '../../../../../../stores/index.mobx';
import { Pricechange } from '../../../../../../stores/Pricechange.mobx';

interface Props {
	pricechange: Pricechange;
}

export default function PricechangePrint({ pricechange }: Props) {
	const {
		company,
		products: { getById: getProductById },
		warehouses: { byId: warehouseById },
	} = stores;

	function formatDate(date: string | Dayjs, dateOnly = false): string {
		if (dateOnly) {
			return dayjs(date).format('DD.MM.YYYY');
		}

		return dayjs(date).format('DD.MM.YYYY HH:mm:ss');
	}

	const [totalsDataSource, taxesDetailsDataSource] = useMemo(() => {
		const totalPurchaseAmount = round(
			pricechange.items?.reduce(
				(acc, item) => acc + Number(item.purchaseAmount || 0),
				0
			),
			4
		);

		const totalSaleAmountBeforePricechange = round(
			pricechange.items?.reduce(
				(acc, item) =>
					acc +
					Number(item.oldSalePriceWithVat || 0) * Number(item.quantity || 0),
				0
			),
			4
		);

		const totalSaleAmountAfterPricechange = round(
			pricechange.items?.reduce(
				(acc, item) => acc + Number(item.saleAmountWithVat || 0),
				0
			),
			4
		);

		const totalPriceDifference = round(
			pricechange.items?.reduce(
				(acc, item) =>
					acc + Number(item.priceDifference || 0) * Number(item.quantity || 0),
				0
			),
			4
		);

		const taxesDetails = pricechange.items?.reduce((acc, item) => {
			const amountWithoutVat = deductTax(item.priceDifference, item.taxRate);

			acc[item.taxRate] = acc[item.taxRate] || {
				priceDifference: 0,
				taxAmount: 0,
			};

			acc[item.taxRate].priceDifference = round(
				acc[item.taxRate].priceDifference +
					item.priceDifference * item.quantity,
				4
			);

			acc[item.taxRate].taxAmount = round(
				acc[item.taxRate].taxAmount +
					(item.priceDifference - amountWithoutVat) * item.quantity,
				4
			);

			return acc;
		}, {}) as Record<
			number,
			{
				priceDifference: number;
				taxAmount: number;
			}
		>;

		const taxesDetailsRows = [];

		Object.keys(taxesDetails || {}).forEach((tax) => {
			taxesDetailsRows.push({
				title: t`Порез на додату вредност ${numberFormat(
					tax,
					false,
					2,
					true
				)}%:`,
				priceDifference: numberFormat(
					taxesDetails[tax].priceDifference,
					false,
					2,
					true
				),
				taxAmount: numberFormat(taxesDetails[tax].taxAmount, false, 2, true),
				key: `tax${tax}`,
			});
		});

		return [
			[
				{
					title: t`Набавна вредност:`,
					amount: numberFormat(totalPurchaseAmount, false, 2, true),
				},
				{
					title: t`Вредност пре нивелације:`,
					amount: numberFormat(
						totalSaleAmountBeforePricechange,
						false,
						2,
						true
					),
				},
				{
					title: t`Нова вредност`,
					amount: numberFormat(totalSaleAmountAfterPricechange, false, 2, true),
				},
				{
					title: t`Разлика у вредности:`,
					amount: numberFormat(totalPriceDifference, false, 2, true),
				},
			],
			taxesDetailsRows,
		];
	}, [pricechange?.items]);

	return (
		<div className={styles.page}>
			<div>
				<div>
					<h3>{company.name}</h3>
					{company.address && (
						<>
							{company.address}
							<br />
						</>
					)}
					{company.city && (
						<>
							{company.zip && <>{company.zip}</>}
							{company.city}
							<br />
						</>
					)}
					{company.email && (
						<>
							{company.email}
							<br />
						</>
					)}
					{company.phone && (
						<>
							{company.phone}
							<br />
						</>
					)}
					<Trans>ПИБ</Trans>: {company.tin}
					<br />
					<Trans>Матични број</Trans>: {company.companyRegistrationNumber}
					<br />
					<br />
				</div>
			</div>
			<div className="calculation">
				<div className="calculationDetails">
					<h3>
						<Trans>Нивелација {pricechange.number}</Trans>
					</h3>
					{pricechange.date && (
						<>
							<strong>
								<Trans>Датум нивелације: </Trans>
							</strong>
							{formatDate(pricechange.date, true)}
							<br />
						</>
					)}
				</div>
				<h4>
					<Trans>Ставке нивелације</Trans>
				</h4>
				<div className="calculationBlock calculationItems">
					<table>
						<thead>
							<tr>
								<th style={{ width: 40 }}>
									<Trans>РБ</Trans>
								</th>
								<th>
									<Trans>Складиште</Trans>
								</th>
								<th>
									<Trans>Шифра</Trans>
								</th>
								<th>
									<Trans>Назив артикла</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Количина</Trans>
								</th>
								<th>
									<Trans>ЈМ</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Наб. цена</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Наб. вред.</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>ПДВ</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Цена пре нив.</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Вредност пре нив.</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Нова цена</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Нова вредност</Trans>
								</th>
								<th className={'alignRight'}>
									<Trans>Раз. у вредности</Trans>
								</th>
							</tr>
						</thead>
						<tbody>
							{pricechange.items.map((item, index) => {
								const warehouse = warehouseById[item.warehouseId];
								const product = getProductById(item.productId);
								const sku = product?.sku;
								const name = product.parent
									? `${product.parent.name} ${product.variantName}`
									: product?.name;
								return (
									<tr>
										<td>{index + 1}</td>
										<td>{warehouse?.name}</td>
										<td>{sku}</td>
										<td>{name}</td>
										<td className={'alignRight'}>
											{numberFormat(item.quantity, false, 3, false)}
										</td>
										<td>{item.productUnit}</td>
										<td className={'alignRight'}>
											{numberFormat(item.purchasePrice, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(item.purchaseAmount, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(item.taxRate, false, 2, true)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(item.oldSalePriceWithVat, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(
												item.oldSalePriceWithVat * item.quantity,
												false,
												4,
												true
											)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(item.salePriceWithVat, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(item.saleAmountWithVat, false, 4, true)}
										</td>
										<td className={'alignRight'}>
											{numberFormat(item.priceDifference, false, 4, true)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<h4>
					<Trans>Рекапитулација нивелације</Trans>
				</h4>
				<Row gutter={8}>
					<Col span={7}>
						<table>
							<tbody>
								{totalsDataSource.map((item, index) => {
									return (
										<tr>
											<td className={styles.heading}>{item.title}</td>
											<td>{item.amount}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
					<Col span={17}>
						<table>
							<thead>
								<tr>
									<th></th>
									<th>
										<Trans>Разлика у цени</Trans>
									</th>
									<th>
										<Trans>Вредност пореза</Trans>
									</th>
								</tr>
							</thead>
							<tbody>
								{taxesDetailsDataSource.map((item, index) => {
									return (
										<tr>
											<td
												align="right"
												className={`${styles.heading} ${item.className}`}
											>
												{item.title}
											</td>
											<td className={item.className}>{item.priceDifference}</td>
											<td className={item.className}>{item.taxAmount}</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</Col>
				</Row>
			</div>
		</div>
	);
}
