import html2canvas, { Options as THtml2canvasOptions } from 'html2canvas';
import jsPDF, { jsPDFOptions as TJsPDFOptions } from 'jspdf';

type TGeneratePDFOptions = {
	fileName?: string;
	heightPerPage?: number;
	html2canvasOptions?: Partial<THtml2canvasOptions>;
	jsPDFOptions?: Partial<TJsPDFOptions>;
	onSuccess?: () => void;
	onError?: (error: any) => void;
	onSettled?: () => void;
	onBeforeCapture?: () => void;
};

const htmlToPdf = async (
	element: HTMLElement,
	options: TGeneratePDFOptions
) => {
	const { heightPerPage = 840, html2canvasOptions, jsPDFOptions } = options;
	if (!element) return;
	const CANVAS_CHROME_FF_LIMIT = 32_767;
	const CANVAS_SAFARI_LIMIT = 4096;
	element.style.gap = '0px';

	const MAX_CANVAS_HEIGHT =
		navigator.userAgent.includes('Chrome') ||
		navigator.userAgent.includes('Firefox')
			? (CANVAS_CHROME_FF_LIMIT % heightPerPage) * heightPerPage
			: (CANVAS_SAFARI_LIMIT % heightPerPage) * heightPerPage;

	options.onBeforeCapture?.();
	try {
		const pdf = new jsPDF({
			orientation: 'l',
			unit: 'px',
			format: [element.offsetWidth, heightPerPage],
			compress: true,
			...jsPDFOptions,
		});

		const pageSize = pdf.internal.pageSize;

		const totalHeight = element.scrollHeight;
		const totalWidth = element.scrollWidth;
		let capturedHeight = 0;

		while (capturedHeight < totalHeight) {
			const remainingHeight = totalHeight - capturedHeight;
			const captureHeight = Math.min(remainingHeight, MAX_CANVAS_HEIGHT);

			const canvas = await html2canvas(element, {
				windowWidth: totalWidth,
				windowHeight: totalHeight,
				x: 0,
				y: capturedHeight,
				width: totalWidth,
				height: captureHeight,
				...html2canvasOptions,
			});

			const imgData = canvas.toDataURL('image/jpeg');
			// if canvas height is smaller than page height, calculate page height using pdf ratio
			const pageHeight = (canvas.height * pageSize.width) / canvas.width;

			pdf.addImage(
				imgData,
				'JPEG',
				0,
				0,
				pageSize.width,
				pageHeight,
				undefined,
				'FAST'
			);

			capturedHeight += captureHeight;
		}

		return pdf;
	} catch (error) {
		options.onError?.(error);
	} finally {
		options.onSettled?.();
	}
};

export default htmlToPdf;
