import { Trans } from '@lingui/macro';
import { Dropdown, Menu, Card, Space, Badge, Button } from 'antd';
import Avatar from 'boring-avatars';
import complementaryColors from 'complementary-colors';
import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router-dom';

import { rgbToHex } from '../../lib/colors';
import SelectStoreDropdown from '../../modules/Application/POS/Components/SelectStoreDropdown';
import stores from '../../stores/index.mobx';
import { LoadingReference } from '../../stores/transformers/Reference';
import { useDrawer } from '../Page';
import SocketStatus from '../SocketStatus';
import './style.less';

const color = new complementaryColors('#7E89C8');
const colors = color.splitComplementary().map((c) => rgbToHex(c.r, c.g, c.b));
function UserMenu() {
	const {
		users: { authenticatedUser, logout },
	} = stores;

	const location = useLocation();

	const [, open] = useDrawer('edit/settings/users');

	if (!authenticatedUser || authenticatedUser instanceof LoadingReference) {
		return null;
	}

	const { initials, fullName, email, isSuperAdmin } = authenticatedUser;

	const userMenu = (
		<Menu className="erp-userMenu-dropdown">
			<Card bordered={false}>
				<Card.Meta
					avatar={
						<div className="avatarWrapper big">
							<Avatar
								size={48}
								name={fullName}
								title={true}
								variant="marble"
								colors={colors}
							></Avatar>
							<span className="initials">{initials}</span>
						</div>
					}
					title={fullName}
					description={email}
				/>
			</Card>
			<Menu.Divider />
			<Space className="store-dropdown">
				<i className="fi fi-rr-shop"></i>
				<Trans>Продајно место:</Trans>
				<SelectStoreDropdown />
			</Space>
			<Menu.Divider />
			<Menu.Item
				key="profile"
				onClick={() => {
					open(authenticatedUser.id);
				}}
			>
				<i className="fi fi-rr-user"></i>
				<Trans>Профил</Trans>
			</Menu.Item>
			<Menu.Item key="settings">
				<Link to="/app/settings">
					<i className="fi fi-rr-settings"></i>
					<Trans>Подешавања</Trans>
				</Link>
			</Menu.Item>
			{isSuperAdmin && (
				<Menu.Item key="administration">
					<Link to="/app/administration">
						<i className="fi fi-rr-admin-alt"></i>
						<Trans>Администрација</Trans>
					</Link>
				</Menu.Item>
			)}
			<Menu.Divider />
			<Menu.Item key="logout" onClick={logout}>
				<i className="fi fi-rr-sign-out-alt"></i>
				<Trans>Одјави се</Trans>
			</Menu.Item>
		</Menu>
	);

	return (
		<span className="erp-userMenu">
			<Dropdown overlay={userMenu} trigger={['click']} placement="bottomRight">
				<div className="avatarWrapper">
					<SocketStatus>
						<div>
							<Avatar
								size={32}
								name={fullName}
								variant="marble"
								colors={colors}
							></Avatar>
						</div>
					</SocketStatus>
					<span className="initials">{initials}</span>
				</div>
			</Dropdown>
		</span>
	);
}

export default observer(UserMenu);
