import { Table, Spin, Card, Button } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { Product } from '../../../../../../stores/Product.mobx';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import numberFormat from '../../../../../../lib/numberFormat';
import { t } from '@lingui/macro';

import styles from '../../Products.module.less';
import { useDrawer } from '../../../../../../components/Page';
import classNames from 'classnames';
interface Props {
	product?: Product;
}

const PurchasesViewBase = (props: Props) => {
	const { product } = props;

	const [purchases, setPurchases] = useState([]);

	useEffect(() => {
		flowResult(product?.purchases?.fetchAll(product.id, 5))?.then((data) => {
			setPurchases(data);
		});
	}, [product]);
	const [, openCalculation, , , , CalculationEditDrawer] = useDrawer(
		'edit/stocks/calculation|purchases-view'
	);
	return (
		<>
			<Spin spinning={product?.purchases?.isFetching}>
				<Card className={classNames(styles.card, styles.tableCard)}>
					<Table
						size="small"
						dataSource={purchases || []}
						scroll={{ x: 800 }}
						columns={[
							{
								title: t`Датум`,
								key: 'calculation.date',
								render(record) {
									return dayjs(record.calculation.date).format('DD.MM.YYYY');
								},
								width: 80,
							},
							{
								title: t`Количина`,
								dataIndex: 'quantity',
								key: 'quantity',
								width: 100,
								render(text) {
									return numberFormat(text, false, 3, false);
								},
							},
							{
								title: t`Износ`,
								key: 'purchaseAmount',
								render(record) {
									return numberFormat(record.purchaseAmount, true, 2, true);
								},
								width: 150,
							},
							{
								title: t`Број калкулације`,
								key: 'calculation.number',
								render: (record) => (
									<Button
										type="link"
										className={styles.columnLink}
										onClick={() => openCalculation(record.calculation.id)}
									>
										{record.calculation.number}
									</Button>
								),
							},
							{
								title: t`Добављач`,
								dataIndex: ['calculation', 'partner', 'name'],
								key: 'partnerId',
								ellipsis: true,
								width: 200,
							},
							{
								title: t`Складиште`,
								dataIndex: ['warehouse', 'name'],
								ellipsis: true,
								key: 'warehouseId',
							},
						]}
						pagination={false}
					/>
				</Card>
			</Spin>
			<CalculationEditDrawer location="purchases-view" />
		</>
	);
};

export const PurchasesView = observer(PurchasesViewBase);
