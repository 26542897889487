import { t, Trans } from '@lingui/macro';
import { Button, Form, InputNumber, Modal } from 'antd';
import { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { Customer } from '../../../../stores/LocalSale.mobx';

interface Props {
	visible: boolean;
	onClose: () => void;
	onSubmit: (type: 'stickers' | 'points', quantity: number) => void;
	customer: Customer;
}

function UsePointsModal({ visible, onClose, onSubmit, customer }: Props) {
	const [form] = Form.useForm();
	const inputRef = useRef(null);

	const submit = useCallback(() => {
		form.validateFields().then((values) => {
			console.log(values);
			onSubmit('points', Number(values.quantity));
			onClose();
		});
	}, [form, onClose, onSubmit]);

	useEffect(() => {
		if (visible) {
			const intervalId = setInterval(() => {
				if (inputRef.current && inputRef.current !== document.activeElement) {
					inputRef.current?.select();
				}
			}, 100);
			return () => {
				clearInterval(intervalId);
			};
		} else {
			setTimeout(() => form.resetFields());
		}
	}, [visible, form]);

	const initialValue = useMemo(() => {
		return customer?.totalPoints;
	}, [customer?.totalPoints]);

	return (
		<Modal
			centered
			open={visible}
			title={t`Коришћење поена`}
			onCancel={onClose}
			width={400}
			footer={
				<Button type="primary" onClick={() => form.submit()}>
					<Trans>Потврди</Trans>
				</Button>
			}
		>
			<Form
				form={form}
				validateTrigger="onFinish"
				layout="vertical"
				onFinish={submit}
			>
				<Form.Item
					style={{ marginBottom: 0 }}
					name="quantity"
					label={t`Број поена`}
					rules={[
						{
							required: true,
							message: t`Унесите број поена`,
						},
						{
							type: 'number',
							validator: async (rule, value) => {
								if (value < 0 || value > customer.totalPoints) {
									throw new Error(
										t`Број поена мора бити између 0 и ${customer.totalPoints}`
									);
								}
							},
						},
					]}
					initialValue={initialValue}
				>
					<InputNumber
						ref={inputRef}
						type="number"
						style={{ width: '100%' }}
						onPressEnter={() => form.submit()}
					/>
				</Form.Item>
			</Form>
		</Modal>
	);
}

export default memo(UsePointsModal);
